import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from '../../../../../services/utils.service';
import { MethodsUtilsService } from '../../../../../services/methodsUtils.service';
import { FormularioBaseComponent } from '../../../../utils/formularioBase.component';
import { PopByProduct } from 'src/models/popByProduct';
import { PopByProductService } from 'src/services/popByProduct.service';

@Component({
  selector: 'app-form-pop-by-products',
  templateUrl: './form.component.html',
  styleUrls: []
})
export class FormOptionsPopByProductComponent  extends FormularioBaseComponent implements OnInit {

  public page = 'PopByProduct';
  public title = 'PopByProduct.Create';
  public popByProduct = new PopByProduct();
  public titleButton = 'Save';
  public regionId = null;
  public brandID = null;
  public categoryID = null;

  constructor(
		private route: ActivatedRoute,
		private popByProductService: PopByProductService,
		private router: Router,
		public utilsService: UtilsService,
		public methodsUtilsService: MethodsUtilsService,
	) {
		super(utilsService, methodsUtilsService);
  }
  
  ngOnInit() {
    const self = this;
    self.getRegionsList();
    self.getListCategories();
    self.getListPopTipologies();
		self.param = self.route.params.subscribe(
			params => {
				if (params['id']) {
					self.title = 'PopByProduct.Update';
					self.titleButton = 'Update';
					self.utilsService.debugConsole(params['id']);
					self.getPopByProduct(params['id']);
				}
			}
		);
  }

  private getPopByProduct (id: any): void {
    const self = this;
		self.popByProductService.getResult(id).then(
			function (response) {
				if (response === false) { return; }
        self.popByProduct.setPopByProduct(self.popByProductService.getVarResults());
        self.regionId = self.popByProduct.country.regionId;
        self.brandID = self.popByProduct.product.brandID;
        self.categoryID = self.popByProduct.product.categoryID;
        self.selectedRegion();
        self.selectedCountry();
        //self.getBrandsByCountryID(self.popByProduct.country.countryID);
        self.getListProductsFilters(self.regionId, self.brandID, self.popByProduct.product.categoryID);
			}
		);
  }

  public selectedRegion(): void {
    const self = this;
    if (self.regionId != null) {
        self.getListCountriesByRegion(self.regionId);
    } else {
        self.popByProduct.countryID = null;
    }
  }

  public selectedCountry(): void {
      const self = this;
      if (self.popByProduct.countryID != null) {
          self.getBrandsByCountryID(self.popByProduct.countryID);
      }
  }

  public selectedBrand(): void {
    const self = this;
    if (self.brandID != null) {
      self.getListProductsFilters(self.regionId, self.brandID, self.categoryID);
    }
  }

  public saveUpdatePopByProduct(): void {
    const self = this;
    if (self.popByProduct.popByProductID !== null) {
        self.popByProductService.putData(self.popByProduct.popByProductID, self.popByProduct.getJsonUpdate()).then(
            function (response) {
                if (response === false) { return; }
                self.router.navigate(['/option-pop-by-product']);
            }
        );
    } else {
        self.popByProductService.postData(self.popByProduct.getJsonCreate()).then(
            function (response) {
                if (response === false) { return; }
                self.router.navigate(['/option-pop-by-product']);
            }
        );
    }
}

}
