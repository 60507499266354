
import { Country } from './country';
import { OptionPopTipology } from './popTipology';
import { Product } from './product';
import { Category } from './category';

export class PopByProduct {
    public popByProductID: number;
    public productID: number;
    public countryID: number;
    public popTipologyID: number;
    public active: boolean;
    public popCod: string;
    public country: Country;
    public popTipology: OptionPopTipology;
    public product: Product;
    private created_By: string; 
	private created_Date: string; 
	private updated_Date: string; 
    public category: Category;

	constructor() {
		this.initalizePopByProduct();
	}

	initalizePopByProduct() {
        this.popByProductID = null;
        this.productID = null;
        this.countryID = null;
        this.popTipologyID = null;
        this.active = null;
        this.popCod = null;
        this.country = new Country();
        this.product = new Product();
        this.popTipology = new OptionPopTipology();
	}

	setPopByProduct(popByProduct) {
        this.popByProductID = popByProduct.popByProductID;
        this.productID = popByProduct.productID;
        this.countryID = popByProduct.countryID;
        this.popTipologyID = popByProduct.popTipologyID ;
        this.active = popByProduct.active;
        this.popCod = popByProduct.popCod;
        if (popByProduct.hasOwnProperty('country') && popByProduct.country !== null) { this.country.setCountry(popByProduct.country); }
        if (popByProduct.hasOwnProperty('product') && popByProduct.product !== null) { this.product.setProduct(popByProduct.product); }
        if (popByProduct.hasOwnProperty('popTipology') && popByProduct.popTipology !== null) { this.popTipology.setPopTipology(popByProduct.popTipology); }
    }

	getJsonBase(): any {
		const jsonBase = {
            'productID' : this.productID,
            'countryID' : this.countryID,
            'popTipologyID' : this.popTipologyID,
            'popCod' : this.popCod,
            'active' : this.active,
		};
		return jsonBase;
	}

	getJsonCreate(): any {
		const json = this.getJsonBase();
		if (this.created_By !== null) { json['created_By'] = this.created_By; }
		return json;
	}

	getJsonUpdate(): any {
		const json = this.getJsonBase();
		if (this.created_Date !== null) { json['created_Date'] = this.created_Date; }
		if (this.created_By !== null) { json['created_By'] = this.created_By; }
		if (this.popByProductID !== null) { json['popByProductID'] = this.popByProductID; }
		return json;
	}
}