import {
  Component,
  Input,
  OnInit,
  EventEmitter,
  Output,
  Inject,
  TemplateRef,
  ViewChild,
  Self,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SearchPipe } from "../../../pipes/searchPipe";
import { TranslatePipe } from "../../../pipes/translate";
import { ExcelService } from "../../../services/excel.service";
import { FiltersService } from "../../../services/filters.service";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import { UsersInformationService } from "../../../services/usersInformation.service";
import { registerLocaleData } from "@angular/common";
import es from "@angular/common/locales/es";
import * as addDays from "date-fns/add_days";
import { defineLocale } from "ngx-bootstrap/chronos";
import { esLocale } from "ngx-bootstrap/locale";
import { FilteringEventArgs } from "@syncfusion/ej2-dropdowns";
import { EmitType } from "@syncfusion/ej2-base";
import { Query } from "@syncfusion/ej2-data";

defineLocale("es", esLocale);
registerLocaleData(es);

@Component({
  selector: "app-table",
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.scss"],
})
export class TableComponent implements OnInit {
  @Input() tableTitles: any = [];
  @Input() data: any = [];
  @Input() public titleTable: string = null;
  @Input() public options: any = {};
  @Input() filtersActive = false;
  @Input() searchTxt: any = "";
  @Input() urlExcel: string;
  @Input() ifExcelExist = false;
  @Input() maxDaysRangeSelect = 0;
  @Input() countryFilterList: any = [];
  @Input() regionFilterList: any = [];
  @Input() dataCache: any = [];
  @Input() productFilterList: any = [];
  @Input() countryZoneFilterList: any = [];
  @Input() cityFilterList: any = [];
  @Input() categoryFilterList: any = [];
  @Input() siteslocationFilterList: any = [];
  @Input() sitestoreFilterList: any = [];
  @Input() cedisFilterList: any = [];
  @Output() sendEvent: EventEmitter<any> = new EventEmitter();
  @Output() downloadExcelReport: EventEmitter<any> = new EventEmitter();
  @Output() deleteEvent: EventEmitter<any> = new EventEmitter();
  @Output() showPdf: EventEmitter<any> = new EventEmitter();
  @ViewChild("modalTable") modalTable: TemplateRef<any>;
  @ViewChild("modalDownloadExcel") modalDownloadExcel: TemplateRef<any>;
  @Output() filter: EventEmitter<any> = new EventEmitter();

  public showTrackerInfo: boolean;
  public showTrackerInfoTable: boolean;

  public placeholder = "Seleccione una opción";
  public popHeight = "350px";

  public regionId;
  public countryId;
  public countryZoneId;
  public cityId;
  public cedisId: any = [];
  public siteStoreId;
  public siteLocationId;
  public categoryId;
  public itemsPerPage: number = 5;

  public productId: any = [];
  public ifDataExist = false;
  public modalTrackData;
  public pointsTrack = [];
  public destinationTrack = {};
  public waypointsTrack = [];
  public originTrack = {};
  public showModalTrackMap = false;
  public showTrackRoute = false;
  public modalTitle;
  public modalSubTitle;
  public modalSubTitle2;
  public modalShowReportPromoterConsolidate = false;
  public modalShowTracking = false;
  public reportPromoterConsolidateBreacks = [];
  public reportPromoterConsolidateTracks = [];

  public viewBreakDetailsBol = false;
  public viewTracksDetailsBol = true;
  public fieldsRegion = {};
  public fieldsCity = {};
  public fieldsSiteStore = {};
  public fieldsCountry = {};
  public fieldsCountryZone = {};
  public fieldsSiteLocations = {};
  public fieldsCategory = {};
  public filedsCampaing = {};
  public filedsPopType = {};
  public daterangepickerModel = [];
  public loggedUser = JSON.parse(localStorage.getItem("user"));
  public emailDownload: any = this.loggedUser.email;

  public pData: any = [];

  /** Errors */
  public filterCountryZoneError = false;
  public filterSiteLocationError = false;
  public filterSitestoreError = false;
  public filterCityError = false;
  public filterCountryError = false;
  public filterRegionError = false;
  public filterCedisError = false;
  public filterCategoryError = false;

  public optionsTableTracks = {
    showData: false,
    updateData: false,
    deleteData: false,
    filterCountryZone: false,
    filterSiteLocation: false,
    filterSitestore: false,
    filterCity: false,
    filterCountry: false,
    filterRegion: false,
    filterCedis: false,
    filterDateRange: false,
    searchBtn: false,
    exportBtn: true,
  };

  public optionsTableBreacks = {
    showData: false,
    updateData: false,
    deleteData: false,
    filterCountryZone: false,
    filterSiteLocation: false,
    filterSitestore: false,
    filterCity: false,
    filterCountry: false,
    filterRegion: false,
    filterCedis: false,
    filterDateRange: false,
    searchBtn: false,
    exportBtn: true,
  };

  public tableTitlesBreaks = [
    { name: "user.userName", label: "UserID", type: "normal" },
    { name: "user.fullName", label: "Name", type: "normal" },
    {
      name: "track.data.breackStartDate",
      label: "Start Date",
      type: "dateTime",
    },
    { name: "track.data.breackEndDate", label: "End Date", type: "dateTime" },
    { name: "track.breakTime", label: "Break Time Duration", type: "normal" },
    { name: "track.breackReason.reason", label: "Reason", type: "normal" },
  ];

  public tableTitlesTracks = [
    { name: "user.userName", label: "UserID", type: "normal" },
    { name: "user.fullName", label: "Name", type: "normal" },
    { name: "track.trackDate", label: "Fecha", type: "dateTime" },
    { name: "track.storeDistance", label: "Distancia", type: "distance" },
    { name: "track.wifiTrack", label: "WiFi Track", type: "boolean" },
    { name: "track.wifiAccuracy", label: "WiFi Accuracy", type: "percent" },
    { name: "track.fakeGPS", label: "Fake GPS", type: "boolean" },
  ];
  public modalDownloadData: any;
  public modalDownloadId: any;
  public dataVistaUsuario;
  public fullName = "";
  public popTypeList: any = [];
  public campaingList: any = [];
  public campaignID: any;
  public popTypeID: any;

  viewBreaksDetails() {
    this.viewBreakDetailsBol = true;
    this.viewTracksDetailsBol = false;
  }

  viewTracksDetails() {
    this.viewBreakDetailsBol = false;
    this.viewTracksDetailsBol = true;
  }

  constructor(
    private router: Router,
    private filterService: FiltersService,
    private usersInformationService: UsersInformationService,
    private modal: NgbModal
  ) {}

  maxDate() {
    const self = this;
    if (self.maxDaysRangeSelect) {
      return addDays(self.daterangepickerModel[0], self.maxDaysRangeSelect);
    } else {
      return false;
    }
  }

  send(id) {
    const self = this;
    self.sendEvent.emit({
      id: id,
    });
  }

  openModalExcelDownload(data, excelExportID) {
    const self = this;
    self.modalDownloadData = data;
    self.modalDownloadId = excelExportID;
    self.modal.open(self.modalDownloadExcel);
  }

  downloadExcel(data, excelExportID) {
    const self = this;
    const listIds: number[] = [];

    for (let obj of data) {
      listIds.push(parseInt(obj[excelExportID]));
    }
    self.downloadExcelReport.emit({
      listIds: listIds,
      email: self.emailDownload,
    });
    self.modal.dismissAll();
  }

  private getCampaingListByCountry(countryID) {
    const self = this;
    const request = [];
    request.push(countryID);
    self.filterService
      .getCampaingListByCountry(request)
      .then(function (result) {
        self.campaingList = [];
        self.campaingList = self.filterService.getVarResults();
        self.campaingList.unshift({
          campaignID: 0,
          campaignName: self.placeholder,
        });
      });
  }

  private getPopTypeListByCountry(countryID) {
    const request = [];
    request.push(countryID);
    const self = this;
    self.filterService.getPopTypeListByCountry(request).then(function (result) {
      self.popTypeList = [];
      self.popTypeList = self.filterService.getVarResults();
      self.popTypeList.unshift({ popTypeID: 0, type: self.placeholder });
    });
  }

  delete(id) {
    const self = this;
    self.deleteEvent.emit({
      id: id,
    });
  }

  /** debug */
  showData(data) {
    console.log(data);
  }

  filterChanged() {
    const self = this;
    if (self.options.filterRegion === "required" && !self.regionId) {
      self.filterRegionError = true;
      return false;
    }
    if (self.options.filterCountry === "required" && !self.countryId) {
      self.filterCountryError = true;
      return false;
    }
    if (self.options.filterCountryZone === "required" && !self.countryZoneId) {
      self.filterCountryZoneError = true;
      return false;
    }
    if (self.options.filterCity === "required" && !self.cityId) {
      self.filterCityError = true;
      return false;
    }
    if (self.options.filterSitestore === "required" && !self.siteStoreId) {
      self.filterSitestoreError = true;
      return false;
    }
    if (
      self.options.filterSiteLocation === "required" &&
      !self.siteLocationId
    ) {
      self.filterSiteLocationError = true;
      return false;
    }
    if (self.options.filterCedis === "required" && !self.cedisId) {
      self.filterCedisError = true;
      return false;
    }
    if (self.options.filterCategory === "required" && !self.categoryId) {
      self.filterCategoryError = true;
      return false;
    }

    const search = {
      regionId: self.regionId,
      countryId: self.countryId,
      cityId: self.cityId,
      siteStoreId: self.siteStoreId,
      countryZoneId: self.countryZoneId,
      siteLocationId: self.siteLocationId,
      categoryId: self.categoryId,
      start_Date: self.daterangepickerModel[0]
        ? self.daterangepickerModel[0]
        : "",
      end_Date: self.daterangepickerModel[1]
        ? self.daterangepickerModel[1]
        : "",
      view: self.router.url,
      popTypeID: self.popTypeID,
      campaignID: self.campaignID,
    };

    sessionStorage.setItem("search_" + self.router.url, JSON.stringify(search));
    self.filter.emit(search);
  }

  ngOnInit(): void {
    const self = this;
    self.itemsPerPage = self.options.itemsPerPage
      ? self.options.itemsPerPage
      : 25;

    if (sessionStorage.getItem("vistaUsuario")) {
      self.dataVistaUsuario = JSON.parse(
        sessionStorage.getItem("vistaUsuario")
      );
      self.fullName = self.dataVistaUsuario["fullname"];
    }

    /**select search*/
    self.fieldsCountry = { text: "country", value: "countryID" };
    self.fieldsRegion = { text: "region", value: "regionId" };
    self.fieldsSiteStore = { text: "name", value: "siteStoreID" };
    self.fieldsCity = { text: "cityName", value: "cityID" };
    self.fieldsCountryZone = { text: "zoneName", value: "countryZoneID" };
    self.fieldsSiteLocations = { text: "name", value: "siteLocationID" };
    self.fieldsCategory = { text: "category", value: "categoryID" };
    self.filedsCampaing = { text: "campaignName", value: "campaignID" };
    self.filedsPopType = { text: "type", value: "popTypeID" };
    self.daterangepickerModel[0] = new Date();
    self.daterangepickerModel[1] = new Date();

    if (typeof this.options.showTablePrinc === "undefined") {
      this.options.showTablePrinc = true;
      this.options.showTablePeq = false;
    }

    if (self.options.filterRegion) {
      self.filterService.getListRegions().then(function (response) {
        if (response) {
          self.regionFilterList = self.filterService.getVarResults();
          if (self.regionFilterList.length === 1) {
            if (!self.regionId) {
              self.regionId = self.regionFilterList[0].regionId;
              self.selectedRegion(self.regionId);
            }
            self.options.filterRegion = false;
          } else {
            self.options.filterRegion = "required";
          }
        }
      });
    }

    if (self.options.filterCategory) {
      self.getCategory();
    }

    if (sessionStorage.getItem("search_" + self.router.url)) {
      // console.log("search " + self.router.url);
      const search = JSON.parse(
        sessionStorage.getItem("search_" + self.router.url)
      );
      if (search.view === self.router.url) {
        if (search.regionId) {
          self.regionId = search.regionId;
          self.selectedRegion(self.regionId);
        }
        if (search.countryId) {
          self.countryId = search.countryId;
          self.selectedCountry(self.countryId);
        }
        if (search.cityId) {
          self.cityId = search.cityId;
          self.selectedCity(self.cityId);
        }
        if (search.siteStoreId) {
          self.siteStoreId = search.siteStoreId;
          self.selectedStore([self.siteStoreId], []);
        }
        if (search.countryZoneId) {
          self.countryZoneId = search.countryZoneId;
          self.selectedCountryZone(self.countryZoneId);
        }
        if (search.siteLocationId) {
          self.siteLocationId = search.siteLocationId;
        }
        if (search.categoryId) {
          self.categoryId = search.categoryId;
        }
        if (search.start_Date) {
          self.daterangepickerModel[0] = search.start_Date;
        }
        if (search.siteStoreId) {
          self.daterangepickerModel[1] = search.end_Date;
        }

        if (self.options.filterPopType && search.countryId) {
          self.getPopTypeListByCountry(search.countryId);
        }

        if (self.options.filterCategory) {
          self.getCategory();
        }

        if (self.options.filterCampaing && search.countryId) {
          self.getCampaingListByCountry(search.countryId);
        }

        self.filterChanged();
      }
    }

    self.ifDataExist = true;
  }

  dataParsed() {
    /* Function to parse data input and make a simple array table to help to filter data */
    const self = this;
    if (self.data !== self.dataCache && self.data.length) {
      self.pData = [];
      for (let d in self.data) {
        self.pData[d] = [];
        self.tableTitles.forEach((element) => {
          self.pData[d].push(
            self.showString(self.data[d], element.name, element.type)
          );
        });
        self.pData[d]["data"] = self.getData(
          self.showString(self.data[d], self.options.dataId, "normal")
        );
      }
      self.dataCache = self.data;
    }
    return self.data.length ? self.pData : [];
  }

  getData(dataId) {
    const self = this;
    const output = self.data.filter((v) => {
      return self.showString(v, self.options.dataId, "normal") === dataId;
    })[0];
    return output;
  }

  getCategory(): void {
    const self = this;
    self.categoryFilterList = {};
    self.filterService.getListCategories().then((response) => {
      if (response) {
        self.categoryFilterList = self.filterService.getVarResults();
        self.categoryFilterList.unshift({
          categoryID: 0,
          category: self.placeholder,
        });
      }
    });
  }

  public selectedRegion(regionId): void {
    const self = this;
    self.countryFilterList = [];
    self.filterService
      .getListCountriesByRegion(regionId)
      .then(function (response) {
        if (response) {
          self.countryFilterList = [];
          self.sitestoreFilterList = [];
          self.cityFilterList = [];
          self.siteslocationFilterList = [];
          self.countryFilterList = self.filterService.getVarResults();
          if (self.options.filterCountry) {
            if (self.countryFilterList.length === 1) {
              self.countryId = self.countryFilterList[0].countryID;
              self.selectedCountry(self.countryId);
            } else {
              self.countryFilterList.unshift({
                countryID: 0,
                country: self.placeholder,
              });
            }
            self.options.filterCountry = "required";
          }
        }
      });
  }

  public onFilteringCampaing(e) {
    const array = this.campaingList.filter((str) => {
      return str.campaignName.toLowerCase().indexOf(e.text.toLowerCase()) >= 0;
    });
    e.updateData(array);
  }

  public onFilteringPopType(e) {
    const array = this.popTypeList.filter((str) => {
      return str.type.toLowerCase().indexOf(e.text.toLowerCase()) >= 0;
    });
    e.updateData(array);
  }

  public selectedCountry(countryId): void {
    const self = this;
    self.countryZoneFilterList = [];
    self.cityFilterList = [];
    self.sitestoreFilterList = [];
    self.siteslocationFilterList = [];

    if (self.options.filterPopType) {
      self.popTypeID = null;
      self.getPopTypeListByCountry(countryId ? countryId : 0);
    }

    if (self.options.filterCampaing) {
      self.campaignID = null;
      self.getCampaingListByCountry(countryId ? countryId : 0);
    }

    if (self.options.filterCountryZone) {
      self.filterService
        .getListCountryZonesFilter(countryId ? [countryId] : countryId)
        .then(function (response) {
          if (response) {
            self.countryZoneFilterList = self.filterService.getVarResults();
            self.countryZoneFilterList.unshift({
              countryZoneID: 0,
              zoneName: self.placeholder,
            });
          }
        });
    }

    if (self.options.filterCity) {
      self.filterService
        .getListCitiesFilters(countryId ? [countryId] : countryId, [])
        .then(function (response) {
          if (response) {
            self.cityFilterList = self.filterService.getVarResults();
            self.cityFilterList.unshift({
              cityID: 0,
              cityName: self.placeholder,
            });
          }
        });
    }

    if (self.options.filterSitestore) {
      self.filterService
        .getListSiteStoresFilters([], countryId ? [countryId] : countryId)
        .then(function (response) {
          if (response) {
            self.sitestoreFilterList = self.filterService.getVarResults();
            self.sitestoreFilterList.unshift({
              siteStoreID: 0,
              name: self.placeholder,
            });
          }
        });
    }
  }

  public selectedCity(cityId): void {
    const self = this;
    if (cityId !== 0) {
      if (self.options.filterSitestore) {
        self.filterService
          .getListSiteStoresFilters(cityId ? [cityId] : [], [self.countryId])
          .then(function (response) {
            if (response) {
              self.sitestoreFilterList = self.filterService.getVarResults();
              self.sitestoreFilterList.unshift({
                siteStoreID: 0,
                name: self.placeholder,
              });
            }
          });
      }

      if (self.options.filterCedis) {
        self.filterService
          .getListCedisFilters([], cityId ? [cityId] : [], [])
          .then(function (response) {
            if (response) {
              self.cedisFilterList = self.filterService.getVarResults();
              self.cedisFilterList.unshift({
                cedisID: 0,
                cedisName: self.placeholder,
              });
            }
          });
      }
    }
  }

  showPdfAudit(id) {
    this.showPdf.emit({
      data: id,
    });
  }

  selectedCountryZone(countryZoneId): void {
    const self = this;
    if (countryZoneId !== 0) {
      self.filterService
        .getListCitiesFilters([], countryZoneId ? [countryZoneId] : [])
        .then(function (response) {
          if (response) {
            self.cityFilterList = self.filterService.getVarResults();
            self.cityFilterList.unshift({
              cityID: 0,
              cityName: self.placeholder,
            });
          }
        });
    } else {
      self.cityFilterList = [];
      self.cityId = 0;
    }
  }

  selectedStore(sitestores, countries): void {
    const self = this;

    if (self.options.filterSiteLocation) {
      self.filterService
        .getListSiteLocationsFilters(sitestores, countries)
        .then(function (response) {
          if (response) {
            self.siteslocationFilterList = self.filterService.getVarResults();
            self.siteslocationFilterList.unshift({
              siteLocationID: 0,
              name: self.placeholder,
            });
          }
        });
    }
  }

  filterData(data, txtSearchVar) {
    const searchPipe = new SearchPipe();
    return searchPipe.transform(data, txtSearchVar);
  }

  exportAsXLSX(data, tableTitles): void {
    const self = this;
    const cls = new ExcelService();
    let excelData = [];
    let excelDataRow = [];
    let translatePipe = new TranslatePipe();

    data.forEach(function (row, key) {
      excelDataRow = [];

      let dataKey = key;
      tableTitles.forEach(function (title, key) {
        let rowTitle = translatePipe.transform(title.label);
        excelDataRow[rowTitle] = self.showString(row, title.name, title.type);
      });
      excelData.push(excelDataRow);
    });
    const url = this.router.url;
    const index = url.indexOf("?");
    const nameFile = index !== -1 ? url.substring(0, index) : url;
    console.log(nameFile);
    cls.exportAsExcelFile(excelData, nameFile);
  }

  exportAsCSV(data, tableTitles): void {
    const self = this;
    const cls = new ExcelService();
    let excelData = [];
    let excelDataRow = [];
    let translatePipe = new TranslatePipe();

    data.forEach(function (row, key) {
      excelDataRow = [];

      let dataKey = key;
      tableTitles.forEach(function (title, key) {
        let rowTitle = translatePipe.transform(title.label);
        excelDataRow[rowTitle] = self.showString(row, title.name, title.type);
      });

      excelData.push(excelDataRow);
    });
    cls.exportAsCSVFile(excelData, this.router.url.substring(1));
  }

  exportAsPDF(data): void {
    const cls = new ExcelService();
    cls.exportAsPDFFile(data, this.router.url.substring(1));
  }

  showModal(modalView, modalId, modalAllow, row) {
    const self = this;
    let datePipe = new DatePipe("es");

    let data = row.data;

    if (modalAllow) {
      if (modalView === "showReportPromoterConsolidate") {
        self.reportPromoterConsolidateBreacks = [];
        self.reportPromoterConsolidateTracks = [];

        self.modalShowReportPromoterConsolidate = true;
        if (data.breacks) {
          if (data.breacks.length > 0 && data.breacks[0] != null) {
            data.breacks.forEach(function (track, key) {
              self.reportPromoterConsolidateBreacks.push({
                track: track,
                user: data.user,
              });
            });
          }
        }
        if (data.trackUser) {
          if (data.trackUser.length > 0 && data.trackUser[0] !== null) {
            data.trackUser.forEach(function (track, key) {
              self.reportPromoterConsolidateTracks.push({
                track: track,
                user: data.user,
              });
            });
          }
        }
        if (data.trackUser) {
          self.modalTitle = data.siteLocation.location;
        } else {
          self.modalTitle = "";
        }

        if (data.checkInOut) {
          const inDate = datePipe.transform(
            data.checkInOut.checkIn_DateTime,
            "dd/MM/yyyy hh:mm:ss a"
          );
          let outDate = "";

          if (data.checkInOut.checkOut_DateTime === null) {
            outDate = datePipe.transform(
              data.lastTrackUser.trackDate,
              "dd/MM/yyyy hh:mm:ss a"
            );
          } else {
            outDate = datePipe.transform(
              data.checkInOut.checkOut_DateTime,
              "dd/MM/yyyy hh:mm:ss a"
            );
          }
          self.modalSubTitle = inDate + " to " + outDate;
          self.modalSubTitle2 = data.user.fullName;
          self.modal.open(self.modalTable);
        }
      }
      if (modalView === "showTracking") {
        self.modalShowTracking = true;
        const inDate = datePipe.transform(
          data.checkIn_DateTime,
          "dd/MM/yyyy hh:mm:ss a"
        );
        let outDate = datePipe.transform(
          data.checkOut_DateTime,
          "dd/MM/yyyy hh:mm:ss a"
        );
        self.showTrackerInfo = true;
        self.usersInformationService
          .getUsersTrack(data[modalId])
          .then(function (response) {
            if (response) {
              self.pointsTrack = [];
              self.waypointsTrack = [];
              self.originTrack = [];
              self.destinationTrack = [];
              self.modalTrackData =
                self.usersInformationService.getVarResults();
              if (self.modalTrackData.length > 0) {
                self.showModalTrackMap = true;

                self.modalTrackData.forEach((element) => {
                  self.pointsTrack = [
                    ...self.pointsTrack,
                    {
                      lng: element.longitude,
                      lat: element.latitude,
                      storeDistance: element.storeDistance,
                      wifiAccuracy: element.wifiAccuracy,
                      wifiTrack: element.wifiTrack,
                      trackDate: element.trackDate,
                      enTienda: element.enTienda,
                      fakeGPS: element.fakeGPS,
                    },
                  ];
                });

                if (self.modalTrackData.length === 1) {
                  self.showTrackRoute = false;
                } else if (self.modalTrackData.length === 2) {
                  self.showTrackRoute = true;
                  self.originTrack = self.pointsTrack[0];
                  self.destinationTrack =
                    self.pointsTrack[self.pointsTrack.length - 1];
                  self.waypointsTrack = [];
                } else if (self.modalTrackData.length > 2) {
                  self.showTrackRoute = true;
                  self.originTrack = self.pointsTrack[0];
                  self.destinationTrack =
                    self.pointsTrack[self.pointsTrack.length - 1];
                  self.modalTrackData.forEach((element) => {
                    self.waypointsTrack = [
                      ...self.waypointsTrack,
                      {
                        lat: element.latitude,
                        lng: element.longitude,
                      },
                    ];
                  });
                }
              } else {
                self.pointsTrack = [];
                self.showModalTrackMap = false;
              }
              self.modalTitle =
                data.siteStoreName + " - " + data.siteLocationName;
              if (outDate === null) {
                if (self.pointsTrack[self.pointsTrack.length - 1]) {
                  outDate = datePipe.transform(
                    self.pointsTrack[self.pointsTrack.length - 1].trackDate,
                    "dd/MM/yyyy hh:mm:ss a"
                  );
                } else {
                  outDate = inDate;
                }
              }
              self.modalSubTitle = inDate + " to " + outDate;

              self.modal.open(self.modalTable);
            }
          });
      }
    }
  }

  changeMapView() {
    const self = this;
    if (self.showTrackerInfo) {
      self.showTrackerInfo = false;
      self.showTrackerInfoTable = true;
    } else {
      self.showTrackerInfo = true;
      self.showTrackerInfoTable = false;
    }
  }

  formatString(value, type) {
    if (type === "date") {
      const datePipe = new DatePipe("es");
      return datePipe.transform(value, "yyyy-MM-dd");
    }
    if (type === "dateTime") {
      const datePipe = new DatePipe("es");
      return datePipe.transform(value, "yyyy-MM-dd hh:mm:ss a");
    }
    if (type === "time") {
      const datePipe = new DatePipe("es");
      return datePipe.transform(value, "hh:mm:ss a");
    }
    if (type === "boolean") {
      const translatePipe = new TranslatePipe();
      if (value === true) {
        return translatePipe.transform("Generals.StatusOptions.Yes");
      } else if (value === false) {
        return translatePipe.transform("Generals.StatusOptions.No");
      }
    }
    if (type === "month") {
      const meses = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      return meses[value] ? meses[value] : value;
    }
    if (type === "inStore") {
      if (value) {
        return "IN";
      } else {
        return "OUT";
      }
    }

    if (type === "distance") {
      return Math.round(value) + "m";
    }
    if (type === "percent") {
      if (value === "") {
        return "0%";
      }
      return value + "%";
    }
    if (type === "active") {
      const translatePipe = new TranslatePipe();
      if (value === 1) {
        return translatePipe.transform("Generals.StatusOptions.Active");
      } else if (value === 0) {
        return translatePipe.transform("Generals.StatusOptions.Inactive");
      }
    }
    if (type === "status") {
      const translatePipe = new TranslatePipe();
      if (value === true) {
        return translatePipe.transform("Generals.StatusOptions.Active");
      } else if (value === false) {
        return translatePipe.transform("Generals.StatusOptions.Inactive");
      }
    }
    if (type === "gender") {
      const translatePipe = new TranslatePipe();
      if (value === 1 || value === "M") {
        return translatePipe.transform("Generals.Gender.Male");
      } else if (value === 2 || value === "F") {
        return translatePipe.transform("Generals.Gender.Female");
      }
    }
    if (type === "number") {
      return value === "" || value === null ? "0" : value;
    }
    if (typeof value === "string") {
      return value === "" || value === null ? "-" : value;
    }

    return value;
  }

  showString(row, title, type) {
    const value = this.showStringArray(row, title);
    try {
      if (typeof value !== "undefined") {
        return this.formatString(value, type);
      } else {
        return this.formatString(row[title], type);
      }
    } catch (e) {}
  }

  saveUser(fullname, userName, userId) {
    const self = this;
    const request = {
      fullname: fullname,
      userName: userName,
      userId: userId,
    };
    sessionStorage.setItem("vistaUsuario", JSON.stringify(request));
  }

  showStringArray(obj, prop) {
    try {
      var parts = prop.split(".");
      if (Array.isArray(parts)) {
        var last = parts.pop(),
          l = parts.length,
          i = 1,
          current = parts[0];
        while ((obj = obj[current]) && i < l) {
          current = parts[i];
          i++;
        }
        if (obj) {
          return obj[last];
        }
      } else {
        return "-";
      }
    } catch (error) {
      console.log("Empty Data");
    }
  }

  public onFiltering: any = (e: FilteringEventArgs, data) => {
    const filter = data.filter((sl) => {
      const nameVar: string = sl.name;
      return nameVar.includes(e.text);
    });
    e.updateData(filter);
  };
}
