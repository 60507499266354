import { Brand } from './brand';
import { Product } from './product';
import { SiteStores } from './stores';
import { Country } from './country';
import { Region } from './region';

export class ProductSiteStore {
	public productSiteStoreID: number;
	public productID: number;
	public siteStoreID: number;
	public regionId: number;
	public countryID: number;
	public brandID: number;
	public local_Price: number;
	public dollar_Price: number;
	public from_Date: string;
	public to_Date: string;
	public status: boolean;
	public product: Product;
	public siteStore: SiteStores;
	public region : Region;
	public country: Country;
	public brand: Brand;
	public created_By: string; 
	private created_Date: string; 
	private updated_Date: string; 

	constructor() {
		this.initalizeProductSiteStore();
	}

	initalizeProductSiteStore() {
		this.productSiteStoreID = null;
		this.productID = null;
		this.siteStoreID = null;
		this.regionId = null;
		this.countryID = null;
		this.brandID = null;
		this.local_Price = null;
		this.dollar_Price = null;
		this.from_Date = null;
		this.to_Date = null;
		this.status = null;
		this.created_By = null;
		this.created_Date = null;
		this.updated_Date = null;
		this.product = new Product();
		this.siteStore = new SiteStores();
		this.region = new Region();
		this.country = new Country();
		this.brand = new Brand();
	}

	setProductSiteStore(productSiteStore) {
		this.productSiteStoreID = productSiteStore.productSiteStoreID;
		this.productID = productSiteStore.productID;
		this.siteStoreID = productSiteStore.siteStoreID;
		this.regionId = productSiteStore.regionId;
		this.countryID = productSiteStore.countryID;
		this.brandID = productSiteStore.brandID;
		this.local_Price = productSiteStore.local_Price;
		this.dollar_Price = productSiteStore.dollar_Price;
		this.from_Date = productSiteStore.from_Date;
		this.to_Date = productSiteStore.to_Date;
		this.status = productSiteStore.status;
		this.created_By = productSiteStore.created_By;
		this.created_Date = productSiteStore.created_Date;
		this.updated_Date = productSiteStore.updated_Date;
		if (productSiteStore.hasOwnProperty('product') && productSiteStore.product !== null) { this.product.setProduct(productSiteStore.product); }
		if (productSiteStore.hasOwnProperty('siteStore') && productSiteStore.siteStore !== null) { this.siteStore.setSiteStore(productSiteStore.siteStore); }
		if (productSiteStore.hasOwnProperty('region') && productSiteStore.region !== null) { this.region.setRegion(productSiteStore.region); }
		if (productSiteStore.hasOwnProperty('country') && productSiteStore.country !== null) { this.country.setCountry(productSiteStore.country); }
		if (productSiteStore.hasOwnProperty('product') && productSiteStore.product !== null) { this.product.setProduct(productSiteStore.product); }
	}

	getJsonBase(): any {
		const jsonBase = {
			'productID': this.productID,
			'siteStoreID': this.siteStoreID,
			'regionId': this.regionId,
			'countryID': this.countryID,
			'brandID': this.brandID,
			'local_Price': this.local_Price,
			'dollar_Price': this.dollar_Price,
			'from_Date': this.from_Date,
			'to_Date': this.to_Date,
			'status': this.status,
		};
		return jsonBase;
	}

	getJsonCreate(): any {
		const json = this.getJsonBase();
		if (this.created_By !== null) { json['created_By'] = this.created_By; }
		return json;
	}

	getJsonUpdate(): any {
		const json = this.getJsonBase();
		if (this.created_Date !== null) { json['created_Date'] = this.created_Date; }
		if (this.created_By !== null) { json['created_By'] = this.created_By; }
		if (this.productSiteStoreID !== null) { json['productSiteStoreID'] = this.productSiteStoreID; }
		return json;
	}
}
