import { Component, OnInit, Input, EventEmitter } from "@angular/core";
import { UtilsService } from "../../../../services/utils.service";
import { UsersInformationService } from "../../../../services/usersInformation.service";
import { CustomHttpService } from "../../../../services/customHttp.service";

@Component({
  selector: "app-index-user-information",
  templateUrl: "./index.component.html",
  styleUrls: [],
})
export class UsersInformationComponent implements OnInit {
  public title = "UsersInformation";
  public ifDataExist = false;

  public regionID = [];
  public cityID = [];
  public countryID = [];

  filter(event) {
    let request = {};
    if (event.regionId) {
      request["regions"] = [event.regionId];
    }
    if (event.countryId) {
      request["countries"] = [event.countryId];
    }
    if (event.cityId) {
      request["cities"] = [event.cityId];
    }
    this.getUsers(request);
  }

  public tableTitles = [
    //{ name: 'userId', label: 'ID', type: 'normal' },
    { name: "region", label: "Región", type: "normal" },
    { name: "country", label: "País", type: "normal" },
    { name: "userName", label: "Username", type: "normal" },
    { name: "fullName", label: "Nombre", type: "normal" },
    { name: "documentType", label: "Tipo de Documento", type: "normal" },
    { name: "document", label: "Documento", type: "normal" },
    { name: "gender", label: "Género", type: "gender" },
    { name: "email", label: "Email", type: "normal" },
    { name: "emailPersonal", label: "Email Personal", type: "normal" },
    { name: "userRol", label: "Rol", type: "normal" },
    { name: "category", label: "Categoria", type: "normal" },
    { name: "start_Date", label: "Fecha de Inicio", type: "dateTime" },
    { name: "end_Date", label: "Fecha de Finalización", type: "dateTime" },
    { name: "disconnectionType", label: "Tipo de Desconexión", type: "normal" },
    { name: "phone1", label: "Teléfono", type: "normal" },
    //{ name: 'supervisors', label: 'Supervisor', type: 'normal' },
    { name: "photo", label: "Photo", type: "image" },
  ];
  public data: any = [];
  public optionsTable = {
    dataId: "userId",
    showData: true,
    actionShowData: "show",
    updateData: false,
    actionUpdateData: "update",
    deleteData: false,
    actionDeleteData: "",
    filterRegion: true,
    filterCountry: true,
    filterCountryZone: false,
    filterCity: false,
    filterCedis: false,
    filterSiteLocation: false,
    filterSitestore: false,
    searchBtn: true,
    exportBtn: true,
  };

  constructor(
    private userService: UsersInformationService,
    private utilsService: UtilsService,
    private customHttpService: CustomHttpService
  ) {
    if (!sessionStorage.hasOwnProperty("token")) {
      const url = window.location.href;
      const searchParams = url.split("?")[1];
      const params = new URLSearchParams(searchParams);
      const token = params.get("t");
      const userCoded = params.get("u");
      const decodedString = atob(userCoded);
      const user = JSON.parse(decodedString);
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("language", "es");
      sessionStorage.setItem("token", token);
    } else {
      console.log("tokenExist.");
    }
  }

  ngOnInit() {}

  getUsers(request) {
    const self = this;

    self.userService.getUsersAllData(request).then(function (result) {
      self.data = self.userService.getVarResults();
      self.ifDataExist = true;
      self.utilsService.debugConsole(result);
    });
  }
}
