import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../../../services/utils.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PushNotificationService } from '../../../services/pushNotification.service';
import { MethodsUtilsService } from '../../../services/methodsUtils.service';
import { FormularioBaseComponent } from '../../utils/formularioBase.component';
import { SiteStores } from '../../../models/stores';
import { StoresService } from '../../../services/stores.service';
import { SiteLocations } from '../../../models/locations';
import { LocationsService } from '../../../services/locations.service';
import { DatePipe } from '@angular/common';

// Declare Vars
declare var $: any;

@Component({
	selector: 'app-index-push-notification',
	templateUrl: './index.component.html',
    styleUrls: [],
    providers: [DatePipe]
})

export class PushNotificationComponent extends FormularioBaseComponent implements OnInit {
    public title = 'PushNotification';
    public page = 'PushNotification';
    public titleButton = 'Send';
    public store = new SiteStores();
    public siteLocations = new SiteLocations();
    public PushTitle: string = '';
    public PushMessage: string = '';
    public PushImage: any;
    public regionId: any;
    public countryId:  any;
    public countryZoneId:  any;
    public cityId:  any;
    public siteStoreId:  any;
    public userId:  any;


	constructor(
        private route: ActivatedRoute,
        private siteStoresService: StoresService,
        private siteLocationsService: LocationsService,
        private router: Router,
        public utilsService: UtilsService,
        public methodsUtilsService: MethodsUtilsService,
        public pushNotificationService: PushNotificationService,
    ){
        super(utilsService, methodsUtilsService);
    }

    ngOnInit() {
        const self = this;
        self.getRegionsList();
        self.param = self.route.params.subscribe(
            params => {
                if (params['id']) {
                    self.title = 'Stores.Update';
                    self.titleButton = 'Update';
                }
            }
        );
    }
    

    public selectedRegion(): void {
        const self = this;
        if (self.regionId != null) {
            self.getListCountriesByRegion(self.regionId);
        } else {
            self.countryId = null;
            self.countryZoneId = null;
            self.cityId = null;
            self.siteStoreId = null;
            self.userId = null;
        }
    }

    public selectedCountry(): void {
        const self = this;
        if (self.countryId != null) {
            self.getListCountryZonesFilters({ 'countries': [ self.countryId ]});
            self.getListCitiesFilters({ 'countries': [ self.countryId ], 'countriesZones': [] });
            self.getListCitiesFilters({ 'countries': [ self.countryId ], 'countriesZones': [] });
            self.getListSiteStoresFilters({ 'cities': [], 'countries': [ self.countryId ] });
            self.getListUsersFilters({ 'regions': [], 'cities': [], 'countries': [ self.countryId ] });
        } else {
            self.countryZoneId = null;
            self.cityId = null;
        }
    }

    public selectedCity(): void {
        const self = this;
        if (self.cityId != null) {
            self.getListUsersFilters({ 'regions': [], 'cities': [self.cityId], 'countries': [ ] });
        } else {
            self.userId = null;
        }
    }

    selectFile($event) {
        this.PushImage = this.pushNotificationService.getImageBase64($event, 512000, '.output_image_notification', '.previewNotificationImg');
    }

    sendNotificacion() {
        const self = this;
        let topic = 'all';

        if (self.regionId !== null && typeof self.regionId !== 'undefined') {
            topic = 'regionID_' + self.regionId; 
        }
        if (self.countryId !== null && typeof self.countryId !== 'undefined') {
            topic = 'countryID_' + self.countryId; 
        }
        if (self.countryZoneId !== null && typeof self.countryZoneId !== 'undefined') {
            topic = 'countryZoneID_' + self.countryZoneId; 
        }
        if (self.cityId !== null && typeof self.cityId !== 'undefined') {
            topic = 'cityID_' + self.cityId; 
        }
        if (self.siteStoreId !== null && typeof self.siteStoreId !== 'undefined') {
            topic = 'siteStoreID_' + self.siteStoreId;
        }
        if (self.userId !== null && typeof self.userId !== 'undefined') {
            topic = 'userID_' + self.userId; 
        }

        if(self.PushMessage != "" && self.PushTitle != ""){
            const request = {
                'titulo': self.PushTitle,
                'mensaje': self.PushMessage,
                'imagen': self.PushImage,
                'topic': topic,
            };

            this.pushNotificationService.sendPushTopic(request).then(
                function (response) {
                    if (response === false) { return; }
                    alert("La notificacón ha sido enviada.");
                    self.router.navigate(['/home']);
                }
            );
        }
       
    }

    openPreview(): void {
        //this.modal.open(this.modalPreview);
        const imgSrc = $('.output_image_notification div img').attr('src');
        $('.previewNotificationImg').html(['<img src="', imgSrc, '" title="Bienvenido a Samsung Plus."/>'].join(''));
    }

}
