import { Component, OnInit } from "@angular/core";
import { ReportPromoterNowService } from "src/services/reportPromoterNow.service";
import { UtilsService } from "../../../../services/utils.service";
import * as endOfWeek from "date-fns/end_of_week";
import * as startOfWeek from "date-fns/start_of_week";
import * as format from "date-fns/format";
import * as addWeek from "date-fns/add_weeks";
import * as addDays from "date-fns/add_days";

@Component({
  selector: "app-index-promoters-track",
  templateUrl: "./index.component.html",
  styleUrls: [],
})
export class ReportsPromoterCheckInOutComponent implements OnInit {
  public title = "ReportsPromotersCheckInOut";
  public ifDataExist = false;
  public ifExcelExist = true;
  public urlExcel: any;
  public user = JSON.parse(localStorage.getItem("user"));
  public tableTitles = [
    { name: "user.userName", label: "UserId", type: "normal" },
    { name: "user.fullName", label: "Name", type: "normal" },
    { name: "siteLocation.site_id", label: "Cod_MCS", type: "normal" },
    { name: "siteLocation.location", label: "Site Location", type: "normal" },
    { name: "checkInOut.checkIn_DateTime", label: "Date", type: "date" },
    { name: "checkInOut.checkIn_DateTime", label: "Check In", type: "time" },
    {
      name: "checkInOut.checkIn_Photo",
      label: "Check In Photo",
      type: "image",
    },
    {
      name: "checkInOut.checkIn_StoreDistance",
      label: "Check In Store Distance",
      type: "distance",
    },
    {
      name: "checkInOut.wifiAccuracy",
      label: "Check In WiFi Accuracy",
      type: "percent",
    },
    { name: "checkInOut.checkOut_DateTime", label: "Check Out", type: "time" },
    {
      name: "checkInOut.checkOut_Photo",
      label: "Check Out Photo",
      type: "image",
    },
    {
      name: "checkInOut.checkOut_StoreDistance",
      label: "Check Out Store Distance",
      type: "distance",
    },
    {
      name: "checkInOut.wifiAccuracyCheckOut",
      label: "Check Out WiFi Accuracy",
      type: "percent",
    },
    { name: "totalWorkTime", label: "Gross Worked Hours", type: "normal" },
    { name: "timeBreaks", label: "Hours absent", type: "normal" },
    { name: "workTime", label: "Net Worked Hours", type: "normal" },
    // { name: 'lastTrackUser.trackDate', label: 'Last Wifi Check', type: 'time' },
    // { name: 'present', label: 'Present at Store Now?', type: 'normal' },
    // { name: 'checkInOut.inPause', label: 'In Pause?', type: 'boolean' },
    // { name: 'breackReason', label: 'Pause Reason?', type: 'normal' },
  ];
  public data: any = [];
  public optionsTable = {
    dataId: "check_InOutID",
    showData: false,
    actionShowData: "",
    updateData: false,
    actionUpdateData: "update",
    deleteData: false,
    actionDeleteData: "",
    filterCountryZone: false,
    filterSiteLocation: true,
    filterSitestore: true,
    filterCity: true,
    filterCountry: true,
    filterRegion: "required",
    filterCedis: false,
    filterDateRange: true,
    searchBtn: true,
    exportBtn: true,
  };

  constructor(
    private reportPromoterNowService: ReportPromoterNowService,
    private utilsService: UtilsService
  ) {
    if (!sessionStorage.hasOwnProperty("token")) {
      const url = window.location.href;
      const searchParams = url.split("?")[1];
      const params = new URLSearchParams(searchParams);
      const token = params.get("t");
      const userCoded = params.get("u");
      const decodedString = atob(userCoded);
      const user = JSON.parse(decodedString);
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("language", "es");
      sessionStorage.setItem("token", token);
    } else {
      console.log("tokenExist.");
    }
  }

  ngOnInit() {
    const self = this;
    self.ifDataExist = true;
  }

  filter(event) {
    const self = this;
    console.log(event);
    let request = {
      start_Date: format(event.start_Date, "YYYY-MM-DD"),
      end_Date: format(event.end_Date, "YYYY-MM-DD"),
    };
    request["userId"] = self.user["userId"];

    if (event.regionId) request["regions"] = event.regionId;

    if (event.countryId) request["countries"] = event.countryId;

    if (event.cityId) request["cities"] = event.cityId;

    if (event.siteStoreId) request["sitestores"] = event.siteStoreId;

    if (event.siteLocationId) request["sitelocation"] = event.siteLocationId;

    self.getReportCheckInOut(request);
  }

  downloadExcelReport($event) {
    const self = this;
    let request = {};
    request["listIds"] = $event.listIds;
    request["email"] = $event.email;
    self.getReportCheckInOutExcel(request);
  }

  async getReportCheckInOut(request) {
    const self = this;
    self.reportPromoterNowService
      .getReportCheckInOut(request)
      .then(function (result) {
        self.data = self.reportPromoterNowService.getVarResults();
        self.urlExcel = true;
        self.ifDataExist = true;
        self.utilsService.debugConsole(result);
      });
  }

  async getReportCheckInOutExcel(request) {
    const self = this;
    self.reportPromoterNowService
      .getReportCheckInOutExcel(request)
      .then(function (result) {
        let urlExcel = self.reportPromoterNowService.getVarResults();
        if (urlExcel.indexOf("http") == 0) {
          window.location.assign(urlExcel);
        } else {
          alert(urlExcel);
        }
        self.utilsService.debugConsole(result);
      });
  }
}
