import { Category } from './category';

export class Brand {
	public brandID: number; 
    public status: string; 
	public brandName: string; 
	public active: boolean; 
	public activeCO: boolean; 
	private created_By: string; 
	public categories: any[];
	public category: Category;
	public showOrder: number; 
	private created_Date: string; 
	private updated_Date: string; 

	constructor() {
		this.initalizeBrand();
	}

	initalizeBrand() {
        this.brandID = null;
        this.status  = null;
        this.brandName  = null;
		this.created_By = null;
		this.created_Date = null;
		this.updated_Date = null;
		this.categories = null;
		this.active = null;
		this.showOrder = null;
		this.activeCO = null; 
		this.category = new Category();
	}

	setBrand(brand) {
		this.brandID = brand.brandID;
		this.brandName = brand.brandName;
		this.active = brand.active;
		this.activeCO = brand.activeCO; 
		this.created_By = brand.created_By;
		this.created_Date = brand.created_Date;
		this.updated_Date = brand.updated_Date;
		this.showOrder = brand.showOrder;
		this.categories = [];
		if(brand.categories){
			brand.categories.forEach(element => {
				this.categories.push(element.categoryID);
			});
		}
	}

	getJsonBase(): any {
		const jsonBase = {
            'brandName' : this.brandName,
			'active' : this.active,
			'activeCO' : this.activeCO, 
			'categories' : this.categories,
			'showOrder' : this.showOrder
		};
		return jsonBase;
	}

	getJsonCreate(): any {
		const json = this.getJsonBase();
		if (this.created_By !== null) { json['created_By'] = this.created_By; }
		return json;
	}

	getJsonUpdate(): any {
		const json = this.getJsonBase();
		if (this.created_Date !== null) { json['created_Date'] = this.created_Date; }
		if (this.created_By !== null) { json['created_By'] = this.created_By; }
		if (this.brandID !== null) { json['brandID'] = this.brandID; }
		return json;
	}
}
