import { Component, Input, OnInit } from '@angular/core';
import { AgmDirectionModule } from 'agm-direction';   // agm-direction

@Component({
	selector: 'my-map',
	templateUrl: './my-map.component.html',
	styleUrls: []
})
export class MyMapComponent implements OnInit {
	@Input() options: any;
	@Input() points: any;
	@Input() showMarkerInfo = false;
	@Input() title: string;
	@Input() showInfoPoints: boolean;
	@Input() showHorizontal: boolean;
	@Input() titleListPoints: string;
	@Input() showCalculateRoute: boolean;
	@Input() showTrackRoute: boolean;
	@Input() showTrackerInfo: boolean = false;
	@Input() showTrackerInfoTable: boolean = false;
	@Input() origin: any;
    @Input() waypoints: any;
	@Input() destination: any;

	public optionsTableTrack = {
		showData: false,
		updateData: false,
		deleteData: false,
		filterCountryZone: false,
		filterSiteLocation: false,
		filterSitestore: false,
		filterCity: false,
		filterCountry: false,
		filterRegion: false,
        filterCedis: false,
		filterDateRange: false,
		searchBtn: true,
        exportBtn: true,
	};
	
    public tableTitlesTrack = [
        { name: 'trackDate', label: 'Fecha', type: 'dateTime' },
        { name: 'storeDistance', label: 'Distancia', type: 'distance' },
		{ name: 'wifiTrack', label: 'WiFi Track', type: 'boolean' },
		{ name: 'wifiAccuracy', label: 'WiFi Accuracy', type: 'percent' },
		{ name: 'enTienda', label: 'En Tienda', type: 'inStore' },
		{ name: 'fakeGPS', label: 'Fake GPS', type: 'boolean' },

    ];
	
	public markers: any;

	constructor() { }

	ngOnInit(): void {
		this.markers = this.points;
	}

	public addToList(point: any): void {
		point.select = true;
		this.reloadPoints();
	}

	public removeToList(point: any): void {
		point.select = false;
		this.reloadPoints();
	}

	public reloadPoints(): void {
		this.markers = [];
		for (const point of this.points) {
			if (point.select) {
				this.markers = [
					...this.markers,
					point
				];
			}
		}
	}

	public calculateRoute(): void {
		this.reloadPoints();
	}

	openedWindow : number = 0; 

	openWindow(id) {
		this.openedWindow = id; 
	}

	wifiAverage(points){
		var sum = 0; 
		for(var i = 0; i < points.length; i++){
			sum += parseInt(points[i].wifiAccuracy, 10); //don't forget to add the base 
		}

		var avg = sum/points.length;

		return avg.toFixed(2); 
	}

	isInfoWindowOpen(id) {
		return this.openedWindow == id; 
	}
}