import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from '../../../../../services/utils.service';
import { MethodsUtilsService } from '../../../../../services/methodsUtils.service';
import { FormularioBaseComponent } from '../../../../utils/formularioBase.component';
import { PopTypeService } from '../../../../../services/popType.service';
import { OptionPopType } from '../../../../../models/popType';
import { CountriesService } from '../../../../../services/countries.service';

// Declare Vars
declare var $: any;

@Component({
  selector: 'app-form-reportPopType',
  templateUrl: './form.component.html',
  styleUrls: []
})

export class FormOptionsPopTypeComponent extends FormularioBaseComponent implements OnInit {

  public page = 'PopType';
  public title = 'PopType.Create';
  public titleButton = 'Save';
  public poptype = new OptionPopType();
  public regionId;

  constructor(
    private route: ActivatedRoute,
    private popTypeService: PopTypeService,
    private countriesService: CountriesService,
    private router: Router,
    public utilsService: UtilsService,
    public methodsUtilsService: MethodsUtilsService,
  ) {
    super(utilsService, methodsUtilsService);
  }

  ngOnInit() {
    const self = this;
    self.getRegionsList();
    self.param = self.route.params.subscribe(
      params => {
        if (params['id']) {
          self.title = 'PopType.Update';
          self.titleButton = 'Update';
          self.utilsService.debugConsole(params['id']);
          self.getPopType(params['id']);
        }
      }
    );
  }

  public selectedRegion(): void {
    const self = this;
    if (self.regionId != null) {
      self.getListCountriesByRegion(self.regionId);
    } else {
      self.poptype.countryID = null;
     
    }
  }

  private getPopType(id: any): void {
    const self = this;
    self.popTypeService.getResult(id).then(
      function (response) {
        if (response === false) { return; }
        self.poptype.setPopType(self.popTypeService.getVarResults());
        self.regionId = self.poptype.country.regionId;
        self.selectedRegion();
      
      }
    );
  }


 
  public saveUpdatePopType(): void {
    const self = this;
    self.poptype.created_By = self.utilsService.getUserId();
    if (self.poptype.popTypeID !== null) {
      self.popTypeService.putData(self.poptype.popTypeID, self.poptype.getJsonUpdate()).then(
        function (response) {
          if (response === false) { return; }
          self.router.navigate(['/option-pop-type']);
        }
      );
    } else {
      self.popTypeService.postData(self.poptype.getJsonCreate()).then(
        function (response) {
          if (response === false) { return; }
          self.router.navigate(['/option-pop-type']);
        }
      );
    }
  }

}
