import { AfterViewInit, Component, OnInit } from '@angular/core';
import { CmsGeneralesService } from '../../../../services/cmsGenerales.service';
import { UtilsService } from '../../../../services/utils.service';
import * as $ from 'jquery';
import { ScheduleService } from 'src/services/schedule.service';
import { DatePipe } from '@angular/common';

@Component({
	selector: 'app-store-schedule',
	templateUrl: './store-schedule.component.html',
	styleUrls: []
})

export class StoreScheduleComponent implements OnInit {
	public title = 'Store Schedule';
	public dataStoreSchedule = {};
	public ifDataExist = false;
	private datePipe = new DatePipe('es');
	public dateStartSelect = this.datePipe.transform(new Date(),"yyyy-MM-dd");
    public dateEndSelect = this.datePipe.transform(new Date(),"yyyy-MM-dd");

	constructor(
		private cmsGeneralesService: CmsGeneralesService,
		private utilsService: UtilsService,
		private scheduleService: ScheduleService,
	) {}

	ngOnInit() {
		const self = this;
		const request = {
			"start_Date": self.dateStartSelect,
			"end_Date": self.dateEndSelect
		};
		self.scheduleService.getReportScheduleBySiteLocations(request).then(
            function (result) {
				self.dataStoreSchedule = self.scheduleService.getVarResults();
				self.ifDataExist = true;
			}
		);
	}
}
