import { Injectable } from '@angular/core';
import { CustomHttpService } from './customHttp.service';
import { BaseServiceService } from './baseService.service';
import { UtilsService } from '../services/utils.service';
import { MethodsUtilsService } from '../services/methodsUtils.service';

@Injectable()
export class FiltersService extends BaseServiceService {
	private resultData: any;

	constructor(
		public customHttpService: CustomHttpService,
	) {
		super(customHttpService);
	}

	getListRegions() {
		const self = this;
		return new Promise(
			resolve => {
				// Get Info from 'v1/api/CmsGenerales/GetAllScheduleTypes'
				self.customHttpService.httpGET(false, true, 'v1/api/Regions/GetListRegions').then(
				function (data) {
					if (data === false) {
						resolve(data);
					} else {
						self.resultData = data.listRegions;
						resolve(true);
					}
				
				});
			}
		);
	}

	getListCategories() {
		const self = this;
		return new Promise(
			resolve => {
				// Get Info from 'v1/api/CmsGenerales/GetAllScheduleTypes'
				self.customHttpService.httpGET(true, true, 'v1/api/Generales/GetCategories').then(
					function (data) {
						if (data === false) {
							resolve(data);
						} else {
							self.resultData = data.categories;
							resolve(true);
						}

					});
			}
		);
	}

	getListCountries(region_id) {
		const self = this;
		return new Promise(
			resolve => {
				// Get Info from 'v1/api/CmsGenerales/GetAllScheduleTypes'
				self.customHttpService.httpGET(false, true, 'v1/api/Countries/GetListCountries/').then(
					function (data) {
						if (data === false) {
							resolve(data);
						} else {
							self.resultData = data.listCountries;
							resolve(true);
					}
				});
			}
		);
	}

	getListCountriesByRegion(region_id) {
		const self = this;
		return new Promise(
			resolve => {
				// 
				self.customHttpService.httpGET(false, true, 'v1/api/Countries/GetListCountriesByRegion/' + region_id).then(
					function (data) {
						if (data === false) {
							resolve(data);
						} else {
							self.resultData = data.listCountries;
							resolve(true);
					}
				});
			}
		);
	}

	getListCountryZonesFilter(countries = {}) {
		const self = this;
		return new Promise(
			resolve => {
				const request = {
					'countries': countries
				};
				self.customHttpService.httpPOST(false, true, 'v1/api/CountryZones/GetListCountryZonesFilters', request).then(
					function (data) {
						if (data === false) {
							resolve(data);
						} else {
							self.resultData = data.listCountryZones;
							resolve(true);
					}
				});
			}
		);
	}

	getListCitiesFilters(countries = {}, countriesZones = {}) {
		const self = this;
		return new Promise(
			resolve => {
			const request = {
				'countries': countries,
				'countriesZones': countriesZones,
			};
			self.customHttpService.httpPOST(false, true, 'v1/api/Cities/GetListCitiesFilters', request).then(
				function (data) {
					if (data === false) {
						resolve(data);
					} else {
						self.resultData = data.listCities;
						resolve(true);
				}
			});
		});
	}

	getListSiteStoresFilters( cities = [], countries = []) {
		const self = this;
		return new Promise(
			resolve => {
				const request = {
					'countries': countries,
					//'cities': cities,
				};
				self.customHttpService.httpPOST(false, true, 'v1/api/SiteStores/GetListSiteStoresFilters', request).then(
					function (data) {
						if (data === false) {
							resolve(data);
						} else {
							self.resultData = data.listSiteStores;
							resolve(true);
					}
				});
			});
	}
	
	getListCedisFilters(countries = {}, cities = {}, countriesZones = {}) {
		const self = this;
		return new Promise(
			resolve => {
			const request = {
				'countries': countries,
				'cities': cities,
				'countriesZones' : countriesZones
			};
			self.customHttpService.httpPOST(false, true, 'v1/api/Cedis/GetListCedisFilters', request).then(
				function (data) {
					if (data === false) {
						resolve(data);
					} else {
						self.resultData = data.listCedis;
						resolve(true);
				}
			});
		});
	}

	getListSiteLocationsFilters( sitestores = [], countries = [], cities = [] ) {
		const self = this;
		return new Promise(
			resolve => {
			const request = {
				'sitestores': [sitestores],
			};
			self.customHttpService.httpPOST(true, true, 'v1/api/SiteLocations/GetListSiteLocationsFilters', request).then(
				function (data) {
					if (data === false) {
						resolve(data);
					} else {
						self.resultData = data.listSiteLocations;
						resolve(true);
					}
				}
			);
		});
	}

	getListPointOfSales(siteStoreId) {
        const self = this;
        return new Promise(
            resolve => {
				let request = {
					"sitestores": [
						siteStoreId
					],
				  }
                // Get Info from 'v1/api/SiteLocations/SiteLocationsBySiteStore'
                self.customHttpService.httpPOST(false, true, 'v1/api/SiteLocations/GetSiteLocationFilters', request).then(
                    function (data) {
                        if (data === false) {
                            resolve(data);
                        } else {
                            self.resultData = data.listSiteLocations;
                            resolve(true);
                        }
                    });
            }
        );
	}
	
	getListStoreOnly() {
        const self = this;
        return new Promise(
            resolve => {
                // Get Info from 'v1/api/SiteStores sin ID'
                self.customHttpService.httpGET(false, true, 'v1/api/SiteStores').then(
                    function (data) {
                        if (data === false) {
                            resolve(data);
                        } else {
                            self.resultData = data.listSiteStores;
                            resolve(true);
                        }
                    });
            }
        );
    }

	getListStore(countryId) {
        const self = this;
        return new Promise(
            resolve => {
				// Get Info from 'v1/api/SiteStores by ID'
				let request = {
					"countries": [
						countryId
					]
				  }
                self.customHttpService.httpPOST(false, true, 'v1/api/SiteStores/GetSiteStoresFilters/', request).then(
                    function (data) {
                        if (data === false) {
                            resolve(data);
                        } else {
                            self.resultData = data.listSiteStores;
                            resolve(true);
                        }
                    });
            }
        );
	}
	

	getCampaingList() {
        const self = this;
        return new Promise(
            resolve => {
				// Get Info from 'v1/api/SiteStores by ID'
                self.customHttpService.httpGET(false, true, 'v1/api/Generales/GetCampaigns/').then(
                    function (data) {
                        if (data === false) {
                            resolve(data);
                        } else {
                            self.resultData = data.campaigns;
                            resolve(true);
                        }
                    });
            }
        );
	}


	getCampaingListByCountry(countryList) {
		const self = this;
        return new Promise(
            resolve => {
				// Get Info from 'v1/api/SiteStores by ID'
                self.customHttpService.httpPOST(false, true, 'v1/api/Campaigns/GetCampaignsActiveByCountry', countryList).then(
                    function (data) {
                        if (data === false) {
                            resolve(data);
                        } else {
							let campaigns = [];
							for(let campaign of data.campaigns){
								campaigns.push({
									"campaignID" : campaign.campaignID, 
									"campaignName":  campaign.campaignName  + ' ' + ((campaign.category) ? '- '+campaign.category.category : '') + ' ' + ((campaign.country) ? '- '+campaign.country.country_Code : '')
								});
							 }
                            self.resultData = campaigns;
                            resolve(true);
                        }
                    });
            }
        );
	}

	
	
	
	getPopTypeList() {
        const self = this;
        return new Promise(
            resolve => {
				// Get Info from 'v1/api/SiteStores by ID'
                self.customHttpService.httpGET(false, true, 'v1/api/PopTypes/GetActivePopType').then(
                    function (data) {
                        if (data === false) {
                            resolve(data);
                        } else {
							let types = [];
							for(let type of data.listPopTypes){
								types.push({
									"popTypeID" : type.popTypeID, 
									"type":  type.type  + ' - ' + ((type.country) ? type.country.country_Code : 'N/A'),
								});
							 }
							self.resultData = types;
                            resolve(true);
                        }
                    });
            }
        );
	}


	getPopTypeListByCountry(country) {
        const self = this;
        return new Promise(
            resolve => {
				// Get Info from 'v1/api/SiteStores by ID'
                self.customHttpService.httpPOST(false, true, 'v1/api/PopTypes/GetActivePopTypeByCountry', country).then(
                    function (data) {
                        if (data === false) {
                            resolve(data);
                        } else {
							let types = [];
							for(let type of data.listPopTypes){
								types.push({
									"popTypeID" : type.popTypeID, 
									"type":  type.type  + ' - ' + ((type.country) ? type.country.country_Code : 'N/A'),
								});
							 }
							self.resultData = types;
                            resolve(true);
                        }
                    });
            }
        );
	}



	// Getters and Setter Variables
	getVarResults () { return this.resultData; }
}
