import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { UtilsService } from '../../../../services/utils.service';
import { UsersInformationService } from '../../../../services/usersInformation.service';
import { CustomHttpService } from '../../../../services/customHttp.service';

@Component({
	selector: 'app-index-my-promoters',
	templateUrl: './index.component.html',
	styleUrls: []
})

export class MySupervisorsComponent implements OnInit {
	public title = 'MySupervisors';
	public ifDataExist = false;
	public promotersAvailable = [];
	public promotersSelected = [];
	public supervisorsSelected = [];
	public format = { add: 'Agregar Supervisor', remove: 'Remover Supervisor', all: 'Seleccionar Todos', none: 'Deseleccionar Todos',
	draggable: true, locale: 'es' };
	public user_id = sessionStorage.getItem('userId');

	constructor(
		private userService: UsersInformationService,
		private utilsService: UtilsService,
		private customHttpService: CustomHttpService,
	) {}

	ngOnInit() {
		const self = this;

		self.userService.getUserSupervisor().then(
			function (result) {
				let data = self.userService.getVarResults();
				data.forEach(user => {
					self.promotersAvailable.push({'userId':user.userId, 'name':user.fullName.toUpperCase() + ' - '+ user.userName + ' - '+ user.userRol});
				});
                self.ifDataExist = true;
				self.utilsService.debugConsole(result);
            }
		);

		self.userService.getUsersByUser(self.user_id).then(
			function (result) {
				let data = self.userService.getVarResults();
				data.forEach(user => {
					self.promotersAvailable.push({'userId':user.promoter.userId, 'name':user.promoter.fullName.toUpperCase() + ' - '+ user.promoter.userName+ ' - '+ user.userRol});
					self.promotersSelected.push({'userId':user.promoter.userId, 'name':user.promoter.fullName.toUpperCase() + ' - '+ user.promoter.userName+ ' - '+ user.userRol});
				});
				self.ifDataExist = true;
				self.utilsService.debugConsole(result);
            }
		);
	}

	public savePromoters(user_id){
		const self = this;
		let promoters = [];
		self.promotersSelected.forEach(user => {
			promoters.push(user.userId);
		});
		
		self.userService.postSavePromoters(self.user_id, promoters).then(
			function (result) {

            }
		);
	}
}
