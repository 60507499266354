import { Injectable } from '@angular/core';
import { CustomHttpService } from './customHttp.service';
import { BaseServiceService } from './baseService.service';

@Injectable()
export class UserService extends BaseServiceService {
    private controller = 'Users';

	constructor(
		public customHttpService: CustomHttpService
	) {
		super(customHttpService);
		this.TAG = this.controller;
		this.varGet = this.controller;
		this.varGetId = 'user';
	}

	public putUserCMS(UserId, request) {
        const self = this;
        return new Promise(
            resolve => {
                self.customHttpService.httpPUT(true, true, 'v1/api/Users/PutUserCMS/' + UserId, request).then(
                    function (response) {
                        if (response === false) { resolve(response); }
                        self.setVarResults(response["user"]);
                        resolve(true);
                    }
                );
            }
        );
    }


    public postUserCMS(request) {
        const self = this;
        return new Promise(
            resolve => {
                self.customHttpService.httpPOST(true, true, 'v1/api/Users/PostUserCMS/', request).then(
                    function (response) {
                        if (response === false) { resolve(response); }
                        self.setVarResults(response["user"]);
                        resolve(true);
                    }
                );
            }
        );
    }

    public getUsersByFilters(request) {
        const self = this;
        return new Promise(
            resolve => {
                self.customHttpService.httpPOST(true, true, 'v1/api/Users/GetUsersByFilters', request).then(
                    function (response) {
                        if (response === false) {
                            resolve(response);
                        }
                        self.setVarResults(response["listUsers"]);
                        resolve(true);
                    }
                );
            }
        );
    }


    public putChangeUserPassword(UserId, request) {
        const self = this;
        return new Promise(
            resolve => {
                self.customHttpService.httpPUT(true, true, 'v1/api/Users/ChangePasswordUser/' + UserId, request).then(
                    function (response) {
                        if (response === false) { resolve(response); }
                        self.setVarResults(response["user"]);
                        resolve(true);
                    }
                );
            }
        );
    }

    getAttendance(request){
		const self = this;
		return new Promise(
			resolve => {
				self.customHttpService.httpPOST(true, true, 'v1/api/Home/GetAttendance', request).then(
					function (response) {
						if (response === false) { resolve(response); }
						self.setVarResults(response);
						resolve(true);
					}
				);
			}
		);
	}
	getEmployeeInfo(request){
		const self = this;
		return new Promise(
			resolve => {
				self.customHttpService.httpPOST(true, true, 'v1/api/Home/GetEmployeeInfo', request).then(
					function (response) {
						if (response === false) { resolve(response); }
						self.setVarResults(response);
						resolve(true);
					}
				);
			}
		);
	}

	getUserStatusInfo(request){
		const self = this;
		return new Promise(
			resolve => {
				self.customHttpService.httpPOST(true, true, 'v1/api/Home/GetUserStatusInfo', request).then(
					function (response) {
						if (response === false) { resolve(response); }
						self.setVarResults(response);
						resolve(true);
					}
				);
			}
		);
	}


}
