import { Injectable } from '@angular/core';
import { CustomHttpService } from './customHttp.service';

@Injectable()
export class ScheduleService {
	private result: any;
	private controller = 'Schedule';

	constructor(
		public customHttpService: CustomHttpService
	) { }

	/** Función que trae todos los tipos de Agendamiento */
	getAllScheduleTypes() {
		const self = this;
		return new Promise(
			resolve => {
				// Get Info from 'v1/api/CmsGenerales/GetAllScheduleTypes'
				self.customHttpService.httpGET (false, true, 'v1/api/' + this.controller + '/GetAllScheduleTypes').then(
					function (response) {
						if (response === false) { resolve(response); }
						self.result = response['scheduleTypes'];
						resolve(true);
					}
				);
			}
		);
	}


	getTrainingTypes() {
		const self = this;
		return new Promise(
			resolve => {
				self.customHttpService.httpGET (false, true, 'v1/api/Generales/GetTrainigTypes').then(
					function (response) {
						if (response === false) { resolve(response); }
						self.result = response['trainigTypes'];
						resolve(true);
					}
				);
			}
		);
	}

	getRoutesById(id) {
		const self = this;
		return new Promise(
			resolve => {
				self.customHttpService.httpGET (false, true, 'v1/api/Generales/GetSiteLocationsByRoute/' + id).then(
					function (response) {
						if (response === false) { resolve(response); }
						self.result = response['siteLocations'];
						resolve(true);
					}
				);
			}
		);
	}

	getTrainingTopics() {
		const self = this;
		return new Promise(
			resolve => {
				self.customHttpService.httpGET (false, true, 'v1/api/Generales/GetTrainigTopics').then(
					function (response) {
						if (response === false) { resolve(response); }
						self.result = response['trainigTopics'];
						resolve(true);
					}
				);
			}
		);
	}

	/** Función que trae todos los Agendamientos por Team de Supervisores*/
	getScheduleForSupervisorTeam(request) {
		const self = this;
		return new Promise(
			resolve => {
				// Get Info from 'v1/api/Schedule/GetScheduleForSupervisorTeam'
				self.customHttpService.httpPOST(false, true, 'v1/api/' + this.controller + '/GetScheduleForSupervisorTeam', request).then(
					function (response) {
						if (response === false) { resolve(response); }
						self.result = response['workSchedule'];
						resolve(true);
					}
				);
			}
		);
	}

	/** Función que crea el agendamiento */
	async createSchedule(request) {
		const self = this;
		return await new Promise(
			resolve => {
				// Get Info from 'v1/api/CmsGenerales/CreateSchedule'
				self.customHttpService.httpPOST (false, true, 'v1/api/' + this.controller + '/CreateSchedule', request).then(
					function (response) {
						if (response === false) { resolve(response); }
						resolve(true);
					}
				);
			}
		);
	}

	/** Función que crea el agendamiento */
	async createScheduleList(request) {
		const self = this;
		return await new Promise(
			resolve => {
				// Get Info from 'v1/api/CmsGenerales/CreateScheduleList'
				self.customHttpService.httpPOST (true, true, 'v1/api/' + this.controller + '/CreateScheduleList', request).then(
					function (response) {
						if (response === false) { resolve(response); }
						resolve(true);
					}
				);
			}
		);
	}

	/** Función que crea el agendamiento */
	async deleteWorkSchedule(workScheduleID) {
		const self = this;
		return await new Promise(
			resolve => {
				// Get Info from 'v1/api/CmsGenerales/DeleteWorkSchedule/{workScheduleID}'
				self.customHttpService.httpGET (true, true, 'v1/api/' + this.controller + '/DeleteWorkSchedule/' + workScheduleID ).then(
					function (response) {
						if (response === false) { resolve(response); }
						resolve(true);
					}
				);
			}
		);
	}

	public getReportScheduleByUsers(request) {
        const self = this;
        return new Promise(
            resolve => {
                self.customHttpService.httpPOST(false, true, 'v1/api/Reportes/GetReportScheduleByUsers', request).then(
                    function (response) {
                        if (response === false) { resolve(response); }
                        self.setVarResults(response["reportScheduleByUser"]);
                        resolve(true);
                    }
                );
            }
        );
    }

    public getReportScheduleBySiteLocations(request) {
        const self = this;
        return new Promise(
            resolve => {
                self.customHttpService.httpPOST(false, true, 'v1/api/Reportes/GetReportScheduleBySiteLocations', request).then(
                    function (response) {
                        if (response === false) { resolve(response); }
                        self.setVarResults(["reportScheduleByLocation"]);
                        resolve(true);
                    }
                );
            }
        );
	}
	

	public getReportScheduleTeam(request) {
        const self = this;
        return new Promise(
            resolve => {
                self.customHttpService.httpPOST(false, true, 'v1/api/Schedule/GetScheduleTeam', request).then(
                    function (response) {
                        if (response === false) { resolve(response); }
                        self.setVarResults(response["workSchedule"]);
                        resolve(true);
                    }
                );
            }
        );
    }

	// Getters and Setter Variables
	getVarResults () { return this.result; }

	public setVarResults (resultVar): void { this.result = resultVar; }
}
