import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../../../../services/utils.service';
import { ReportDisplaySosService } from '../../../../services/reportDisplaySos.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-display-sos',
  templateUrl: './index.component.html',
  styleUrls: []
})
export class DisplaySosComponent implements OnInit {

  public title = 'ReportsDisplaySos';
  public ifDataExist = false;
  public ifExcelExist = true;
  public regionID = [];
  public cityID = [];
  public countryID = [];
  public data: any = [];
  public urlExcel: any;

  public tableTitles = [
    { name: 'year', label: 'Year', type: 'number' },
    { name: 'month', label: 'Month', type: 'month' },
    { name: 'yearWeek', label: 'Week', type: 'number' },
    { name: 'country', label: 'Country', type: 'normal' },
    { name: 'siteStore', label: 'Store', type: 'normal' },
    { name: 'siteLocation', label: 'Location', type: 'normal' },
    { name: 'site_id', label: 'ID Site', type: 'normal' },
    { name: 'category', label: 'Category', type: 'normal' },
    { name: 'productGroup', label: 'Product Group', type: 'normal' },
    { name: 'productType', label: 'Product Type', type: 'normal' },
    { name: 'brand', label: 'Brand', type: 'normal' },
    { name: 'product', label: 'Product', type: 'normal' },
    { name: 'sosType', label: 'SOS Type', type: 'normal' },
    { name: 'damageDegree', label: 'Damage', type: 'normal' },
    { name: 'description', label: 'Description', type: 'normal' },
    { name: 'photo1', label: 'Photo 1', type: 'image' },
    { name: 'photo2', label: 'Photo 2', type: 'image' },
    { name: 'photo3', label: 'Photo 3', type: 'image' },
    { name: 'photo4', label: 'Photo 4', type: 'image' },
    { name: 'photo5', label: 'Photo 5', type: 'image' },
    { name: 'photo6', label: 'Photo 6', type: 'image' },
    { name: 'fullName', label: 'User', type: 'normal' },
    { name: 'userName', label: 'User Name', type: 'normal' },
    { name: 'supervisors', label: 'Supervisor', type: 'normal' },
    { name: 'created_Date', label: 'Created Date', type: 'date' },
  ];

  public optionsTable = {
    dataId: 'displayPopSosID',
    showData: false,
    actionShowData: '',
    updateData: false,
    actionUpdateData: 'update',
    deleteData: false,
    actionDeleteData: '',
    filterCountryZone: false,
    filterSiteLocation: false,
    filterDateRange: true,
    filterSitestore: true,
    filterCity: true,
    filterCategory: true,
    filterCountry: true,
    filterRegion: true,
    filterCedis: false,
    searchBtn: true,
    exportBtn: true,
    itemsPerPage: 5,
  };

  constructor(
    private reportDisplaySosService: ReportDisplaySosService,
    private utilsService: UtilsService
  ) { }

  ngOnInit() {
    const self = this;
    const request = {};
    self.ifDataExist = true;
  }


  downloadExcelReport($event){
    const self = this;
    let request = {}
    request["listIds"] = $event.listIds;
    request["email"] = $event.email;
    this.getExcelDisplaySos(request);
  }

  filter(event) {
    let request = {}
    const self = this;
    if(event.start_Date && event.end_Date) {
      request = {
        'start_Date': event.start_Date,
        'end_Date': event.end_Date
      };
    } else return false;
    if(event.siteStoreId) {
      request['sitestores'] = [event.siteStoreId]
    } else if (event.cityId) {
      request['cities'] = [event.cityId]
    } else if (event.countryId) {
      request['countries'] = [event.countryId]
    } else if (event.regionId) {
      request['regions'] = [event.regionId]
    }
    if (event.categoryId) {
      request['categoryID'] = event.categoryId
    }
    this.getReportDisplaySos(request)
   
  }

  private getReportDisplaySos(request) {
    const self = this;
    self.reportDisplaySosService.getReportDisplaySos(request).then(
      function (result) {
        self.data = self.reportDisplaySosService.getVarResults();
        self.ifDataExist = true;
        self.urlExcel = true;
        self.utilsService.debugConsole(result);
      }
    );
  }

  private getExcelDisplaySos(request) {
    const self = this;
    self.reportDisplaySosService.getExcelDisplaySos(request).then(
      function (result) {
        let urlExcel = self.reportDisplaySosService.getVarResultsExcel();

        if( urlExcel.indexOf("http") == 0 ) {
            window.location.assign(urlExcel)
        } else {
           self.urlExcel = urlExcel;
        }
        self.utilsService.debugConsole(result);
      }
    );
  }


}