import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';

// Import Fragments
import { MenuComponent } from './fragment/menu/menu.component';
import { HeaderComponent } from './fragment/header/header.component';
import { FooterComponent } from './fragment/footer/footer.component';

// ImportPipes
import { CustomCurrencyPipe} from '../pipes/currencyPipe';
import { TranslatePipe } from '../pipes/translate';
import { SearchPipe } from '../pipes/searchPipe';
import { KeephtmlPipe } from '../pipes/keephtmlPipe';
import { ScheduleFilter } from '../pipes/scheduleFilter';

// Import Components
import { ChartDonutComponent } from './components/chart-donut/chart-donut.component';
import { ChartBarComponent } from './components/chart-bar/chart-bar.component';
import { CardUserPromoterComponent } from './components/card-user-promoter/card-user-promoter.component';
import { MyMapComponent } from './components/map/my-map.component';
import { RoutemapComponent } from './components/routemap/routemap.component';
import { ScheduleUsersComponent } from './components/schedule-user/schedule-users.component';
import { ScheduleDetailComponent } from './components/schedule/schedule.component';
import { TableComponent } from './components/table/table.component';
import { SearchComponentComponent } from './components/search-component/search-component.component';
import { DropDownListAllModule, MultiSelectModule } from '@syncfusion/ej2-angular-dropdowns';
import { NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { DatepickerModule, BsDatepickerModule, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxPaginationModule } from 'ngx-pagination'; // <-- import the module


// Import Pages and Forms
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { RutasComponent } from './pages/supervisorModule/rutas/rutas.component';
import { ScheduleComponent } from './pages/supervisorModule/schedule/schedule.component';

import { RegionsComponent } from './pages/storeManagment/regions/index.component';
import { FormRegionComponent } from './pages/storeManagment/regions/form/form.component';

import { CountriesComponent } from './pages/storeManagment/countries/index.component';
import { FormCountryComponent } from './pages/storeManagment/countries/form/form.component';

import { SegmentsComponent } from './pages/storeManagment/segments/index.component';
import { FormSegmentComponent } from './pages/storeManagment/segments/form/form.component';

import { StoresComponent } from './pages/storeManagment/stores/index.component';
import { FormStoresComponent } from './pages/storeManagment/stores/form/form.component';

import { CitiesComponent } from './pages/storeManagment/cities/index.component';
import { FormCitiesComponent } from './pages/storeManagment/cities/form/form.component';

import { LocationsComponent } from './pages/storeManagment/locations/index.component';
import { FormLocationsComponent } from './pages/storeManagment/locations/form/form.component';

import { CedisComponent } from './pages/storeManagment/cedis/index.component';
import { FormCedisComponent } from './pages/storeManagment/cedis/form/form.component';

import { CountryZonesComponent } from './pages/storeManagment/countryZones/index.component';
import { FormCountryZonesComponent } from './pages/storeManagment/countryZones/form/form.component';

import { DocumentTypeComponent } from './pages/fieldForceManagment/documentType/index.component';
import { FormDocumentTypeComponent } from './pages/fieldForceManagment/documentType/form/form.component';

import { UsersRolComponent} from './pages/usersManagment/usersRol/index.component';
import { FormUsersRolComponent} from './pages/usersManagment/usersRol/form/form.component';

import { UsersInformationComponent } from './pages/usersManagment/usersInformation/index.component';
import { UsersInformationShowComponent } from './pages/usersManagment/usersInformation/show.component';
import { PushNotificationComponent } from './pages/pushNotification/index.component';

import { DisconnectionTypeComponent } from './pages/fieldForceManagment/disconnectionType/index.component';
import { FormDisconnectionTypeComponent } from './pages/fieldForceManagment/disconnectionType/form/form.component';

import { UserScheduleComponent } from './pages/usersManagment/userSchedule/user-schedule.component';
import { StoreScheduleComponent } from './pages/usersManagment/storeSchedule/store-schedule.component';

import { ReportInventoryComponent } from './pages/reports/inventory/index.component';

// Import Directives
import { LoaderContentDirective } from './directives/loader-content.directive';

// Import Services
import { UtilsService } from '../services/utils.service';
import { CustomHttpService } from '../services/customHttp.service';
import { MethodsUtilsService } from '../services/methodsUtils.service';
import { MorrisJsModule } from 'angular-morris-js';
import { CmsGeneralesService } from '../services/cmsGenerales.service';
import { ScheduleService } from '../services/schedule.service';
import { RegionsService } from '../services/regions.service';
import { StoresService } from '../services/stores.service';
import { CitiesService } from '../services/cities.service';
import { UserService } from '../services/user.service';
import { LocationsService } from '../services/locations.service';
import { CountryZonesService } from '../services/countryZones.service';
import { CountriesService } from '../services/countries.service';
import { CedisService } from '../services/cedis.service';
import { SegmentsService } from '../services/segments.service';
import { CategoriesService } from '../services/categories.service';
import { ProductGroupsService } from '../services/productGroups.service';
import { ProductTypesService } from '../services/productTypes.service';
import { ChanelsService } from '../services/chanels.service';
import { ExcelService } from '../services/excel.service';
import { DocumentTypeService } from '../services/documentType.service';
import { DisconnectionTypeService } from '../services/disconnectionType.service';
import { ReportPopExecuteService } from '../services/reportPopExecute.service';
import { ReportPopRequestService } from '../services/reportPopRequest.service';
import { ReportPriceEvidenceService } from '../services/reportPriceEvidence.service';
import { ReportSamplingAuditService } from '../services/reportSamplingAudit.service';
import { ReportPromoterNowService } from '../services/reportPromoterNow.service';
import { PopTypeService } from '../services/popType.service';
import { PopExecutionTypeService } from '../services/popExecutionType.service';
import { PopTipologyService } from '../services/popTipology.service';
import { PopSosTypeService } from '../services/popSosTypes.service';
import { CampaignService } from '../services/campaign.service';
import { PopByProductService } from '../services/popByProduct.service';
import { ReportDisplaySosService } from '../services/reportDisplaySos.service';
import { UsersRolService } from '../services/usersRol.service';
import { UsersInformationService } from '../services/usersInformation.service';
import { FiltersService } from '../services/filters.service';
import { PushNotificationService } from '../services/pushNotification.service';
import { PdfService } from '../services/pdf.service';
import { ProductService } from '../services/product.service';
import { PopSosSubTypesService } from '../services/popSosSubTypes.service';
import { ReportInventoryService } from '../services/reportInventory.service';
import { ReportSelloutService } from '../services/reportSelloutservice';
import { ReportsPromoterCheckInOutComponent } from './pages/reports/promotersCheckInOut/index.component';
import { StaffInfoService } from '../services/staff-info.service';


// Import Third Components
import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';   // agm-direction
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTableModule } from 'angular-6-datatable';
import { CategoriesComponent } from './pages/storeManagment/categories/index.component';
import { FormCategoryComponent } from './pages/storeManagment/categories/form/form.component';
import { ProductTypesComponent } from './pages/storeManagment/products/productTypes/index.component';
import { FormProductTypeComponent } from './pages/storeManagment/products/productTypes/form/form.component';
import { ChannelsComponent } from './pages/storeManagment/channels/index.component';
import { FormChannelComponent } from './pages/storeManagment/channels/form/form.component';
import { FormPopExecuteComponent } from './pages/reports/popExecution/form/form.component';
import { PopExecutionComponent } from './pages/reports/popExecution/index.component';
import { PopRequestComponent } from './pages/reports/popRequest/index.component';
import { FormPopRequestComponent } from './pages/reports/popRequest/form/form.component';
import { ReportsPromoterControlComponent } from './pages/reports/promotersNow/index.component';
import { ReportsPromoterConsolidateComponent } from './pages/reports/promotersConsolidate/index.component';
import { ReportsPromoterTrackComponent } from './pages/reports/promotersTrack/index.component';


import { SamplingAuditComponent } from './pages/reports/samplingAudit/index.component';
import { FormUserInformationComponent } from './pages/usersManagment/usersInformation/form/form.component';
import { DisplaySosComponent } from './pages/reports/displaySos/index.component';

import { OptionsPopTypeComponent } from './pages/optionsReports/popType/index.component';
import { FormOptionsPopTypeComponent } from './pages/optionsReports/popType/form/form.component';

import { OptionsPopExecutionTypeComponent } from './pages/optionsReports/popExecutionType/index.component';
import { FormOptionsPopExecutionTypeComponent } from './pages/optionsReports/popExecutionType/form/form.component';

import { OptionsPopTipologyComponent } from './pages/optionsReports/popTipology/index.component';
import { FormOptionsTipologyComponent } from './pages/optionsReports/popTipology/form/form.component';

import { OptionsPopSosTypesComponent } from './pages/optionsReports/popSosTypes/index.component';
import { FormOptionsPopSosTypesComponent } from './pages/optionsReports/popSosTypes/form/form.component';

import { OptionsCampaingComponent } from './pages/optionsReports/campaing/index.component';
import { FormOptionsCampaingComponent } from './pages/optionsReports/campaing/form/form.component';

import { OptionsPopByProductComponent } from './pages/optionsReports/popByProduct/index.component';
import { FormOptionsPopByProductComponent } from './pages/optionsReports/popByProduct/form/form.component';

import { ProductComponent } from './pages/storeManagment/product/index.component';
import { FormProductComponent } from './pages/storeManagment/product/form/form.component';

import { PopSosSubTypesComponent } from './pages/optionsReports/popSosSubTypes/index.component';
import { FormPopSosSubTypesComponent } from './pages/optionsReports/popSosSubTypes/form/form.component';

import { MySupervisorsComponent } from './pages/supervisorModule/mySupervisors/index.component';
import { SupervisorPromotersComponent } from './pages/supervisorModule/supervisorPromoters/index.component';

import { AngularDualListBoxModule } from 'angular-dual-listbox';

import { FormBrandComponent } from './pages/storeManagment/brand/form/form.component';
import { BrandComponent } from './pages/storeManagment/brand/index.component';
import { BrandsService } from 'src/services/brands.service';

import { GiveAwayTypesComponent } from './pages/storeManagment/giveAwayTypes/index.component';
import { GiveAwayCampaignsComponent } from './pages/storeManagment/giveAwayCampaigns/index.component';

import { FormGiveAwayTypesComponent } from './pages/storeManagment/giveAwayTypes/form/form.component';
import { FormGiveAwayCampaignsComponent } from './pages/storeManagment/giveAwayCampaigns/form/form.component';

import { GiveAwayCampaignsService } from 'src/services/giveAwayCampaigns.service';
import { GiveAwayTypesService } from 'src/services/giveAwayTypes.service';
import { ReportGiveAwayService } from 'src/services/reportGiveAway.service';
import { ReportGiveAwayComponent } from './pages/reports/giveAway/index.component';

import { ProductSiteStoreComponent } from './pages/storeManagment/products/productSiteStore/index.component';
import { FormProductSiteStoreComponent } from './pages/storeManagment/products/productSiteStore/form/form.component';
import { ProductSiteStoreService } from 'src/services/productSiteStore.service';

import { PromotionTypesComponent } from './pages/storeManagment/promotionType/index.component';
import { FormPromotionTypesComponent } from './pages/storeManagment/promotionType/form/form.component';
import { PromotionTypesService } from 'src/services/promotionType.service';

import { PromotionSubTypesComponent } from './pages/storeManagment/promotionSubType/index.component';
import { FormPromotionSubTypesComponent } from './pages/storeManagment/promotionSubType/form/form.component';
import { PromotionSubTypesService  } from 'src/services/promotionSubType.service';
import { ReportStoreActivityComponent } from './pages/reports/storeActivity/index.component';
import { ReportStoreActivityService } from 'src/services/reportStoreActivity.service';

import { ReportFlooringComponent } from './pages/reports/reportFlooring/index.component';
import { ReportPriceEvidenceComponent } from './pages/reports/priceEvidence/index.component';
import { ReportFlooringService } from 'src/services/reportFlooring.service';
import { ReportSelloutComponent } from './pages/reports/sellout/index.component';



import { SurveysComponent } from './pages/surveys/surveys.component';
import { CreateSurveysComponent } from './pages/surveys/form/surveys-create.component';
import { ShowSurveysComponent } from './pages/surveys/surveys-show.component';
import { ReportSurveysComponent } from './pages/surveys/report/report.component';

import { RouteManagementComponent } from './pages/usersManagment/route-management/route.component';
import { ShowRouteManagementComponent } from './pages/usersManagment/route-management/show.component';
import { RouteManagementService } from '../services/route-management.service';

import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { SurveyService } from 'src/services/surveys.service';
import { ModalService } from 'src/services/modal.service';
import { ModalComponent } from './fragment/modal/modal.component';

import { NgCircleProgressModule } from 'ng-circle-progress';
import { PopExecutionSelaComponent } from './pages/reports/popExecutionSela/index.component';
import {ModalAlertComponent} from './components/modal-alert/modal-alert.component';
import {LoginService} from '../services/login.service';
import {PasswordRecoveryComponent} from './pages/password-recovery/password-recovery.component';
import {FormPopExecuteSelaComponent} from './pages/reports/popExecutionSela/form/form.component';

@NgModule({
  declarations: [
    // Fragments
    MenuComponent,
    HeaderComponent,
    FooterComponent,
    // Pipe
    CustomCurrencyPipe,
    TranslatePipe,
    SearchPipe,
    KeephtmlPipe,
    ScheduleFilter,
    // Directive
    LoaderContentDirective,
    // Components
    ChartDonutComponent,
    ChartBarComponent,
    CardUserPromoterComponent,
    MyMapComponent,
    RoutemapComponent,
    ScheduleUsersComponent,
    ScheduleDetailComponent,
    TableComponent,
    SearchComponentComponent,

    // Pages
    AppComponent,
    LoginComponent,
    HomeComponent,
    AccessDeniedComponent,
    RutasComponent,
    ScheduleComponent,
    UserScheduleComponent,
    StoreScheduleComponent,
    RegionsComponent,
    FormRegionComponent,
    CountriesComponent,
    FormCountryComponent,
    SegmentsComponent,
    FormSegmentComponent,
    CategoriesComponent,
    FormCategoryComponent,
    ProductTypesComponent,
    FormProductTypeComponent,
    ChannelsComponent,
    FormChannelComponent,
    StoresComponent,
    FormStoresComponent,
    UsersRolComponent,
    CitiesComponent,
    FormCitiesComponent,
    LocationsComponent,
    FormLocationsComponent,
    CedisComponent,
    FormCedisComponent,
    CountryZonesComponent,
    FormCountryZonesComponent,
    DocumentTypeComponent,
    DisconnectionTypeComponent,
    FormDocumentTypeComponent,
    UsersRolComponent,
    FormUsersRolComponent,
    UsersInformationComponent,
    UsersInformationShowComponent,
    PushNotificationComponent,
    DisconnectionTypeComponent,
    FormDisconnectionTypeComponent,
    FormPopExecuteComponent,
    PopExecutionComponent,
    PopExecutionSelaComponent,
    PopRequestComponent,
    FormPopRequestComponent,
    SamplingAuditComponent,
    FormUserInformationComponent,
    ReportsPromoterControlComponent,
    ReportsPromoterConsolidateComponent,
    DisplaySosComponent,
    ReportsPromoterControlComponent,
    ReportsPromoterTrackComponent,
    OptionsPopTypeComponent,
    OptionsPopExecutionTypeComponent,
    OptionsPopTipologyComponent,
    FormOptionsPopTypeComponent,
    FormOptionsPopExecutionTypeComponent,
    FormOptionsTipologyComponent,
    OptionsPopSosTypesComponent,
    OptionsCampaingComponent,
    OptionsPopByProductComponent,
    FormOptionsPopSosTypesComponent,
    FormOptionsCampaingComponent,
    FormOptionsPopByProductComponent,
    ProductComponent,
    FormProductComponent,
    MySupervisorsComponent,
    SupervisorPromotersComponent,
    FormBrandComponent,
    BrandComponent,
    SupervisorPromotersComponent,
    PopSosSubTypesComponent,
    FormPopSosSubTypesComponent,
    GiveAwayTypesComponent,
    GiveAwayCampaignsComponent,
    FormGiveAwayTypesComponent,
    FormGiveAwayCampaignsComponent,
    ReportGiveAwayComponent,
    ProductSiteStoreComponent,
    FormProductSiteStoreComponent,
    ReportsPromoterCheckInOutComponent,
    PromotionTypesComponent,
    FormPromotionTypesComponent,
    PromotionSubTypesComponent,
    FormPromotionSubTypesComponent,
    ReportStoreActivityComponent,
    ReportFlooringComponent,
    ReportPriceEvidenceComponent,
    SurveysComponent,
    CreateSurveysComponent,
    ShowSurveysComponent,
    ReportSurveysComponent,
    ModalComponent,
    ReportInventoryComponent,
    ReportSelloutComponent,
    RouteManagementComponent,
    ShowRouteManagementComponent,
    ModalAlertComponent,
    PasswordRecoveryComponent,
    FormPopExecuteSelaComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    MorrisJsModule,
    NgbModalModule,
    DataTableModule,
    DropDownListAllModule,
    MultiSelectModule,
    NgbModule,
    DatepickerModule,
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    NgCircleProgressModule.forRoot({}),
    AgmCoreModule.forRoot({
      // apiKey: 'AIzaSyC2vG7ogPgTE3E9Y63MMQ9JW9Xeqf5Vp0Y'
      apiKey: 'AIzaSyB1QRJq3dL5TrbDlCaHjTFbbFrs2GO6MRg'
    }),
    NgMultiSelectDropDownModule.forRoot(),
    AgmDirectionModule,
    AgmJsMarkerClustererModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    BrowserAnimationsModule,
    AngularDualListBoxModule,
    NgxPaginationModule
  ],
  providers: [
    UtilsService,
    CustomHttpService,
    MethodsUtilsService,
    CmsGeneralesService,
    ScheduleService,
    RegionsService,
    StoresService,
    CitiesService,
    CountriesService,
    LocationsService,
    SegmentsService,
    CategoriesService,
    ProductGroupsService,
    ProductTypesService,
    ChanelsService,
    ExcelService,
    CedisService,
    CountryZonesService,
    DocumentTypeService,
    DisconnectionTypeService,
    UsersRolService,
    UsersInformationService,
    FiltersService,
    PushNotificationService,
    ReportPopExecuteService,
    ReportPopRequestService,
    ReportPriceEvidenceService,
    ReportSamplingAuditService,
    ReportDisplaySosService,
    UserService,
    PdfService,
    ReportPromoterNowService,
    BsLocaleService,
    PopTypeService,
    PopSosTypeService,
    PopExecutionTypeService,
    PopTipologyService,
    CampaignService,
    PopByProductService,
    BrandsService,
    ProductService,
    PopSosSubTypesService,
    GiveAwayCampaignsService,
    GiveAwayTypesService,
    ReportGiveAwayService,
    ReportGiveAwayComponent,
    ProductSiteStoreService,
    PromotionTypesService,
    PromotionSubTypesService,
    ReportStoreActivityService,
    ReportFlooringService,
    SurveyService,
    ModalService,
    ReportInventoryService,
    ReportSelloutService,
    RouteManagementService,
    LoginService,
    StaffInfoService
  ],
  bootstrap: [AppComponent]

})
export class AppModule {



}
