import { Component, OnInit } from '@angular/core';
import { NgbModalConfig, NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-dialog',
  template:'',
	/*template:`
<div>
  <div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
  </div>
  <div class="modal-body">
    <p>{{subtitle}}</p>
  </div>
  <div class="modal-footer">
    <button type="button"
	  class="btn btn-outline-dark"
	   (click)="activeModal.close(false)">Cancel</button>
    <button type="button"
      class="btn btn-outline-dark"
      (click)="activeModal.close(true)">Send</button>
  </div>
</div>`,*/
})
export class ModalComponent implements OnInit {

	title: string;
	subtitle: string;

	constructor(
		//public activeModal: NgbActiveModal
	) {}

	ngOnInit() { }

}
