import { Component, OnInit, Input, ViewChild, TemplateRef, ViewChildren, ɵConsole, AfterViewInit, AfterViewChecked, AfterContentChecked, OnChanges, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UtilsService } from '../../../../services/utils.service';
import { MethodsUtilsService } from '../../../../services/methodsUtils.service';
import { SurveyService } from 'src/services/surveys.service';
import { FormularioBaseComponent } from 'src/app/utils/formularioBase.component';
import { Survey } from 'src/models/survey';
import { Question } from 'src/models/question';
import * as CanvasJS from 'src/assets/js/canvasjs.min.js';
import { ExcelService } from 'src/services/excel.service';

declare var $

@Component({
	selector: 'app-surveys-report',
	templateUrl: './report.component.html',
	styleUrls: []
})

export class ReportSurveysComponent extends FormularioBaseComponent implements OnInit {


	public survey = new Survey();
	public surveyQuestions: Question[] = [];

	public ifDataExist = false;
	public data: any = [];
	public today = new Date();
	public regionAccess:boolean = false;

	public regionFilterList: any = [];
	public countryFilterList: any = [];
	public rolFilterList: any = [];
	public categoryFilterList: any = [];
	public storeFilterList: any = [];
	public locationFilterList : any = [];
	public questionTypesList: any = [];
	public employeeTypeFilterList: any = [];

	public end_hours: any;
	public end_minutes: any;
	public start_hours: any;
	public start_minutes: any;
	public title: string;
	public questionCount = 0;

	// Inject HttpClient into your component or service.
	constructor(
		private route: ActivatedRoute,
		public utilsService: UtilsService,
		public methodsUtilsService: MethodsUtilsService,
		private surveyService: SurveyService,
		private ref: ChangeDetectorRef,
		private router: Router,
	){
		super(utilsService, methodsUtilsService);
	}

	ngOnInit(): void {
		const self = this;
		self.param = self.route.params.subscribe(
		params => {
			if (params['id']) {
				let surveyID = params['id'];
				self.getSurveyByReportID(surveyID);
			}
		}
		);
	}

	upDown(element, i, question){
		const self = this;
		self.ref.detectChanges();
		element.abierto = !element.abierto; 
		self.printGraph(i, question);      
	}

	downloadReport(question){
		const cls = new ExcelService();
		let excelData = [];
		question.listResponses.forEach(function (row, key) {
			excelData.push({
				"User":row.user.fullName, 
				"Rol" :row.user.level, 
				"Supervisor" :row.user.supervisors, 
				"Response":row.response,
				"File":row.photo
			});
		});
	
		cls.exportAsExcelFile(excelData, this.router.url);
	}





	printGraph(element, question){
		const self = this;
		if(question.table){
			let dataPoints = [];
			let title = question.question;
			
			for (let dat of Object.values(question.tableData.table))
			{
				dataPoints.push({ y: parseInt(dat["count"]), label: dat["title"], z: dat["percent"]  })
			}

			$(".graph_"+element).show();
			$(".graph_"+element).width("800");
			$(".graph_"+element).height("260");
			let chart = new CanvasJS.Chart("graph_"+element, {
				animationEnabled: true,
				height: 240,
				width: 800,
				axisY:{
					title: "Options",
					interval: 10,
					labelFontSize: 16,
				},
				toolTip: {
					shared: true
				},
				data: [{
					type: "bar",
					name: "Responses",
					toolTipContent: "<b>{label}</b> <br> <span style=\"color:#4F81BC\">{name}</span>: {y}",
					dataPoints: dataPoints,
					color: "#1DB954",  
					indexLabel: "{y} Responses - {z}%",
				},]
			});
			
			chart.render();
		}
	}


	getUserResponse(response){
		let tempResponse =
			{
				userId: response.user.userId,
				img: (response.photo ? response.photo : '../../assets/images/perfil.png'),
				name: response.user.fullName,
				//cargo: response.user.employeeType.employeeType,
				rol: response.user.userRolID,
				response: response.response,
				type: response.txt,
			};
		return tempResponse;
	}

	getResponses(response){
		if (response.indexOf(',') > -1) {
			return JSON.parse(response).join(", ");
		}else{
			response = response.replace("[", '');
			response = response.replace("]", '');
			response = response.replace('"', '');
			response = response.replace('"', '');
			return response;
		}
	}

	getSurveyByReportID(surveyID){
		const self = this;
		self.surveyService.getSurveyReportByID(surveyID).then(
			function (response) {
				if (response) {
					let surveyTemp = self.surveyService.getVarResults();
					self.survey.setSurvey(surveyTemp["survey"]); 
					self.surveyQuestions = surveyTemp["listQuestions"]; 
					self.ref.detectChanges();
					let k = 0;
				
					for(let qt of self.surveyQuestions){
						self.printGraph(k,qt);
						k = k + 1;
					}
				
				}
			}
		);
	}


}
