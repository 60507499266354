/**
 * Created by Juan Vidales Cheil on 30/5/2019.
 */
export class ProductType {
	public productTypeID: number; // 123
	public categoryID: number; // 123
	public type: string; // El nombre max 50
	public created_By: string; // El nombre de quien lo crea
	private created_Date: string; // "2019-05-31T02:33:33.380Z"
	private updated_Date: string; // "2019-05-31T02:33:33.380Z"

	constructor() {
		this.initalizeProductType();
	}

	initalizeProductType() {
		this.productTypeID = null;
		this.categoryID = null;
		this.type = null;
		this.created_By = null;
		this.created_Date = null;
		this.updated_Date = null;
	}

	setProductType(productType) {
		this.productTypeID = productType.productTypeID;
		this.categoryID = productType.categoryID;
		this.type = productType.type;
		this.created_By = productType.created_By;
		this.created_Date = productType.created_Date;
		this.updated_Date = productType.updated_Date;
	}

	getJsonBase(): any {
		const jsonBase = {
			'categoryID': this.categoryID,
			'type': this.type
		};
		return jsonBase;
	}

	getJsonCreate(): any {
		const json = this.getJsonBase();
		if (this.created_By !== null) { json['created_By'] = this.created_By; }
		return json;
	}

	getJsonUpdate(): any {
		const json = this.getJsonBase();
		if (this.created_Date !== null) { json['created_Date'] = this.created_Date; }
		if (this.created_By !== null) { json['created_By'] = this.created_By; }
		if (this.productTypeID !== null) { json['productTypeID'] = this.productTypeID; }
		return json;
	}
}
