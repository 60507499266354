/**
 * Created by Viviana Rincón Cheil on 24/07/2019.
 */

import { Country } from './country';

export class OptionPopTipology {

    /*datos para crear reporte */
    public popTipologyID: number;
    public type: string;
    public countryID: number;
    public created_By: string;
    public created_Date: string;
    public updated_Date: string;
    public country: Country;

    constructor() {
        this.initalizePopTipology();
    }

    initalizePopTipology() {
        this.popTipologyID = null;
        this.type = null;
        this.countryID=null;
        this.created_By = null;
        this.updated_Date = null;
        this.created_Date = null;
        this.country = new Country();
    }

    setPopTipology(optionPopTipology) {
        this.popTipologyID = optionPopTipology.popTipologyID;
        this.countryID = optionPopTipology.countryID
        this.type = optionPopTipology.type;
        this.created_By = optionPopTipology.Created_By;
        this.updated_Date = optionPopTipology.Updated_Date;
        this.created_Date = optionPopTipology.Created_Date;
        if (optionPopTipology.hasOwnProperty('country') && optionPopTipology.country !== null) { this.country.setCountry(optionPopTipology.country); }
    }

    getJsonBase(): any {
        const jsonBase = {
            'type': this.type,
            'countryID': this.countryID,
        };
        return jsonBase;
    }

    getJsonCreate(): any {
        const json = this.getJsonBase();
        if (this.created_By !== null) { json['created_By'] = this.created_By; }
        return json;
    }

    getJsonUpdate(): any {
        const json = this.getJsonBase();
        if (this.created_Date !== null) { json['created_Date'] = this.created_Date; }
        if (this.created_By !== null) { json['created_By'] = this.created_By; }
        if (this.popTipologyID !== null) { json['popTipologyID'] = this.popTipologyID; }
        return json;
    }
}