import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from '../../../../../services/utils.service';
import { MethodsUtilsService } from '../../../../../services/methodsUtils.service';
import { FormularioBaseComponent } from '../../../../utils/formularioBase.component';
import { PopTipologyService } from '../../../../../services/popTipology.service';
import { OptionPopTipology } from '../../../../../models/popTipology';
import { CountriesService } from '../../../../../services/countries.service';

// Declare Vars
declare var $: any;

@Component({
  selector: 'app-form-Typology',
  templateUrl: './form.component.html',
  styleUrls: []
})

export class FormOptionsTipologyComponent extends FormularioBaseComponent implements OnInit {

  public page = 'PopTipology';
  public title = 'PopTipology.Create';
  public titleButton = 'Save';
  public popTipology = new OptionPopTipology();
  public regionId;

  constructor(
    private route: ActivatedRoute,
    private popTipologyService: PopTipologyService,
    private countriesService: CountriesService,
    private router: Router,
    public utilsService: UtilsService,
    public methodsUtilsService: MethodsUtilsService,
  ) {
    super(utilsService, methodsUtilsService);
  }

  ngOnInit() {
    const self = this;
    self.getRegionsList();
    self.param = self.route.params.subscribe(
      params => {
        if (params['id']) {
          self.title = 'PopTipology.Update';
          self.titleButton = 'Update';
          self.utilsService.debugConsole(params['id']);
          self.getTipology(params['id']);
        }
      }
    );
  }

  public selectedRegion(): void {
    const self = this;
    if (self.regionId != null) {
      self.getListCountriesByRegion(self.regionId);
    } else {
      self.popTipology.countryID = null;
     
    }
  }

  private getTipology(id: any): void {
    const self = this;
    self.popTipologyService.getResult(id).then(
      function (response) {
        if (response === false) { return; }
        self.popTipology.setPopTipology(self.popTipologyService.getVarResults());
        self.regionId = self.popTipology.country.regionId;
        self.selectedRegion();
      
      }
    );
  }


 
  public saveUpdatePopTipology(): void {
    const self = this;
    self.popTipology.created_By = self.utilsService.getUserId();
    if (self.popTipology.popTipologyID !== null) {
      self.popTipologyService.putData(self.popTipology.popTipologyID, self.popTipology.getJsonUpdate()).then(
        function (response) {
          if (response === false) { return; }
          self.router.navigate(['/option-pop-tipology']);
        }
      );
    } else {
      self.popTipologyService.postData(self.popTipology.getJsonCreate()).then(
        function (response) {
          if (response === false) { return; }
          self.router.navigate(['/option-pop-tipology']);
        }
      );
    }
  }

}
