import { Injectable } from '@angular/core';
import { CustomHttpService } from './customHttp.service';
import { BaseServiceService } from './baseService.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Injectable()
export class DocumentTypeService extends BaseServiceService {
	private controller = 'DocumentTypes';

	constructor(
		public customHttpService: CustomHttpService
	) {
		super(customHttpService);
		this.TAG = this.controller;
		this.varGet = 'list' + this.controller;
		this.varGetId = 'documentType';
	}

}
