import { Injectable } from '@angular/core';
import { CustomHttpService } from './customHttp.service';
import { BaseServiceService } from './baseService.service';
import { DatePipe } from '@angular/common';
import * as $ from 'jquery';

@Injectable()
export class ReportPromoterNowService extends BaseServiceService {
	private controller = 'Reportes';

	constructor(
		public customHttpService: CustomHttpService
	) {
		super(customHttpService);
    }
    
    public getReportPromotersNow(request) {
        const self = this;
        return new Promise(
            resolve => {
                self.customHttpService.httpPOST(true, true, 'v1/api/Reportes/GetReportPromotoresNow', request).then(
                    function (response) {
                        if (response === false) { resolve(response); }
                        self.setVarResults(response["reportPromotorsNow"]);
                        resolve(true);
                    }
                );
            }
        );
    }


    public getReportPromotersConsolidate(request) {
        const self = this;
        return new Promise(
            resolve => {
                self.customHttpService.httpPOST(true, true, 'v1/api/Reportes/GetReportPromotoresConsolidate', request).then(
                    function (response) {
                        if (response === false) { resolve(response); }
                        self.setVarResults(response["reportPromotoresConsolidate"]);
                        resolve(true);
                    }
                );
            }
        );
    }

    public getReportCheckInOut(request) {
        const self = this;
        return new Promise(
            resolve => {
                self.customHttpService.httpPOST(true, true, 'v1/api/Reportes/GetReportCheckInOuts', request).then(
                    function (response) {
                        if (response === false) { resolve(response); }
                        self.setVarResults(response["reportPromotoresConsolidate"]);
                        resolve(true);
                    }
                );
            }
        );
    }

    public getReportCheckInOutExcel(request) {
        const self = this;
        return new Promise(
            resolve => {
                self.customHttpService.httpPOST(true, true, 'v1/api/ReportesExcel/GetReportCheckInOuts', request).then(
                    function (response) {
                        if (response === false) { resolve(response); }
                        self.setVarResults(response['reportUrl']);
                        resolve(true);
                    }
                );
            }
        );
    }


    public getTrackingForSupervisorTeam(request) {
        const self = this;
        return new Promise(
            resolve => {
                self.customHttpService.httpPOST(true, true, 'v1/api/Reportes/GetTarckingForSupervisorTeam', request).then(
                    function (response) {
                        if (response === false) { resolve(response); }
                        let tracks = response["trackReport"];

                        console.log(response);
                        
                        let tracksInfo = [];
                        tracks.forEach(function(track){
                            if(track[0].trackList.length > 0){
                                track[0].trackList.forEach(function(trackelem){
                                    tracksInfo.push({'userId':track[0].userName, 'fullName':track[0].fullName, 'trackList':trackelem});
                                });
                            }
                        });
                        console.log(tracksInfo);
                        
                        self.setVarResults(tracksInfo);
                        resolve(true);
                    }
                );
            }
        );
    }
}
