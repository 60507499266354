import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CustomHttpService } from './customHttp.service';
import { BaseServiceService } from './baseService.service';


@Injectable()
export class RouteManagementService extends BaseServiceService {
    private controller = 'Routes';

    constructor(
        public customHttpService: CustomHttpService,
        private router: Router
    ) {
        super(customHttpService);
        this.TAG = this.controller;
        this.varGet = this.controller;
        this.varGetId = 'routes';
    }

    public getRoutesManagement(route_id) {
        const self = this;
        return new Promise(
            resolve => {
                self.customHttpService.httpGET(true, true, 'v1/api/Routes/GetRoute/' + route_id).then(
                    function (response) {
                        if (response === false) {
                            resolve(response);
                        }
                        self.setVarResults(response);
                        resolve(true);
                    }
                );
            }
        );
    }

    public updateRoutesManagement(request) {
        const self = this;
        return new Promise(
            resolve => {
                self.customHttpService.httpPOST(true, true, 'v1/api/Routes/AddorUpdateRoute', request).then(
                    function (response) {
                        if (response === false) {
                            resolve(response);
                        }


                        self.setVarResults(response['route']);
                        resolve(response);
                    }
                );
            }
        );
    }

    public deleteRoutesManagement(request) {
        const self = this;
        return new Promise(
            resolve => {
                self.customHttpService.httpPOST(true, true, 'v1/api/Routes/DeleteRoute', request).then(
                    function (response) {
                        if (response === false) {
                            resolve(response);
                        }


                        self.setVarResults(response['route']);
                        resolve(true);
                    }
                );
            }
        );
    }

  public setPrincipal = (request) => {
    const self = this;
    return new Promise(
      resolve => {
        self.customHttpService.httpPOST(true, true, 'v1/api/Routes/setPrincipal', request).then(
          function (response) {
            if (response === false) {
              resolve(response);
            }
            self.setVarResults(response['route']);
            resolve(true);
          }
        );
      }
    );
  }
}
