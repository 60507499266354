import { Component, Input, OnInit, OnChanges, EventEmitter } from '@angular/core';


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'routemap',
  templateUrl: './routemap.component.html',
  styleUrls: []
})
export class RoutemapComponent implements OnInit, OnChanges {

  constructor() { }
  @Input() options: any;
  @Input() points: any;
  @Input() showMarkerInfo = false;
  @Input() title: string;
  @Input() showInfoPoints: boolean;
  @Input() showHorizontal: boolean;
  @Input() titleListPoints: string;
  @Input() showCalculateRoute: boolean;
  @Input() showTrackRoute: boolean;
  @Input() showTrackerInfo = false;
  @Input() showTrackerInfoTable = false;
  @Input() origin: any;
  @Input() waypoints: any;
  @Input() destination: any;
  @Input() zoom = 8;
  public openedWindow = 0;

  public markers: any;

  public optionsTableTrack = {
    showData: false,
    updateData: false,
    deleteData: false,
    filterCountryZone: false,
    filterSiteLocation: false,
    filterSitestore: false,
    filterCity: false,
    filterCountry: false,
    filterRegion: false,
    filterCedis: false,
    filterDateRange: false,
    searchBtn: true,
    exportBtn: true,
  };

  public tableTitlesTrack = [
    { name: 'trackDate', label: 'Fecha', type: 'dateTime' },
    { name: 'storeDistance', label: 'Distancia', type: 'distance' },
    { name: 'wifiTrack', label: 'WiFi Track', type: 'boolean' },
    { name: 'wifiAccuracy', label: 'WiFi Accuracy', type: 'percent' },
    { name: 'enTienda', label: 'En Tienda', type: 'inStore' },
  ];

  ngOnChanges(changes) {
    this.reloadPoints();
  }

  ngOnInit(): void {
    this.markers = this.points;
  }

  public addToList(point: any): void {
    point.select = true;
    this.reloadPoints();
  }

  public removeToList(point: any): void {
    point.select = false;
    this.reloadPoints();
  }

  public reloadPoints(): void {
    this.markers = [];
    for (const point of this.points) {
      if (point.select) {
        this.markers = [
          ...this.markers,
          point
        ];
      }
    }
  }

  public calculateRoute(): void {
    this.reloadPoints();
  }

  openWindow(id) {
    this.openedWindow = id;
  }

  wifiAverage(points) {
    let sum = 0;
    for (let i = 0; i < points.length; i++) {
      sum += parseInt(points[i].wifiAccuracy, 10); // don't forget to add the base
    }

    const avg = sum / points.length;

    return avg.toFixed(2);
  }

  isInfoWindowOpen(id) {
    return this.openedWindow === id;
  }
}
