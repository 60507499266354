import { SiteStores } from './stores';
import { Country } from './country';

export class SiteLocations {
    public siteLocationID: number; // 123
    public siteStoreID: number; // 123
    public countryID: number; // 123
    public countryZoneID: number; // 123
    public cityID: number; // 123
    public segmentID: number; // 123
    public location: string; // max Length 50
    public address: string; // max Length 100
    public name: string; // max Length 50
    public monitoring: boolean; // true or false
    public samsung_official: boolean; // true or false
    public segment_IM: string; // max length 1
    public segment_AV: string; // max length 1
    public segment_DA: string; // max length 1
    public training: boolean; // true or false
    public market: boolean; // true or false
    public allocation: boolean; // true or false
    public latitude: string;
    public longitude: string;
    public customer_code: string;
    public site_id: string;
    public subs_code: string;
    public active: boolean; // true or false
    public allowWifiCheckIn: boolean; // true or false
    public created_By: string;
    private created_Date: string;
    private updated_Date: string;
    public country: Country;
    public siteStore: SiteStores;

    constructor() {
        this.initalizeSiteLocation();
    }

    initalizeSiteLocation() {
        this.siteLocationID = null;
        this.siteStoreID = null;
        this.countryID = null;
        this.countryZoneID = null;
        this.cityID = null;
        this.segmentID = null;
        this.location = null;
        this.address = null;
        this.name = null;
        this.monitoring = null;
        this.samsung_official = null;
        this.segment_IM = null;
        this.segment_AV = null;
        this.segment_DA = null;
        this.training = null;
        this.market = null;
        this.allocation = null;
        this.latitude = null;
        this.longitude = null;
        this.customer_code = null;
        this.site_id = null;
        this.subs_code = null;
        this.active = null;
        this.allowWifiCheckIn = null;
        this.created_By = null;
        this.created_Date = null;
        this.updated_Date = null;
        this.country = new Country();
        this.siteStore = new SiteStores();
    }

    public setSiteLocation(siteLocation): void {
        this.siteLocationID = siteLocation.siteLocationID;
        this.siteStoreID = siteLocation.siteStoreID;
        this.countryID = siteLocation.countryID;
        this.countryZoneID = siteLocation.countryZoneID;
        this.cityID = siteLocation.cityID;
        this.segmentID = siteLocation.segmentID;
        this.location = siteLocation.location;
        this.address = siteLocation.address;
        this.name = siteLocation.name;
        this.monitoring = siteLocation.monitoring;
        this.samsung_official = siteLocation.samsung_official;
        this.segment_IM = siteLocation.segment_IM;
        this.segment_AV = siteLocation.segment_AV;
        this.segment_DA = siteLocation.segment_DA;
        this.training = siteLocation.training;
        this.market = siteLocation.market;
        this.allocation = siteLocation.allocation;
        this.latitude = siteLocation.latitude;
        this.longitude = siteLocation.longitude;
        this.customer_code = siteLocation.customer_code;
        this.site_id = siteLocation.site_id;
        this.subs_code = siteLocation.subs_code;
        this.active = siteLocation.active;
        this.allowWifiCheckIn = siteLocation.allowWifiCheckIn;
        this.created_By = siteLocation.created_By;
        this.created_Date = siteLocation.created_Date;
        this.updated_Date = siteLocation.updated_Date;
        // tslint:disable-next-line:max-line-length
        if (siteLocation.hasOwnProperty('siteStore') && siteLocation.siteStore !== null) { this.siteStore.setSiteStore(siteLocation.siteStore); }
        if (siteLocation.hasOwnProperty('country') && siteLocation.country !== null) { this.country.setCountry(siteLocation.country); }
    }

    getJsonBase(): any {
        const jsonBase = {
            'siteStoreID': this.siteStoreID,
            'countryID': this.countryID,
            'countryZoneID': this.countryZoneID,
            'cityID': this.cityID,
            'segmentID': this.segmentID,
            'location': this.location,
            'address': this.address,
            'name': this.name,
            'monitoring': this.monitoring,
            'samsung_official': this.samsung_official,
            'training': this.training,
            'market': this.market,
            'latitude': this.latitude,
            'longitude': this.longitude,
            'customer_code': this.customer_code,
            'site_id': this.site_id,
            'subs_code': this.subs_code,
            'active': this.active,
            'allowWifiCheckIn': this.allowWifiCheckIn,
        };
        return jsonBase;
    }

    getJsonCreate(): any {
        const json = this.getJsonBase();
        if (this.created_By !== null) { json['created_By'] = this.created_By; }
        return json;
    }

    getJsonUpdate(): any {
        const json = this.getJsonBase();
        if (this.created_Date !== null) { json['created_Date'] = this.created_Date; }
        if (this.created_By !== null) { json['created_By'] = this.created_By; }
        if (this.siteLocationID !== null) { json['siteLocationID'] = this.siteLocationID; }
        return json;
    }
}
