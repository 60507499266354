import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from '../../../../../services/utils.service';
import { MethodsUtilsService } from '../../../../../services/methodsUtils.service';
import { FormularioBaseComponent } from '../../../../utils/formularioBase.component';
import { ProductService } from '../../../../../services/product.service';
import { Product } from '../../../../../models/product';
import { CountriesService } from '../../../../../services/countries.service';

// Declare Vars
declare var $: any;

@Component({
  selector: 'app-form-product',
  templateUrl: './form.component.html',
  styleUrls: []
})

export class FormProductComponent extends FormularioBaseComponent implements OnInit {

  public page = 'Product';
  public title = 'Product.Create';
  public titleButton = 'Save';
  public product = new Product();
  public regionId = null;
  public brandID = null;
  public categoryID = null;

  constructor(
    private route: ActivatedRoute,
    private productService: ProductService,
    private countriesService: CountriesService,
    private router: Router,
    public utilsService: UtilsService,
    public methodsUtilsService: MethodsUtilsService,
  ) {
    super(utilsService, methodsUtilsService);
  }

  ngOnInit() {
    const self = this;
    self.getRegionsList();
    self.getListCategories();
    self.getListProductGroups();
    self.getListProductTypes();
    self.getListBrands();
    self.param = self.route.params.subscribe(
      params => {
        if (params['id']) {
          self.title = 'Product.Update';
          self.titleButton = 'Update';
          self.utilsService.debugConsole(params['id']);
          self.getProduct(params['id']);
        }
      }
    );
  }


  private getProduct(id: any): void {
    const self = this;
    self.productService.getResult(id).then(
      function (response) {
        if (response === false) { return; }
        self.product.setProduct(self.productService.getVarResults());

      
        //self.getBrandsByCountryID(self.popByProduct.country.countryID);
        self.getListProductsFilters(self.regionId, self.brandID, self.product.categoryID);
      
      }
    );
  }


 
  public saveUpdateProduct(): void {
    const self = this;
    self.product.created_By = self.utilsService.getUserId();
    if (self.product.productID !== null) {
      self.productService.putData(self.product.productID, self.product.getJsonUpdate()).then(
        function (response) {
          if (response === false) { return; }
          self.router.navigate(['/product']);
        }
      );
    } else {
      self.productService.postData(self.product.getJsonCreate()).then(
        function (response) {
          if (response === false) { return; }
          self.router.navigate(['/product']);
        }
      );
    }
  }

}
