import { Country } from './country';
/**
 * Created by Juan Vidales Cheil on 30/5/2019.
 */
export class CountryZone {
  public countryZoneID: number; // 123
  public countryID: number; // 123
  public zoneName: string; // El nombre max 150
  public active: boolean; // true or false
  public created_By: string; // El nombre de quien lo crea
  private created_Date: string; // "2019-05-31T02:33:33.380Z"
  private updated_Date: string; // "2019-05-31T02:33:33.380Z"
  public country: Country;

  constructor() {
    this.initalizeCountryZone();
  }

  initalizeCountryZone() {
    this.countryZoneID = null;
    this.countryID = null;
    this.zoneName = null;
    this.active = null;
    this.created_By = null;
    this.created_Date = null;
    this.updated_Date = null;
    this.country = new Country();
  }

  public setCountryZone(countryZone): void {
    this.countryZoneID = countryZone.countryZoneID;
    this.countryID = countryZone.countryID;
    this.zoneName = countryZone.zoneName;
    this.active = countryZone.active;
    this.created_By = countryZone.created_By;
    this.created_Date = countryZone.created_Date;
    this.updated_Date = countryZone.updated_Date;
    if (countryZone.hasOwnProperty('country') && countryZone.country !== null) { this.country.setCountry(countryZone.country); }
  }

  getJsonBase(): any {
    const jsonBase = {
      'countryID': this.countryID,
      'zoneName': this.zoneName,
      'active': this.active
    };
    return jsonBase;
  }

  getJsonCreate(): any {
    const json = this.getJsonBase();
    if (this.created_By !== null) { json['created_By'] = this.created_By; }
    return json;
  }

  getJsonUpdate(): any {
    const json = this.getJsonBase();
    if (this.created_Date !== null) { json['created_Date'] = this.created_Date; }
    if (this.created_By !== null) { json['created_By'] = this.created_By; }
    if (this.countryZoneID !== null) { json['countryZoneID'] = this.countryZoneID; }
    return json;
  }
}
