/**
 * Created by Viviana Rincón Cheil on 24/07/2019.
 */

import { Country } from './country';

 export class OptionPopExecutionType {

    /*datos para crear reporte */
     public popExecutionTypeID: number;
    public type: string;
    public countryID: number;
    public created_By: string;
    public created_Date: string;
    public updated_Date: string;
    public country: Country;

    constructor() {
        this.initalizePopExecutionType();
    }

     initalizePopExecutionType() {
        this.popExecutionTypeID = null;
        this.type = null;
        this.countryID=null;
        this.created_By = null;
        this.updated_Date = null;
        this.created_Date = null;
        this.country = new Country();
    }

     setPopExecutionType(optionPopExecutionType) {
         this.popExecutionTypeID = optionPopExecutionType.popExecutionTypeID;
         this.countryID = optionPopExecutionType.countryID
         this.type = optionPopExecutionType.type;
         this.created_By = optionPopExecutionType.Created_By;
         this.updated_Date = optionPopExecutionType.Updated_Date;
         this.created_Date = optionPopExecutionType.Created_Date;
         if (optionPopExecutionType.hasOwnProperty('country') && optionPopExecutionType.country !== null) { this.country.setCountry(optionPopExecutionType.country); }
    }

    getJsonBase(): any {
        const jsonBase = {
            'type': this.type,
            'countryID': this.countryID,
        };
        return jsonBase;
    }

    getJsonCreate(): any {
        const json = this.getJsonBase();
        if (this.created_By !== null) { json['created_By'] = this.created_By; }
        return json;
    }

    getJsonUpdate(): any {
        const json = this.getJsonBase();
        if (this.created_Date !== null) { json['created_Date'] = this.created_Date; }
        if (this.created_By !== null) { json['created_By'] = this.created_By; }
        if (this.popExecutionTypeID !== null) { json['popExecutionTypeID'] = this.popExecutionTypeID; }
        return json;
    }
}