import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'search'
})


export class SearchPipe implements PipeTransform {
    transform(value: any, args?: any): any {
        if (!args) return value;
        args = args.toLowerCase();
        return value.filter(item => this.checkValues(item, args));
    }
    
    checkValues(item, args) {
        const value = Object.keys(item).map(k => item[k]);
        return String(value).toLowerCase().indexOf(args) > -1;
    }
}



