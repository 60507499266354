import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from '../../../../../services/utils.service';
import { MethodsUtilsService } from '../../../../../services/methodsUtils.service';
import { FormularioBaseComponent } from '../../../../utils/formularioBase.component';
import { City } from '../../../../../models/city';
import { CitiesService } from '../../../../../services/cities.service';
// Declare Vars
declare var $: any;

@Component ({
    selector: 'app-form-city',
    templateUrl: './form.component.html',
    styleUrls: []
})

export class FormCitiesComponent extends FormularioBaseComponent implements OnInit {

    public page = 'Cities';
    public title = 'Cities.Create';
    public city = new City();
    public titleButton = 'Save';
    public regionId = null;

    constructor(
        private route: ActivatedRoute,
        private citiesService: CitiesService,
        private router: Router,
        public utilsService: UtilsService,
        public methodsUtilsService: MethodsUtilsService,
    ) {
        super(utilsService, methodsUtilsService);
    }

    ngOnInit() {
        const self = this;
        self.getRegionsList();
        self.param = self.route.params.subscribe(
            params => {
                if (params['id']) {
                    self.title = 'Cities.Update';
                    self.titleButton = 'Update';
                    self.utilsService.debugConsole(params['id']);
                    self.getCity(params['id']);
                }
            }
        );
    }

    private getCity (id: any): void {
        const self = this;
        self.citiesService.getResult(id).then(
            function (response) {
                if (response === false) { return; }
                self.city.setCity(self.citiesService.getVarResults());
                self.regionId = self.city.country.regionId;
                self.selectedRegion();
                self.selectedCountry();
            }
        );
    }

    public selectedRegion(): void {
        const self = this;
        if (self.regionId != null) {
            self.getListCountriesByRegion(self.regionId);
        } else {
            self.city.countryID = null;
            self.city.countryZoneID = null;
        }
    }

    public selectedCountry(): void {
        const self = this;
        if (self.city.countryID != null) {
            self.getListCountryZonesFilters({ 'countries': [ self.city.countryID ]});
            console.log(self.city);
        } else {
            self.city.countryZoneID = null;
        }
    }

    public saveUpdateRegion(): void {
        const self = this;
        if (self.city.cityID !== null) {
            self.citiesService.putData(self.city.cityID, self.city.getJsonUpdate()).then(
                function (response) {
                    if (response === false) { return; }
                    self.router.navigate(['/cities']);
                }
            );
        } else {
            self.citiesService.postData(self.city.getJsonCreate()).then(
                function (response) {
                    if (response === false) { return; }
                    self.router.navigate(['/cities']);
                }
            );
        }
    }

}
