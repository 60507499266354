import { Component, OnInit, Self } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from '../../../../../services/utils.service';
import { MethodsUtilsService } from '../../../../../services/methodsUtils.service';
import { FormularioBaseComponent } from '../../../../utils/formularioBase.component';
import { PromotionTypes } from '../../../../../models/promotionTypes';
import { PromotionTypesService } from '../../../../../services/promotionType.service';

// Declare Vars
declare var $: any;

@Component ({
	selector: 'app-form-promotion-type',
	templateUrl: './form.component.html',
	styleUrls: []
})

export class FormPromotionTypesComponent extends FormularioBaseComponent implements OnInit {

	public page = 'PromotionTypes';
	public title = 'PromotionTypes.Create';
	public promotionTypes = new PromotionTypes();
	public titleButton = 'Save';
	public regionId;

	constructor(
		private route: ActivatedRoute,
		private promotionTypesService: PromotionTypesService,
		private router: Router,
		public utilsService: UtilsService,
		public methodsUtilsService: MethodsUtilsService,
	) {
		super(utilsService, methodsUtilsService);
	}

	ngOnInit() {
		const self = this;
		self.getRegionsList();
		self.param = self.route.params.subscribe(
			params => {
				if (params['id']) {
					self.title = 'PromotionTypes.Update';
					self.titleButton = 'Update';
					self.utilsService.debugConsole(params['id']);
					self.getPromotionTypes(params['id']);
				}
			}
		);
	}

	private getPromotionTypes(id: any): void {
		const self = this;
		self.promotionTypesService.getResult(id).then(
			function (response) {
				if (response === false) { return; }
				self.promotionTypes.setPromotionTypes(self.promotionTypesService.getVarResults());
				self.regionId = self.promotionTypes.country.regionId;
				self.getRegionsList();
				self.getListCountriesByRegion(self.regionId);
			}
		);
	}

	public saveUpdatePromotionTypes(): void {
		const self = this;
		if (self.promotionTypes.promotionTypeID !== null) {
			self.promotionTypesService.putData(self.promotionTypes.promotionTypeID, self.promotionTypes.getJsonUpdate()).then(
				function (response) {
					if (response === false) { return; }
					self.router.navigate(['/promotion-types']);
				}
			);
		} else {
			self.promotionTypesService.postData(self.promotionTypes.getJsonCreate()).then(
				function (response) {
					if (response === false) { return; }
					self.router.navigate(['/promotion-types']);
				}
			);
		}
	}
	
	public selectedRegion(): void {
        const self = this;
        if (self.regionId != null) {
            self.getListCountriesByRegion(self.regionId);
        } else {
            self.promotionTypes.countryID = null;
        }
    }
}
