import { AfterViewInit, Component, OnInit } from '@angular/core';
import { CmsGeneralesService } from '../../../../services/cmsGenerales.service';
import { UtilsService } from '../../../../services/utils.service';
import * as $ from 'jquery';

@Component({
	selector: 'app-scheddule-users',
	templateUrl: './schedule.component.html',
	styleUrls: []
})

export class ScheduleComponent implements OnInit {
	public title = 'Agenda de Usuarios';
	public promoters = [];
	public almacenes = [];

	constructor(
		private cmsGeneralesService: CmsGeneralesService,
		private utilsService: UtilsService
	) {}

	ngOnInit() {
		// this.getPromotersForSupervisor();
	}

}
