import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../../../../services/utils.service';
import { CampaignService } from '../../../../services/campaign.service';


@Component({
  selector: 'app-pop-type',
  templateUrl: './index.component.html',
  styleUrls: []
})
export class OptionsCampaingComponent implements OnInit {
  public title = 'Campaing';
  public ifDataExist = false;
  public tableTitles = [
    { name: 'campaignName', label: 'Campaña', type: 'normal' },
    { name: 'siteStore.name', label: 'Store', type: 'normal' },
    { name: 'country.name', label: 'País', type: 'normal' },
    { name: 'active', label: 'Activo', type: 'boolean' },
  ];
  public data: any = [];
  public optionsTable = {
    dataId: 'campaignID',
    showData: false,
    actionShowData: '',
    updateData: true,
    actionUpdateData: 'update',
    deleteData: false,
    actionDeleteData: '',
    filterCountryZone: false,
    filterSiteLocation: false,
    filterSitestore: false,
    filterCity: false,
    filterCountry: false,
    filterRegion: false,
    filterCedis: false,
    searchBtn: true,
    exportBtn: true,
  };

  constructor(
    private campaignService: CampaignService,
    private utilsService: UtilsService
  ) { }

  ngOnInit() {
    this.getCampaing();
  }

  private getCampaing() {
    const self = this;
    self.campaignService.getResults().then(
      function (result) {
        self.data = self.campaignService.getVarResults();
        self.ifDataExist = true;
        self.utilsService.debugConsole(result);
      }
    );
  }
}