import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../../../../services/utils.service';
import * as $ from 'jquery';
import { ReportInventoryService } from 'src/services/reportInventory.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-report-market-promotions',
  templateUrl: './index.component.html',
  styleUrls: []
})
export class ReportInventoryComponent implements OnInit {

  public title = 'ReportInventory';
  public ifDataExist = false;
  public ifExcelExist = true;
  public regionID = [];
  public cityID = [];
  public countryID = [];
  public data: any = [];
  public urlExcel: any;

  public tableTitles = [
    { name: 'year', label: 'Year', type: 'number' },
    { name: 'month', label: 'Month', type: 'month' },
    { name: 'week', label: 'Week', type: 'number' },
    { name: 'country', label:'Country', type:'normal'},
    { name: 'store', label:'Store', type:'normal'},
    { name: 'location', label:'Location', type:'normal'},
    { name: 'siteID', label:'Site ID', type:'normal'},
    { name: 'category', label:'Category', type:'normal'},
    { name: 'productGroup', label:'Product Group', type:'normal'},
    { name: 'productType', label: 'Product Type', type: 'normal' },
    { name: 'brand', label: 'Brand', type: 'normal' },
    { name: 'productName', label: 'Product', type: 'normal' },
    { name: 'inventoryCount', label: 'Inventory', type: 'number' },
    { name: 'dollarPriceBI', label: 'Dollar Price', type: 'number' },
    { name: 'localPriceBI', label: 'Local Price', type: 'number' },
    { name: 'pricePromoter', label: 'Price Promoter', type: 'number' },
    { name: 'userFullname', label: 'User Name', type: 'normal' },
    { name: 'userAccessName', label: 'User Acess Name', type: 'normal' },
    { name: 'supervisors', label: 'Supervisor', type: 'normal' },
    { name: 'createDate', label: 'Created Date', type: 'date' },
  ];

  public optionsTable = {
    dataId: 'ReportInventoryID',
    showData: false,
    actionShowData: '',
    updateData: false,
    actionUpdateData: 'update',
    deleteData: false,
    filterDateRange: true,
    actionDeleteData: '',
    filterCountryZone: false,
    filterSiteLocation: true,
    filterSitestore: true,
    filterCity: false,
    filterCategory: true,
    filterCountry: 'required',
    filterRegion: 'required',
    filterCedis: false,
    searchBtn: true,
    exportBtn: true,
  };

  constructor(
    private reportInventoryService: ReportInventoryService,
    private utilsService: UtilsService
  ) { }

  ngOnInit() {
    const self = this;
    const request = {};
    self.ifDataExist = true;
  }

  downloadExcelReport(event){
    const self = this;
    let request = {}
    let datePipe = new DatePipe('es');
    if(event.start_Date && event.end_Date) {
      request = {
        'start_Date': datePipe.transform(event.start_Date, 'yyyy-MM-dd'),
        'end_Date': datePipe.transform(event.end_Date, 'yyyy-MM-dd')
      };
    } else return false;
    if(event.siteStoreId) {
      request['siteStoreID'] = event.siteStoreId
    }
    if (event.cityId) {
      request['cities'] = event.cityId
    }
    if (event.countryId) {
      request['countries'] = [event.countryId]
    } 
    if (event.regionId) {
      request['regions'] = event.regionId
    }
    if (event.categoryId) {
      request['categoryID'] = event.categoryId
    }
    if (event.siteLocationId) {
      request['siteLocationID'] = event.siteLocationId
    }
    //self.getReportExcelFlooring(request);
  }

  filter(event) {
    const self = this;
    let request = {}
    let datePipe = new DatePipe('es');
    if(event.start_Date && event.end_Date) {
      request = {
        'start_Date': datePipe.transform(event.start_Date, 'yyyy-MM-dd'),
        'end_Date': datePipe.transform(event.end_Date, 'yyyy-MM-dd')
      };
    } else return false;
    if(event.siteStoreId) {
      request['siteStoreID'] = event.siteStoreId
    }
    if (event.cityId) {
      request['cities'] = event.cityId
    }
    if (event.countryId) {
      request['countries'] = [event.countryId]
    } 
    if (event.regionId) {
      request['regions'] = event.regionId
    }
    if (event.categoryId) {
      request['categoryID'] = event.categoryId
    }
    if (event.siteLocationId) {
      request['siteLocationID'] = event.siteLocationId
    }

    self.getReportFlooring(request);

  }

  private getReportFlooring(request) {
    const self = this;
    request["format"]="json";
    self.reportInventoryService.getReportInventory(request).then(
      function (result) {
        self.data = self.reportInventoryService.getVarResults();
        self.ifDataExist = true;
        self.utilsService.debugConsole(result);
      }
    );
  }

  private getReportExcelFlooring(request) {
    const self = this;
    request["format"]="excel";
    self.reportInventoryService.getReportInventory(request).then(
      function (result) {
        let urlExcel =  self.reportInventoryService.getVarResultsExcel();
        if(urlExcel.indexOf("http") == 0 ) {
            window.location.assign(urlExcel)
        } else {
          self.urlExcel = urlExcel;
        }
        self.utilsService.debugConsole(result);
      }
    );
  }
}