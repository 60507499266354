import { Component, Input, OnInit } from '@angular/core';
import * as $ from 'jquery';

@Component({
	selector: 'app-search-component',
	templateUrl: './search-component.component.html',
	styleUrls: []
})
export class SearchComponentComponent implements OnInit {
	@Input() classSearch: string;
	@Input() compositeTable: boolean;
	@Input() secondClass: any;
	public arrayDataSecond = [];

	constructor() { }

	ngOnInit(): void { }

	public searchValue(): void {
		const self = this;
		const valueFilter = $('#search-component').val();
		self.arrayDataSecond = [];
		let count = 0;
		$('.' + this.classSearch).each(function (index, value) {
			const textHtml = $(this).html().toUpperCase();
			if (textHtml.includes(valueFilter.toUpperCase())) {
				$(this).removeClass('d-none');
				$(this).addClass('d-flex');
				self.arrayDataSecond.push(count);
			} else {
				$(this).addClass('d-none');
				$(this).removeClass('d-flex');
			}
			count++;
		});
		console.log(self.arrayDataSecond);
		if (this.compositeTable) {
			let secondCount = 0;
			$('.' + this.secondClass).each(function (index, value) {
				const indexSearch = self.arrayDataSecond.indexOf(secondCount, 0);
				if (indexSearch > -1) {
					$(this).removeClass('d-none');
					$(this).addClass('d-flex');
				} else {
					$(this).addClass('d-none');
					$(this).removeClass('d-flex');
				}
				secondCount++;
			});
		}
	}
}
