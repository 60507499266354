import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from '../../../../../../services/utils.service';
import { MethodsUtilsService } from '../../../../../../services/methodsUtils.service';
import { FormularioBaseComponent } from '../../../../../utils/formularioBase.component';
import { ProductType } from '../../../../../../models/productType';
import { ProductTypesService } from '../../../../../../services/productTypes.service';
// Declare Vars
declare var $: any;

@Component ({
  selector: 'app-form-segment',
  templateUrl: './form.component.html',
  styleUrls: []
})

export class FormProductTypeComponent extends FormularioBaseComponent implements OnInit {

  public page = 'ProductTypes';
  public title = 'ProductTypes.Create';
  public productType = new ProductType();
  public titleButton = 'Save';

  constructor(
    private route: ActivatedRoute,
    private productTypesService: ProductTypesService,
    private router: Router,
    public utilsService: UtilsService,
    public methodsUtilsService: MethodsUtilsService,
  ) {
    super(utilsService, methodsUtilsService);
  }

  ngOnInit() {
    const self = this;
    self.getListCategories();
    self.param = self.route.params.subscribe(
      params => {
        if (params['id']) {
          self.title = 'ProductTypes.Update';
          self.titleButton = 'Update';
          self.utilsService.debugConsole(params['id']);
          self.getRegion(params['id']);
        }
      }
    );
  }

  private getRegion (id: any): void {
    const self = this;
    self.productTypesService.getResult(id).then(
      function (response) {
        if (response === false) { return; }
        self.productType.setProductType(self.productTypesService.getVarResults());
      }
    );
  }

  public saveUpdate(): void {
    const self = this;
    self.productType.created_By = self.utilsService.getUserId();
    if (self.productType.productTypeID !== null) {
      self.productTypesService.putData(self.productType.productTypeID, self.productType.getJsonUpdate()).then(
        function (response) {
          if (response === false) { return; }
          self.router.navigate(['/productTypes']);
        }
      );
    } else {
      self.productTypesService.postData(self.productType.getJsonCreate()).then(
        function (response) {
          if (response === false) { return; }
          self.router.navigate(['/productTypes']);
        }
      );
    }
  }

}
