import { Injectable } from '@angular/core';
import { CustomHttpService } from './customHttp.service';
import { BaseServiceService } from './baseService.service';
import { DatePipe } from '@angular/common';
import * as $ from 'jquery';


@Injectable()
export class ReportGiveAwayService extends BaseServiceService {
	private resultData: any;
	
	constructor(
		public customHttpService: CustomHttpService,
	) {
		super(customHttpService);
	}


	getReportGiveAway(datos) {
		const self = this;
		return new Promise(
			resolve => {
				const datePipe = new DatePipe('es');
			
				// Get Info from 'v1/api/Reportes/GetReportGiveAway'
				self.customHttpService.httpPOST(true, true, 'v1/api/Reportes/GetGiveAwayReport', datos).then(
					function (data) {
						if (data === false) {
							resolve(data);
						
						} else {
						
							self.resultData = data['giveAwayReport'];
							self.setVarResults(self.resultData);
							resolve(true);
						}

					});
			}
		);
	}

	getExcelGiveAway(datos) {
		const self = this;
		let loggedUser = JSON.parse(localStorage.getItem('user'));
		return new Promise(
			resolve => {
				const datePipe = new DatePipe('es');
				self.customHttpService.httpPOST(true, true, 'v1/api/ReportesExcel/GetGiveAwayReport', datos).then(
					function (data) {
						if (data === false) {
							resolve(data);

						} else {
							self.setVarResultsExcel(data['reportUrl'])
							console.log(data['reportUrl'])
							resolve(true);
						}

					});
			}
		);
	}



}
