import { Injectable } from '@angular/core';
import { CustomHttpService } from './customHttp.service';
import { BaseServiceService } from './baseService.service';

@Injectable()
export class UsersInformationService extends BaseServiceService {
    private controller = 'GetUsersFilters';

	constructor(
		public customHttpService: CustomHttpService
	) {
		super(customHttpService);
		this.TAG = this.controller;
		this.varGet = this.controller;
		this.varGetId = 'users';
	}

	public getUsersAllData (request) {
        const self = this;
        return new Promise(
            resolve => {
                self.customHttpService.httpPOST(true, true, 'v1/api/Users/GetUsersFilters', request).then(
                    function (response) {
                        if (response === false) { resolve(response); }
                        self.setVarResults(response['users']);
                        resolve(true);
                    }
                );
            }
            
        );
    }
    
    public getUsersTrack(checkInOutId) {
        const self = this;
        return new Promise(
            resolve => {
                self.customHttpService.httpGET(true, true, 'v1/api/Users/GetUserTrackByCheckInOut/' + checkInOutId).then(
                    function (response) {
                        if (response === false) { resolve(response); }
                        self.setVarResults(response["trackUser"]);
                        resolve(true);
                    }
                );
            }
        );
    }

    public getUserPromoters(){
        const self = this;
        return new Promise(
            resolve => {
                self.customHttpService.httpGET(true, true, 'v1/api/Users/GetUsersPromoters').then(
                    function (response) {
                        if (response === false) { resolve(response); }
                        self.setVarResults(response["promoters"]);
                        resolve(true);
                    }
                );
            }
        );
    }

    public getUserSupervisor(){
        const self = this;
        return new Promise(
            resolve => {
                self.customHttpService.httpGET(true, true, 'v1/api/Users/GetUsersSupervisor').then(
                    function (response) {
                        if (response === false) { resolve(response); }
                        self.setVarResults(response["supervisors"]);
                        resolve(true);
                    }
                );
            }
        );
    }


    public getUsersByUser(userId) {
        const self = this;
        return new Promise(
            resolve => {
                let request = {
                    'userId' : userId
                }
                self.customHttpService.httpPOST(true, true, 'v1/api/Users/GetUsersByUser', request).then(
                    function (response) {
                        if (response === false) { resolve(response); }
                        self.setVarResults(response["users"]);
                        resolve(true);
                    }
                );
            }
        );
    }

    public postSavePromoters(userId, promoters) {
        const self = this;
        return new Promise(
            resolve => {
                let request = {
                    'userIdPadre' : userId,
                    'usersIdHijos' : promoters
                }
                self.customHttpService.httpPOST(true, true, 'v1/api/SupervisorPromoters/PostAddUsersToUser', request).then(
                    function (response) {
                        if (response === false) { resolve(response); }
                        self.setVarResults(response["users"]);
                        resolve(true);
                    }
                );
            }
        );
    }
}