import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from '../../../../../services/utils.service';
import { MethodsUtilsService } from '../../../../../services/methodsUtils.service';
import { FormularioBaseComponent } from '../../../../utils/formularioBase.component';
import { GiveAwayCampaigns } from '../../../../../models/giveAwayCampaigns';
import { GiveAwayCampaignsService } from '../../../../../services/giveAwayCampaigns.service';
// Declare Vars
declare var $: any;

@Component ({
	selector: 'app-form-FormGiveAwayCampaigns',
	templateUrl: './form.component.html',
	styleUrls: []
})

export class FormGiveAwayCampaignsComponent extends FormularioBaseComponent implements OnInit {

	public page = 'GiveAwayCampaigns';
	public title = 'GiveAwayCampaigns.Create';
	public giveAwayCampaign = new GiveAwayCampaigns();
	public titleButton = 'Save';
	public regionId;

	constructor(
		private route: ActivatedRoute,
		private giveAwayCampaignsService: GiveAwayCampaignsService,
		private router: Router,
		public utilsService: UtilsService,
		public methodsUtilsService: MethodsUtilsService,
	) {
		super(utilsService, methodsUtilsService);
	}

	ngOnInit() {
		const self = this;
		self.getRegionsList();
		self.getListCategories();
		self.getListGiveAwayTypes();
		self.param = self.route.params.subscribe(
			params => {
				if (params['id']) {
					self.title = 'GiveAwayCampaigns.Update';
					self.titleButton = 'Update';
					self.utilsService.debugConsole(params['id']);
					self.getGiveAwayCampaigns(params['id']);
				}
			}
		);
	}

	private getGiveAwayCampaigns(id: any): void {
		const self = this;
		self.giveAwayCampaignsService.getResult(id).then(
			function (response) {
				if (response === false) { return; }
				self.giveAwayCampaign.setGiveAwayCampaigns(self.giveAwayCampaignsService.getVarResults());
				self.regionId = self.giveAwayCampaign.country.regionId;
				self.getRegionsList();
				self.getListCountriesByRegion(self.regionId);
				self.getListSiteStoresFilters({ 'cities': [], 'countries': [ self.giveAwayCampaign.countryID ] });
				self.getListCategories();
				self.getListGiveAwayTypes();
			}
		);
	}

	public saveUpdateGiveAwayCampaign(): void {
		const self = this;
		if (self.giveAwayCampaign.giveAwayCampaignID !== null) {
			self.giveAwayCampaignsService.putData(self.giveAwayCampaign.giveAwayCampaignID, self.giveAwayCampaign.getJsonUpdate()).then(
				function (response) {
					if (response === false) { return; }
					self.router.navigate(['/GiveAwayCampaigns']);
				}
			);
		} else {
			self.giveAwayCampaignsService.postData(self.giveAwayCampaign.getJsonCreate()).then(
				function (response) {
					if (response === false) { return; }
					self.router.navigate(['/GiveAwayCampaigns']);
				}
			);
		}
	}

	public selectedRegion(): void {
        const self = this;
        if (self.regionId != null) {
            self.getListCountriesByRegion(self.regionId);
        } else {
            self.giveAwayCampaign.countryID = null;
        }
	}
	
	public selectedCountry(): void {
        const self = this;
        if (self.giveAwayCampaign.countryID != null) {
            self.getListSiteStoresFilters({ 'cities': [], 'countries': [ self.giveAwayCampaign.countryID ] });
        } else {
            self.giveAwayCampaign.siteStoreID = null;
        }
    }

}
