
export class UserRol {
    public userRolId: number;
    public created_By: string; 
    private created_Date: string; 
    private updated_Date: string; 

    constructor() {
        this.initalizeUserRol();
    }

    initalizeUserRol() {
        this.userRolId = null;
        this.created_By = null;
        this.created_Date = null;
        this.updated_Date = null;
    }

    setUserRol(userRol) {
        this.userRolId = userRol.userRolId;
        this.created_By = userRol.created_By;
        this.created_Date = userRol.created_Date;
        this.updated_Date = userRol.updated_Date;
    }

    getJsonBase(): any {
        const jsonBase = {
            'userRol': this.userRolId
        };
        return jsonBase;
    }

    getJsonCreate(): any {
        const json = this.getJsonBase();
        if (this.created_By !== null) { json['created_By'] = this.created_By; }
        return json;
    }

    getJsonUpdate(): any {
        const json = this.getJsonBase();
        if (this.created_Date !== null) { json['created_Date'] = this.created_Date; }
        if (this.created_By !== null) { json['created_By'] = this.created_By; }
        if (this.userRolId !== null) { json['userRolId'] = this.userRolId; }
        return json;
    }
}