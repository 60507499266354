import { Component, OnInit, Input, ViewChild, TemplateRef, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { UtilsService } from '../../../services/utils.service';
import { MethodsUtilsService } from '../../../services/methodsUtils.service';
import { CustomHttpService } from '../../../services/customHttp.service';
import { NgbModalConfig, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from 'src/services/modal.service';
import { SurveyService } from 'src/services/surveys.service';
import { FormularioBaseComponent } from 'src/app/utils/formularioBase.component';
import { SiteStores } from 'src/models/stores';
import { SiteLocations } from 'src/models/locations';
import { TranslatePipe } from 'src/pipes/translate';
import { DatePipe } from '@angular/common';
import { MatSidenav } from '@angular/material/sidenav';

declare var $

@Component({
	selector: 'app-surveys',
	templateUrl: './surveys.component.html',
	styleUrls: []
})

export class SurveysComponent extends FormularioBaseComponent implements OnInit {

	public ifDataExist = false;
	public data: any = [];
	public surveyDetails;
	public title = 'Surveys';
	public status: string = null;
	public startDate = new Date(new Date().getTime() - (30 * 24 * 60 * 60 * 1000));
	public endDate = new Date();

	public optionsFilters = {
		dataId: 'surveyID',
		filterRegion: false,
		filterCountry: false,
		filterStore: false,
		filterPointOfSales: false,
		filterDate: true,
		filterSurveyStatus: true,
		showData: true,
		actionShowData: 'show',
		updateData: true,
		actionUpdateData: 'update',
		deleteData: false,
		actionDeleteData: 'delete',
		sendData: true,
		sendSurvey: true,
		actionSendData: 'send',
		showTablePrinc: true,
		showTablePeq: false,
		showBtnDownload: false,
		showAlertTable: true,
		sideBar: false,
		filterSearch: true,
		report: true,
		surveyReport: true,
		deleteSurvey: true,
		actionCreate: '/surveys/create-survey',
		actionReport: '/surveys/report/'
	};


	public tableTitles = [
		//{ name: 'surveyID', label: 'Number', type: 'normal' },
		{ name: 'title', label: 'Title', type: 'normal' },
		{ name: 'created_By', label: 'Sender', type: 'normal' },
		{ name: 'start_Survey', label: 'Sent date', type: 'date' },
		{ name: 'end_Survey', label: 'End Date', type: 'date' },
		{ name: 'surveyRequestStatus', label: 'Sent status', type: 'normal' },
	];


	// Inject HttpClient into your component or service.
	constructor(
		private router: Router,
		config: NgbModalConfig,
		private modal: NgbModal,
		public utilsService: UtilsService,
		public methodsUtilsService: MethodsUtilsService,
		private customHttpService: CustomHttpService,
		private surveyService: SurveyService,
		private modalService: ModalService,
	) {
		super(utilsService, methodsUtilsService);
	}

	alertMsg(message, type) {
		const self = this;
		alert(message);
	}

	deleteSurvey(event) {
		const self = this;
		self.surveyService.deleteSurvey(event.id).then(
			function (result) {
				if (result) {
					let translatePipe = new TranslatePipe();
					let msg = translatePipe.transform('Pages.Surveys.Form.SurveyDeleted');
					self.alertMsg(msg, 'success');
					self.loadList();
				}
			}
		);
	}

	sendSurvey(event) {
		const self = this;
		let request = {
			"surveyID": event.id,
			"surveyRequestStatus": "Sent"
		};

		self.surveyService.changeState(request).then(
			function (result) {
				if (result) {
					let translatePipe = new TranslatePipe();
					let msg = translatePipe.transform('Pages.Surveys.Form.SurveySended');
					self.alertMsg(msg, 'success');
					self.loadList();
				}
			}
		);
	}


	ngOnInit(): void {
		const self = this;
		self.loadList();
	}

	filter(event) {
		const self = this;
		self.status = event.status
		self.startDate = event.startDate;
		self.endDate = event.endDate;
		self.loadList();
	}

	loadList() {
		const self = this;
		let request = {
		}

		if (self.startDate && self.startDate != null && self.endDate && self.endDate != null) {
			request["startDate"] = self.startDate;
			request["endDate"] = self.endDate;
		}
		if (self.status != "null" && self.status != null) {
			request["surveyRequestStatus"] = self.status;
		}
		self.getSurveys(request);
	}

	private getSurveys(request) {
		const self = this;
		self.surveyService.getSurveys(request).then(
			function (result) {
				self.data = self.surveyService.getVarResults();
				self.ifDataExist = true;
				self.utilsService.debugConsole(result);
			}
		);
	}
}