import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../../../../services/utils.service';

@Component({
	selector: 'app-rutas',
	templateUrl: './rutas.component.html',
	styleUrls: []
})

export class RutasComponent implements OnInit {
	public points: any = [];

	constructor(
		private utilsService: UtilsService
	) {}

	ngOnInit() {
		this.points = this.utilsService.getAlmacenes();
	}

}
