import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../../../../services/utils.service';
import { PopSosTypeService } from '../../../../services/popSosTypes.service';


@Component({
  selector: 'app-pop-type',
  templateUrl: './index.component.html',
  styleUrls: []
})

export class OptionsPopSosTypesComponent implements OnInit {
  public title = 'PopSosTypes';
  public ifDataExist = false;
  public tableTitles = [
    { name: 'country.name', label: 'País', type: 'normal' },
    { name: 'sosType', label: 'Tipo', type: 'normal' },
  ];
  public data: any = [];
  public optionsTable = {
    dataId: 'popSosTypeID',
    showData: false,
    actionShowData: '',
    updateData: true,
    actionUpdateData: 'update',
    deleteData: false,
    actionDeleteData: '',
    filterCountryZone: false,
    filterSiteLocation: false,
    filterSitestore: false,
    filterCity: false,
    filterCountry: false,
    filterRegion: false,
    filterCedis: false,
    searchBtn: true,
    exportBtn: true,
  };

  constructor(
    private popSosTypeService: PopSosTypeService,
    private utilsService: UtilsService
  ) { }

  ngOnInit() {
    this.getPopSosTypes();
  }

  private getPopSosTypes() {
    const self = this;
    self.popSosTypeService.getResults().then(
      function (result) {
        self.data = self.popSosTypeService.getVarResults();
        self.ifDataExist = true;
        self.utilsService.debugConsole(result);
      }
    );
  }
}