import { Country } from './country';
import { PromotionTypes } from './promotionTypes';

export class PromotionSubTypes {
    public promotionSubTypeID: number;
    public active: boolean;
    public countryID: number;
    public promotionTypeID: number;
    public subType: string;
    public country: Country;
    public promotionType: PromotionTypes;
    public created_By: string;
    private created_Date: string; 
    private updated_Date: string;

    constructor() {
        this.initalizePromotionSubTypes();
    }

    initalizePromotionSubTypes() {
        this.promotionSubTypeID = null;
        this.active = null;
        this.countryID = null;
        this.promotionTypeID = null;
        this.subType = null;
        this.country = new Country();
        this.promotionType = new PromotionTypes();
        this.created_By = null;
        this.created_Date = null;
        this.updated_Date = null;
    }

    setPromotionSubTypes(promotionSubType) {
        this.promotionSubTypeID = promotionSubType.promotionSubTypeID;
        this.active = promotionSubType.active;
        this.countryID = promotionSubType.countryID;
        this.subType = promotionSubType.subType;
        this.created_By = promotionSubType.created_By;
        this.created_Date = promotionSubType.created_Date;
        this.updated_Date = promotionSubType.updated_Date;
        this.promotionTypeID = promotionSubType.promotionTypeID;
        if (promotionSubType.hasOwnProperty('country') && promotionSubType.country !== null) { this.country.setCountry(promotionSubType.country); }    
        if (promotionSubType.hasOwnProperty('promotionType') && promotionSubType.promotionType !== null) { this.promotionType.setPromotionTypes(promotionSubType.promotionType); }    

    }

    getJsonBase(): any {
        const jsonBase = {
            'active' : this.active,
            'countryID' : this.countryID,
            'promotionTypeID' : this.promotionTypeID,
            'subType' : this.subType,
        };
        return jsonBase;
    }

    getJsonCreate(): any {
        const json = this.getJsonBase();
        if (this.created_By !== null) { json['created_By'] = this.created_By; }
        return json;
    }

    getJsonUpdate(): any {
        const json = this.getJsonBase();
        if (this.created_Date !== null) { json['created_Date'] = this.created_Date; }
        if (this.created_By !== null) { json['created_By'] = this.created_By; }
        if (this.promotionSubTypeID !== null) { json['promotionSubTypeID'] = this.promotionSubTypeID; }
        return json;
    }
}