import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UtilsService } from '../../../services/utils.service';
// Declares
declare var jquery: any;
declare var $: any;

@Component({
	selector: 'app-menu',
	templateUrl: './menu.component.html',
	styleUrls: []
})
export class MenuComponent implements OnInit {

	public name = '';
	public email = 'test@cheil.com';
	private user = JSON.parse(localStorage.getItem('user'));
	private userID: any;
	public showMenu = false;
	public urlImg = '/assets/images/profile.png';
	private rol: any;
	private rolName: any;
	public administrationMenu = [];
	public reportsMenu = []
	public usersMenu = [];
	public storeManagementMenu = [];
	public surveyMenu = [];
	public settingsMenu = [];
	public adminMenu = [];
	public principalMenu = [];

	public showUsersMenuOpen: boolean;
	public showAdministrationMenuOpen: boolean;
	public showReportsMenuOpen: boolean;
	public showStoreManagementMenuOpen: boolean;
	public showPrincipalMenuOpen: boolean;
	public showAdminMenuOpen: boolean;
	public showSurveyMenuOpen: boolean;
	public showSettingsMenuOpen: boolean;

	constructor(
		private router: Router,
		private utilsService: UtilsService
	) {}

	ngOnInit() {
		this.email = this.user.email; // ['email'];
		this.userID = this.user['usuarioID'];
		if (localStorage.getItem('user')) {
			this.urlImg = this.user['photo'];
			this.rol = sessionStorage.getItem('userRolId');
			this.rolName = sessionStorage.getItem('userRolName');
			this.showMenu = true;

			this.principalMenu.push({"url":"home", "title":"Menu.Home", "icon":"home"});

			if(this.rol == 17 || this.rol == 16 || this.rol == 15 || this.rol == 14){
				this.storeManagementMenu.push({"url":"product-sitestore", "title":"Menu.ProductSiteStore"});
				this.storeManagementMenu.push({"url":"locations", "title":"Menu.Locations"});
			}
			if(this.rol == 17  || this.rol == 3 || this.rol == 6 || this.rol == 16 || this.rol == 15 || this.rol == 14){ 	
				this.usersMenu.push({"url":"user-information", "title": "Menu.UserInformation" });
				this.usersMenu.push({"url":"push-notification", "title":"Menu.PushNotification"})
				//this.usersMenu.push({"url":"mi-ruta", "title":"Menu.MyRoute", "icon":"map"});
				//if(this.rol == 3  || this.rol == 6 || this.rol == 16 || this.rol == 15){ 
					this.usersMenu.push({"url":"calendario-usuarios", "title":"Menu.UsersCalendar", "icon":"calendar_today"});
				//}
				
				if(this.rol == 6){ 
					this.usersMenu.push({"url":"user-supervisors", "title":"Menu.UserSupervisors"});
				}
				this.usersMenu.push({"url":"route-management", "title":"Menu.RouteManagement"});
			}
			if(this.rol == 3  || this.rol == 6 || this.rol == 16 || this.rol == 15 || this.rol == 14){ 
				this.reportsMenu.push({"url":"reports-promoters-now", "title":"Menu.ReportsPromoterControl"});
				this.reportsMenu.push({"url":"reports-promoters-checkin-out", "title":"Menu.ReportsPromotersCheckInOut"});
				this.reportsMenu.push({"url":"reports-promoters-consolidate", "title": "Menu.ReportsPromoterConsolidate" });
				this.reportsMenu.push({"url":"reports-promoters-track", "title": "Menu.ReportsPromoterTrack" });
				this.reportsMenu.push({"url":"reports-display-sos", "title":"Menu.ReportsDisplaySos"});
				this.reportsMenu.push({"url":"reports-pop-execute", "title":"Menu.ReportsPopExecution"});
				this.reportsMenu.push({"url":"reports-pop-execute-sela", "title":"Menu.ReportsPopExecutionSela"});
				this.reportsMenu.push({"url":"reports-pop-request", "title":"Menu.ReportsPopRequest"});
				this.reportsMenu.push({"url":"reports-sampling-audit", "title":"Menu.ReportsSamplingAudit"});
				this.reportsMenu.push({"url":"user-schedule", "title":"Menu.UserSchedule"});
				this.reportsMenu.push({"url":"reports-giveaway", "title": "Menu.ReportsGiveAway" });
				this.reportsMenu.push({"url":"reports-store-activity", "title": "Menu.ReportsStoreActivity" });
				this.reportsMenu.push({"url":"reports-flooring", "title": "Menu.ReportsFlooring" });
				this.reportsMenu.push({"url":"reports-price-evidence", "title": "Menu.PriceEvidence" });
				this.reportsMenu.push({"url":"reports-inventory", "title": "Menu.Inventory" });
				this.reportsMenu.push({"url":"reports-sellout", "title": "Menu.Sellout" });
			}
			if(this.rol == 6 || this.rol == 16 || this.rol == 15 || this.rol == 14){
				this.surveyMenu.push({"url":"surveys", "title":"Menu.Surveys"})
			}
			if(this.rol == 15 ||this.rol == 14){
				this.adminMenu.push({"url":"product", "title":"Menu.Product"});
				this.adminMenu.push({"url":"stores", "title":"Menu.Stores"});
				this.adminMenu.push({"url":"locations", "title":"Menu.Locations"});
				this.adminMenu.push({"url":"country-zones", "title":"Menu.CountryZones"});
				this.adminMenu.push({"url":"cities", "title":"Menu.Cities"});
				this.adminMenu.push({"url":"cedis", "title":"Menu.CEDIS"});
				this.adminMenu.push({"url":"option-pop-type", "title":"Menu.PopType"})
				this.adminMenu.push({"url":"option-execution-type", "title":"Menu.PopExecutionType"});
				this.adminMenu.push({"url":"option-pop-tipology", "title":"Menu.PopTipology"});
				this.adminMenu.push({"url":"option-pop-by-product", "title":"Menu.PopByProduct"});
				this.adminMenu.push({"url":"option-campaign", "title":"Menu.Campaign"});
				this.adminMenu.push({"url":"option-pop-sos-types", "title":"Menu.PopSosTypes"});                    
				this.adminMenu.push({"url":"pop-sos-sub-types", "title": "Menu.PopSosSubTypes" });
				this.adminMenu.push({"url":"promotion-types", "title":"Menu.PromotionTypes"});                    
				this.adminMenu.push({"url":"promotion-sub-types", "title": "Menu.PromotionSubTypes" });
				this.adminMenu.push({"url":"GiveAwayTypes", "title": "Menu.GiveAwayTypes" });
				this.adminMenu.push({"url":"GiveAwayCampaigns", "title":"Menu.GiveAwayCampaigns"});  
			}
			if(this.rol == 14){ 
				this.settingsMenu.push({"url":"regions", "title":"Menu.Regions"});
				this.settingsMenu.push({"url":"countries", "title":"Menu.Countries"});
				this.settingsMenu.push({"url":"segments", "title":"Menu.Segments"});
				this.settingsMenu.push({"url":"categories", "title":"Menu.Categories"});
				this.settingsMenu.push({"url":"productTypes", "title":"Menu.ProductTypes"});
				this.settingsMenu.push({"url":"channels", "title":"Menu.Channels"});
				this.settingsMenu.push({"url":"brands", "title":"Menu.Brands"});
				
				this.settingsMenu.push({"url":"document-type", "title":"Menu.DocumentType"});
				this.settingsMenu.push({"url":"disconnection-type", "title":"Menu.DisconnectionType"});
			}
	
			/* VALIDACION DE MENU ACTIVO */
			let currentUrl = this.router.url.split('/')[1]; 
			const self = this;

			self.usersMenu.forEach(function(menu){
				if(menu.url == currentUrl){
					self.showUsersMenuOpen = true;
				}
			});

			self.administrationMenu.forEach(function(menu){
				if(menu.url == currentUrl){
					self.showAdministrationMenuOpen = true;
				}
			});

			self.reportsMenu.forEach(function(menu){
				if(menu.url == currentUrl){
					self.showReportsMenuOpen = true;
				}
			});

			self.storeManagementMenu.forEach(function(menu){
				if(menu.url == currentUrl){
					self.showStoreManagementMenuOpen = true;
				}
			});

			self.principalMenu.forEach(function(menu){
				if(menu.url == currentUrl){
					self.showPrincipalMenuOpen = true;
				}
			});

		}else{
			this.showMenu = false;
		}
		// this.utilsService.loadScript('./assets/Customjs/default.js');
		
	}

	logout() {
		sessionStorage.clear();
		this.router.navigate(['login']);
	}

	public menuHide() {
		$('.sidebar').toggleClass('mini-menu');
		$('.content').toggleClass('no-padding-left');
	}

}