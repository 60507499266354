import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from '../../../../../services/utils.service';
import { MethodsUtilsService } from '../../../../../services/methodsUtils.service';
import { FormularioBaseComponent } from '../../../../utils/formularioBase.component';
import { PopSosSubTypes } from '../../../../../models/popSosSubTypes';
import { PopSosSubTypesService } from '../../../../../services/popSosSubTypes.service';
// Declare Vars
declare var $: any;

@Component ({
    selector: 'app-form-pop-sos-types',
    templateUrl: './form.component.html',
    styleUrls: []
})

export class FormPopSosSubTypesComponent extends FormularioBaseComponent implements OnInit {

    public page = 'PopSosSubTypes';
    public title = 'PopSosSubTypes.Create';
    public popSosSubType = new PopSosSubTypes();
    public titleButton = 'Save';
    public regionId = null;

    constructor(
        private route: ActivatedRoute,
        private popSosSubTypeService: PopSosSubTypesService,
        private router: Router,
        public utilsService: UtilsService,
        public methodsUtilsService: MethodsUtilsService,
    ) {
        super(utilsService, methodsUtilsService);
    }

    ngOnInit() {
        const self = this;
        self.getRegionsList();
        self.getListPopSosTypes();
        self.param = self.route.params.subscribe(
            params => {
                if (params['id']) {
                    self.title = 'PopSosSubTypes.Update';
                    self.titleButton = 'Update';
                    self.utilsService.debugConsole(params['id']);
                    self.getpopSosSubType(params['id']);
                }
            }
        );
    }

    private getpopSosSubType (id: any): void {
        const self = this;
        self.popSosSubTypeService.getResult(id).then(
            function (response) {
                if (response === false) { return; }
                self.popSosSubType.setPopSosSubTypes(self.popSosSubTypeService.getVarResults());
                self.regionId = self.popSosSubType.country.regionId;
                self.selectedRegion();
            }
        );
    }

    public selectedRegion(): void {
        const self = this;
        if (self.regionId != null) {
            self.getListCountriesByRegion(self.regionId);
        } else {
            self.popSosSubType.countryID = null;
        }
    }

    public saveUpdatePopSosSubType(): void {
        const self = this;
        if (self.popSosSubType.popSosSubTypeID !== null) {
            self.popSosSubTypeService.putData(self.popSosSubType.popSosSubTypeID, self.popSosSubType.getJsonUpdate()).then(
                function (response) {
                    if (response === false) { return; }
                    self.router.navigate(['/pop-sos-sub-types']);
                }
            );
        } else {
            self.popSosSubTypeService.postData(self.popSosSubType.getJsonCreate()).then(
                function (response) {
                    if (response === false) { return; }
                    self.router.navigate(['/pop-sos-sub-types']);
                }
            );
        }
    }

}

