import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { UtilsService } from '../../../../services/utils.service';
import { UsersInformationService } from '../../../../services/usersInformation.service';
import { CustomHttpService } from '../../../../services/customHttp.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormularioBaseComponent } from 'src/app/utils/formularioBase.component';

@Component({
	selector: 'app-index-supervisor-promoters',
	templateUrl: './index.component.html',
	styleUrls: []
})

export class SupervisorPromotersComponent implements OnInit {
	public title = 'MySupervisors';
	public ifDataExist = false;
	public promotersAvailable = [];
	public promotersSelected = [];
	public param: any;
	public format = { add: 'Agregar Promotor', remove: 'Remover Promotor', all: 'Seleccionar Todos', none: 'Deseleccionar Todos',
	draggable: true, locale: 'es' };
	public user_id = sessionStorage.getItem('userId');
	public user: any;

	constructor(
		private userService: UsersInformationService,
		private utilsService: UtilsService,
		private customHttpService: CustomHttpService,
		private route: ActivatedRoute,
		private router: Router,
	) {}

	ngOnInit() {
		const self = this;

		self.param = self.route.params.subscribe(
			params => {
				if (params['id']) {
					self.user_id = params['id'];
					this.customHttpService.httpGET(true, true, 'v1/api/Users/' + self.user_id + '/',)
					.then(data => {
						self.user = data.user;
						self.userService.getUserPromoters().then(
							function (result) {
								let data = self.userService.getVarResults();
								data.forEach(user => {
									self.promotersAvailable.push({'userId':user.userId, 'name':user.fullName.toUpperCase() + ' - '+ user.userName + ' - '+ user.userRol});
								});
								self.ifDataExist = true;
								self.utilsService.debugConsole(result);
							}
						);
				
						self.userService.getUsersByUser(self.user_id).then(
							function (result) {
								let data = self.userService.getVarResults();
								data.forEach(user => {
									self.promotersAvailable.push({'userId':user.promoter.userId, 'name':user.promoter.fullName.toUpperCase() + ' - '+ user.promoter.userName+ ' - '+ user.userRol});
									self.promotersSelected.push({'userId':user.promoter.userId, 'name':user.promoter.fullName.toUpperCase() + ' - '+ user.promoter.userName+ ' - '+ user.userRol});
								});
								self.ifDataExist = true;
								self.utilsService.debugConsole(result);
							}
						);
					});
				}
			}
		);
	}

	public savePromoters(user_id){
		const self = this;
		let promoters = [];
		self.promotersSelected.forEach(user => {
			promoters.push(user.userId);
		});
		
		self.userService.postSavePromoters(self.user_id, promoters).then(
			function (result) {

            }
		);
	}
}
