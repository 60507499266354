import { Component, OnInit, Input, ViewChild, TemplateRef, ViewChildren, ɵConsole  } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UtilsService } from '../../../../services/utils.service';
import { MethodsUtilsService } from '../../../../services/methodsUtils.service';
import { CustomHttpService } from '../../../../services/customHttp.service';
import { NgbModalConfig, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from 'src/services/modal.service';
import { SurveyService } from 'src/services/surveys.service';
import { FormularioBaseComponent } from 'src/app/utils/formularioBase.component';
import { Survey } from 'src/models/survey';
import { TranslatePipe } from 'src/pipes/translate';
import { FiltersService } from 'src/services/filters.service';
import { Question } from 'src/models/question';
import * as format from 'date-fns/format';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { SelectorFlags } from '@angular/compiler/src/core';

declare var $

@Component({
	selector: 'app-surveys-create',
	templateUrl: './surveys-create.component.html',
	styleUrls: []
})

export class CreateSurveysComponent extends FormularioBaseComponent implements OnInit {
	public survey = new Survey();
	public surveyQuestions: Question[] = [];

	public ifDataExist = false;
	public data: any = [];
	public today = new Date();
	public regionAccess:boolean = false;

	public regionFilterList: any = [];
	public countryFilterList: any = [];
	public rolFilterList: any = [];
	public categoryFilterList: any = [];
	public storeFilterList: any = [];
	public locationFilterList : any = [];
	public questionTypesList: any = [];
	public userRolsFilterList: any = [];

	public end_hours: any;
	public end_minutes: any;
	public start_hours: any;
	public start_minutes: any;
	public title: string;
	public questionCount = 0;
	public sendSurveyNow: boolean = false;
	public dropdownList = [];
	public selectedItems = [];
	public dropdownSettings = {};
	public dropdownSettingsRegion: {};
	public dropdownSettingsCountry: {};
	public dropdownSettingsSiteStore: {};
	public dropdownSettingsSiteLocation: {};
	public dropdownSettingsCategory: {};
	public dropdownSettingsRol: {};
	public tempSurvey: any = false;

	// Inject HttpClient into your component or service.
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		config: NgbModalConfig,
		private modal: NgbModal,
		public utilsService: UtilsService,
		public methodsUtilsService: MethodsUtilsService,
		private customHttpService: CustomHttpService,
		private surveyService: SurveyService,
		private modalService: ModalService,
		private filtersService: FiltersService,
	){
		super(utilsService, methodsUtilsService);
	}

	ngOnInit(): void {
		const self = this;
		self.param = self.route.params.subscribe(
		params => {
			if (params['id']) {
				let surveyID = params['id'];
				self.getSurveyById(surveyID);
				//self.changeState(surveyID, "Saved")
				self.title = "Pages.Surveys.Form.TitleUpdate";
			}else{
				self.title = "Pages.Surveys.Form.TitleCreate";
			}
		}
		);

		self.getListCountries();
		self.getListUserRols();
		//self.listEmployeeTypes();
		self.listCategories();
		self.getQuestionsTypes();

		this.dropdownSettingsRegion = {
			singleSelection: false,
			idField: 'regionId',
			textField: 'regionName',
			selectAllText: 'Seleccionar Todo',
			unSelectAllText: 'Deseleccionar Todo',
			itemsShowLimit: 5,
			allowSearchFilter: true,
			enableCheckAll: true,
		};
		this.dropdownSettingsCountry = {
			singleSelection: true,
			idField: 'countryID',
			textField: 'country',
			selectAllText: 'Seleccionar Todo',
			unSelectAllText: 'Deseleccionar Todo',
			itemsShowLimit: 5,
			allowSearchFilter: true,
			enableCheckAll: false,
		};
		this.dropdownSettingsSiteStore = {
			singleSelection: false,
			idField: 'siteStoreID',
			textField: 'name',
			selectAllText: 'Seleccionar Todo',
			unSelectAllText: 'Deseleccionar Todo',
			itemsShowLimit: 5,
			allowSearchFilter: true,
			enableCheckAll: true,
		};
		this.dropdownSettingsSiteLocation = {
			singleSelection: false,
			idField: 'siteLocationID',
			textField: 'location',
			selectAllText: 'Seleccionar Todo',
			unSelectAllText: 'Deseleccionar Todo',
			itemsShowLimit: 5,
			allowSearchFilter: true,
			enableCheckAll: true,
		};
		this.dropdownSettingsCategory = {
			singleSelection: false,
			idField: 'categoryID',
			textField: 'category',
			selectAllText: 'Seleccionar Todo',
			unSelectAllText: 'Deseleccionar Todo',
			itemsShowLimit: 5,
			allowSearchFilter: true,
			enableCheckAll: true,
		};
		this.dropdownSettingsRol = {
			singleSelection: false,
			idField: 'userRolID',
			textField: 'level',
			selectAllText: 'Seleccionar Todo',
			unSelectAllText: 'Deseleccionar Todo',
			itemsShowLimit: 5,
			allowSearchFilter: true,
			enableCheckAll: true,
		};
	}

	getListCountries() {
        const self = this;
        this.filtersService.getListCountries(0).then(
            function (response) {
                if (response) {
					self.countryFilterList = self.filtersService.getVarResults();
                }
            }
        );
    }

	onItemSelect(item: any) {
		//console.log(item);
	}
	onSelectAll(items: any) {
		//console.log(items);
	}


	getQuestions(){
		const self = this;
		if(self.survey.surveyID != null){
			self.surveyService.getSurveyQuestions(self.survey.surveyID).then(
				function (response) {
					if (response) {
						self.surveyQuestions = self.surveyService.getVarResults();
						self.questionCount = self.surveyQuestions.length;
					}
				}
			);
		}
	}

	getSurveyById(surveyID){
		const self = this;
		self.surveyService.getSurveyByID(surveyID).then(
			function (response) {
				if (response) {
					let survey = self.surveyService.getVarResults()
					self.tempSurvey = survey;
					self.survey.setSurvey(survey["survey"]); 
					self.getQuestions();
					self.selectedCountry();
					self.getListRols();
					self.listCategories();
					//console.log(self.survey);
					if(self.survey.surveyRequestStatus == "Sent"){
						$("#myDivSurvey input").attr("disabled", true);
						$("#myDivSurvey select").attr("disabled", true);
						$("#myDivSurvey textarea").attr("disabled", true);
					}
				}
			}
		);
	}

	getListUserRols() {
        const self = this;
        this.methodsUtilsService.getListRols().then(
            function (response) {
                if (response) {
                    let rols = self.methodsUtilsService.getVarResults();
					self.listUserRolID = rols;
					self.setUserRols(rols);
                }
            }
        );
    }

	setCategories(listCategories){
		const self = this;
		let tempEmp1 = [];
		for(let cat of listCategories){
			if(cat["categoryID"] != null){
				tempEmp1.push({"categoryID": cat["categoryID"], "category" : self.getCategoryName(cat["categoryID"]) });
			}
		}
		self.survey.listCategoryID = tempEmp1;
	}

	setUserRols(listUserRols){
		const self = this;
		let tempEmp = []
		for(let emt of listUserRols){
			if(emt["userRolID"] != null){
				tempEmp.push({ "userRolID": emt["userRolID"], "level" : emt["level"] });
			}
		}	
		self.survey.listUserRolID = tempEmp;
	}

	setLocations(listStoresLocations){
		const self = this;
		let tempEmp1 = [];
		for(let st of listStoresLocations){
			if(st["siteLocationID"] != null){
				tempEmp1.push({ "siteLocationID": st["siteLocationID"], "location" : self.getLocationName(st["siteLocationID"])});
			}
		}	
		self.survey.listSiteLocationID = tempEmp1;
	}

	storeExists(arr, store) {
		return arr.some(function(el) {
		  return el["siteStoreID"] === store["siteStoreID"];
		}); 
	}

	setStores(listStoresLocations){
		const self = this;
		let tempEmp2 = [];
		for(let st of listStoresLocations){
			if(st["siteStoreID"] != null){
				if(!self.storeExists(tempEmp2, st)){
					tempEmp2.push({ "siteStoreID": st["siteStoreID"], "name" : self.getStoreName(st["siteStoreID"]) });
				}
			}
		}	
		self.survey.listSiteStoreID = tempEmp2;
	}

	getStoreName(storeId){
		const self = this;
		let name = self.storeFilterList.filter(obj => {
			return obj.siteStoreID === storeId;
		});
		return name[0].name;
	}

	getLocationName(locationId){
		const self = this;
		let name = self.locationFilterList.filter(obj => {
			return obj.siteLocationID === locationId;
		});
		return (name[0]) ? name[0].location : '';
	}

	getCategoryName(categoryId){
		const self = this;
		let name = self.categoryFilterList.filter(obj => {
			return obj.categoryID === categoryId;
		});
		return name[0].category;
	}

	getUserRolName(employeeTypeId){
		const self = this;
		let name = self.userRolsFilterList.filter(obj => {
			return obj.userRolID === employeeTypeId;
		});
		console.log(employeeTypeId);
		console.log(self.userRolsFilterList);
		return (name[0]) ? name[0].level : '';
	}

	resetOptions(count){
		let question = $("#questionsContainer").find(".question[data-count='"+count+"']");
		let clone = $("#questionsContainer .question:first .options .optionContainer").clone();

		$(question).find(".options").html(clone); 
	}

	selectedQuestionType(count){
		const self = this;

		let question = $("#questionsContainer").find(".question[data-count='"+count+"']");
		
		self.resetOptions(count);

		let questionType = $(question).find(".questionType");
		let options = $(question).find(".options");
		

		if(questionType.val() == 5 || questionType.val() == 6){ //OPTION OR MULTI OPTION	
			$(options).css("display", "initial");
			$(options).find(".addOptionSurvey").css("display", "initial");
			$(options).find(".option").attr("disabled", false);
			$(options).find(".option").val("");

			if(typeof $(options).find(".addOptionSurvey")[0] != "undefined"){
				$(options).find(".addOptionSurvey")[0].addEventListener('click', function(){
					self.addOption(count);
				});
			}
			
			$(options).find(".option").attr("placeholder", "Option");
			$(options).find(".option").attr("type", "text");
		
		}else if(questionType.val() == 4){ //NUMERIC

			$(options).find(".optionsContainer").hide();
			$(options).css("display", "initial");
			self.addOption(count);
			$(options).find(".addOptionSurvey").hide();
			$(options).find(".removeOptionSurvey").hide();
			$(options).find(".option").attr("disabled", false);

			$(options).find(".option").first().attr("placeholder", "Min");
			$(options).find(".option").first().attr("type", "number");
			$(options).find(".option").first().val("");

			$(options).find(".option").last().attr("type", "number");
			$(options).find(".option").last().attr("placeholder", "Max");
			$(options).find(".option").last().val("");

		}else if(questionType.val() == 3){ //TEXTO

			$(options).css("display", "initial");
			$(options).find(".option").val("");
			$(options).find(".addOptionSurvey").hide();
			$(options).find(".option").attr("disabled", false);
			$(options).find(".option").attr("placeholder", "Placeholder");
			$(options).find(".option").attr("type", "text");

		}else{
			$(options).css("display", "none");
			$(options).find(".option").val("");
			$(options).find(".option").attr("disabled", true);
			$(options).find(".optionContainer:last");
			$.each($(question).find(".optionContainer"), function(key, q){
				if(key != 0){
					$(q).remove();
				}
			}); 
		}
	}

	addOption(count){
		const self = this;
		let option = $("#questionsContainer").find(".question[data-count='"+count+"']");
		let optionContainer = $(option).find(".optionContainer:last");

		let clone = optionContainer.clone();

		clone.attr("data-count", parseInt(optionContainer.attr("data-count")) + 1)
		clone.find(".addOptionSurvey").css("display", "none");
		clone.find(".removeOptionSurvey").show();
		clone.find(".removeOptionSurvey").css("display", "initial");

		
		clone.find(".option").val("");
		clone.find(".option").attr("disabled", false);
		
		if(typeof clone.find(".removeOptionSurvey")[0] != "undefined"){
			clone.find(".removeOptionSurvey")[0].addEventListener('click', function(){
				self.removeOption(clone);
			});
		}
		$(optionContainer).parent().append(clone);
	}

	removeOption(option){
		$(option).remove();
	}

	removeOptionCreated(i, k){
		let question = $("#questionsContainer").find(".question[data-count='"+i+"']");
		let optionContainer = $(question).find(".optionContainer[data-count='"+k+"']");
		optionContainer.remove();
	}

	resetQuestion(question){
		const self = this;
		$(question).find(".questionTitle").val("");
		$(question).find(".option").val("");
		$(question).find(".questionMandatory").prop("checked", false);
		$(question).find(".option").attr("disabled", true);
		$(question).find(".deleteSurvey").css("display", "initial");
		$(question).find(".copySurvey").css("display", "initial");
		$(question).find(".addOptionSurvey").css("display", "none");
		$(question).find(".options").css("display", "none");
		
		$.each($(question).find(".optionContainer"), function(key, q){
			if(key != 0){
				q.remove();
			}
		}); 
	}

	addQuestion(){
		const self = this;
		let clone = $("#questionsContainer .question:first").clone();
		self.questionCount = self.questionCount + 1;
		let countElement = self.questionCount;
		clone.attr("data-count", countElement);
		clone.find("label:first").html("Pegunta "+ countElement);
		
		clone.find(".questionTitle").attr("disabled", false);
		clone.find(".questionType").attr("disabled", false);
		clone.find(".option").attr("disabled", false);
		
		clone.find(".questionType")[0].addEventListener('change', function(){
			self.selectedQuestionType(countElement);
		});
		
		clone.find(".deleteSurvey")[0].addEventListener('click', function(){
			self.removeQuestion(countElement);
		});

		clone.find(".copySurvey")[0].addEventListener('click', function(){
			self.duplicateQuestion(countElement);
		});

		self.resetQuestion(clone);
		clone.show();
		$("#questionsContainer").append(clone);
	}

	duplicateQuestion(question){
		const self = this;
		let qt = $("#questionsContainer").find(".question[data-count='"+question+"']");
		let clone  = qt.clone();

		self.questionCount = self.questionCount + 1;
		let countElement = self.questionCount;

		clone.attr("data-count", countElement);
		clone.find("label:first").html("Question "+ countElement);

		clone.find(".questionType").val(qt.find(".questionType").val());
		clone.find(".surveyQuestionID").val("");
		
		clone.find(".questionType")[0].addEventListener('change', function(){
			self.selectedQuestionType(countElement);
		});
		
		clone.find(".deleteSurvey")[0].addEventListener('click', function(){
			self.removeQuestion(countElement);
		});

		clone.find(".copySurvey")[0].addEventListener('click', function(){
			self.duplicateQuestion(countElement);
		});

		clone.show();
		$("#questionsContainer").append(clone);
		
	}

	removeQuestion(question){
		const self = this;
		self.questionCount = self.questionCount - 1;
		let qt = $("#questionsContainer").find(".question[data-count='"+question+"']");
		qt.remove();
	}

	validateSurvey(){
		const self = this;
		let error = [];
		let translatePipe = new TranslatePipe();
			
		if(self.survey.countryID == null){
			error.push(translatePipe.transform("Pages.Surveys.Form.CountryIsRequired"));
		}
		if(self.survey.active == null){
			error.push(translatePipe.transform("Pages.Surveys.Form.StatusIsRequired"));
		} 
		if(self.survey.workPlace == null){
			error.push(translatePipe.transform("Pages.Surveys.Form.WorkplaceIsRequired"));
		} 
		if(self.survey.title == ""){
			error.push(translatePipe.transform("Pages.Surveys.Form.TitlesIsRequired"));
		} 
		if(self.survey.titleApp == ""){
			error.push(translatePipe.transform("Pages.Surveys.Form.TitleInAppIsRequired"));
		} 
		if(self.survey.end_Survey == null){
			error.push(translatePipe.transform("Pages.Surveys.Form.EndDateIsIsRequired"));
		} 
		if(self.survey.start_Survey == null){
			error.push(translatePipe.transform("Pages.Surveys.Form.StartDateIsRequired"));
		} 
		if(self.survey.listUserRolID == null || self.survey.listUserRolID.length == 0) {
			error.push(translatePipe.transform("Pages.Surveys.Form.EmployeeTypeIsRequired"));
		} 

		self.survey.start_Survey =  format(self.survey.start_Survey, 'MM/DD/YYYY 00:00:00');
		self.survey.end_Survey = format(self.survey.end_Survey , 'MM/DD/YYYY 00:00:00');

		//console.log(self.survey.start_Survey);
		//console.log(self.survey.end_Survey);

		if(self.survey.start_Survey > self.survey.end_Survey){
			error.push(translatePipe.transform("Pages.Surveys.Form.StartDateInvalid"));
		} 

		if(error.length > 0){
			let msg =  error.join('\n');
			self.alertMsg(msg, "danger");
			return false;
		}else{
			return true;
		}
	}

	saveSurvey(send){
		const self = this;
		if(self.validateSurvey()){
			let questions = $("#questionsContainer .question");

			let questionSurvey = [];
			let order = 0;
			$.each(questions, function(key, question){
				if(key != 0){
					order = order + 1;
					let tempQuestion = new Question();
					tempQuestion.question = $(question).find(".questionTitle").val();
					
					if(self.survey.surveyID != null){
						tempQuestion.surveyQuestionID = parseInt($(question).find(".surveyQuestionID").val());
					}

					tempQuestion.surveyQuestionTypeID = parseInt($(question).find(".questionType :selected").val());
					tempQuestion.order = order;
					let options = $(question).find(".option");
					let optionsTemp = {};
					$.each(options, function(key, opt){
						let optionName = "";
						if(tempQuestion.surveyQuestionTypeID == 3){
							optionName = "placeholder";
						}else if(tempQuestion.surveyQuestionTypeID == 4){ //NUMERIC
							if(key == 0){
								optionName = "min";
							}else{
								optionName = "max";
							}
							
						}else{
							optionName = "option"+key;
						}
						let optionValue = $(opt).val();
						optionsTemp[optionName] = optionValue;
					});
					if (tempQuestion.surveyQuestionTypeID == 5 
						|| tempQuestion.surveyQuestionTypeID == 6
						|| tempQuestion.surveyQuestionTypeID == 3
						|| tempQuestion.surveyQuestionTypeID == 4) {
						tempQuestion.options = JSON.stringify(optionsTemp);
					} else {
						tempQuestion.options = '';
					}
				
					tempQuestion.active = true;
					tempQuestion.mandatory = $(question).find(".questionMandatory").is(':checked'); ;
					order = order + 1;
					questionSurvey.push(tempQuestion);
				}
				
				self.survey.listSurveyQuestion = questionSurvey;
				
				
			});

			self.sendSurveyCreateUpdate(send);
			return true;
		}
	}

	public alertMsg(message, type){
		const self = this;
		alert(message);
	}

	public sendSurveyCreateUpdate(send) {
		const self = this;
		
		self.survey.start_Survey =  format(self.survey.start_Survey, 'MM/DD/YYYY 00:00:00');
		self.survey.end_Survey = format(self.survey.end_Survey , 'MM/DD/YYYY 00:00:00');

		self.survey.listSiteStoreID = self.survey.listSiteStoreID.map(function(item) { return item["siteStoreID"]; });
		self.survey.listSiteLocationID = self.survey.listSiteLocationID.map(function(item) { return item["siteLocationID"]; });
		self.survey.listUserRolID = self.survey.listUserRolID.map(function(item) { return item["userRolID"]; });
		self.survey.listCategoryID = self.survey.listCategoryID.map(function(item) { return item["categoryID"]; });
		//self.survey.regionID = self.survey.country.regionID;

		if(self.survey.surveyID == null){

			self.surveyService.createSurvey(self.survey).then(
				function (result) {
					if(result){
						let survey = self.surveyService.getVarResults();
						self.survey.setSurvey(survey);
						
						if(send){
							self.changeState(self.survey.surveyID, "Sent");
						}else{
							let translatePipe = new TranslatePipe();
							let msg = translatePipe.transform('Pages.Surveys.Form.SurveySaved');
							self.alertMsg(msg, 'success');
							self.title = "Surveys.Form.TitleUpdate";
							self.router.navigate(['/surveys']);
						}
					}
				}
			);
		}else{
			self.surveyService.updateSurvey(self.survey).then(
				function (result) {
					if(result){
						let survey = self.surveyService.getVarResults();
						self.survey.setSurvey(survey);

						if(send){
							self.changeState(self.survey.surveyID, "Sent");
						}else{
							let translatePipe = new TranslatePipe();
							let msg = translatePipe.transform('Pages.Surveys.Form.SurveyUpdated');
							self.alertMsg(msg, 'success');
							self.router.navigate(['/surveys/']);
						}
					}
				}
			);
		}

		return self.survey.surveyID;
	}

	public sendNow(){
		const self = this;
		self.sendSurveyNow = true;

		return true;
	}

	public changeState(surveyID, state){
		const self = this;
		let request = {
			"surveyID": surveyID,
			"surveyRequestStatus": state
		};

		self.surveyService.changeState(request).then(
			function (result) {
				if(result){
					let translatePipe = new TranslatePipe();
					let msg = translatePipe.transform('Pages.Surveys.Form.SurveySended');
					self.alertMsg(msg, 'success');
					self.router.navigate(['/surveys']);
					return true;
				}else{
					return false;
				}
			}
		);
	}

	public listRegion() {
		const self = this;
		self.filtersService.getListRegions().then(
			function (response) {
				if (response) {
					self.regionFilterList = self.filtersService.getVarResults();
				}
			}
		);
	}

	/**listar Categories*/
	listCategories() {
		const self = this;
		self.filtersService.getListCategories().then(
			function (response) {
				if (response) {
					self.categoryFilterList = self.filtersService.getVarResults();
					self.ifDataExist = true;

					if(self.tempSurvey){
						self.setCategories(self.tempSurvey["listCategories"]); 
					}
				}
			}
		);
	}

	getQuestionsTypes(){
		const self = this;
		// listar site stores
		self.surveyService.getQuestionsTypes().then(
			function (response) {
				if (response) {
					self.questionTypesList = self.surveyService.getVarResults();
				}
			}
		);
	}

	goToQuestions(event){
		const self = this;
		event.stopImmediatePropagation();

		if(self.survey.surveyRequestStatus == "Sent"){
			$("#questions input").attr("disabled", true);
			$("#questions select").attr("disabled", true);
			$("#questions textarea").attr("disabled", true);
			$(".deleteSurvey").hide();
			$(".addOptionSurvey").hide();
			$(".removeOptionSurvey").hide();
			$(".copySurvey").hide();
		}

		if(self.validateSurvey()){
			$('#details-tab').removeClass('active');
			$('#questions-tab').addClass('active');
			$('#details').removeClass('active show');
			$('#questions').addClass('active show');
			return false;
			
		}else{
			$('#details-tab').addClass('active');
			$('#questions-tab').removeClass('active');
			$('#details').addClass('active show');
			$('#questions').removeClass('active show');
			return false;
		}
	}

	filterArray(array1, array2, field){
		var keys = array2.map(function(item) { return item[field]; });
		let arrayFiltered = array1.filter(o => !keys.includes(o[field]));
		return arrayFiltered;
	}

	filterArrayLevel2(array1, array2, field, field2){
		var keys = array2.map(function(item) { return item[field][field2]; });
		let arrayFiltered = array1.filter(o => !keys.includes(o[field][field2]));
		return arrayFiltered;
	}
	
	/*
	selectedRegion($event) {
		const self = this;
		let regionID = $event.regionId;
		self.filtersService.getListCountryByRegion(regionID).then(
			function (response) {
				if (response) {
					let results = self.filtersService.getVarResults();
					self.countryFilterList = self.countryFilterList.concat(results);
				}
			}
		);
	}
	*/

	/*
	unSelectedRegion($event){
		const self = this;
		let regionID = $event.regionId;
		let userRol = sessionStorage.getItem('userRolId');
		self.filtersService.getListCountryByRegion(regionID).then(
			function (response) {
				if (response) {
					let results = self.filtersService.getVarResults();
					let resultsFiltered = self.filterArray(self.countryFilterList, results, "countryID")
					self.countryFilterList = resultsFiltered;
					
					let selectedFiltered = self.filterArray(self.survey.countryID, results, "countryID")
					self.survey.countryID = selectedFiltered;

				}
			}
		);
	}
	*/

	/*
	selectAllRegion($event){
		const self = this;
		for(let region of $event){
			self.selectedRegion(region);
		}
	}
	*/

	unSelectAllRegion($event){
		const self = this;
		self.countryFilterList = [];
		//self.survey.countryID = [];
	}
	

	selectedCountry(){
		const self = this;
		let countryID = self.survey.countryID;
		if(countryID){
			self.filtersService.getListSiteStoresFilters().then(
				function (response) {
					if (response) {
						let results = self.filtersService.getVarResults();
						self.storeFilterList = self.storeFilterList.concat(results);
						if(self.tempSurvey){
							self.setStores(self.tempSurvey["listStoresLocations"]);
							for(const store of self.tempSurvey["listStoresLocations"]){
								self.selectedStore(store);
							}
						}
					}
				}
			);
		}
	}

	unSelectedCountry($event){
		const self = this;
		let countryID = $event.countryID;

		self.filtersService.getListSiteStoresFilters().then(
			function (response) {
				if (response) {
					let results = self.filtersService.getVarResults();
					let resultsFiltered = self.filterArray(self.storeFilterList, results, "siteStoreID")
					self.storeFilterList  = resultsFiltered;
					
					let selectedFiltered = self.filterArray(self.survey.listSiteStoreID, results, "siteStoreID")
					self.survey.listSiteStoreID = selectedFiltered;
				}
			}
		);
	}

	unSelectAllCountry($event){
		const self = this;
		self.storeFilterList = [];
		self.survey.listSiteStoreID = [];
		self.locationFilterList = [];
		self.survey.listSiteLocationID = [];
	}

	selectedStore($event) {
		const self = this;
		let storeId = $event.siteStoreID;
		if(storeId){
			self.filtersService.getListSiteLocationsFilters(storeId).then(
				function (response) {
					if (response) {
						let results = self.filtersService.getVarResults();
						self.locationFilterList = self.locationFilterList.concat(results);

						if(self.tempSurvey){
							self.setLocations(self.tempSurvey["listStoresLocations"]); 
						}
						
					}
				}
			);
		}
	}

	unSelectedStore($event){
		const self = this;
		let storeId = $event.siteStoreID;
		self.filtersService.getListSiteLocationsFilters(storeId).then(
			function (response) {
				if (response) {
					let results = self.filtersService.getVarResults();
					let resultsFiltered = self.filterArray(self.locationFilterList, results, "countryID")
					self.locationFilterList = resultsFiltered;
					let selectedFiltered = self.filterArray(self.survey.listSiteLocationID, results, "siteLocationID")
					self.survey.listSiteLocationID = selectedFiltered;
				}
			}
		);
	}

	selectAllStore($event){
		const self = this;
		for(let store of $event){
			self.selectedStore(store);
		}
	}

	unSelectAllStore($event){
		const self = this;
		self.locationFilterList = [];
		self.survey.listSiteLocationID = [];
	}
}