import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../../../../services/utils.service';
import { GiveAwayCampaignsService } from '../../../../services/giveAwayCampaigns.service';

@Component({
    selector: 'app-index-GiveAwayCampaigns',
    templateUrl: './index.component.html',
    styleUrls: []
})

export class GiveAwayCampaignsComponent implements OnInit {

    public title = 'GiveAwayCampaigns';
    public ifDataExist = false;
    public tableTitles = [
        { name: 'campaignName', label: 'Nombre', type: 'normal' },
        { name: 'country.name', label: 'País', type: 'active' },
        { name: 'category.category', label: 'Categoría', type: 'active' },
        { name: 'giveAwayType.type', label: 'Give Away Type', type: 'active' },
    ];
    public data: any = [];
    public optionsTable = {
        dataId: 'giveAwayCampaignID',
        showData: false,
        actionShowData: '',
        updateData: true,
        actionUpdateData: 'update',
        deleteData: false,
        actionDeleteData: '',
        filterCountryZone: false,
        filterSiteLocation: false,
        filterSitestore: false,
        filterCity: false,
        filterCountry: false,
        filterRegion: false,
        filterCedis: false,
        searchBtn: true,
        exportBtn: true
    };

    constructor(
        private giveAwayCampaignsService: GiveAwayCampaignsService,
        private utilsService: UtilsService
    ) {}

    ngOnInit() {
        this.getBrands();
    }

    private getBrands() {
        const self = this;
        self.giveAwayCampaignsService.getResults().then(
            function (result) {
                self.data = self.giveAwayCampaignsService.getVarResults();
                self.ifDataExist = true;
                self.utilsService.debugConsole(result);
            }
        );
    }

}
