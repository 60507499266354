import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../../../../services/utils.service';
import { CitiesService } from '../../../../services/cities.service';

@Component({
    selector: 'app-index-cities',
    templateUrl: './index.component.html',
    styleUrls: []
})

export class CitiesComponent implements OnInit {
    public title = 'Cities';
    public ifDataExist = false;
    public tableTitles = [
        { name: 'cityName', label: 'Ciudad', type: 'normal' },
        { name: 'country.country', label: 'País', type: 'normal' },
        { name: 'countryZone.zoneName', label: 'Country Zona', type: 'normal' },
        { name: 'active', label: 'Estado', type: 'active' },
    ];
    public data: any = [];
    public optionsTable = {
        dataId: 'cityID',
        showData: false,
        actionShowData: '',
        updateData: true,
        actionUpdateData: 'update',
        deleteData: false,
        actionDeleteData: '',
        filterCountryZone: true,
        filterSiteLocation: false,
        filterSitestore: false,
        filterCity: false,
        filterCountry: true,
        filterRegion: true,
        filterCedis: false,
        searchBtn: true,
        exportBtn: true
    };


    constructor(
        private citiesService: CitiesService,
        private utilsService: UtilsService
    ) {}

    ngOnInit() {
    }

    filter(event) {
		let request = {
			'countriesZones': (event.countryZoneId) ? [event.countryZoneId] : [],
			'countries': (event.countryId) ? [event.countryId] : [],
		};
		this.getCities(request);
	}

    private getCities (request) {
        const self = this;
        self.citiesService.getCitiesFilter(request).then(
            function (result) {
                self.data = self.citiesService.getVarResults();
                self.ifDataExist = true;
                self.utilsService.debugConsole(result);
            }
        );
    }

}