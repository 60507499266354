import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from '../../../../../services/utils.service';
import { MethodsUtilsService } from '../../../../../services/methodsUtils.service';
import { FormularioBaseComponent } from '../../../../utils/formularioBase.component';
import { Brand } from '../../../../../models/brand';
import { BrandsService } from '../../../../../services/brands.service';
// Declare Vars
declare var $: any;

@Component ({
	selector: 'app-form-region',
	templateUrl: './form.component.html',
	styleUrls: []
})

export class FormBrandComponent extends FormularioBaseComponent implements OnInit {

	public page = 'Brands';
	public title = 'Brands.Create';
	public brand = new Brand();
	public titleButton = 'Save';
	public fieldsCategory;

	constructor(
		private route: ActivatedRoute,
		private brandService: BrandsService,
		private router: Router,
		public utilsService: UtilsService,
		public methodsUtilsService: MethodsUtilsService,
	) {
		super(utilsService, methodsUtilsService);
	}

	ngOnInit() {
		const self = this;
		this.fieldsCategory = { text: 'category', value: 'categoryID' };
		self.getListCategories();
		
		self.param = self.route.params.subscribe(
			params => {
				if (params['id']) {
					self.title = 'Brands.Update';
					self.titleButton = 'Update';
					self.utilsService.debugConsole(params['id']);
					self.getBrand(params['id']);
				}
			}
		);
	}

	private getBrand (id: any): void {
		const self = this;
		self.brandService.getResult(id).then(
			function (response) {
				if (response === false) { return; }
				self.brand.setBrand(self.brandService.getVarResults());
			}
		);
	}

	public saveUpdateBrand(): void {
		const self = this;
		if (self.brand.brandID !== null) {
			self.brandService.putData(self.brand.brandID, self.brand.getJsonUpdate()).then(
				function (response) {
					if (response === false) { return; }
					self.router.navigate(['/brands']);
				}
			);
		} else {
			self.brandService.postData(self.brand.getJsonCreate()).then(
				function (response) {
					if (response === false) { return; }
					self.router.navigate(['/brands']);
				}
			);
		}
	}

}
