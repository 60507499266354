import { Question } from './question';
import * as format from 'date-fns/format';
import { Country } from './country';

export class Survey {
	public surveyID: number; 
    public title: string;
    public titleApp: string;
    public description: string;
	public countryID: number; 
    public workPlace: boolean; 
    public start_Survey: string; // "2019-05-31T02:33:33.380Z"
    public end_Survey: string; // "2019-05-31T02:33:33.380Z"
	public active: boolean;
	public surveyRequestStatus: string; 
	public listSurveyQuestion = [];
	public listSiteStoreID: any[]; 
	public listSiteLocationID: any[]; 
	public listCategoryID: any[]; 
	public listUserRolID: any[]; 
	//public country: Country;
    
	constructor() {
		this.initalizeSurvey();
	}

	initalizeSurvey() {
		this.surveyID = null;
		this.title = null;
		this.titleApp = null;
		this.description = null;
		this.countryID = null;
		this.listSiteStoreID = [];
		this.listSiteLocationID = []; 
		this.listCategoryID = [];
		this.listUserRolID = []; 
		this.workPlace = null;
		this.start_Survey = null;
		this.end_Survey = null;
		this.active = null;
		this.surveyRequestStatus = null;
		this.listSurveyQuestion = [];
		//this.country = new Country();
	}

	setSurvey(survey) {
		this.surveyID = survey.surveyID;
		this.title = survey.title;
		this.titleApp = survey.titleApp;
		this.description = survey.description;
		this.countryID = survey.countryID;
		this.workPlace = survey.workPlace;
		this.start_Survey = format(survey.start_Survey, 'MM-DD-YYYY');
		this.end_Survey = format(survey.end_Survey, 'MM-DD-YYYY');
		this.active = survey.active;
		this.surveyRequestStatus = survey.surveyRequestStatus;
		//if (survey.hasOwnProperty('country') && survey.country !== null) { this.country.setCountry(survey.country); }
	}

	getJsonBase(): any {
		const jsonBase = {
			'title': this.title,
			'titleApp': this.titleApp,
			'description': this.description,
			'countryID': this.countryID,
			'workPlace': this.workPlace,
			'start_Survey': this.start_Survey,
			'end_Survey': this.end_Survey,
			'active': this.active
		};
		return jsonBase;
	}

	getJsonCreate(): any {
		const json = this.getJsonBase();
		return json;
	}

	getJsonUpdate(): any {
		const json = this.getJsonBase();
		if (this.surveyID !== null) { json['surveyID'] = this.surveyID; }
		return json;
	}
}