import { Region } from './region';
/**
 * Created by Juan Vidales Cheil on 30/5/2019.
 */
export class Country {
  public countryID: number; // 123
  public regionId: number; // 123
  public country: string; // El nombre max 25
  public name: string; // El nombre max 25
  public country_Code: string; // El nombre max 5
  public branch: string; // El nombre max 5
  public status: number; // 1 or 0
  public created_By: string; // El nombre de quien lo crea
  private created_Date: string; // "2019-05-31T02:33:33.380Z"
  private updated_Date: string; // "2019-05-31T02:33:33.380Z"
  public timeZone: string; // string Max 50
  public trackingTimeSec: number; // string Max 50
  public allowTracking: boolean; // string Max 50
  public region: Region;

  constructor() {
    this.initalizeCountry();
  }

  initalizeCountry() {
    this.countryID = null;
    this.regionId = null;
    this.country = null;
    this.name = null;
    this.country_Code = null;
    this.branch = null;
    this.status = null;
    this.created_By = null;
    this.created_Date = null;
    this.updated_Date = null;
    this.timeZone = null;
    this.trackingTimeSec = null;
    this.allowTracking = null;
    this.region = new Region();
  }

  setCountry(country) {
    this.countryID = country.countryID;
    this.regionId = country.regionId;
    this.country = country.country;
    this.name = country.name;
    this.country_Code = country.country_Code;
    this.branch = country.branch;
    this.status = country.status;
    this.created_By = country.created_By;
    this.created_Date = country.created_Date;
    this.updated_Date = country.updated_Date;
    this.timeZone = country.timeZone;
    this.trackingTimeSec = country.trackingTimeSec;
    this.allowTracking = country.allowTracking;
    if (country.hasOwnProperty('region') && country.region !== null) { this.region.setRegion(country.region); }
  }

  getJsonBase(): any {
    const jsonBase = {
      'regionId': this.regionId,
      'country': this.country,
      'name': this.name,
      'country_Code': this.country_Code,
      'branch': this.branch,
      'status': this.status,
      'timeZone': this.timeZone,
      'trackingTimeSec': this.trackingTimeSec,
      'allowTracking': this.allowTracking
    };
    return jsonBase;
  }

  getJsonCreate(): any {
    const json = this.getJsonBase();
    if (this.created_By !== null) { json['created_By'] = this.created_By; }
    return json;
  }

  getJsonUpdate(): any {
    const json = this.getJsonBase();
    if (this.created_Date !== null) { json['created_Date'] = this.created_Date; }
    if (this.created_By !== null) { json['created_By'] = this.created_By; }
    if (this.countryID !== null) { json['countryID'] = this.countryID; }
    return json;
  }
}
