import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../../../../services/utils.service';
import { ReportSamplingAuditService } from '../../../../services/reportSamplingAudit.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-sampling-audit',
  templateUrl: './index.component.html',
  styleUrls: []
})

export class SamplingAuditComponent implements OnInit {

  public title = 'ReportsSamplingAudit';
  public ifDataExist = false;
  public ifExcelExist = true;
  public regionID = [];
  public cityID = [];
  public countryID = [];
  public data: any = [];
  public urlExcel: any;

  public tableTitles = [
    { name: 'year', label: 'Year', type: 'number' },
    { name: 'month', label: 'Month', type: 'normal' },
    { name: 'yearWeek', label: 'Week', type: 'number' },
    { name: 'day', label: 'Day', type: 'number' },
    { name: 'siteLocCountry', label: 'Country', type: 'normal' },
    { name: 'siteStoreName', label: 'Store', type: 'normal' },
    { name: 'siteLoc.name', label: 'Location', type: 'normal' },
    { name: 'siteLoc.site_id', label: 'Site ID', type: 'normal' },
    { name: 'merchandising.model', label: 'Model', type: 'normal' },
    { name: 'merchandising.serialIMEI', label: 'Serial', type: 'normal' },
    { name: 'status', label: 'State', type: 'normal' },
    { name: 'statusComment', label: 'Description', type: 'normal' },
    { name: 'photo1', label: 'Photo 1', type: 'image' },
    { name: 'photo2', label: 'Photo 2', type: 'image' },
    { name: 'photo3', label: 'Photo 3', type: 'image' },
    { name: 'photo4', label: 'Photo 4', type: 'image' },
    { name: 'photo5', label: 'Photo 5', type: 'image' },
    { name: 'photo6', label: 'Photo 6', type: 'image' },
    { name: 'auditUser.fullName', label: 'User Name', type: 'normal' },
    { name: 'auditUser.userName', label: 'User', type: 'normal' },
    { name: 'supervisors', label: 'Supervisor', type: 'normal' },
    { name: 'auditDate', label: 'Created Date', type: 'date' },
  ];

  public optionsTable = {
    dataId: 'merchandisingEvidenceID',
    showData: false,
    actionShowData: '',
    updateData: false,
    actionUpdateData: 'update',
    deleteData: false,
    actionDeleteData: '',
    filterCountryZone: false,
    filterSiteLocation: true,
    filterSitestore: true,
    filterDateRange: true,
    filterCategory: true,
    filterCity: true,
    filterCountry: true,
    filterRegion: true,
    filterCedis: false,
    searchBtn: true,
    exportBtn: true,
    descargaPdf:true,
    itemsPerPage: 5,
  };

  constructor(
    private reportSamplingAuditService: ReportSamplingAuditService,
    private utilsService: UtilsService
  ) { }

  ngOnInit() {
    const self = this;
    self.ifDataExist = true;
    const request = {};
  }

  downloadExcelReport($event){
    const self = this;
    let request = {}
    request["listIds"] = $event.listIds;
    request["email"] = $event.email;
    self.getExcelSamplingAudit(request);
  }

  showPdf(event) {
    console.log("mostrando id pdf" + event.data);
    const self = this;
    self.reportSamplingAuditService.downloadPdf(event.data)
  }

  filter(event) {
    const self = this;
    let request = {}
    if(event.start_Date && event.end_Date) {
      request = {
        'start_Date': event.start_Date,
        'end_Date': event.end_Date
      };
    } else return false;
    
    if(event.siteLocationId) {
      request['siteLocation'] = [event.siteLocationId]
    } else if(event.siteStoreId) {
      request['sitestores'] = [event.siteStoreId]
    } else if (event.cityId) {
      request['cities'] = [event.cityId]
    } else if (event.countryId) {
      request['countries'] = [event.countryId]
    } else if (event.regionId) {
      request['regions'] = [event.regionId]
    }
    if (event.categoryId) {
      request['categoryID'] = event.categoryId
    }
    self.getReportSamplingAudit(request);
  }

  private getReportSamplingAudit(request) {

    const self = this;
    self.reportSamplingAuditService.getReportSamplingAudit(request).then(
      function (result) {
        self.data = self.reportSamplingAuditService.getVarResults();
        self.ifDataExist = true;
        self.urlExcel = true;
        self.utilsService.debugConsole(result);
      }
    );
  }
  private getExcelSamplingAudit(request) {
    const self = this;
    self.reportSamplingAuditService.getExcelSamplingAudit(request).then(
      function (result) {
        let urlExcel  = self.reportSamplingAuditService.getVarResultsExcel();
        if( urlExcel.indexOf("http") == 0 ) {
            window.location.assign(urlExcel)
        } else {
            self.urlExcel = urlExcel;
        }
        self.utilsService.debugConsole(result);
      }
    );
  }
}