import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../../../../../services/utils.service';
import { ProductTypesService } from '../../../../../services/productTypes.service';

@Component({
    selector: 'app-index-product-types',
    templateUrl: './index.component.html',
    styleUrls: []
})

export class ProductTypesComponent implements OnInit {

    public title = 'ProductTypes';
    public ifDataExist = false;
    public tableTitles = [
        { name: 'category.category', label: 'Categoria', type: 'normal' },
        { name: 'type', label: 'Tipo de Producto', type: 'normal' },
        { name: 'created_By', label: 'Creado por', type: 'normal' },
        { name: 'created_Date', label: 'Fecha de Creación', type: 'dateTime' }
    ];
    public data: any = [];
    public optionsTable = {
        dataId: 'productTypeID',
        showData: false,
        actionShowData: '',
        updateData: true,
        actionUpdateData: 'update',
        deleteData: false,
        actionDeleteData: '',
        filterCountryZone: false,
        filterSiteLocation: false,
        filterSitestore: false,
        filterCity: false,
        filterCountry: false,
        filterRegion: false,
        filterCedis: false,
        searchBtn: true,
        exportBtn: true
    };

    constructor(
        private productTypesService: ProductTypesService,
        private utilsService: UtilsService
    ) {}

    ngOnInit() {
        this.getCountries();
    }

    private getCountries () {
        const self = this;
        self.productTypesService.getResults().then(
            function (result) {
                self.data = self.productTypesService.getVarResults();
                self.ifDataExist = true;
                self.utilsService.debugConsole(result);
            }
        );
    }

}
