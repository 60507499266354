import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from '../../../../../services/utils.service';
import { MethodsUtilsService } from '../../../../../services/methodsUtils.service';
import { FormularioBaseComponent } from '../../../../utils/formularioBase.component';
import { RegionsService } from '../../../../../services/regions.service';
import { Country } from '../../../../../models/country';
import { Region } from '../../../../../models/region';
// Declare Vars
declare var $: any;

@Component ({
	selector: 'app-form-country',
	templateUrl: './form.component.html',
	styleUrls: []
})

export class FormUsersRolComponent extends FormularioBaseComponent implements OnInit {

	public page = 'Stores';
	public title = 'Stores.Create';
	public country = new Country();
	public titleButton = 'Save';
	public region = new Region();

	constructor(
		private route: ActivatedRoute,
		private regionsService: RegionsService,
		private router: Router,
		public utilsService: UtilsService,
		public methodsUtilsService: MethodsUtilsService,
	) {
		super(utilsService, methodsUtilsService);
	}

	ngOnInit() {
		const self = this;
		self.param = self.route.params.subscribe(
			params => {
				if (params['id']) {
					self.title = 'Stores.Update';
					self.titleButton = 'Update';
					self.utilsService.debugConsole(params['id']);
					self.getRegion(params['id']);
				}
			}
		);
	}

	private getRegion (id: any): void {
		const self = this;
		self.regionsService.getResult(id).then(
			function (response) {
				if (response === false) { return; }
				self.country.setCountry(self.regionsService.getVarResults());
			}
		);
	}

	public saveUpdateRegion(): void {
		const self = this;
		self.country.created_By = self.utilsService.getUserId();
		if (self.country.countryID !== null) {
			self.regionsService.putData(self.country.countryID, self.country.getJsonUpdate()).then(
				function (response) {
					if (response === false) { return; }
					self.router.navigate(['/stores']);
				}
			);
		} else {
			self.regionsService.postData(self.country.getJsonCreate()).then(
				function (response) {
					if (response === false) { return; }
					self.router.navigate(['/stores']);
				}
			);
		}
	}

}
