import { Component, Input, OnInit } from '@angular/core';
import { MorrisJsModule } from 'angular-morris-js';
import { ChartDonutOptions } from 'angular-morris-js';

@Component({
	selector: 'app-card-user-promoter',
	templateUrl: './card-user-promoter.component.html',
	styleUrls: []
})
export class CardUserPromoterComponent implements OnInit {
	@Input() typelist: any;
	@Input() promoter: any;

	constructor() { }

	ngOnInit(): void {

	}
}