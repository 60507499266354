import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'chart-donut',
	templateUrl: './chart-donut.component.html',
	styleUrls: []
})
export class ChartDonutComponent implements OnInit {
	@Input() dataChar: any;
	@Input() optionsChar: any;
	@Input() showData: any;
	@Input() title: string;

	constructor() { }

	ngOnInit(): void {
		console.log(this.dataChar);
	}

	public sumValues(): number {
		let sum = 0;
		for (const item of this.dataChar) {
			sum += item.value;
		}
		return sum;
	}
}