/**
 * Created by Juan Vidales Cheil on 25/4/2019.
 */
import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
@Pipe({ name: 'customCurrencyPipe' })

export class CustomCurrencyPipe implements PipeTransform {

	constructor(private currencyPipe: CurrencyPipe) { }

	transform(value: any, currency: string, symbol: boolean = false): string {
		if (value != null) {
			return this.currencyPipe.transform(value, currency, symbol);
		}
		return this.currencyPipe.transform(0, currency, symbol).toString().split('0.00')[0];
	}
}
