import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from '../../../../../services/utils.service';
import { MethodsUtilsService } from '../../../../../services/methodsUtils.service';
import { FormularioBaseComponent } from '../../../../utils/formularioBase.component';
import { Channel } from '../../../../../models/channel';
import { ChanelsService } from '../../../../../services/chanels.service';
// Declare Vars
declare var $: any;

@Component ({
	selector: 'app-form-channel',
	templateUrl: './form.component.html',
	styleUrls: []
})

export class FormChannelComponent extends FormularioBaseComponent implements OnInit {

	public page = 'Channels';
	public title = 'Channels.Create';
	public channel = new Channel();
	public titleButton = 'Save';

	constructor(
		private route: ActivatedRoute,
		private channelsService: ChanelsService,
		private router: Router,
		public utilsService: UtilsService,
		public methodsUtilsService: MethodsUtilsService,
	) {
		super(utilsService, methodsUtilsService);
	}

	ngOnInit() {
		const self = this;
		self.param = self.route.params.subscribe(
			params => {
				if (params['id']) {
					self.title = 'Channels.Update';
					self.titleButton = 'Update';
					self.utilsService.debugConsole(params['id']);
					self.getChannel(params['id']);
				}
			}
		);
	}

	private getChannel (id: any): void {
		const self = this;
		self.channelsService.getResult(id).then(
			function (response) {
				if (response === false) { return; }
				self.channel.setChannel(self.channelsService.getVarResults());
			}
		);
	}

	public saveUpdateRegion(): void {
		const self = this;
		self.channel.created_By = self.utilsService.getUserId();
		if (self.channel.chanelID !== null) {
			self.channelsService.putData(self.channel.chanelID, self.channel.getJsonUpdate()).then(
				function (response) {
					if (response === false) { return; }
					self.router.navigate(['/channels']);
				}
			);
		} else {
			self.channelsService.postData(self.channel.getJsonCreate()).then(
				function (response) {
					if (response === false) { return; }
					self.router.navigate(['/channels']);
				}
			);
		}
	}

}
