import {
  Component,
  OnInit,
  Input,
  ViewChild,
  TemplateRef,
  ViewChildren,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UtilsService } from "../../../../services/utils.service";
import { CustomHttpService } from "../../../../services/customHttp.service";
import { UserService } from "../../../../services/user.service";
import { ModalService } from "../../../../services/modal.service";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslatePipe } from "../../../../pipes/translate";
import { DatePipe } from "@angular/common";

declare var $;

@Component({
  selector: "app-route",
  templateUrl: "route.component.html",
  styleUrls: ["route.component.scss"],
})
export class RouteManagementComponent implements OnInit {
  @ViewChild("modalViewLogin", {}) modalViewLogin: TemplateRef<any>;

  public filterChannel = true;
  public filtersActive = true;
  public ifDataExist = false;
  public data: any = [];
  private param: any;
  private userID: any;

  public optionsFilters = {
    dataId: "routeID",
    filterRegion: true,
    filterCountry: true,
    filterStore: false,
    filterPointOfSales: false,
    filterDate: false,
    showData: false,
    actionShowData: "show",
    showTablePrinc: false,
    showTablePeq: true,
    showBtnDownload: false,
    showNameViewing: false,
    titleLink: "Ver Tiendas",
  };

  public tableTitles = [
    { name: "fullName", label: "Usuario", type: "title" },
    { name: "userName", label: "UserName", type: "subtitle" },
  ];

  // Inject HttpClient into your component or service.
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    config: NgbModalConfig,
    private modal: NgbModal,
    private utilsService: UtilsService,
    private customHttpService: CustomHttpService,
    private userService: UserService,
    private modalService: ModalService
  ) {
    if (!sessionStorage.hasOwnProperty("token")) {
      const url = window.location.href;
      const searchParams = url.split("?")[1];
      const params = new URLSearchParams(searchParams);
      const token = params.get("t");
      const userCoded = params.get("u");
      const decodedString = atob(userCoded);
      const user = JSON.parse(decodedString);
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("language", "es");
      sessionStorage.setItem("token", token);
    } else {
      console.log("tokenExist.");
    }
  }

  ngOnInit(): void {
    const request = {};
  }

  filter(event) {
    const request = {
      isActive: true,
    };
    if (
      (event.hasOwnProperty("startDate") || event.hasOwnProperty("endDate")) &&
      event.hasOwnProperty("pointOfSalesId")
    ) {
      request["siteLoctacionID"] = event.pointOfSalesId;
      request["startDate"] = event.startDate;
      request["endDate"] = event.endDate;
    } else if (
      (event.hasOwnProperty("startDate") || event.hasOwnProperty("endDate")) &&
      event.hasOwnProperty("storeId")
    ) {
      request["SiteStoreID"] = event.storeId;
      request["startDate"] = event.startDate;
      request["endDate"] = event.endDate;
    } else if (
      (event.hasOwnProperty("startDate") || event.hasOwnProperty("endDate")) &&
      event.hasOwnProperty("countryId")
    ) {
      request["countryID"] = event.countryId;
      request["startDate"] = event.startDate;
      request["endDate"] = event.endDate;
    } else if (
      (event.hasOwnProperty("startDate") || event.hasOwnProperty("endDate")) &&
      event.hasOwnProperty("regionId")
    ) {
      request["regionID"] = event.regionId;
      request["startDate"] = event.startDate;
      request["endDate"] = event.endDate;
    } else if (event.startDate || event.endDate) {
      request["startDate"] = event.startDate;
      request["endDate"] = event.endDate;
    } else if (event.hasOwnProperty("pointOfSalesId")) {
      request["siteLoctacionID"] = event.pointOfSalesId;
    } else if (event.hasOwnProperty("storeId")) {
      request["SiteStoreID"] = event.storeId;
    } else if (event.hasOwnProperty("countryId")) {
      request["countryID"] = event.countryId;
    } else if (event.hasOwnProperty("regionId")) {
      request["regionID"] = event.regionId;
    }
    this.getUsersByFilters(request);
  }

  private getUsersByFilters(request) {
    const self = this;
    self.userService.getUsersByFilters(request).then(function (result) {
      self.data = self.userService.getVarResults();
      self.ifDataExist = true;
      self.utilsService.debugConsole(result);
    });
  }
}
