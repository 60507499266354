import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../../../../services/utils.service';
import { StoresService } from '../../../../services/stores.service';

@Component({
    selector: 'app-index-region',
    templateUrl: './index.component.html',
    styleUrls: []
})

export class StoresComponent implements OnInit {

    public title = 'Stores';
    public ifDataExist = false;
    public tableTitles = [
        { name: 'store', label: 'Store', type: 'normal' },
        { name: 'name', label: 'Nombre', type: 'normal' },
        { name: 'country.name', label: 'Pais', type: 'normal' },
        { name: 'city.cityName', label: 'Ciudad', type: 'normal' },
        { name: 'countryZone.zoneName', label: 'Country Zone', type: 'normal' },
        //{ name: 'created_By', label: 'Creado por', type: 'normal' },
    ];
    public data: any = [];
    public optionsTable = {
        dataId: 'siteStoreID',
        showData: false,
        actionShowData: '',
        updateData: true,
        actionUpdateData: 'update',
        deleteData: false,
        actionDeleteData: '',
        filterCountryZone: false,
        filterSiteLocation: false,
        filterSitestore: false,
        filterCity: false,
        filterCountry: true,
        filterRegion: true,
        filterCedis: false,
        searchBtn: true,
        exportBtn: true
    };

    constructor(
        private storesService: StoresService,
        private utilsService: UtilsService
    ) {}

    ngOnInit() {
        
    }

    filter(event) {
		let request = {
			'regions': (event.regionId) ? [event.regionId] : [],
			'countryZones': (event.countryZoneId) ? [event.countryZoneId] : [],
			'countries': (event.countryId) ? [event.countryId] : [],
		};
		this.getStores(request);
	}

    private getStores (request) {
        const self = this;
        self.storesService.getStoresFilter(request).then(
            function (result) {
                self.data = self.storesService.getVarResults();
                self.ifDataExist = true;
                self.utilsService.debugConsole(result);
            }
        );
    }

}
