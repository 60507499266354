import { Category } from './category';
import { ProductType } from './productType';
import { ProductGroup } from './productGroup';
import { Region } from './region';
import { Brand } from './brand';

export class Product {
	public productID: number; 
    public brandID: number; 
    public categoryID: number; 
    public codEAN: string;
    public model: string;
    public modelCode: string;
    public pdF_PopGuide: string;
    public productGroupID: number;
    public productTypeID: number;
    public regionId: number;
    public status: true;
    public category: Category;
    public productType: ProductType;
    public productGroup: ProductGroup;
    public brand: Brand;
    public region: Region;
    public  created_By: string; 
    public created_Date: string; 
    public updated_Date: string; 

	constructor() {
		this.initalizeProduct();
	}

	initalizeProduct() {
        this.regionId = null;
        this.productID = null;
        this.brandID = null;
        this.categoryID = null;
        this.productGroupID = null;
        this.productTypeID = null;
        this.codEAN = null;
        this.model = null;
        this.modelCode = null;
        this.pdF_PopGuide = null;
        this.status = null;
		this.created_By = null;
		this.created_Date = null;
        this.updated_Date = null;
        this.category = new Category();
        this.productType = new ProductType();
        this.productGroup = new ProductGroup();
        this.region = new Region();
        this.brand = new Brand();
	}

	setProduct(product) {
		this.productID = product.productID;
        this.brandID = product.brandID; 
        this.categoryID = product.categoryID;
        this.codEAN = product.codEAN;
        this.model = product.model;
        this.modelCode = product.modelCode;
        this.pdF_PopGuide = product.pdF_PopGuide;
        this.productGroupID = product.productGroupID;
        this.productTypeID = product.productTypeID;
        this.regionId = product.regionId;
        this.status = product.status;
		this.created_By = product.created_By;
		this.created_Date = product.created_Date;
        this.updated_Date = product.updated_Date;
        if (product.hasOwnProperty('brand') && product.brand !== null) { this.brand.setBrand(product.brand); }
        if (product.hasOwnProperty('category') && product.category !== null) { this.category.setCategory(product.category); }
        if (product.hasOwnProperty('productType') && product.productType !== null) { this.productType.setProductType(product.productType); }
        if (product.hasOwnProperty('productGroup') && product.productGroup !== null) { this.productGroup.setProductGroup(product.productGroup); }
        if (product.hasOwnProperty('region') && product.region !== null) { this.region.setRegion(product.region); }
    }

	getJsonBase(): any {
		const jsonBase = {
            'brandID': this.brandID,
            'categoryID': this.categoryID,
            'codEAN': this.codEAN,
            'model': this.model,
            'modelCode': this.modelCode,
            'pdF_PopGuide': this.pdF_PopGuide,
            'productGroupID': this.productGroupID,
            'productTypeID': this.productTypeID,
            'regionId': this.regionId,
            'status': this.status,
		};
		return jsonBase;
	}

	getJsonCreate(): any {
		const json = this.getJsonBase();
		if (this.created_By !== null) { json['created_By'] = this.created_By; }
		return json;
	}

	getJsonUpdate(): any {
		const json = this.getJsonBase();
		if (this.created_Date !== null) { json['created_Date'] = this.created_Date; }
		if (this.created_By !== null) { json['created_By'] = this.created_By; }
		if (this.productID !== null) { json['productID'] = this.productID; }
		return json;
	}
}
