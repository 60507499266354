import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../../../../services/utils.service';
import { ReportPriceEvidenceService } from 'src/services/reportPriceEvidence.service';
import * as format from 'date-fns/format';

@Component({
  selector: 'app-report-market-promotions',
  templateUrl: './index.component.html',
  styleUrls: []
})
export class ReportPriceEvidenceComponent implements OnInit {

  public title = 'PriceEvidence';
  public ifDataExist = false;
  public ifExcelExist = true;
  public regionID = [];
  public cityID = [];
  public countryID = [];
  public data: any = [];
  public user = JSON.parse(localStorage.getItem('user'));

  public tableTitles = [
    { name: 'year', label: 'Year', type: 'number' },
    { name: 'month', label: 'Month', type: 'month' },
    { name: 'yearWeek', label: 'Week', type: 'number' },
    { name: 'countryName', label:'Country', type:'normal'},
    { name: 'siteStoreName', label: 'Store', type: 'normal' },
    { name: 'siteLocationName', label: 'Location', type: 'normal' },
    { name: 'siteLocationSiteId', label: 'Site ID', type: 'normal' },
    { name: 'categoryName', label:'Category', type:'normal'},
    { name: 'productTypeName', label:'Product Type', type:'normal'},
    { name: 'productGroupName', label:'Product Group', type:'normal'},
    { name: 'brandName', label:'Brand', type:'normal'},
    { name: 'productName', label:'Product', type:'normal'},
    { name: 'regularPrice', label: 'Regular Price', type: 'normal' },
    { name: 'cardPrice', label: 'Card Price', type: 'normal' },
    { name: 'offerPrice', label: 'Offer Price', type: 'normal' },
    { name: 'date_Start', label: 'Start Date', type: 'date' },
    { name: 'date_End', label: 'End Date', type: 'date' },
    { name: 'comments', label: 'Comments', type: 'normal' },
    { name: 'userName', label:'User', type:'normal'},
    { name: 'supervisors', label: 'Supervisor', type: 'normal' },
    { name: 'created_Date', label: 'Created Date', type: 'date' },
  ];

  public optionsTable = {
    dataId: 'ReportFlooringID',
    showData: false,
    actionShowData: '',
    updateData: false,
    actionUpdateData: 'update',
    deleteData: false,
    filterDateRange: true,
    actionDeleteData: '',
    filterCountryZone: false,
    filterSiteLocation: false,
    filterSitestore: true,
    filterCity: true,
    filterCategory: true,
    filterCountry: true,
    filterRegion: true,
    filterCedis: false,
    searchBtn: true,
    exportBtn: true,
    itemsPerPage: 5,
  };

  constructor(
    private reportPriceEvidenceService: ReportPriceEvidenceService,
    private utilsService: UtilsService
  ) { }

  ngOnInit() {
    const self = this;
    const request = {};
    
    self.ifDataExist = true;
    //this.getReportFlooring(request);
  }

  /*
    filter(event) {
      const request = {
        'start_Date': event.start_Date,
        'end_Date': event.end_Date
      };
      this.getDisconnectionType(request) 
    }
  */

  filter(event) {
    const self = this;
    let request = {}
    if(event.start_Date && event.end_Date) {
      request = {
        'start_Date': format(event.start_Date, 'YYYY-MM-DD'),
        'end_Date': format(event.end_Date, 'YYYY-MM-DD'),
      };
    } else return false;
    //request['userId'] = self.user['userId'];
    if(event.siteStoreId) {
      request['sitestores'] = [event.siteStoreId]
    } else if (event.cityId) {
      request['cities'] = [event.cityId]
    } else if (event.countryId) {
      request['countries'] = [event.countryId]
    } else if (event.regionId) {
      request['regions'] = [event.regionId]
    }
    if (event.categoryId) {
      request['categoryID'] = event.categoryId
    }
    
    this.getReportPriceEvidence(request);
  }

  private getReportPriceEvidence(request) {
    const self = this;
    self.reportPriceEvidenceService.getReportPriceEvidence(request).then(
      function (result) {
        self.data = self.reportPriceEvidenceService.getVarResults();
        self.ifDataExist = true;
        self.utilsService.debugConsole(result);
      }
    );
  }
}