import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from '../../../../../services/utils.service';
import { MethodsUtilsService } from '../../../../../services/methodsUtils.service';
import { FormularioBaseComponent } from '../../../../utils/formularioBase.component';
import { Country } from '../../../../../models/country';
import { CountriesService } from '../../../../../services/countries.service';
// Declare Vars
declare var $: any;

@Component ({
    selector: 'app-form-country',
    templateUrl: './form.component.html',
    styleUrls: []
})

export class FormCountryComponent extends FormularioBaseComponent implements OnInit {

    public page = 'Countries';
    public title = 'Countries.Create';
    public country = new Country();
    public titleButton = 'Save';

    constructor(
        private route: ActivatedRoute,
        private countriesService: CountriesService,
        private router: Router,
        public utilsService: UtilsService,
        public methodsUtilsService: MethodsUtilsService
    ) {
        super(utilsService, methodsUtilsService);
    }

    ngOnInit() {
        const self = this;
        self.getRegionsList();
        self.param = self.route.params.subscribe(
            params => {
                if (params['id']) {
                    self.title = 'Regions.Update';
                    self.titleButton = 'Update';
                    self.utilsService.debugConsole(params['id']);
                    self.getCountry(params['id']);
                }
            }
        );
    }

    private getCountry (id: any): void {
        const self = this;
        self.countriesService.getResult(id).then(
            function (response) {
                if (response === false) { return; }
                self.country.setCountry(self.countriesService.getVarResults());
            }
        );
    }

    public saveUpdateRegion(): void {
        const self = this;
        self.country.created_By = self.utilsService.getUserId();
        self.country.name = self.country.country;
        self.country.country = self.country.country.toUpperCase();
        if (self.country.countryID !== null) {
            self.countriesService.putData(self.country.countryID, self.country.getJsonUpdate()).then(
                function (response) {
                    if (response === false) { return; }
                    self.router.navigate(['/countries']);
                }
            );
        } else {
            self.countriesService.postData(self.country.getJsonCreate()).then(
                function (response) {
                    if (response === false) { return; }
                    self.router.navigate(['/countries']);
                }
            );
        }
    }

}
