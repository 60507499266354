import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UtilsService } from '../../../../services/utils.service';
import { CustomHttpService } from '../../../../services/customHttp.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslatePipe } from '../../../../pipes/translate';
import { DatePipe, registerLocaleData } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/services/user.service';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale } from 'ngx-bootstrap/locale';

import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import es from '@angular/common/locales/es';
import * as format from 'date-fns/format';
import * as $ from 'jquery';
import { UsersInformationService } from '../../../../services/usersInformation.service';

defineLocale('es', esLocale);
registerLocaleData(es);

@Component({
  selector: 'app-index-user-information-show',
  templateUrl: './show.component.html',
  styleUrls: ['./show.component.scss']
})

export class UsersInformationShowComponent implements OnInit {
  private param: any;
  private user: any;
  public userData: any;
  public newPassword: string;
  public newPassword2: string;
  public points: any = [];
  public pointsTrack: any = [];
  public ifDataExist = false;
  private userID: any;
  public arrColors = ['#8AC540', '#00A69C', '#F15F29', '#8AC540', '#00A69C', '#F15F29', '#8AC540', '#00A69C', '#F15F29', '#8AC540', '#00A69C', '#F15F29'];
  public dataBarChart = [];
  public dataUserSchedule = {};
  public currentUserRol = sessionStorage.getItem('userRolId');
  public showPasswordVar = false;
  public showPasswordRepeatVar = false;

  @ViewChild('modalChangePassword') modalChangePassword: TemplateRef<any>;
  @ViewChild('modalChangeSupervisors') modalChangeSupervisors: TemplateRef<any>;
  @ViewChild('modalChangePromoters') modalChangePromoters: TemplateRef<any>;
  private translatePipe = new TranslatePipe();

  public daterangepickerModel = [];

  public filter = {
    filterSitestore: false,
    filterCity: false,
    filterCountry: false,
    filterRegion: false,
  };

  public optionsBarChar = {
    xkey: 'y',
    ykeys: 'a',
    labels: [this.translatePipe.transform('Generals.Quantity')],
    resize: true,
    stacked: true,
    gridTextWeight: '10px'
  };
  public promoters = [];
  public titleDate = 'Day';
  private datePipe = new DatePipe('es');

  public optionsTableCheckInOut = {
    showData: false,
    updateData: false,
    deleteData: false,
    filterCountryZone: false,
    filterSiteLocation: false,
    filterSitestore: false,
    filterCity: false,
    filterCountry: false,
    filterRegion: false,
    filterCedis: false,
    filterDateRange: false,
    searchBtn: false,
    exportBtn: true,
    modalView: 'showTracking',
    modalId: 'check_InOutID',
    dataId: 'check_InOutID',
    modalAllow: true,
  };

  public optionsTableSupervisors = {
    showData: false,
    updateData: false,
    deleteData: false,
    filterCountryZone: false,
    filterSiteLocation: false,
    filterSitestore: false,
    filterCity: false,
    filterCountry: false,
    filterRegion: false,
    filterCedis: false,
    filterDateRange: false,
    searchBtn: false,
    exportBtn: false,
  };

  public tableTitlesSupervisor = [
    { name: 'userSupervisorName', label: 'Nombre', type: 'normal' },
    { name: 'email', label: 'Email', type: 'normal' },
    { name: 'phone1', label: 'Nombre', type: 'normal' },
  ];

  public tableTitlesCheckInOut = [
    { name: 'siteStoreName', label: 'Store', type: 'normal' },
    { name: 'siteLocationName', label: 'Location', type: 'normal' },
    { name: 'checkIn_DateTime', label: 'checkIn Date', type: 'date' },
    { name: 'checkIn_DateTime', label: 'checkIn Time', type: 'time' },
    { name: 'checkIn_StoreDistance', label: 'Distance', type: 'distance' },
    { name: 'chekInWifi', label: 'Wifi In Allow', type: 'boolean' },
    { name: 'wifiAccuracy', label: 'Wifi Accuracy', type: 'percent' },
    { name: 'checkInBiometricsAllow', label: 'Biometrics In Allow', type: 'boolean' },
    { name: 'checkInBiometricsSuccess', label: 'Biometrics In', type: 'boolean' },
    { name: 'checkIn_Photo', label: 'Photo In', type: 'image' },
    { name: 'checkOut_DateTime', label: 'checkOut Date', type: 'date' },
    { name: 'checkOut_DateTime', label: 'checkOut Time', type: 'time' },
    { name: 'checkOut_StoreDistance', label: 'Distance', type: 'distance' },
    { name: 'chekOutWifi', label: 'Wifi Out Allow', type: 'boolean' },
    { name: 'checkOutBiometricsAllow', label: 'Biometrics Out Allow', type: 'boolean' },
    { name: 'checkOutBiometricsSuccess', label: 'Biometrics Out', type: 'boolean' },
    { name: 'checkOut_Photo', label: 'Photo Out', type: 'image' },
    { name: 'workingTimeSecs', label: 'Working Time Secs', type: 'normal' },
  ];

  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    public customHttpService: CustomHttpService,
    public userService: UserService,
    public utilsService: UtilsService,
    private modal: NgbModal
  ) {

  }

  loadUserData(){
    const self = this;
    const request = {
      'start_Date': format(self.daterangepickerModel[0], 'YYYY-MM-DD'),
      'end_Date':   format(self.daterangepickerModel[1], 'YYYY-MM-DD'),
    };

    this.customHttpService.httpPOST(true, true, 'v1/api/Users/GetUsersAllData/' + self.userID + '/', request)
      .then(data => {
        this.user = data.user;
        this.userData = data.userData;
        this.ifDataExist = true;
        this.points = [];
        //this.optionsBarChar['barColors'];

        this.dataBarChart = [];
        this.dataBarChart = [
          ...this.dataBarChart,
          { y: 'Discount Status ',  a: data.userData.stats.marketDiscountStatus, b : 'Qty' },
        ];
        this.dataBarChart = [
          ...this.dataBarChart,
          { y: 'Flooring ', a: data.userData.stats.marketFlooring }
        ];
        this.dataBarChart = [
          ...this.dataBarChart,
          { y: 'Inventory AV ', a: data.userData.stats.marketInventoryAV }
        ];
        this.dataBarChart = [
          ...this.dataBarChart,
          { y: 'Inventory DA', a: data.userData.stats.marketInventoryDA }
        ];
        this.dataBarChart = [
          ...this.dataBarChart,
          { y: 'Inventory IM ', a: data.userData.stats.marketInventoryIM }
        ];
        this.dataBarChart = [
          ...this.dataBarChart,
          { y: 'Promotion ', a: data.userData.stats.marketPromotion }
        ];
        this.dataBarChart = [
          ...this.dataBarChart,
          { y: 'Report ', a: data.userData.stats.marketReport }
        ];
        this.dataBarChart = [
          ...this.dataBarChart,
          { y: 'Sell Out AV ', a: data.userData.stats.marketSellOutAV }
        ];
        this.dataBarChart = [
          ...this.dataBarChart,
          { y: 'Sell Out DA ', a: data.userData.stats.marketSellOutDA }
        ];
        this.dataBarChart = [
          ...this.dataBarChart,
          { y: 'Sell Out IM ', a: data.userData.stats.marketSellOutIM }
        ];
        this.dataBarChart = [
          ...this.dataBarChart,
          { y: 'Show Product', a: data.userData.stats.marketShowProduct }
        ];

        this.dataUserSchedule = {
          userId: this.user.userId,
          img: this.user.photo,
          name: this.user.fullName,
          cargo: this.user.userRolName,
          schedule: this.userData.schedule,
          rol: this.userData.userRolID,
        }

        this.userData.routeStores.forEach(element => {
          this.points = [
            ...this.points,
            {
              lng: element.siteLocation.longitude,
              lat: element.siteLocation.latitude,
              name: element.siteLocation.name,
              location: element.siteLocation.location,
              siteLocationID: element.siteLocation.siteLocationID
            }
          ];
        });
        /*
        this.userSupervisors =

        this.userPromoters =
        */
      });
  }

  changePasswordModal(e){
    const self = this;
    self.modal.open(self.modalChangePassword);
    return false;
  }

  changeSupervisors(e){
    const self = this;
    self.modal.open(self.modalChangeSupervisors);
    return false;
  }

  changePromoters(e){
    const self = this;
    self.modal.open(self.modalChangePromoters);
    return false;
  }


  saveChangePassword(){
    const self = this;
    if(self.newPassword == self.newPassword2){
      let request  = {
        "newPassword": self.newPassword
      }
      self.userService.putChangeUserPassword(self.userID, request).then(
        function (response) {
          if (response === false) { return; }
          self.newPassword = "";
          self.newPassword2 = "";
          self.modal.dismissAll(self.modalChangePassword);
        }
      );
    }else{
      alert(this.translatePipe.transform('Pages.UsersInformation.PasswordNotMatch'));
    }
  }


  ngOnInit(): void {
    // Making the HTTP Request
    const self = this;
    self.daterangepickerModel[0] =  new Date();
    self.daterangepickerModel[1] =  new Date();
    self.param = self.route.params.subscribe(
      params => {
        if (params['id'] !== null) {
          const datePipe = new DatePipe('es');
          self.userID = params['id'];
          self.loadUserData();
        }
      });
  }

  public changeDate = () => {
    const self = this;
    self.loadUserData();
  }

  public showPassword = () => {
    this.showPasswordVar = !this.showPasswordVar;
  }

  public showPasswordRepeat = () => {
    this.showPasswordRepeatVar = !this.showPasswordRepeatVar;
  }
}
