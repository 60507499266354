import { Component, OnInit } from '@angular/core';
import { CmsGeneralesService } from '../../services/cmsGenerales.service';
import { UtilsService } from '../../services/utils.service';
import { TranslatePipe } from '../../pipes/translate';
import * as format from 'date-fns/format';
import * as addDays from 'date-fns/add_days';
import * as subDays from 'date-fns/sub_days';
import { UserService } from 'src/services/user.service';
import * as CanvasJS from 'src/assets/js/canvasjs.min.js';
declare var $;
@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: []
})

export class HomeComponent implements OnInit {

	public titleDate = 'Dia';
	public dateSelect = new Date();

	public dataAttendance: any = [];
	public dataEmployeeInfo: any= [];
	public dataUserStatus: any= [];
	public dataUserStatusInfo: any= [];
	public dataDashboard: boolean;
	public weekday = {
		0: "Domingo",
		1: "Lunes",
		2: "Martes",
		3: "Miércoles",
		4: "Jueves",
		5: "Viernes",
		6: "Sábado",
	}


	constructor(
		private userService: UserService
	) {}

	ngOnInit() {
		const self = this;
		self.loadHomeData();
	}

	public before(): void {
		this.dateSelect = subDays(this.dateSelect, 1);
		this.loadHomeData();
	}

	public next(): void {
		this.dateSelect = addDays(this.dateSelect, 1);
		this.loadHomeData();
	}

	public loadHomeData(): void {
		const self = this;
		let request = {
			"start_Date": format(self.dateSelect, 'YYYY-MM-DD'),
			"end_Date": format(self.dateSelect, 'YYYY-MM-DD'),
		};
		self.titleDate = self.weekday[self.dateSelect.getDay()] + ' '+ format(self.dateSelect, 'DD/MM/YY');
		self.userService.getAttendance(request).then(
			function (response) {
				if (response) {
					self.dataAttendance = self.userService.getVarResults();
				}
			}
		);
		self.userService.getEmployeeInfo(request).then(
			function (response) {
				if (response) {
					self.dataEmployeeInfo = self.userService.getVarResults();
					var chart = new CanvasJS.Chart("employeeInfo", {
					animationEnabled: true,
						data: [{
							type: "doughnut",
							startAngle: -90,
							//innerRadius: 50,
							toolTipContent: "<b>{name}:</b> {y}<span style='margin:0,padding:0;margin-left:40%;display:block;font-weight: bold;'>{percent}%</span>",
							dataPoints: [
								{ name: "Schedule to work", y: self.dataEmployeeInfo.usersHaveSchedule, color: "#86DBFF", percent: self.dataEmployeeInfo.usersHaveSchedulePercent },
								{ name: "No Schedule", y: self.dataEmployeeInfo.usersDontHaveSchedule, color: "#1DB954", percent: self.dataEmployeeInfo.usersDontHaveSchedulePercent },
								{ name: "Day off", y: self.dataEmployeeInfo.usersDayOff, color: "#00063F",percent: self.dataEmployeeInfo.usersDayOffPercent }
							]
						}]
					});
				chart.render();
				}
			}
		);

		/*
		self.userService.getUserStatusInfo(request).then(
			function (response) {
				if (response) {
					self.dataUserStatus = self.userService.getVarResults();
					let tempStatusBar = [];
					let tempStatusLine= [];
					if(typeof self.dataUserStatus != "undefined"){
						for(let userStatus of self.dataUserStatus.hours){
							tempStatusBar.push({ x: new Date(userStatus["dateTimeVar"]), y: userStatus["totalUsersHour"] })
							tempStatusLine.push({ x: new Date(userStatus["dateTimeVar"]), y: userStatus["wifiAverageInPercent"] })
						}
					}
					let chart = new CanvasJS.Chart("chartContainer", {
						animationEnabled: true,
						theme: "light2",
						title: {
							text: ""
						},
						axisX: {
							valueFormatString: "hhTT",
							interval: 1,
							intervalType: "hour",
							titleFontColor: "#C4C4C4",
							titlefontSize: 1,
							labelFontColor: "#C4C4C4",
							labelfontSize: 1,
							labelAngle: 0
						},
						axisY: {
							suffix: " %",
							valueFormatString:"#0.##",
							titleFontColor: "#C4C4C4",
							titlefontSize: 10,
							labelFontColor: "#C4C4C4",
							labelfontSize: 10
						},
						axisY2: {
							suffix: "",
							valueFormatString:"##",
							titleFontColor: "#C4C4C4",
							titlefontSize: 10,
							labelFontColor: "#C4C4C4",
							labelfontSize: 10
						},
						toolTip: {
							shared: true
						},
						legend: {
							cursor: "pointer",
							verticalAlign: "top",
							horizontalAlign: "right",
							display: false,
						},
						data: [
						{
							type: "column",
							name: "Number of employees (stayed)",
							color: "#1DB954",  
							fillOpacity: .3,
							showInLegend: false,
							xValueFormatString: "hh:mm TT",
							yValueFormatString: "#",
							dataPoints: tempStatusBar,
							axisYType: "secondary",
						}, 
						{
							type: "line",
							color: "#006ED3",
							name: "Work place stay rate",
							showInLegend: false,
							xValueFormatString: "hh:mm TT",
							yValueFormatString: "#0.# '%'",
							dataPoints: tempStatusLine
						}]
					});
					chart.render();
				}
			}
		);
		*/
	}
	

	animPercent() {
		/* Wait until the canvas is ready and print values */
		const self = this;
		if($('#employeeInfo')[0]) {
			self.loadHomeData();
		} else {
			setTimeout(()=>{
				self.animPercent()
			},1000);
		}
	}
}