import { Injectable } from '@angular/core';
import { CustomHttpService } from './customHttp.service';

@Injectable()
export class CmsGeneralesService {
	private result: any;
	private controller = 'CmsGenerales';

	constructor(
		public customHttpService: CustomHttpService
	) { }

	/** Función que trae todos los Promotores por Supervisor */
	getPromotersForSupervisor(id) {
		const self = this;
		return new Promise(
			resolve => {
				// Get Info from 'v1/api/CmsGenerales/GetPromotersForSupervisor/{UserSupervisorID}'
				self.customHttpService.httpGET (true, true, 'v1/api/' + this.controller + '/GetPromotersForSupervisor/' + id).then(
					function (response) {
						if (response === false) { resolve(response); }
						self.result = response;
						resolve(true);
					}
				);
			}
		);
	}
	
	

	/** Función que trae todos los Promotores por Supervisor en una fecha */
	getPromotersForSupervisorAnyDate(request) {
		const self = this;
		return new Promise(
			resolve => {
				// Get Info from 'v1/api/CmsGenerales/GetPromotersForSupervisorAnyDate'
				self.customHttpService.httpPOST (true, true, 'v1/api/' + this.controller + '/GetPromotersForSupervisorAnyDate', request).then(
					function (response) {
						if (response === false) { resolve(response); }
						self.result = response;
						resolve(true);
					}
				);
			}
		);
	}

	// Getters and Setter Variables
	getVarResults () { return this.result; }

}
