import { Country } from './country';
import { Channel } from './channel';
import { City } from './city';
import { CountryZone } from './countryZone';

export class SiteStores {
    public siteStoreID: number; // 123
    public store: string; // El nombre max 25
    public active: boolean; // true or false
    public countryId: number;
    public country: Country;
    public channelId: number;
    public chanel: Channel;
    public cityId: number;
    public city: City;
    public countryZoneID: number;
    public countryZone: CountryZone;
    public created_By: string;
    private created_Date: string;
    private updated_Date: string;

    constructor() {
        this.initalizeStore();
    }

    initalizeStore() {
        this.siteStoreID = null;
        this.store = null;
        this.active = null;
        this.siteStoreID = null;
        this.countryId = null;
        this.channelId = null;
        this.cityId = null;
        this.countryZoneID = null;
        this.countryZone = null;
        this.created_By = null;
        this.created_Date = null;
        this.updated_Date = null;
        this.city = new City();
        this.country = new Country();
        this.chanel = new Channel();
        this.countryZone = new CountryZone();
    }

    setSiteStore(siteStore) {
        this.siteStoreID = siteStore.siteStoreID;
        this.store = siteStore.store;
        this.active  = siteStore.active;
        this.countryId  = siteStore.countryID;
        this.channelId = siteStore.chanelID;

        this.cityId = siteStore.cityID;
        this.countryZoneID  = siteStore.countryZoneID;
        this.created_By = siteStore.created_By;
        this.created_Date = siteStore.created_Date;
        this.updated_Date = siteStore.updated_Date;
        if (siteStore.hasOwnProperty('chanel') && siteStore.chanel !== null) { this.chanel.setChannel(siteStore.chanel); }
        if (siteStore.hasOwnProperty('country') && siteStore.country !== null) { this.country.setCountry(siteStore.country); }
        if (siteStore.hasOwnProperty('city') && siteStore.city !== null) { this.city.setCity(siteStore.city); }
        // tslint:disable-next-line:max-line-length
        if (siteStore.hasOwnProperty('countryZone') && siteStore.countryZone !== null) { this.countryZone.setCountryZone(siteStore.countryZone); }
    }

    getJsonBase(): any {
        const jsonBase = {
            'store': this.store,
            'name': this.store,
            'active': this.active,
            'countryId': this.countryId,
            'countryZoneID': this.countryZoneID,
        };
        return jsonBase;
    }

    getJsonCreate(): any {
        const json = this.getJsonBase();
        if (this.created_By !== null) { json['created_By'] = this.created_By; }
        if (this.channelId !== null) { json['chanelID'] = this.channelId; }
        if (this.cityId !== null) { json['cityID'] = this.cityId; }
        return json;
    }

    getJsonUpdate(): any {
        const json = this.getJsonBase();
        if (this.created_Date !== null) { json['created_Date'] = this.created_Date; }
        if (this.created_By !== null) { json['created_By'] = this.created_By; }
        if (this.siteStoreID !== null) { json['siteStoreID'] = this.siteStoreID; }
        if (this.channelId !== null) { json['chanelID'] = this.channelId; }
        if (this.cityId !== null) { json['cityID'] = this.cityId; }
        return json;
    }
}
