import { Component, OnInit } from "@angular/core";
import { UtilsService } from "../../../../services/utils.service";
import { ReportGiveAwayService } from "../../../../services/reportGiveAway.service";
import * as $ from "jquery";

@Component({
  selector: "app-report-giveway",
  templateUrl: "./index.component.html",
  styleUrls: [],
})
export class ReportGiveAwayComponent implements OnInit {
  public title = "ReportsGiveAway";
  public ifDataExist = false;
  public ifExcelExist = true;
  public regionID = [];
  public cityID = [];
  public countryID = [];
  public data: any = [];
  public urlExcel: any;

  public tableTitles = [
    { name: "year", label: "Year", type: "number" },
    { name: "month", label: "Month", type: "normal" },
    { name: "yearWeek", label: "Week", type: "number" },
    { name: "regionName", label: "Region", type: "normal" },
    { name: "countryName", label: "Country", type: "normal" },
    { name: "siteStoreName", label: "Store", type: "normal" },
    { name: "siteLocationName", label: "Location", type: "normal" },
    { name: "siteLocationSiteId", label: "Site ID", type: "normal" },
    { name: "categoryName", label: "Category", type: "normal" },
    { name: "productGroupName", label: "Product Group", type: "normal" },
    { name: "productTypeName", label: "Product Type ", type: "normal" },
    { name: "giveAwayCampaignName", label: "Campaign Name", type: "normal" },
    { name: "giveAwayTypeName", label: "Type", type: "normal" },
    { name: "quantity", label: "Quantity", type: "normal" },
    { name: "description", label: "Description", type: "normal" },
    { name: "photo1", label: "Photo  1", type: "image" },
    { name: "photo2", label: "Photo  2", type: "image" },
    { name: "photo3", label: "Photo  3", type: "image" },
    { name: "photo4", label: "Photo  4", type: "image" },
    { name: "photo5", label: "Photo  5", type: "image" },
    { name: "photo6", label: "Photo  6", type: "image" },
    { name: "userName", label: "User Name", type: "normal" },
    { name: "supervisors", label: "Supervisor", type: "normal" },
    { name: "created_Date", label: "Created Date", type: "date" },
  ];

  public optionsTable = {
    dataId: "giveAwayEvidenceID",
    showData: false,
    actionShowData: "",
    updateData: false,
    actionUpdateData: "update",
    deleteData: false,
    actionDeleteData: "",
    filterCountryZone: false,
    filterSiteLocation: false,
    filterSitestore: true,
    filterDateRange: true,
    filterCity: false,
    filterCategory: true,
    filterCountry: true,
    filterRegion: true,
    filterCedis: false,
    searchBtn: true,
    exportBtn: true,
    itemsPerPage: 5,
  };

  constructor(
    private reportGiveAwayService: ReportGiveAwayService,
    private utilsService: UtilsService
  ) {
    if (!sessionStorage.hasOwnProperty("token")) {
      const url = window.location.href;
      const searchParams = url.split("?")[1];
      const params = new URLSearchParams(searchParams);
      const token = params.get("t");
      const userCoded = params.get("u");
      const decodedString = atob(userCoded);
      const user = JSON.parse(decodedString);
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("language", "es");
      sessionStorage.setItem("token", token);
    } else {
      console.log("tokenExist.");
    }
  }

  ngOnInit() {
    const self = this;
    self.ifDataExist = true;
    const request = {};
  }

  downloadExcelReport($event) {
    const self = this;
    let request = {};
    request["listIds"] = $event.listIds;
    request["email"] = $event.email;
    this.getExcelGiveAway(request);
  }

  filter(event) {
    const self = this;
    let request = {};
    if (event.start_Date && event.end_Date) {
      request = {
        start_Date: event.start_Date,
        end_Date: event.end_Date,
      };
    } else return false;
    if (event.siteStoreId) {
      request["sitestores"] = [event.siteStoreId];
    } else if (event.cityId) {
      request["cities"] = [event.cityId];
    } else if (event.countryId) {
      request["countries"] = [event.countryId];
    } else if (event.regionId) {
      request["regions"] = [event.regionId];
    }
    if (event.categoryId) {
      request["categoryID"] = event.categoryId;
    }
    self.getReportGiveAway(request);
  }

  private getReportGiveAway(request) {
    const self = this;
    self.reportGiveAwayService
      .getReportGiveAway(request)
      .then(function (result) {
        self.data = self.reportGiveAwayService.getVarResults();
        self.urlExcel = true;
        self.ifDataExist = true;
        self.utilsService.debugConsole(result);
      });
  }

  private getExcelGiveAway(request) {
    const self = this;
    self.reportGiveAwayService
      .getExcelGiveAway(request)
      .then(function (result) {
        let urlExcel = self.reportGiveAwayService.getVarResultsExcel();
        if (urlExcel.indexOf("http") == 0) {
          window.location.assign(urlExcel);
        } else {
          self.urlExcel = urlExcel;
        }
        self.utilsService.debugConsole(result);
      });
  }
}
