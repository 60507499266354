import { Country } from './country';
import { Region } from './region';
import { PopSosType } from './popSosType';

export class PopSosSubTypes {

    /*datos para crear reporte */
    public popSosSubTypeID: number;
    public sosSubType: string;
    public popSosTypeID: number;
    public countryID: number;
    public regionId: number;
    public created_By: string;
    public created_Date: string;
    public updated_Date: string;
    public country: Country;
    public region: Region;
    public popSosType: PopSosType;

    constructor() {
        this.initalizePopSosSubTypes();
    }

    initalizePopSosSubTypes() {
        this.popSosSubTypeID = null;
        this.popSosTypeID = null;
        this.sosSubType = null;
        this.countryID=null;
        this.regionId = null;
        this.created_By = null;
        this.updated_Date = null;
        this.created_Date = null;
        this.country = new Country();
        this.region = new Region();
        this.popSosType = new PopSosType();
    }

    setPopSosSubTypes(popSosSubTypes) {
        this.popSosSubTypeID = popSosSubTypes.popSosSubTypeID;
        this.countryID = popSosSubTypes.countryID
        this.sosSubType = popSosSubTypes.sosSubType;
        this.popSosTypeID = popSosSubTypes.popSosTypeID;
        this.regionId = popSosSubTypes.regionId;
        this.created_By = popSosSubTypes.Created_By;
        this.updated_Date = popSosSubTypes.Updated_Date;
        this.created_Date = popSosSubTypes.Created_Date;
        if (popSosSubTypes.hasOwnProperty('country') && popSosSubTypes.country !== null) { this.country.setCountry(popSosSubTypes.country); }
        if (popSosSubTypes.hasOwnProperty('region') && popSosSubTypes.region !== null) { this.region.setRegion(popSosSubTypes.region); }
        if (popSosSubTypes.hasOwnProperty('popSosType') && popSosSubTypes.popSosType !== null) { this.popSosType.setPopSosType(popSosSubTypes.popSosType); }
    }

    getJsonBase(): any {
        const jsonBase = {
            'sosSubType': this.sosSubType,
            'countryID': this.countryID,
            'popSosTypeID': this.popSosTypeID,
            'regionId': this.regionId,
        };
        return jsonBase;
    }

    getJsonCreate(): any {
        const json = this.getJsonBase();
        if (this.created_By !== null) { json['created_By'] = this.created_By; }
        return json;
    }

    getJsonUpdate(): any {
        const json = this.getJsonBase();
        if (this.created_Date !== null) { json['created_Date'] = this.created_Date; }
        if (this.created_By !== null) { json['created_By'] = this.created_By; }
        if (this.popSosSubTypeID !== null) { json['popSosSubTypeID'] = this.popSosSubTypeID; }
        return json;
    }
}