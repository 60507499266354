import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from '../../../../../services/utils.service';
import { MethodsUtilsService } from '../../../../../services/methodsUtils.service';
import { FormularioBaseComponent } from '../../../../utils/formularioBase.component';

import { User } from '../../../../../models/users';
import { Category } from '../../../../../models/category';
import { Country } from '../../../../../models/country';
import { Region } from '../../../../../models/region';
import { CountryZone } from 'src/models/countryZone';
import { City } from 'src/models/city';

import { RegionsService } from '../../../../../services/regions.service';
import { UsersInformationService } from '../../../../../services/usersInformation.service';
import { CategoriesService } from '../../../../../services/categories.service';
import { UserService } from '../../../../../services/user.service';
import { DocumentType } from 'src/models/documentType';

// Declare Vars
declare var $: any;

@Component ({
  selector: 'app-form-user',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})

export class FormUserInformationComponent extends FormularioBaseComponent implements OnInit {

  public page = 'Users';
  public title = 'Users.Create';
  public category = new Category();
  public user = new User();
  public region = new Region();
  public country = new Country();
  public countryZone = new CountryZone();
  public documentType = new DocumentType();
  public city = new City();
  public titleButton = 'Save';
  public regionID = null;
  public showPasswordVar = false;

  constructor(
    private route: ActivatedRoute,
    private categoriesService: CategoriesService,
    private usersService: UserService,
    private router: Router,
    public utilsService: UtilsService,
    public methodsUtilsService: MethodsUtilsService,
  ) {
    super(utilsService, methodsUtilsService);
  }

  ngOnInit() {
    const self = this;
    self.getRegionsList();
    self.getListCategories();
    self.getListRols();
    self.getListDocumentTypes();
    self.getListDisconnectionTypes();
    self.param = self.route.params.subscribe(
      params => {
        if (params['id']) {
          self.title = 'Users.Update';
          self.titleButton = 'Update';
          self.utilsService.debugConsole(params['id']);
          self.getUser(params['id']);
        }
      }
    );
  }

  private getUser (id: any): void {
    const self = this;
    self.usersService.getResult(id).then(
      function (response) {
        if (response === false) { return; }
        self.user.setUser(self.usersService.getVarResults());
        self.getListCountriesByRegion(self.user.regionID);
        self.getListCountryZonesFilters({ 'countries': [ self.user.countryID ]});
        self.getListCitiesFilters({ 'countries': [ self.user.countryID ], 'countriesZones': [] });
      }
    );
  }

  public selectedRegion(): void {
    const self = this;
    if (self.user.regionID != null) {
      self.getListCountriesByRegion(self.user.regionID);
    } else {
      self.user.countryID = null;
      self.user.countryZoneID = null;
    }
  }

  public selectedCountry(): void {
    const self = this;
    if (self.user.countryID != null) {
      self.getListCountryZonesFilters({ 'countries': [ self.user.countryID ]});
      self.getListCitiesFilters({ 'countries': [ self.user.countryID ], 'countriesZones': [] });
    } else {
      self.user.countryZoneID = null;
    }
  }


  public saveUpdate(): void {
    const self = this;
    self.user.created_By = self.utilsService.getUserId();
    if (self.user.userId !== null) {
      self.usersService.putUserCMS(self.user.userId, self.user.getJsonUpdate()).then(
        function (response) {
          if (response === false) { return; }
          self.router.navigate(['/user-information/show/'+self.user.userId]);
        }
      );
    } else {
      self.usersService.postUserCMS(self.user.getJsonCreate()).then(
        function (response) {
          if (response === false) { return; }
          self.router.navigate(['/user-information']);
        }
      );
    }
  }

  public showPassword = () => {
    this.showPasswordVar = !this.showPasswordVar;
  }

}
