import { Component, OnInit } from '@angular/core';
import { ReportPromoterNowService } from 'src/services/reportPromoterNow.service';
import { UtilsService } from '../../../../services/utils.service';
import * as endOfWeek from 'date-fns/end_of_week';
import * as startOfWeek from 'date-fns/start_of_week';
import * as format from 'date-fns/format';
import * as addWeek from 'date-fns/add_weeks';
import * as addDays from 'date-fns/add_days';

@Component({
    selector: 'app-index-promoters-track',
    templateUrl: './index.component.html',
    styleUrls: []
})

export class ReportsPromoterTrackComponent implements OnInit {
    public title = 'ReportsPromotersTrack';
    public ifDataExist = false;
    public user = JSON.parse(localStorage.getItem('user'));
    public tableTitles = [
        { name: 'userId', label: 'UserId', type: 'normal' },
        { name: 'fullName', label: 'Name', type: 'normal' },
        { name: 'trackList.site_id', label: 'Cod_MCS', type: 'normal' },
        { name: 'trackList.trackDate', label: 'Date', type: 'date' },
        { name: 'trackList.trackDate', label: 'Programmed WiFi Check', type: 'time' },
        { name: 'trackList.enTienda', label: 'Match Successful', type: 'boolean' },
        { name: 'trackList.wifiAccuracy', label: 'Match Percentage', type: 'percent' },
        { name: 'trackList.storeDistance', label: 'Distance', type: 'distance' },
        { name: 'trackList.enTienda', label: 'State', type: 'inStore' },
    ];
    public data: any = [];
    public optionsTable = {
        dataId: 'promotersNowID',
        showData: false,
        actionShowData: '',
        updateData: false,
        actionUpdateData: 'update',
        deleteData: false,
        actionDeleteData: '',
        filterCountryZone: false,
        filterSiteLocation: false,
        filterSitestore: false,
        filterCity: true,
        filterCountry: true,
        filterRegion: 'required',
        filterCedis: false,
        filterDateRange: true,
        searchBtn: true,
        exportBtn: true,
    };

    constructor(
        private reportPromoterNowService : ReportPromoterNowService,
        private utilsService: UtilsService
    ) {}



    ngOnInit() {
        const self = this;
        const firstDay = new Date();
        const lastDay = new Date();
        let request = {
            "start_Date": format(firstDay, 'YYYY-MM-DD'),
            "end_Date": format(lastDay, 'YYYY-MM-DD'),
        };
        self.ifDataExist = true;
        //this.getTrackingForSupervisorTeam(request);
    }

    filter(event) {
        const self = this;
        let request = {}
        if(event.start_Date && event.end_Date) {
        request = {
            'start_Date': format(event.start_Date, 'YYYY-MM-DD'),
            'end_Date': format(event.end_Date, 'YYYY-MM-DD')
        };
        } else return false;

        if (event.cityId) {
            request['cities'] = event.cityId
        } else if (event.countryId) {
            request['countries'] = event.countryId
        } else if (event.regionId) {
            request['regions'] = event.regionId
        }

        this.getTrackingForSupervisorTeam(request);
    }

    async getTrackingForSupervisorTeam(request) {
        const self = this;
        self.reportPromoterNowService.getTrackingForSupervisorTeam(request).then(
            function (result) {
                self.data = self.reportPromoterNowService.getVarResults();
                self.ifDataExist = true;
                self.utilsService.debugConsole(result);
            }
        );
    }
}