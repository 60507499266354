import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UtilsService } from './utils.service';
import * as $ from 'jquery';

@Injectable()

export class CustomHttpService {

	constructor(
		private http: HttpClient,
		private utilsService: UtilsService
	) {}


	private getHeaderBaseJson(): HttpHeaders {
		const headerBaseJson = new HttpHeaders()
			.set('Authorization', 'Bearer ' + sessionStorage.getItem('token'))
			.set('Content-Type', 'application/json');
		return headerBaseJson;
	}

	private getHeaderBaseFormData(): HttpHeaders {
		const headerBaseFormData = new HttpHeaders()
			.set('Authorization', 'Bearer ' + sessionStorage.getItem('token'));
		return headerBaseFormData;
	}


	/**
	 * Metodos de conexion con el API (GET, POST, PUT, GET-DATA)
	 */
	// Metodo GET
	


	httpGET (tempLoader, msg, path): Promise<any> {
		
		if (tempLoader) {
			$('.preloader-content').fadeIn();
		}
		
		this.utilsService.debugConsole(this.getHeaderBaseJson());
		return new Promise(
			resolve => {
				// Make the HTTP request:
				this.http.get(this.utilsService.getUrl() + path, {
					headers: this.getHeaderBaseJson(),
				}).subscribe(
					// Successful responses call the first callback.
					data => {
						if (data != null && data.hasOwnProperty('errorCode')) {
							if (msg) { this.utilsService.showError(data['error']); }
							resolve(false);
						} else {
							if (tempLoader) { $('.preloader-content').fadeOut(); }
							resolve(data);
						}
					},
					// Errors will call this callback instead:
					err => {
						if (tempLoader) { $('.preloader-content').fadeOut(); }
						if (msg) { this.utilsService.showError(err); }
						resolve(false);
					}
				);
			}
		);
	}

	// Metodo POST
	httpPOST (tempLoader, msg, path, params = null, formData = null): Promise<any> {
		if (tempLoader) {
			$('.preloader-content').fadeIn();
		}
		return new Promise(
			resolve => {
				let varHeader = this.getHeaderBaseJson();
				let varData = params;
				if (formData !== null) {
					varHeader = this.getHeaderBaseFormData();
					varData = formData;
				}
				// Make the HTTP request:
				this.http.post(
					this.utilsService.getUrl() + path,
					varData,
					{ headers: varHeader }
				).subscribe(
					// Successful responses call the first callback.
					data => {
						if (data != null && data.hasOwnProperty('errorCode')) {
							if (msg) { this.utilsService.showError(data); }
							resolve(false);
						} else {
							if (tempLoader) { $('.preloader-content').fadeOut(); }
							resolve(data);
						}
					},
					// Errors will call this callback instead:
					err => {
						if (tempLoader) { $('.preloader-content').fadeOut(); }
						if (msg) { this.utilsService.showError(err); }
						resolve(false);
					}
				);
			}
		);
	}

	// Metodo PUT
	httpPUT (tempLoader, msg, path, params = null, formData = null): Promise<any> {
		if (tempLoader) {
			$('.preloader-content').fadeIn();
		}
		return new Promise(
			resolve => {
				let varHeader = this.getHeaderBaseJson();
				let varData = params;
				if (formData !== null) {
					varHeader = this.getHeaderBaseFormData();
					varData = formData;
				}
				// Make the HTTP request:
				this.http.put(
					this.utilsService.getUrl() + path,
					varData,
					{ headers: varHeader }
				).subscribe(
					// Successful responses call the first callback.
					data => {
						if (data != null && data.hasOwnProperty('errorCode')) {
							if (msg) { this.utilsService.showError(data['error']); }
							resolve(false);
						} else {
							if (tempLoader) { $('.preloader-content').fadeOut(); }
							resolve(data);
						}
					},
					// Errors will call this callback instead:
					err => {
						if (tempLoader) { $('.preloader-content').fadeOut(); }
						if (msg) { this.utilsService.showError(err); }
						resolve(false);
					}
				);
			}
		);
	}
	/**
	 * Fin Metodos de conexion con el API (GET, POST, PUT, GET-DATA)
	 */

	httpDELETE(tempLoader, msg, path): Promise<any> {

		if (tempLoader) {
			$('.preloader-content').fadeIn();
		}

		this.utilsService.debugConsole(this.getHeaderBaseJson());
		return new Promise(
			resolve => {
				// Make the HTTP request:
				this.http.delete(this.utilsService.getUrl() + path, {
					headers: this.getHeaderBaseJson(),
				}).subscribe(
					// Successful responses call the first callback.
					data => {
						if (data != null && data.hasOwnProperty('errorCode')) {
							if (msg) { this.utilsService.showError(data['error']); }
							resolve(false);
						} else {
							if (tempLoader) { $('.preloader-content').fadeOut(); }
							resolve(data);
						}
					},
					// Errors will call this callback instead:
					err => {
						if (tempLoader) { $('.preloader-content').fadeOut(); }
						if (msg) { this.utilsService.showError(err); }
						resolve(false);
					}
				);
			}
		);
	}
}