import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from '../../../../../services/utils.service';
import { MethodsUtilsService } from '../../../../../services/methodsUtils.service';
import { FormularioBaseComponent } from '../../../../utils/formularioBase.component';
import { SegmentsService } from '../../../../../services/segments.service';
import { Segment } from '../../../../../models/segment';
// Declare Vars
declare var $: any;

@Component ({
    selector: 'app-form-segment',
    templateUrl: './form.component.html',
    styleUrls: []
})

export class FormSegmentComponent extends FormularioBaseComponent implements OnInit {

    public page = 'Segments';
    public title = 'Segments.Create';
    public segment = new Segment();
    public titleButton = 'Save';

    constructor(
        private route: ActivatedRoute,
        public segmentsService: SegmentsService,
        private router: Router,
        public utilsService: UtilsService,
        public methodsUtilsService: MethodsUtilsService,
    ) {
        super(utilsService, methodsUtilsService, segmentsService);
    }

    ngOnInit() {
        const self = this;
        self.param = self.route.params.subscribe(
            params => {
                if (params['id']) {
                    self.title = 'Segments.Update';
                    self.titleButton = 'Update';
                    self.utilsService.debugConsole(params['id']);
                    self.getSegment(params['id']);
                }
            }
        );
    }

    private getSegment (id: any): void {
        const self = this;
        self.segmentsService.getResult(id).then(
            function (response) {
                if (response === false) { return; }
                self.segment.setSegment(self.segmentsService.getVarResults());
            }
        );
    }

    public saveUpdate(): void {
        const self = this;
        self.segment.created_By = self.utilsService.getUserId();
        if (self.segment.segmentID !== null) {
            self.segmentsService.putData(self.segment.segmentID, self.segment.getJsonUpdate()).then(
                function (response) {
                    if (response === false) { return; }
                    self.router.navigate(['/segments']);
                }
            );
        } else {
            self.segmentsService.postData(self.segment.getJsonCreate()).then(
                function (response) {
                    if (response === false) { return; }
                    self.router.navigate(['/segments']);
                }
            );
        }
    }

}
