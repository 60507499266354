import { Component, OnInit, Input, ViewChild, TemplateRef, ViewChildren, ɵConsole  } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UtilsService } from '../../../services/utils.service';
import { MethodsUtilsService } from '../../../services/methodsUtils.service';
import { CustomHttpService } from '../../../services/customHttp.service';
import { NgbModalConfig, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from 'src/services/modal.service';
import { SurveyService } from 'src/services/surveys.service';
import { FormularioBaseComponent } from 'src/app/utils/formularioBase.component';
import { Survey } from 'src/models/survey';
import { FiltersService } from 'src/services/filters.service';
import { Question } from 'src/models/question';


declare var $

@Component({
	selector: 'app-surveys-show',
	templateUrl: './surveys-show.component.html',
	styleUrls: []
})

export class ShowSurveysComponent extends FormularioBaseComponent implements OnInit {
	public survey = new Survey();
	public surveyQuestions: Question[] = [];

	public ifDataExist = false;
	public data: any = [];
	public today = new Date();
	public regionAccess:boolean = false;

	public regionFilterList: any = [];
	public countryFilterList: any = [];
	public rolFilterList: any = [];
	public categoryFilterList: any = [];
	public storeFilterList: any = [];
	public locationFilterList : any = [];
	public questionTypesList: any = [];
	public employeeTypeFilterList: any = [];

	public end_hours: any;
	public end_minutes: any;
	public start_hours: any;
	public start_minutes: any;
	public title: string;
	public questionCount = 0;

	// Inject HttpClient into your component or service.
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		config: NgbModalConfig,
		private modal: NgbModal,
		public utilsService: UtilsService,
		public methodsUtilsService: MethodsUtilsService,
		private customHttpService: CustomHttpService,
		private surveyService: SurveyService,
		private modalService: ModalService,
		private filtersService: FiltersService,
	){
		super(utilsService, methodsUtilsService);
	}

	ngOnInit(): void {
		const self = this;
		self.param = self.route.params.subscribe(
		params => {
			if (params['id']) {
				let surveyID = params['id'];
					self.getSurveyById(surveyID);
					self.getQuestionsTypes();
			}
		}
		);
	}

	getQuestionsTypes(){
		const self = this;
		// listar site stores
		self.surveyService.getQuestionsTypes().then(
			function (response) {
				if (response) {
					self.questionTypesList = self.surveyService.getVarResults();
				}
			}
		);
	}


	getQuestions(){
		const self = this;
		if(self.survey.surveyID != null){
			self.surveyService.getSurveyQuestions(self.survey.surveyID).then(
				function (response) {
					if (response) {
						self.surveyQuestions = self.surveyService.getVarResults();
						self.questionCount = self.surveyQuestions.length;
					}
				}
			);
		}
	}

	getSurveyById(surveyID){
		const self = this;
		self.surveyService.getSurveyByID(surveyID).then(
			function (response) {
				if (response) {
					let survey = self.surveyService.getVarResults();
					self.survey.setSurvey(survey["survey"]); 
					self.getQuestions();
					if(self.survey.surveyRequestStatus == "Sent"){
						$("#myDivSurvey input").attr("disabled", true);
						$("#myDivSurvey select").attr("disabled", true);
						$("#myDivSurvey textarea").attr("disabled", true);
					}
				}
			}
		);
	}
}