import { Component, OnInit } from '@angular/core';
import { ReportPromoterNowService } from 'src/services/reportPromoterNow.service';
import { UtilsService } from '../../../../services/utils.service';


@Component({
    selector: 'app-index-promoters-now',
    templateUrl: './index.component.html',
    styleUrls: []
})

export class ReportsPromoterControlComponent implements OnInit {
    public title = 'ReportsPromotersNow';
    public ifDataExist = false;
    public user = JSON.parse(localStorage.getItem('user'));
    public tableTitles = [
        { name: 'user.userName', label: 'UserId', type: 'normal' },
        { name: 'user.fullName', label: 'Name', type: 'normal' },
        { name: 'siteLocation.site_id', label: 'Cod_MCS', type: 'normal' },
        { name: 'siteLocation.location', label: 'Site Location', type: 'normal' },
        { name: 'checkInOut.checkIn_DateTime', label: 'Date', type: 'date' },
        { name: 'checkInOut.checkIn_DateTime', label: 'Check In', type: 'time' },
        { name: 'checkInOut.checkOut_DateTime', label: 'Check Out', type: 'time' },
        // { name: 'trackUser.trackDate', label: 'Last Wifi Check', type: 'time' },
        { name: 'present', label: 'Present at Store Now?', type: 'normal' },
        { name: 'checkInOut.inPause', label: 'In Pause?', type: 'boolean' },
        { name: 'breackReason', label: 'Pause Reason?', type: 'normal' },
    ];
    public data: any = [];
    public optionsTable = {
        dataId: 'promotersNowID',
        showData: false,
        actionShowData: '',
        updateData: false,
        actionUpdateData: 'update',
        deleteData: false,
        actionDeleteData: '',
        filterCountryZone: false,
        filterSiteLocation: true,
        filterSitestore: true,
        filterCity: true,
        filterCountry: true,
        filterRegion: 'required',
        filterCedis: false,
        filterDateRange: false,
        searchBtn: true,
        exportBtn: true,
        supervisor: true,
        supervisorCountryId: this.user['countryID'],
        supervisorCityId: this.user['cityID'],
    };

    constructor(
        private reportPromoterNowService : ReportPromoterNowService,
        private utilsService: UtilsService
    ) {}

    ngOnInit() {
        const self = this;
        let request = {
            "userId": this.user['userId'],
            "siteLocationID": 0
        };
        self.ifDataExist = true;
        //this.getReportPromoterNow(request);
    }

    filter(event) {
        const self = this;
        let request = {
            "userId": self.user['userId']
        }
        
        if(event.siteLocationId) {
            request['sitelocation'] = event.siteLocationId;
        }else if (event.siteStoreId) {
            request['sitestores'] = event.siteStoreId;
        }else if (event.cityId) {
            request['cities'] = event.cityId;
        }else if (event.countryId) {
            request['countries'] = event.countryId;
        }else if (event.regionId) {
            request['regions'] = event.regionId;
        }

       
    
    
		self.getReportPromoterNow(request);
	}

    getReportPromoterNow(request) {
        const self = this;
        self.reportPromoterNowService.getReportPromotersNow(request).then(
            function (result) {
                self.data = self.reportPromoterNowService.getVarResults();
                self.ifDataExist = true;
                self.utilsService.debugConsole(result);
            }
        );
    }
}