import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../../../../services/utils.service';
import { LocationsService } from '../../../../services/locations.service';

@Component({
    selector: 'app-index-locations',
    templateUrl: './index.component.html',
    styleUrls: []
})

export class LocationsComponent implements OnInit {
    public title = 'Locations';
    public ifDataExist = false;
    public tableTitles = [
        { name: 'country.name', label: 'País', type: 'normal' },
        { name: 'city.cityName', label: 'Ciudad', type: 'normal' },
        { name: 'siteStore.name', label: 'Site Store', type: 'normal' },
        { name: 'name', label: 'Nombre', type: 'normal' },
        { name: 'active', label: 'Estado', type: 'active' },
    ];
    public data: any = [];
    public optionsTable = {
        dataId: 'siteLocationID',
        showData: false,
        actionShowData: '',
        updateData: true,
        actionUpdateData: 'update',
        deleteData: false,
        actionDeleteData: '',
        filterCountryZone: false,
        filterSiteLocation: false,
        filterSitestore: true,
        filterCity: false,
        filterCountry: true,
        filterRegion: true,
        filterCedis: false,
        searchBtn: true,
        exportBtn: true
    };

    constructor(
        private sitelocationsService: LocationsService,
        private utilsService: UtilsService
    ) {}

    ngOnInit() {

    }

    filter(event) {
        let request = {
            'sitestores': (event.siteStoreId) ? [event.siteStoreId] : [],
            'cities': (event.cityId) ? [event.cityId] : [],
            'countries': (event.countryId) ? [event.countryId] : [],
        };

        this.getLocations(request);
	}

    private getLocations (request) {
        const self = this;
        self.sitelocationsService.getSiteLocationFilters(request).then(
            function (response) {
                if (response) {
                    self.data = self.sitelocationsService.getVarResults();
                    self.ifDataExist = true;
                    self.utilsService.debugConsole(response);
                }
            }
        );
    }
}