import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from '../../../../../services/utils.service';
import { MethodsUtilsService } from '../../../../../services/methodsUtils.service';
import { FormularioBaseComponent } from '../../../../utils/formularioBase.component';
import { DocumentTypeService } from '../../../../../services/documentType.service';
import { DocumentType } from '../../../../../models/documentType';

// Declare Vars
declare var $: any;

@Component ({
	selector: 'app-form-document',
	templateUrl: './form.component.html',
	styleUrls: []
})

export class FormDocumentTypeComponent extends FormularioBaseComponent implements OnInit {

	public page = 'DocumentType';
	public title = 'DocumentType.Create';
	public titleButton = 'Save';
	public document = new DocumentType();

	constructor(
		private route: ActivatedRoute,
		private documentTypeService: DocumentTypeService,
		private router: Router,
		public utilsService: UtilsService,
		public methodsUtilsService: MethodsUtilsService,
	) {
		super(utilsService, methodsUtilsService);
	}

	ngOnInit() {
		const self = this;
		self.param = self.route.params.subscribe(
			params => {
				if (params['id']) {
					console.log('entro en id')
					self.title = 'DocumentType.Update';
					self.titleButton = 'Update';
					self.utilsService.debugConsole(params['id']);
					self.getDocumentType(params['id']);
				}
			}
		);
	}

	private getDocumentType (id: any): void {
		console.log('entro update document');
		const self = this;
		self.documentTypeService.getResult(id).then(
			function (response) {
				if (response === false) { return; }
				self.document.setDocumentType(self.documentTypeService.getVarResults());
			}
		);
	}

	public saveUpdateDocumentType(): void {
		const self = this;
		self.document.created_By = self.utilsService.getUserId();
		if (self.document.documentTypeId !== null) {
			self.documentTypeService.putData(self.document.documentTypeId, self.document.getJsonUpdate()).then(
				function (response) {
					if (response === false) { return; }
					self.router.navigate(['/document-type']);
				}
			);
		} else {
			self.documentTypeService.postData(self.document.getJsonCreate()).then(
				function (response) {
					if (response === false) { return; }
					self.router.navigate(['/document-type']);
				}
			);
		}
	}

}
