import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from '../../../../../services/utils.service';
import { MethodsUtilsService } from '../../../../../services/methodsUtils.service';
import { FormularioBaseComponent } from '../../../../utils/formularioBase.component';
import { CountryZonesService } from '../../../../../services/countryZones.service';
import { CountryZone } from '../../../../../models/countryZone';
// Declare Vars
declare var $: any;

@Component ({
    selector: 'app-form-country',
    templateUrl: './form.component.html',
    styleUrls: []
})

export class FormCountryZonesComponent extends FormularioBaseComponent implements OnInit {

    public page = 'CountryZones';
    public title = 'CountryZones.Create';
    public countryZone = new CountryZone();
    public titleButton = 'Save';
    public regionId = null;

    constructor(
        private route: ActivatedRoute,
        private countryZonesService: CountryZonesService,
        private router: Router,
        public utilsService: UtilsService,
        public methodsUtilsService: MethodsUtilsService,
    ) {
        super(utilsService, methodsUtilsService);
    }

    ngOnInit() {
        const self = this;
        self.getRegionsList();
        self.param = self.route.params.subscribe(
            params => {
                if (params['id']) {
                    self.title = 'CountryZones.Update';
                    self.titleButton = 'Update';
                    self.utilsService.debugConsole(params['id']);
                    self.getCountryZone(params['id']);
                }
            }
        );
    }

    private getCountryZone (id: any): void {
        const self = this;
        self.countryZonesService.getResult(id).then(
            function (response) {
                if (response === false) { return; }
                self.countryZone.setCountryZone(self.countryZonesService.getVarResults());
                self.regionId = self.countryZone.country.regionId;
                self.selectedRegion();
            }
        );
    }

    public selectedRegion(): void {
        const self = this;
        if (self.regionId != null) {
            self.getListCountriesByRegion(self.regionId);
        } else {
            self.countryZone.countryID = null;
        }
    }

    public saveUpdateCountryZone(): void {
        const self = this;
        if (self.countryZone.countryID !== null) {
            self.countryZonesService.putData(self.countryZone.countryZoneID, self.countryZone.getJsonUpdate()).then(
                function (response) {
                    if (response === false) { return; }
                    self.router.navigate(['/country-zones']);
                }
            );
        } else {
            self.countryZonesService.postData(self.countryZone.getJsonCreate()).then(
                function (response) {
                    if (response === false) { return; }
                    self.router.navigate(['/country-zones']);
                }
            );
        }
    }

}
