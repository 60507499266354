import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from '../../../../../services/utils.service';
import { MethodsUtilsService } from '../../../../../services/methodsUtils.service';
import { FormularioBaseComponent } from '../../../../utils/formularioBase.component';
import { DisconnectionTypeService } from '../../../../../services/disconnectionType.service';
import { DisconnectionType } from '../../../../../models/disconnectionType';

// Declare Vars
declare var $: any;

@Component({
  selector: 'app-form-disconnection',
  templateUrl: './form.component.html',
  styleUrls: []
})

export class FormDisconnectionTypeComponent extends FormularioBaseComponent implements OnInit {

  public page = 'DisconnectionType';
  public title = 'DisconnectionType.Create';
  public titleButton = 'Save';
  public disconnection = new DisconnectionType();

  constructor(
    private route: ActivatedRoute,
    private disconnectionTypeService: DisconnectionTypeService,
    private router: Router,
    public utilsService: UtilsService,
    public methodsUtilsService: MethodsUtilsService,
  ) {
    super(utilsService, methodsUtilsService);
  }

  ngOnInit() {
    const self = this;
    self.param = self.route.params.subscribe(
      params => {
        if (params['id']) {
          self.title = 'DisconnectionType.Update';
          self.titleButton = 'Update';
          self.utilsService.debugConsole(params['id']);
          self.getDisconnectionType(params['id']);
        }
      }
    );
  }

  private getDisconnectionType(id: any): void {
    const self = this;
    self.disconnectionTypeService.getResult(id).then(
      function (response) {
        if (response === false) { return; }
        self.disconnection.setDisconnectionType(self.disconnectionTypeService.getVarResults());
      }
    );
  }

  public saveUpdateDisconnectionType(): void {
    const self = this;
    self.disconnection.created_By = self.utilsService.getUserId();
    if (self.disconnection.disconnectionTypeID !== null) {
      self.disconnectionTypeService.putData(self.disconnection.disconnectionTypeID, self.disconnection.getJsonUpdate()).then(
        function (response) {
          if (response === false) { return; }
          self.router.navigate(['/disconnection-type']);
        }
      );
    } else {
      self.disconnectionTypeService.postData(self.disconnection.getJsonCreate()).then(
        function (response) {
          if (response === false) { return; }
          self.router.navigate(['/disconnection-type']);
        }
      );
    }
  }

}
