import { Injectable } from '@angular/core';
import { CustomHttpService } from './customHttp.service';
import { BaseServiceService } from './baseService.service';

@Injectable()
export class LocationsService extends BaseServiceService {
    private controller = 'SiteLocations';

    constructor(
        public customHttpService: CustomHttpService
    ) {
        super(customHttpService);
        this.TAG = this.controller;
        this.varGet = 'list' + this.controller;
        this.varGetId = 'siteLocation';
    }

    public getSiteLocationFilters (request) {
        const self = this;
        return new Promise(
            resolve => {
                // Get Info from 'v1/api/Categories/GetListCategories'
                self.customHttpService.httpPOST(false, true, 'v1/api/SiteLocations/GetSiteLocationFilters', request).then(
                    function (response) {
                        if (response === false) { resolve(response); }
                        self.setVarResults(response['listSiteLocations']);
                        resolve(true);
                    }
                );
            }
        );
    }
}
