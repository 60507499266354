import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
	selector: '[loaderContent]'
})

export class LoaderContentDirective implements AfterViewInit {
	private loaderHTML: string;
	@Input() loaderID: string;

	constructor (
		private el: ElementRef,
	) { }

	ngAfterViewInit() {
		this.loaderHTML = '<div id="' + this.loaderID + '" class="preloader-content">' +
			'<div class="preloader">' +
				'<div class="spinner-layer pl-light-blue">' +
					'<div class="circle-clipper left">' +
						'<div class="circle"></div>' +
					'</div>' +
					'<div class="circle-clipper right">' +
						'<div class="circle"></div>' +
					'</div>' +
				'</div>' +
			'</div>' +
		'</div>';

		this.el.nativeElement.insertAdjacentHTML('beforeend', this.loaderHTML);
	}
}
