import { Region } from './region';
import { CountryZone } from './countryZone';
import { City } from './city';
import { Country } from './country';
import { SiteStores } from './stores';

export class Cedis {
    public cedisID: number; 
    public active: boolean; 
	  public countryID: number;
	  public countryZoneID: number;
    public cityID: number;
    public siteStoreID: number;
    public cedisName: string;
    public latitude: string; 
    public longitude: string; 
    public created_By: string; 
    public region: Region;
	  public country: Country;
	  public countryZone: CountryZone;
    public city: City;
    public sitestore: SiteStores;
    private created_Date: string; 
    private updated_Date: string;
  
    constructor() {
      this.initalizeCedis();
    }
  
    initalizeCedis() {
      this.cedisID = null;
      this.active = null;
      this.cityID = null;
      this.countryID = null;
      this.countryZoneID = null; 
      this.siteStoreID = null;
      this.cedisName = null;
      this.latitude = null;
      this.longitude = null;
      this.created_By = null;
      this.created_Date = null;
      this.updated_Date = null;
      this.country = new Country();
      this.countryZone = new CountryZone();
      this.city = new City();
      this.sitestore = new SiteStores();
    }
  
    setCedis(cedis) {
      this.cedisID = cedis.cedisID;
      this.active = cedis.active;
      this.siteStoreID = cedis.siteStoreID
      this.cityID = cedis.cityID;
	    this.countryID = cedis.countryID;
      this.countryZoneID = cedis.countryZoneID;
      this.cedisName = cedis.cedisName;
      this.latitude =  cedis.latitude;
      this.longitude =  cedis.longitude;
      this.created_By = cedis.created_By;
      this.created_Date = cedis.created_Date;
      this.updated_Date = cedis.updated_Date;

	    if (cedis.hasOwnProperty('country') && cedis.country !== null) { this.country.setCountry(cedis.country); }
	    if (cedis.hasOwnProperty('countryZone') && cedis.countryZone !== null && typeof this.countryZone != 'undefined') { this.countryZone.setCountryZone(cedis.countryZone); }
	    if (cedis.hasOwnProperty('city') && cedis.city !== null && typeof this.city != 'undefined') { this.city.setCity(cedis.city); }
      if (cedis.hasOwnProperty('sitestore') && cedis.sitestore !== null && typeof this.sitestore != 'undefined') { this.sitestore.setSiteStore(cedis.sitestore); }

    }
  
    getJsonBase(): any {
      const jsonBase = {
        'active': this.active,
        'cityID': this.cityID,
		    'countryID': this.countryID ,
        'countryZoneID': this.countryZoneID,
        'cedisName': this.cedisName, 
        'siteStoreID': this.siteStoreID, 
        'latitude': this.latitude, 
        'longitude': this.longitude, 
      };
      return jsonBase;
    }
  
    getJsonCreate(): any {
      const json = this.getJsonBase();
      if (this.created_By !== null) { json['created_By'] = this.created_By; }
      return json;
    }
  
    getJsonUpdate(): any {
      const json = this.getJsonBase();
      if (this.created_Date !== null) { json['created_Date'] = this.created_Date; }
      if (this.updated_Date !== null) { json['updated_Date'] = this.updated_Date; }
      if (this.created_By !== null) { json['created_By'] = this.created_By; }
      if (this.cedisID !== null) { json['cedisID'] = this.cedisID; }
      return json;
    }
  }
  