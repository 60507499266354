import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../../../services/login.service';

@Component({
  selector: 'app-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.scss']
})
export class PasswordRecoveryComponent implements OnInit {
  public code: string;
  public email: string;
  public password: string;
  public repeatPassword: string;
  public errorRepeatPassword = false;
  // Spinner
  public activeSpinner = false;

  constructor(
    private loginService: LoginService,
    private router: Router
  ) { }

  ngOnInit() {
    const self = this;
  }

  public passwordRecovery = () => {
    const self = this;
    self.loginService.changePassword(self.code, self.email, self.password).then(
      (response) => {
        self.activeSpinner = false;
        if (response) {
          self.router.navigate(['login']);
        }
      }
    );
  }

  public validatePasswordRepeat = () => {
    this.errorRepeatPassword = this.password !== this.repeatPassword;
  }
}
