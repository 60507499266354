import { Injectable } from '@angular/core';
import { CustomHttpService } from './customHttp.service';
import { BaseServiceService } from './baseService.service';

@Injectable()
export class ProductService extends BaseServiceService {
	private controller = 'Products';

	constructor(
		public customHttpService: CustomHttpService
	) {
		super(customHttpService);
		this.TAG = this.controller;
		this.varGet = 'listProducts';
		this.varGetId = 'product';
	}

}
