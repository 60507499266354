import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from '../../../../../../services/utils.service';
import { MethodsUtilsService } from '../../../../../../services/methodsUtils.service';
import { FormularioBaseComponent } from '../../../../../utils/formularioBase.component';
import { ProductSiteStore } from '../../../../../../models/productSiteStore';
import { ProductSiteStoreService } from '../../../../../../services/productSiteStore.service';
// Declare Vars
declare var $: any;

@Component ({
  selector: 'app-form-segment',
  templateUrl: './form.component.html',
  styleUrls: []
})

export class FormProductSiteStoreComponent extends FormularioBaseComponent implements OnInit {

  public page = 'ProductSiteStore';
  public title = 'ProductSiteStore.Create';
  public productSiteStore = new ProductSiteStore();
  public titleButton = 'Save';
  public categoryID = null;

  constructor(
    private route: ActivatedRoute,
    private productSiteStoreService: ProductSiteStoreService,
    private router: Router,
    public utilsService: UtilsService,
    public methodsUtilsService: MethodsUtilsService,
  ) {
    super(utilsService, methodsUtilsService);
  }

  ngOnInit() {
    const self = this;
    self.getListCategories();
    self.getRegionsList();
    self.getListBrands();
    self.param = self.route.params.subscribe(
      params => {
        if (params['id']) {
          self.title = 'ProductSiteStore.Update';
          self.titleButton = 'Update';
          self.utilsService.debugConsole(params['id']);
          self.getproductSiteStore(params['id']);
        }
      }
    );
  }

  private getproductSiteStore (id: any): void {
    const self = this;
    self.productSiteStoreService.getResult(id).then(
      function (response) {
        if (response === false) { return; }
        self.productSiteStore.setProductSiteStore(self.productSiteStoreService.getVarResults());
        self.getListCountriesByRegion(self.productSiteStore.regionId);
        self.getListSiteStoresFilters({ 'cities': [], 'countries': [ self.productSiteStore.countryID ] });
        self.categoryID = self.productSiteStore.product.categoryID;
        self.getListProductsFilters(null, self.productSiteStore.brandID, self.categoryID);

      }
    );
  }

  public selectedRegion(): void {
    const self = this;
    if (self.productSiteStore.regionId != null) {
        self.getListCountriesByRegion(self.productSiteStore.regionId);
    } else {
        self.productSiteStore.countryID = null;
        self.productSiteStore.siteStoreID = null;
    }
  }

  public selectedCountry(): void {
      const self = this;
      if (self.productSiteStore.countryID != null) {
        self.getListSiteStoresFilters({ 'cities': [], 'countries': [ self.productSiteStore.countryID ] });
      } else {
        self.productSiteStore.countryID = null;
        self.productSiteStore.siteStoreID = null;
      }
  }

  public selectedCategory(): void {
    const self = this;
    if (self.categoryID != null) {
      self.getListProductsFilters(self.productSiteStore.regionId, self.productSiteStore.brandID, self.categoryID);
    } 
  }

  public selectedBrand(): void {
    const self = this;
    if (self.productSiteStore.brandID != null) {
      self.getListProductsFilters(self.productSiteStore.regionId, self.productSiteStore.brandID, self.categoryID);
    } 
  }

  public saveUpdate(): void {
    const self = this;
    self.productSiteStore.created_By = self.utilsService.getUserId();
    if (self.productSiteStore.productSiteStoreID !== null) {
      self.productSiteStoreService.putData(self.productSiteStore.productSiteStoreID, self.productSiteStore.getJsonUpdate()).then(
        function (response) {
          if (response === false) { return; }
          self.router.navigate(['/product-sitestore']);
        }
      );
    } else {
      console.log("POSRTTT");
      self.productSiteStoreService.postData(self.productSiteStore.getJsonCreate()).then(
        function (response) {
          if (response === false) { return; }
          self.router.navigate(['/product-sitestore']);
        }
      );
    }
  }

}
