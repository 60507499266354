import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../../../../services/utils.service';
import * as $ from 'jquery';
import { ReportFlooringService } from 'src/services/reportFlooring.service';

@Component({
  selector: 'app-report-market-promotions',
  templateUrl: './index.component.html',
  styleUrls: []
})
export class ReportFlooringComponent implements OnInit {

  public title = 'ReportFlooring';
  public ifDataExist = false;
  public ifExcelExist = true;
  public regionID = [];
  public cityID = [];
  public countryID = [];
  public data: any = [];
  public urlExcel: string;

  public tableTitles = [
    { name: 'year', label: 'Year', type: 'number' },
    { name: 'month', label: 'Month', type: 'month' },
    { name: 'yearWeek', label: 'Week', type: 'number' },
    { name: 'countryName', label:'Country', type:'normal'},
    { name: 'siteStoreName', label: 'Store', type: 'normal' },
    { name: 'siteLocationName', label: 'Location', type: 'normal' },
    { name: 'site_id', label: 'Site ID', type: 'normal' },
    { name: 'productGroupName', label: 'Product Group', type: 'normal' },
    { name: 'productTypeName', label: 'Product Type', type: 'normal' },
    { name: 'categoryName', label:'Category', type:'normal'},
    { name: 'brandName', label:'Brand', type:'normal'},
    { name: 'model', label: 'Product', type: 'normal' },
    { name: 'quantity', label: 'Quantity', type: 'normal' },
    { name: 'userName', label: 'User', type: 'normal' },
    { name: 'fullName', label: 'User Name', type: 'normal' },
    { name: 'supervisors', label: 'Supervisor', type: 'normal' },
    { name: 'created_Date', label: 'Created Date', type: 'date' },
  ];

  public optionsTable = {
    dataId: 'ReportFlooringID',
    showData: false,
    actionShowData: '',
    updateData: false,
    actionUpdateData: 'update',
    deleteData: false,
    filterDateRange: true,
    actionDeleteData: '',
    filterCountryZone: false,
    filterSiteLocation: false,
    filterSitestore: true,
    filterCity: true,
    filterCategory: true,
    filterCountry: true,
    filterRegion: true,
    filterCedis: false,
    searchBtn: true,
    exportBtn: true,
    itemsPerPage: 5,
  };

  constructor(
    private reportFlooringService: ReportFlooringService,
    private utilsService: UtilsService
  ) { }

  ngOnInit() {
    const self = this;
    const request = {};
    self.ifDataExist = true;
    //this.getReportFlooring(request);
  }

  /*
    filter(event) {
      const request = {
        'start_Date': event.start_Date,
        'end_Date': event.end_Date
      };
      this.getDisconnectionType(request) 
    }
  */

  filter(event) {
    let request = {}
    if(event.start_Date && event.end_Date) {
      request = {
        'start_Date': event.start_Date,
        'end_Date': event.end_Date
      };
    } else return false;
    if(event.siteStoreId) {
      request['sitestores'] = [event.siteStoreId]
    } else if (event.cityId) {
      request['cities'] = [event.cityId]
    } else if (event.countryId) {
      request['countries'] = [event.countryId]
    } else if (event.regionId) {
      request['regions'] = [event.regionId]
    }
    if (event.categoryId) {
      request['categoryID'] = event.categoryId
    }
    
    this.getReportFlooring(request);

  }

  private getReportFlooring(request) {

    const self = this;
    self.reportFlooringService.getReportFlooring(request).then(
      function (result) {
        self.data = self.reportFlooringService.getVarResults();
        self.ifDataExist = true;
        self.utilsService.debugConsole(result);
      }
    );
  }
}