import { Injectable } from '@angular/core';
import { CustomHttpService } from './customHttp.service';
import { BaseServiceService } from './baseService.service';
import { DatePipe } from '@angular/common';
import * as $ from 'jquery';


@Injectable()
export class ReportFlooringService extends BaseServiceService {
	private resultData: any;
	
	constructor(
		public customHttpService: CustomHttpService,
	) {
		super(customHttpService);
	}

	getReportFlooring(datos) {
		const self = this;
		return new Promise(
			resolve => {
				const datePipe = new DatePipe('es');
			
				self.customHttpService.httpPOST(true, true, 'v1/api/Reportes/GetReportFlooring', datos).then(
					function (data) {
						if (data === false) {
							resolve(data);
						} else {
							self.resultData = data['reportMarketFlooring'];
							self.setVarResults(self.resultData);
							resolve(true);
						}	
				});
			}
		);
	}
}