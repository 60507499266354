import { Injectable } from '@angular/core';
import { CustomHttpService } from './customHttp.service';
import { BaseServiceService } from './baseService.service';

@Injectable()
export class CedisService extends BaseServiceService {
	private controller = 'Cedis';

	constructor(
		public customHttpService: CustomHttpService
	) {
		super(customHttpService);
		this.TAG = this.controller;
		this.varGet = 'list' + this.controller;
		this.varGetId = 'cedis';
	}

}
