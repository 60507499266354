import { Component, OnInit, Self } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from '../../../../../services/utils.service';
import { MethodsUtilsService } from '../../../../../services/methodsUtils.service';
import { FormularioBaseComponent } from '../../../../utils/formularioBase.component';
import { PromotionSubTypes } from '../../../../../models/promotionsSubTypes';
import { PromotionSubTypesService } from '../../../../../services/promotionSubType.service';

// Declare Vars
declare var $: any;

@Component ({
	selector: 'app-form-promotion-sub-type',
	templateUrl: './form.component.html',
	styleUrls: []
})

export class FormPromotionSubTypesComponent extends FormularioBaseComponent implements OnInit {

	public page = 'PromotionSubTypes';
	public title = 'PromotionSubTypes.Create';
	public promotionSubTypes = new PromotionSubTypes();
	public titleButton = 'Save';
	public regionId;

	constructor(
		private route: ActivatedRoute,
		private promotionSubTypesService: PromotionSubTypesService,
		private router: Router,
		public utilsService: UtilsService,
		public methodsUtilsService: MethodsUtilsService,
	) {
		super(utilsService, methodsUtilsService);
	}

	ngOnInit() {
		const self = this;
		self.getRegionsList();
		self.param = self.route.params.subscribe(
			params => {
				if (params['id']) {
					self.title = 'PromotionSubTypes.Update';
					self.titleButton = 'Update';
					self.utilsService.debugConsole(params['id']);
					self.getPromotionSubTypes(params['id']);
				}
			}
		);
	}

	private getPromotionSubTypes(id: any): void {
		const self = this;
		self.promotionSubTypesService.getResult(id).then(
			function (response) {
				if (response === false) { return; }
				self.promotionSubTypes.setPromotionSubTypes(self.promotionSubTypesService.getVarResults());
				self.regionId = self.promotionSubTypes.country.regionId;
				self.getRegionsList();
				self.getListCountriesByRegion(self.regionId);
			}
		);
	}

	public saveUpdatePromotionSubTypes(): void {
		const self = this;
		if (self.promotionSubTypes.promotionSubTypeID !== null) {
			self.promotionSubTypesService.putData(self.promotionSubTypes.promotionSubTypeID, self.promotionSubTypes.getJsonUpdate()).then(
				function (response) {
					if (response === false) { return; }
					self.router.navigate(['/promotion-sub-types']);
				}
			);
		} else {
			self.promotionSubTypesService.postData(self.giveAwayTypes.getJsonCreate()).then(
				function (response) {
					if (response === false) { return; }
					self.router.navigate(['/promotion-sub-types']);
				}
			);
		}
	}
	
	public selectedRegion(): void {
        const self = this;
        if (self.regionId != null) {
            self.getListCountriesByRegion(self.regionId);
        } else {
            self.promotionSubTypes.countryID = null;
        }
    }
}
