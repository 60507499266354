/**
 * Created by Viviana Rincón Cheil on 24/07/2019.
 */
export class DisconnectionType {
    public disconnectionTypeID: number; // 123
    public disconnection: string; // El nombre max 25
    public created_By: string; // El nombre de quien lo crea
    private created_Date: string; // "2019-05-31T02:33:33.380Z"
    private updated_Date: string; // "2019-05-31T02:33:33.380Z"

    constructor() {
        this.initalizesetdisconnection();
    }

    initalizesetdisconnection() {
        this.disconnectionTypeID = null;
        this.disconnection = null;
        this.created_By = null;
        this.created_Date = null;
        this.updated_Date = null;
    }

    setDisconnectionType(disconnection) {
        this.disconnectionTypeID = disconnection.disconnectionTypeID;
        this.disconnection = disconnection.disconnection;
        this.created_By = disconnection.created_By;
        this.created_Date = disconnection.created_Date;
        this.updated_Date = disconnection.updated_Date;
    }

    getJsonBase(): any {
        const jsonBase = {
            'disconnection': this.disconnection
        };
        return jsonBase;
    }

    getJsonCreate(): any {
        const json = this.getJsonBase();
        if (this.created_By !== null) { json['created_By'] = this.created_By; }
        return json;
    }

    getJsonUpdate(): any {
        const json = this.getJsonBase();
        if (this.created_Date !== null) { json['created_Date'] = this.created_Date; }
        if (this.created_By !== null) { json['created_By'] = this.created_By; }
        if (this.disconnectionTypeID !== null) { json['disconnectionTypeID'] = this.disconnectionTypeID; }
        return json;
    }
}