import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

// Declare Vars
declare var $: any;

@Injectable()
export class UtilsService {
  private isDebug = true;
  private loggedIn = false;
  // private url = "https://api.msocheil.com.pe/";
  private url = "https://prod.aws.msocheil.com/";
  // private url = 'https://test.api.msocheil.com/';
  // private url = 'http://192.168.1.21:5002/';
  // private url = 'http://2366f00ac5ad.ngrok.io/';

  constructor(private router: Router) {}

  isLoggedIn(): boolean {
    return this.loggedIn;
  }

  setLoggedIn(isLoggedIn: boolean) {
    this.loggedIn = isLoggedIn;
  }

  getUrl(): string {
    return this.url;
  }

  loadScript(url) {
    console.log("preparing to load... " + url);
    const node = document.createElement("script");
    node.type = "text/javascript";
    node.src = url;
    document.getElementsByTagName("head")[0].appendChild(node);
  }

  debugConsole(message) {
    if (this.isDebug === true) {
      // console.log(message);
    }
  }

  alert(message) {
    alert(message);
  }

  showError(message) {
    if (message.hasOwnProperty("success")) {
      this.debugConsole(message);
      this.alert(message.error);
    } else {
      if (message.status === 401) {
        this.logout();
      } else if (message.status === 400) {
        if (
          message["error"].error === "paila" &&
          message["error"].errorCode === 0
        ) {
          return this.logout();
        }
        this.debugConsole(message["error"].error);
        this.alert(message["error"].error);
      } else if (message.status === 404) {
        this.debugConsole(message);
        if (message.error === null) {
          this.alert("La Url consultada no responde");
        } else {
          this.alert(message["error"].error);
        }
      } else if (
        message.status === 503 ||
        message.status === 500 ||
        message.status === 0
      ) {
        console.log(message);
        this.debugConsole(message["error"].error);
        // alert('Error (500-503), Hemos detectado un problema de conexión por favor intenta mas tarde!');
      } else if (this.isDebug === true) {
        this.debugConsole(message);
        this.alert("aqui esta" + message.message);
      }
    }
  }

  logout() {
    sessionStorage.clear();
    localStorage.clear();
    this.router.navigate(["login"]);
  }

  getUserCountryID(): string {
    return JSON.parse(sessionStorage.getItem("user")).countryID;
  }

  getUserId(): string {
    return JSON.parse(localStorage.getItem("user")).userId;
  }

  getAlmacenes(): any {
    let points = [];
    for (const point of JSON.parse(localStorage.getItem("user")).routeList) {
      points = [
        ...points,
        {
          lng: point.longitude,
          lat: point.latitude,
          name: point.name,
          location: point.location,
          siteLocationID: point.siteLocationID,
        },
      ];
    }
    return points;
  }
}
