import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from "xlsx";
import * as pdfMake from 'pdfmake/build/pdfmake.js';
import * as pdfFonts from 'pdfmake/build/vfs_fonts.js';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

const CSV_TYPE = 'application/csv;charset=UTF-8';
const CSV_EXTENSION = '.csv';

const PDF_TYPE = 'application/pdf;charset=UTF-8';
const PDF_EXTENSION = '.pdf';

export class ExcelService {

  constructor() { }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  public exportAsCSVFile(json: any[], csvFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'csv', type: 'array' });
    this.saveAsCSVFile(excelBuffer, csvFileName);
  }

  public exportAsPDFFile(data: any[], pdfFileName: string): void {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    var dd = { content: this.table(data, Object.keys(data[0])) };
    let pdfBuffer = pdfMake.createPdf(dd).getBuffer((buffer) => {
      this.saveAsPDFFile(buffer, pdfFileName);
    });
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName.replace("_","") + '_' + new Date().toLocaleDateString() + EXCEL_EXTENSION);
  }

  private saveAsCSVFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: CSV_TYPE
    });
    FileSaver.saveAs(data, fileName.replace("_","") + '_' + new Date().toLocaleDateString()  + CSV_EXTENSION);
  }

  private saveAsPDFFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: PDF_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + PDF_EXTENSION);
  }

  private buildTableBody(data, columns) {
    var body = [];

    body.push(columns);

    data.forEach(function(row) {
        var dataRow = [];

        columns.forEach(function(column) {
          if(row[column]){
            dataRow.push(row[column].toString());
          }
        })
        body.push(dataRow);
    });

    return body;
  }

  private table(data, columns) {
    return {
        table: {
            headerRows: 1,
            body: this.buildTableBody(data, columns)
        }
    };
  }
}