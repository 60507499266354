import { Component, Input, OnInit, TemplateRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as $ from 'jquery';
import * as subDays from 'date-fns/sub_days';
import * as format from 'date-fns/format';
import * as getDaysInMonth from 'date-fns/get_days_in_month';
import * as getYear from 'date-fns/get_year';
import * as subMonths from 'date-fns/sub_months';
import * as addMonths from 'date-fns/add_months';
import * as getMonth from 'date-fns/get_month';
import * as endOfWeek from 'date-fns/end_of_week';
import * as startOfWeek from 'date-fns/start_of_week';
import * as subWeek from 'date-fns/sub_weeks';
import * as addWeek from 'date-fns/add_weeks';
import * as addDays from 'date-fns/add_days';
import * as getDay from 'date-fns/get_day';
import * as getDate from 'date-fns/get_date';
import * as compareDesc from 'date-fns/compare_desc';
import * as getHours from 'date-fns/get_hours';
import * as getMinutes from 'date-fns/get_minutes';
import { UtilsService } from '../../../services/utils.service';
import { ScheduleService } from '../../../services/schedule.service';
import { Schedule } from '../../../models/schedule';
import * as localeEs from 'date-fns/locale/es';
import { TranslatePipe } from '../../../pipes/translate';
import { Router } from '@angular/router';
import { ExcelService } from '../../../services/excel.service';
import { StoresService } from 'src/services/stores.service';
import { LocationsService } from 'src/services/locations.service';

@Component({
  selector: 'app-schedule-users',
  templateUrl: './schedule-users.component.html',
  styleUrls: []
})

export class ScheduleUsersComponent implements OnInit {
  @Input() users: any;
  @ViewChild('modalViewDay') modalViewDay: TemplateRef<any>;
  @Output() ngInit = new EventEmitter();

  public dateSelect = new Date();
  public days: any = [];
  public titleDate = 'Month';
  public viewMonthBool = true;
  public modalDayData = {};
  public hours = [
    '06:00', '07:00', '08:00', '09:00',
    '10:00', '11:00', '12:00', '13:00',
    '14:00', '15:00', '16:00', '17:00',
    '18:00', '19:00', '20:00', '21:00',
    '22:00', '23:00'];
  public colorsPrimary = ['green'];
  public hoursArray = [
    '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30',
    '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30',
    '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30',
    '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30',
    '22:00', '22:30', '23:00', '23:30'];
  public hoursInit = [];
  public hoursEnd = [];
  public hoursBlocked = [];
  public startHour: any = null;
  public endHour: any = null;
  public locations: any;
  public scheduleTypes: any = '';
  public trainingTypes: any = '';
  public trainingTopics: any = '';
  public schedule = new Schedule();
  public listSchedule: Schedule[] = [];
  public multiSelect = false;
  public datesList = [];
  public showSaveButton:boolean = false;
  public siteLocationsFields = {};
  public siteStores = [];
  public p = 0;

  private siteStoreId;
  public siteStoreID: number = null;
  pointName: any;

  constructor (
    private router: Router,
    private modal: NgbModal,
    private utilsService: UtilsService,
    private scheduleService: ScheduleService,
    private storesService: StoresService,
    private locationsService: LocationsService,
  ) { }

  ngOnInit(): void {
    $('.preloader-content').fadeIn();
    this.dateSelect = new Date();
    this.viewWeek();
    // this.locations = this.utilsService.getAlmacenes();
    this.siteLocationsFields = { text: 'location', value: 'siteLocationID' };
  }

  /*
  getListStores() {
      const self = this;
      this.storesService.getResults().then(
          function (response) {
              if (response) {
                  self.siteStores = self.storesService.getVarResults();
              }
          }
      );
  }
  */

  getListStores(countryID) {
    const self = this;
    self.storesService.getStoresByCountry(countryID).then(
      function (response) {
        if (response) {
          self.siteStores = self.storesService.getVarResults();
        }
      }
    );
  }

  getListSiteLocationFilters(request) {
    const self = this;
    this.locationsService.getSiteLocationFilters(request).then(
      function (response) {
        if (response) {
          self.locations = self.locationsService.getVarResults();
        }
      }
    );
  }

  public getAllScheduleTypes(): void {
    const self = this;
    self.scheduleService.getAllScheduleTypes().then(
      function (response) {
        if (response) {
          self.scheduleTypes = self.scheduleService.getVarResults();
        }
      }
    );
  }

  public getTrainigTypes(): void {
    const self = this;
    self.scheduleService.getTrainingTypes().then(
      function (response) {
        if (response) {
          self.trainingTypes = self.scheduleService.getVarResults();
        }
      }
    );
  }

  selectedStore(){
    const self = this;
    let request = {};
    request["sitestores"] = [self.siteStoreID];
    self.getListSiteLocationFilters(request);
  }


  public getTrainigTopcis(): void {
    const self = this;
    self.scheduleService.getTrainingTopics().then(
      function (response) {
        if (response) {
          self.trainingTopics = self.scheduleService.getVarResults();
        }
      }
    );
  }

  public searchType(typeID): string {
    let tempString = null;
    for (const type of this.scheduleTypes) {
      if (type.workScheduleTypeID === typeID) {
        tempString = type.type;
      }
    }
    return tempString;
  }

  public getScheduleTeam(request): void {
    const self = this;
    self.users = [];
    self.scheduleService.getScheduleForSupervisorTeam(request).then(
      function (response) {
        if (response) {
          for (const promoter of self.scheduleService.getVarResults()) {
            self.users = [
              ...self.users,
              {
                userId: promoter.userId,
                img: promoter.photo,
                name: promoter.fullName,
                cargo: promoter.userRolName,
                schedule: promoter.schedule,
                rol: promoter.userRolID,
                countryID: promoter.countryID,
              }
            ];
          }
          $('.preloader-content').fadeOut();
          self.getAllScheduleTypes();
          self.getTrainigTypes();
          self.getTrainigTopcis();
          //self.getListStores();
        }
      }
    );
  }

  public before(): void {
    if (this.viewMonthBool) {
      this.dateSelect = subMonths(this.dateSelect, 1);
      this.viewMonth();
    } else {
      this.dateSelect = subWeek(this.dateSelect, 1);
      this.viewWeek();
    }
  }

  public next(): void {
    if (this.viewMonthBool) {
      this.dateSelect = addMonths(this.dateSelect, 1);
      this.viewMonth();
    } else {
      this.dateSelect = addWeek(this.dateSelect, 1);
      this.viewWeek();
    }
  }

  async viewWeek(){
    const self = this;
    self.viewMonthBool = false;
    self.days = [];
    const firstDay = addDays(startOfWeek(new Date(self.dateSelect)), 1);
    const lastDay = addDays(endOfWeek(new Date(self.dateSelect)), 1);

    let translatePipe = new TranslatePipe();

    self.titleDate = translatePipe.transform('Week')
      + ' ' + format(firstDay, 'W')
      + ' ' + translatePipe.transform('From')
      + ' ' + format(firstDay, 'DD/MM')
      + ' ' + translatePipe.transform('To')
      + ' ' + format(lastDay, 'DD/MM');

    const request = {
      'supervisorUserId': this.utilsService.getUserId(),
      'from_date': format(firstDay, 'YYYY-MM-DD'),
      'to_Date': format(lastDay, 'YYYY-MM-DD')
    };
    this.getScheduleTeam(request);
    const widthScroll = (7 * 100);
    $('.cronograma-width').width(widthScroll);
    $('.month').removeClass('active');
    $('.week').addClass('active');
    let tempDate = firstDay;
    for (let i = 1; i <= 7; i++) {
      this.days = [
        ...this.days,
        {
          day: new Date(getYear(tempDate), getMonth(tempDate), getDate(tempDate)),
          numDay: getDate(tempDate) < 10 ? '0' + getDate(tempDate) : getDate(tempDate),
          numMonth: format(tempDate, 'MM'),
          strDay: format(new Date(getYear(tempDate), getMonth(tempDate), getDate(tempDate)), 'ddd', { locale: localeEs })
        }
      ];
      tempDate = addDays(tempDate, 1);
    }
  }

  async viewMonth() {
    $('.preloader-content').fadeIn();
    this.viewMonthBool = true;
    this.days = [];
    this.titleDate = format(this.dateSelect, 'YYYY-MM');
    const request = {
      'supervisorUserId': this.utilsService.getUserId(),
      'from_date': format(new Date(this.dateSelect), 'YYYY-MM') + '-' + 1,
      'to_Date': format(new Date(this.dateSelect), 'YYYY-MM') + '-' + getDaysInMonth(this.dateSelect)
    };
    this.getScheduleTeam(request);
    const widthScroll = (getDaysInMonth(this.dateSelect) * 100);
    $('.cronograma-width').width(widthScroll);
    $('.week').removeClass('active');
    $('.month').addClass('active');
    for (let i = 1; i <= getDaysInMonth(this.dateSelect); i++) {
      this.days = [
        ...this.days,
        {
          day: new Date(getYear(this.dateSelect), getMonth(this.dateSelect), i),
          numDay: i < 10 ? '0' + i : i,
          numMonth: format(this.dateSelect, 'MM'),
          strDay: format(new Date(getYear(this.dateSelect), getMonth(this.dateSelect), i), 'ddd', { locale: localeEs })
        }
      ];
    }

  }

  public compareSchedule (schedule, day): string {
    let tempString = null;
    let firstHour = null;
    let firstMinutes = null;
    let lastHour = null;
    let lastMinutes = null;
    for (const task of schedule) {
      if (compareDesc(task.schedule_Date, day.day) === 0) {
        if (firstHour === null || firstHour > getHours(new Date(task.schedule_InitTime))) {
          firstHour = getHours(new Date(task.schedule_InitTime));
          firstMinutes = getMinutes(new Date(task.schedule_InitTime));
        }
        if (lastHour === null || lastHour < getHours(new Date(task.schedule_EndTime))) {
          lastHour = getHours(new Date(task.schedule_EndTime));
          lastMinutes = getMinutes(new Date(task.schedule_EndTime));
        }
        tempString = (firstHour < 10 ? '0' + firstHour : firstHour)  + ':' + (firstMinutes < 10 ? '0' + firstMinutes : firstMinutes) + ' - ' + (lastHour < 10 ? '0' + lastHour : lastHour) + ':' + (lastMinutes < 10 ? '0' + lastMinutes : lastMinutes);
      }
    }
    return tempString;
  }


  public compareScheduleXLSX(schedule, day): string {
    let tempString = null;
    for (const task of schedule) {
      if (compareDesc(task.schedule_Date, day.day) === 0) {
        tempString = (task.workScheduleType.type) ? task.workScheduleType.type + ': ' : '';

        if (task.workScheduleTypeID === 1 || task.workScheduleTypeID === 8) {
          tempString += (task.siteLocation) ? task.siteLocation.location + ' - ' : '';
        }

        tempString += (task.initTime) ? task.initTime + ' - ' : '';
        tempString += (task.endTime) ? task.endTime : '';
      }
    }
    return tempString;
  }

  public validateDay(day): string {
    let tempString = 'work';
    if (compareDesc(day, new Date()) === 1) {
      tempString = 'old-date';
      if (format(day, 'YYYY-MM-DD') === format(new Date(), 'YYYY-MM-DD')) {
        tempString = 'today';
      }
    } else {
      if (format(new Date(day), 'd') === '0') {
        tempString = 'no-work';
      }
      if (compareDesc(format(day, 'YYYY-MM-DD'), format(new Date(), 'YYYY-MM-DD')) === 0) {
        tempString = 'today';
      }
    }
    return tempString;
  }

  public viewDay(user: any, day: any, showForm: boolean): void {
    this.startHour = null;
    this.endHour = null;
    this.listSchedule = [];
    this.hoursBlocked = [];
    this.schedule.initalizeSchedule();
    this.modalDayData = {
      user: user,
      day: day,
      list: false,
      showForm: showForm
    };
    console.log(user.countryID);
    this.getListStores(user.countryID);
    this.modal.open(this.modalViewDay);
    const self = this;
    setTimeout(function () {
      self.showDay(user, day);
    }, 50);
  }

  public async showDay(user: any, day: any) {
    const self = this;
    let count = 0;
    for (const task of user.schedule) {
      if (compareDesc(task.schedule_Date, day.day) === 0) {
        new Promise(
          resolve => {
            const scheduleTemp = new Schedule();
            scheduleTemp.setSchedule(task);
            scheduleTemp.schedule_InitHour = format(scheduleTemp.schedule_InitTime, 'HH:mm');
            scheduleTemp.schedule_EndHour = format(scheduleTemp.schedule_EndTime, 'HH:mm');
            scheduleTemp.color = self.colorsPrimary[0];
            const numberPlus = (parseInt(format(scheduleTemp.schedule_InitTime, 'm'), 0) === 30 ? 35 : 10);
            const top = ((parseInt(format(scheduleTemp.schedule_InitTime, 'H'), 0) - 6) * 50) + numberPlus;
            const diffHour = parseInt(format(scheduleTemp.schedule_EndTime, 'H'), 0)
              - parseInt(format(scheduleTemp.schedule_InitTime, 'H'), 0);
            const diffMinutes = parseInt(format(scheduleTemp.schedule_EndTime, 'm'), 0)
              - parseInt(format(scheduleTemp.schedule_InitTime, 'm'), 0);
            const height = (diffHour * 50) + (diffMinutes > 0 ? 25 : ( diffMinutes < 0 ? -25 : 0 ));
            scheduleTemp.top = top.toString();
            scheduleTemp.height = height.toString();
            self.listSchedule.push(scheduleTemp);
            for (const hour of this.hoursArray) {
              if (hour >= scheduleTemp.schedule_InitHour && hour < scheduleTemp.schedule_EndHour) {
                self.blockHour(hour);
              }
            }
            resolve(true);
          }
        );
        count++;
      }
    }
  }

  public blockedHour(hour): string {
    let tempString = null;
    const index = this.hoursBlocked.indexOf(hour, 0);
    if (index > -1) {
      tempString = 'existe';
    }
    return tempString;
  }

  public blockHour(hour): void {
    const index = this.hoursBlocked.indexOf(hour, 0);
    if (index === -1) {
      this.hoursBlocked.push(hour);
    }
  }

  public unblockHour(hour): void {
    const index = this.hoursBlocked.indexOf(hour, 0);
    if (index > -1) {
      this.hoursBlocked.splice(index, 1);
    }
  }

  public selectHour(hour): void {
    if (this.startHour === null) {
      this.startHour = hour;
      this.endHour = hour;
    } else if (this.endHour === null) {
      this.endHour = hour;
    } else if (hour < this.startHour) {
      this.startHour = hour;
    } else if (hour > this.endHour) {
      this.endHour = hour;
    } else {
      this.startHour = hour;
      this.endHour = hour;
    }
    this.schedule.schedule_InitTime = format(new Date(this.modalDayData['day'].day), 'YYYY-MM-DD') + ' ' + this.startHour + ':00';
    this.schedule.schedule_EndTime = format(new Date(this.modalDayData['day'].day), 'YYYY-MM-DD') + ' ' + this.endHour + ':00';
    this.schedule.schedule_InitHour = this.startHour + ':00';
    this.schedule.schedule_EndHour = this.endHour + ':00';
  }

  public saveHour(): void {
    const self = this;
    if (self.schedule.schedule_InitTime === null && self.schedule.schedule_InitHour === null) {
      alert('Debe elegir una fecha inicial para agendar');
      return;
    }
    if (self.schedule.schedule_EndTime === null && self.schedule.schedule_EndHour === null) {
      alert('Debe elegir una fecha final para agendar');
      return;
    }
    if (self.schedule.schedule_InitHour >= self.schedule.schedule_EndHour) {
      alert('La Hora Final debe ser superior a la Inicial');
      return;
    }
    if (self.schedule.workScheduleTypeID === null) {
      alert('Debe elegir un tipo de agendamiento');
      return;
    }
    if (self.schedule.workScheduleTypeID === 1 && self.schedule.siteLocationID === null) {
      alert('Debe elegir un punto de venta');
      return;
    }
    const scheduleTemp = new Schedule();
    scheduleTemp.schedule_InitTime = format(
      this.datesList.length === 0 ? new Date(this.modalDayData['day'].day) : new Date(), 'YYYY-MM-DD') + ' '
      + this.schedule.schedule_InitHour + ':00';
    scheduleTemp.schedule_EndTime = format(
      this.datesList.length === 0 ? new Date(this.modalDayData['day'].day) : new Date(), 'YYYY-MM-DD') + ' '
      + this.schedule.schedule_EndHour + ':00';
    scheduleTemp.schedule_InitHour = self.schedule.schedule_InitHour;
    scheduleTemp.schedule_EndHour = self.schedule.schedule_EndHour;
    scheduleTemp.creatorUserId = parseInt(self.utilsService.getUserId(), 0);
    scheduleTemp.scheduleUserId = self.modalDayData['user'].userId;
    scheduleTemp.workScheduleTypeID = self.schedule.workScheduleTypeID;
    scheduleTemp.trainingTypeID = self.schedule.trainingTypeID;
    scheduleTemp.trainingTopicID = self.schedule.trainingTopicID;
    scheduleTemp.color = self.colorsPrimary[0];
    const numberPlus = (parseInt(format(scheduleTemp.schedule_InitTime, 'm'), 0) === 30 ? 35 : 10);
    const top = ((parseInt(format(scheduleTemp.schedule_InitTime, 'H'), 0) - 6) * 50) + numberPlus;
    const diffHour = parseInt(format(scheduleTemp.schedule_EndTime, 'H'), 0)
      - parseInt(format(scheduleTemp.schedule_InitTime, 'H'), 0);
    const diffMinutes = parseInt(format(scheduleTemp.schedule_EndTime, 'm'), 0)
      - parseInt(format(scheduleTemp.schedule_InitTime, 'm'), 0);
    const height = (diffHour * 50) + (diffMinutes > 0 ? 25 : ( diffMinutes < 0 ? -25 : 0 ));
    scheduleTemp.top = top.toString();
    scheduleTemp.height = height.toString();
    if (self.schedule.workScheduleTypeID === 1) {
      scheduleTemp.siteLocationID = self.schedule.siteLocationID;
    }
    self.listSchedule.push(scheduleTemp);
    self.schedule.initalizeSchedule();
    for (const hour of this.hoursArray) {
      if (hour >= scheduleTemp.schedule_InitHour && hour < scheduleTemp.schedule_EndHour) {
        self.blockHour(hour);
      }
    }
    this.startHour = null;
    this.endHour = null;
  }

  public async sendHoursMultiple() {
    const self = this;
    $('.preloader-content').fadeIn();
    const sendListSchedule = [];
    if (self.datesList.length > 0) {
      for (const daySelected of self.datesList) {
        for (const scheduleSelected of self.listSchedule) {
          scheduleSelected.schedule_InitTime = daySelected + ' ' + scheduleSelected.schedule_InitHour;
          scheduleSelected.schedule_EndTime = daySelected + ' ' + scheduleSelected.schedule_EndHour;
          sendListSchedule.push(scheduleSelected.getJsonCreateSchedule());
        }
      }
    } else {
      for (const scheduleSelected of self.listSchedule) {
        if (scheduleSelected.workScheduleID === null) {
          scheduleSelected.schedule_InitTime = format(scheduleSelected.schedule_InitTime, 'YYYY-MM-DD HH:mm:ss');
          scheduleSelected.schedule_EndTime = format(scheduleSelected.schedule_EndTime, 'YYYY-MM-DD HH:mm:ss');
          sendListSchedule.push(scheduleSelected.getJsonCreateSchedule());
        }
      }
    }
    await self.sendlistScheduleCreate(sendListSchedule);

    self.modal.dismissAll();
    if (self.viewMonthBool) {
      self.viewMonth();
    } else {
      self.viewWeek();
    }
  }

  async sendScheduleCreate(scheduleSelected) {
    const self = this;
    return await self.scheduleService.createSchedule(scheduleSelected.getJsonCreateSchedule()).then(
      function (response) {}
    );
  }

  async sendlistScheduleCreate(listSchedule) {
    const self = this;
    return await self.scheduleService.createScheduleList(listSchedule).then(
      function (response) {}
    );
  }

  public enableMultiSelect() {
    this.datesList = [];
    this.multiSelect = !this.multiSelect;
  }

  public addOrRemoveDayOfList(day) {
    const varDay = format(new Date(day), 'YYYY-MM-DD');
    const index = this.datesList.indexOf(varDay, 0);
    if (index > -1) {
      this.datesList.splice(index, 1);
    } else {
      this.datesList.push(varDay);
    }
  }

  public getNameAlmacen(id){
    const self = this;
    self.locationsService.getResult(id).then(
      function (response) {
        if (response === false) { return; }
        const pointName = self.locationsService.getVarResults();
        self.pointName = pointName.location;
      }
    );
    return self.pointName;
  }

  public selectUser(user) {
    this.startHour = null;
    this.endHour = null;
    this.schedule.initalizeSchedule();
    this.listSchedule = [];
    this.hoursBlocked = [];
    this.modalDayData = {
      user: user,
      day: this.datesList,
      list: true,
      showForm: true
    };
    this.modal.open(this.modalViewDay);
  }

  public async removeSchedule(schedule) {
    const self = this;
    return await self.scheduleService.deleteWorkSchedule(schedule.workScheduleID).then(
      function (response) {
        if (response) {
          self.deleteSchedule(schedule);
          for (const hour of self.hoursArray) {
            if (hour >= schedule.schedule_InitHour && hour <= schedule.schedule_EndHour) {
              self.unblockHour(hour);
            }
          }
          self.schedule.initalizeSchedule();
          self.showSaveButton = true;
        }
      }
    );
  }

  public deleteSchedule(schedule) {
    const index = this.listSchedule.indexOf(schedule, 0);
    if (index > -1) {
      this.listSchedule.splice(index, 1);
    }
  }

  public exportAsXLSX(users, days): void {
    const self = this;
    const cls = new ExcelService();
    let excelData = [];
    let excelDataRow = []
    let translatePipe = new TranslatePipe();

    users.forEach(function(user, key) {
      excelDataRow = [];
      let rowTitle = "Nombre";
      let rowValue = user.name;
      excelDataRow[rowTitle] = rowValue;
      days.forEach(function(day, key) {
        rowTitle = day.strDay + ' ' + day.numDay + '/' + day.numMonth;
        rowValue = self.compareScheduleXLSX(user.schedule, day);
        excelDataRow[rowTitle] = ((rowValue) ? rowValue : '' );
      });

      excelData.push(excelDataRow);
    });

    cls.exportAsExcelFile(excelData, this.router.url.substring(1));
  }
}
