import { Component, OnInit, Input, ViewChild, TemplateRef, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from '../../../../services/utils.service';
import { RouteManagementService } from '../../../../services/route-management.service';
import { ModalService } from '../../../../services/modal.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { FiltersService } from 'src/services/filters.service';
import { User } from 'src/models/users';
import { StaffInfoService } from 'src/services/staff-info.service';


declare var $;

@Component({
  selector: 'app-show-route',
  templateUrl: 'show.component.html',
  styleUrls: ['show.component.scss']
})

export class ShowRouteManagementComponent implements OnInit {
  @ViewChild('modalCreateStore', { }) modalCreateStore: TemplateRef<any>;
  @ViewChild('modalDeleteStore', { }) modalDeleteStore: TemplateRef<any>;
  @ViewChild('routemap', { }) routemap;

  public filtersActive = false;
  public ifDataExist = false;
  private param: any;
  private userRolID: any;
  public points: any = [];
  public data: any = [];
  public datos: any = [];
  public dataVistaUsuario;
  public fullName;
  public nameRol;
  // VIVI
  public a = 0;
  public storeId = 0;
  private userID: any;
  private routeID: any;
  private tmpRouteList: any = [];
  public deleteRoute: any = [];
  public storeFilterList: any = 0;
  public regionFilterList: any = [];
  public countryFilterList: any = [];
  public locationFilterList: any = [];
  public pointOfSalesId;
  public arrayStore: any = [];
  public showStoreAdd = true;
  public fieldsLocation  = {};
  public fieldsStore = {};
  public fieldsRegion = {};
  public fieldsCountry = {};
  public p = 0;

  public user = new User();
  regionId: any;
  countryId: any;

  // Inject HttpClient into your component or service.
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private modal: NgbModal,
    private utilsService: UtilsService,
    private routeManagementService: RouteManagementService,
    private modalService: ModalService,
    private filtersService: FiltersService,
    private staffInfoService: StaffInfoService,
  ) { }

  ngOnInit(): void {
    const self = this;
    self.modal.dismissAll();

    self.fieldsStore = { text: 'name', value: 'siteStoreID' };
    self.fieldsLocation = { text: 'name', value: 'siteLocationID' };
    self.fieldsCountry = { text: 'country', value: 'countryID' };
    self.fieldsRegion = { text: 'region', value: 'regionId' };

    self.filtersService.getListRegions().then(
      function (response) {
        if (response) {
          self.regionFilterList = self.filtersService.getVarResults();
          if (self.regionFilterList.length === 1) {
            if (!self.regionId) {
              self.regionId = self.regionFilterList[0].regionId;
              self.selectedRegion(self.regionId);
            }
          }
        }
      }
    );

    self.param = self.route.params.subscribe(
      params => {
        if (params['idUser'] !== null) {
          const datePipe = new DatePipe('es');
          self.userID = params['idUser'];
          self.getUser(self.userID);
          self.routeID = params['idRoute'];
        }
      });

    self.getRoutesManagement(self.routeID);
  }

  public selectedRegion(regionId): void {
    const self = this;
    self.countryFilterList = [];
    self.filtersService.getListCountriesByRegion(regionId).then(
      function (response) {
        if (response) {
          self.countryFilterList = [];
          self.storeFilterList = [];
          self.locationFilterList = [];
          self.countryFilterList = self.filtersService.getVarResults();
          if (self.countryFilterList.length === 1) {
            self.countryId = self.countryFilterList[0].countryID;
            self.selectedCountry(self.countryId);
          } else {
            self.countryFilterList.unshift({countryID: 0, country: 'Seleccione una opción'});
          }
        }
      }
    );
  }

  public selectedCountry(countryId): void {
    const self = this;
    self.storeFilterList = [];
    self.locationFilterList = [];

    self.filtersService.getListSiteStoresFilters(
      [],
      (countryId ? [countryId] : countryId),
    ).then(
      function (response) {
        if (response) {
          self.storeFilterList = self.filtersService.getVarResults();
          self.storeFilterList.unshift({siteStoreID: 0, name: 'Seleccione una opción'});
        }
      }
    );
  }

  private getUser(id: any): void {
    const self = this;
    self.staffInfoService.GetEmployeeInfo(id).then(
      (response) => {
        if (response === false) { return; }
        const user = self.staffInfoService.getVarResults();
        self.user.setUser(user.user);
        self.fullName = self.user.fullName + ' (' + self.user.userName + ' - ' + user.user.userRol.level + ')';
        // self.getListStoresByCountryID(self.user.countryID);
      }
    );
  }

  //#region Metodos para el mapa
  public addToList(point: any): void {
    point.select = true;
    this.reloadMarkers();
  }

  public removeToList(point: any): void {
    point.select = false;
    this.reloadMarkers();

  }

  public reloadMarkers(): void {
    this.routemap.reloadPoints();
  }
  //#endregion

  //#region Metodos Creacion
  addStore() {
    this.modal.open(this.modalCreateStore, {size: 'lg', windowClass: 'modal-dialog-xl'});
  }

  locationFilteredList() {
    const self = this;
    let o = self.locationFilterList;
    if (o) {
      for (let i of self.points) {
        o = o.filter((v) => {
          return v.siteLocationID !== i.siteLocationID;
        });
      }

      for (let i of self.tmpRouteList) {
        o = o.filter((v) => {
          return v.siteLocationID !== i.id;
        });
      }
    }

    return o ? o : 0;
  }
  deleteLocationSelected(locationId) {
    const self = this;
    self.tmpRouteList = self.tmpRouteList.filter((v) => {
      return v.id !== locationId;
    });
  }

  // listar site location
  selectedStore(storeId) {
    const self = this;
    self.filtersService.getListPointOfSales(storeId).then(
      function (response) {
        if (response) {
          const locationsFilter = [];
          for (let loca of self.filtersService.getVarResults()) {
            const newLoca = {
              name: loca.site_id + ' - ' + loca.location,
              siteLocationID: loca.siteLocationID
            };
            locationsFilter.push(newLoca);
          }
          self.locationFilterList = locationsFilter;
        }
      }
    );
  }

  addArray() {
    this.a++;
    const self = this;
    if (self.pointOfSalesId !== 0) {
      self.filtersService.getListPointOfSales(this.storeId).then(
        function (response) {
          if (response) {
            self.locationFilterList = self.filtersService.getVarResults();
            console.log(self.locationFilterList);
            for (let i = 0; i < self.locationFilterList.length; i++) {
              if (self.locationFilterList[i].siteLocationID === self.pointOfSalesId) {
                self.tmpRouteList.push({
                  'id': self.pointOfSalesId,
                  'name': self.locationFilterList[i].site_id + ': ' + self.locationFilterList[i].siteStore.name + ' - ' + self.locationFilterList[i].location
                });
              }
            }
          }
          self.pointOfSalesId = 0;
        }
      );
    }
  }

  ConfirmAddStore() {
    const self = this;
    if (self.tmpRouteList.length) {
      const divAdd = $('#DivAddStore');
      for (let a = 0; a < divAdd[0].children.length; a++) {
        const div = divAdd[0].children[a];
        if (self.routeID && self.routeID !== 0 && self.routeID !== '0') {
          self.arrayStore.push({ 'routeID': self.routeID, 'userId': self.userID, 'siteLocationID': div.value });
        } else {
          self.arrayStore.push({ 'userId': self.userID, 'siteLocationID': div.value });
        }
      }
      const request = JSON.stringify(JSON.parse(JSON.stringify(this.arrayStore)));
      this.updateRoutesManagement(request);
    }
    self.arrayStore.length = 0;
    self.pointOfSalesId = 0;
    self.storeId = 0;
    self.locationFilterList = [];
    self.tmpRouteList = [];
  }

  //#endregion

  //#region Metodos para eliminar
  delete(routeID, siteLocationID) {
    this.deleteRoute = {
      'routeID': routeID,
      'userId': parseInt(this.userID, 0),
      'siteLocationID': siteLocationID
    };
    this.modal.open(this.modalDeleteStore);
  }

  confirmDelete() {
    this.deleteRoutesManagement(this.deleteRoute);
    this.modal.dismissAll(this.modalDeleteStore);
  }

  cancel() {
    const self = this;
    self.modal.dismissAll(this.modalDeleteStore);
    self.arrayStore.length = 0;
    self.storeId = 0;
    self.pointOfSalesId = 0;
    self.locationFilterList = [];
    self.tmpRouteList = [];
  }
  // #endregion

  // Set Principal
  private setPrincipal = (routeID: number, siteLocationID: number) => {
    const self = this;
    const request = {
      'routeID': routeID,
      'userId': parseInt(this.userID, 0),
      'siteLocationID': siteLocationID
    };
    self.routeManagementService.setPrincipal(request).then(
      function (result) {
        self.getRoutesManagement(self.routeID);
        self.utilsService.debugConsole(self.routeManagementService.getVarResults());
      }
    );
  }

  private getListStoresByCountryID(countryID) {
    const self = this;
    // listar site stores
    self.filtersService.getListSiteStoresFilters([], [countryID]).then(
      function (response) {
        if (response) {
          self.storeFilterList = self.filtersService.getVarResults();
        }
      }
    );
  }

  private getRoutesManagement(request) {
    const self = this;
    self.points = [];
    if (request != null && request !== '0') {
      self.routeManagementService.getRoutesManagement(request).then(
        function (result) {
          self.data = self.routeManagementService.getVarResults();

          for (let a = 0; a < self.data.siteLocations.length; a++) {
            const point = self.data.siteLocations[a];
            self.points = [
              ...self.points,
              {
                lng: point.longitude,
                lat: point.latitude,
                name: point.fullName,
                location: point.location,
                siteLocationID: point.siteLocationID,
                select: true,
                routeID: self.routeID,
                principal: point.principal,
                siteID: point.site_id,
              }
            ];
          }
          self.ifDataExist = true;
          self.utilsService.debugConsole(self.points);
        }
      );
    } else {

    }
  }

  private deleteRoutesManagement(request) {
    const self = this;
    self.routeManagementService.deleteRoutesManagement(request).then(
      function (result) {
        self.getRoutesManagement(self.routeID);
        self.utilsService.debugConsole(self.routeManagementService.getVarResults());
      }
    );
  }

  private updateRoutesManagement(request) {
    const self = this;
    self.routeManagementService.updateRoutesManagement(request).then(
      function (result) {
        if (result.hasOwnProperty('user')) {
          if (self.routeID === 0 || self.routeID === '0') {
            self.routeID = result['user'].routeID;
            self.router.navigateByUrl('/route-management/show/' + self.userID + '/' + self.routeID);
          }
          self.routeID = result['user'].routeID;
          self.user = result['user'];
        }
        self.modal.dismissAll(self.modalCreateStore);
        self.getRoutesManagement(self.routeID);
        self.utilsService.debugConsole(result);
      }
    );
  }
  //#endregion

}
