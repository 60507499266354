/**
 * Created by Juan Vidales Cheil on 30/5/2019.
 */
export class Schedule {
	public workScheduleID: number; // 123
	public scheduleUserId: number; // 12312
	public siteLocationID: number; // 12312
	public creatorUserId: number; // 12312
	public workScheduleTypeID: number; // 12312
	public schedule_InitTime: string; // "2019-05-31T02:33:33.380Z"
	public schedule_EndTime: string; // "2019-05-31T02:33:33.380Z"
	public schedule_InitHour: string; // "02:33:33"
	public schedule_EndHour: string; // "02:33:33"
	public trainingTypeID: number;
	public trainingTopicID: number;
	public color: string = null;
	public top: string = null;
	public height: string = null;
	public count: number = null;

	constructor() {
		this.initalizeSchedule();
	}

	initalizeSchedule() {
		this.workScheduleID = null;
		this.scheduleUserId = null;
		this.siteLocationID = null;
		this.creatorUserId = null;
		this.workScheduleTypeID = null;
		this.schedule_InitTime = null;
		this.schedule_EndTime = null;
		this.schedule_InitHour = null;
		this.schedule_EndHour = null;
		this.trainingTypeID = null;
		this.trainingTopicID = null;
	}

	setSchedule(schedule) {
		this.workScheduleID = schedule.workScheduleID;
		this.scheduleUserId = schedule.scheduleUserId;
		this.siteLocationID = schedule.siteLocationID;
		this.creatorUserId = schedule.creatorUserId;
		this.workScheduleTypeID = schedule.workScheduleTypeID;
		this.schedule_InitTime = schedule.schedule_InitTime;
		this.schedule_EndTime = schedule.schedule_EndTime;
		this.trainingTypeID = schedule.trainingTypeID;
		this.trainingTopicID = schedule.trainingTopicID;
	}

	getJsonBase(): any {
		const jsonBase = {
			'scheduleUserId': this.scheduleUserId,
			'creatorUserId': this.creatorUserId,
			'workScheduleTypeID': this.workScheduleTypeID,
			'schedule_InitTime': this.schedule_InitTime,
			'schedule_EndTime': this.schedule_EndTime,
			'trainingTypeID':  this.trainingTypeID,
			'trainingTopicID':  this.trainingTopicID
		};
		return jsonBase;
	}

	getJsonCreateSchedule(): any {
		const json = this.getJsonBase();
		if (this.workScheduleTypeID === 1) {
			json['siteLocationID'] = this.siteLocationID;
		}
		return json;
	}
}