import { Injectable } from '@angular/core';
import { CustomHttpService } from './customHttp.service';
import { BaseServiceService } from './baseService.service';
import { DatePipe } from '@angular/common';
import * as $ from 'jquery';
import { UtilsService } from './utils.service';


@Injectable()
export class ReportSamplingAuditService extends BaseServiceService {
	private resultData: any;
	
	constructor(
		public customHttpService: CustomHttpService,
		public utilsService: UtilsService,
	) {
		super(customHttpService);
	}

	

	getReportSamplingAudit(datos) {
		$('.preloader-content').fadeIn();
		const self = this;
		return new Promise(
			resolve => {
				const datePipe = new DatePipe('es');
				// Get Info from 'v1/api/Reportes/GetReportPopExecution'
				self.customHttpService.httpPOST(true, true, 'v1/api/Reportes/GetSamplingAuditReport', datos).then(
					function (data) {
						if (data === false) {
							resolve(data);
						} else {
							self.resultData = data['sampligAuditReport'];

							let arrayID = [];
							for(let data of self.resultData){
								arrayID.push(data.merchandisingEvidenceID);
							}
							self.setVarResults(self.resultData);
							resolve(true);
						}

					}
				);
			}
		);
	}

	getExcelSamplingAudit(datos) {
		const self = this;
		let loggedUser = JSON.parse(localStorage.getItem('user'));
		return new Promise(
			resolve => {
				const datePipe = new DatePipe('es');
				self.customHttpService.httpPOST(true, true, 'v1/api/ReportesExcel/GetSamplingAuditReport', datos).then(
					function (data) {
						if (data === false) {
							resolve(data);
						} else {
							self.setVarResultsExcel(data['reportUrl'])
							resolve(true);
						}
					}
				);
			}
		);
	}

	downloadPdf(mechandisingID) {
		window.open(this.utilsService.getUrl() + 'v1/api/Mechandising/GenerarMerchanPDF/' + mechandisingID);
	}

}
