/**
 * Created by Juan Vidales Cheil on 30/5/2019.
 */
export class Segment {
    public segmentID: number; // 123
    public segment: string; // El nombre max 50
    public created_By: string; // El nombre de quien lo crea
    private created_Date: string; // "2019-05-31T02:33:33.380Z"
    private updated_Date: string; // "2019-05-31T02:33:33.380Z"

    constructor() {
        this.initalizeSegment();
    }

    initalizeSegment() {
        this.segmentID = null;
        this.segment = null;
        this.created_By = null;
        this.created_Date = null;
        this.updated_Date = null;
    }

    setSegment(segment) {
        this.segmentID = segment.regionId;
        this.segment = segment.segment;
        this.created_By = segment.created_By;
        this.created_Date = segment.created_Date;
        this.updated_Date = segment.updated_Date;
    }

    getJsonBase(): any {
        const jsonBase = {
            'segment': this.segment
        };
        return jsonBase;
    }

    getJsonCreate(): any {
        const json = this.getJsonBase();
        if (this.created_By !== null) { json['created_By'] = this.created_By; }
        return json;
    }

    getJsonUpdate(): any {
        const json = this.getJsonBase();
        if (this.created_Date !== null) { json['created_Date'] = this.created_Date; }
        if (this.created_By !== null) { json['created_By'] = this.created_By; }
        if (this.segmentID !== null) { json['segmentID'] = this.segmentID; }
        return json;
    }
}
