import { Injectable } from '@angular/core';
import { CustomHttpService } from './customHttp.service';
import { BaseServiceService } from './baseService.service';
import { DatePipe } from '@angular/common';
import * as $ from 'jquery';


@Injectable()
export class ReportStoreActivityService extends BaseServiceService {
	private resultData: any;
	
	constructor(
		public customHttpService: CustomHttpService,
	) {
		super(customHttpService);
	}


	getReportStoreActivity(datos) {
		const self = this;
		return new Promise(
			resolve => {
				const datePipe = new DatePipe('es');
			
				self.customHttpService.httpPOST(true, true, 'v1/api/Reportes/GetReportMarketPromotion', datos).then(
					function (data) {
						if (data === false) {
							resolve(data);
						
						} else {
						
							self.resultData = data['reportMarketPromotion'];
							self.setVarResults(self.resultData);
							resolve(true);
						}

					});
			}
		);
	}

	getExcelStoreActivity(datos) {
		const self = this;
		let loggedUser = JSON.parse(localStorage.getItem('user'));
		return new Promise(
			resolve => {
				const datePipe = new DatePipe('es');
				self.customHttpService.httpPOST(true, true, 'v1/api/ReportesExcel/GetReportMarketPromotion', datos).then(
					function (data) {
						if (data === false) {
							resolve(data);

						} else {
							self.setVarResultsExcel(data['reportUrl'])
							console.log(data['reportUrl'])
							resolve(true);
						}

					});
			}
		);
	}
}