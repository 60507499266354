import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../../../../services/utils.service';
import { ReportStoreActivityService } from '../../../../services/reportStoreActivity.service';
import * as format from 'date-fns/format';
 
@Component({
  selector: 'app-report-market-promotions',
  templateUrl: './index.component.html',
  styleUrls: []
})
export class ReportStoreActivityComponent implements OnInit {

  public title = 'ReportsStoreActivity';
  public ifDataExist = false;
  public ifExcelExist = true;
  public regionID = [];
  public cityID = [];
  public countryID = [];
  public data: any = [];
  public urlExcel: any;

  
  public tableTitles = [
    { name: 'year', label: 'Year', type: 'normal' },
    { name: 'month', label: 'Month', type: 'month' },
    { name: 'yearWeek', label: 'Week', type: 'normal' },
    { name: 'day', label: 'Day', type: 'normal' },
    { name: 'countryName', label:'Country', type:'normal'},
    { name: 'siteStoreName', label: 'Store', type: 'normal' },
    { name: 'siteLocationName', label: 'Location', type: 'normal' },
    { name: 'site_id', label: 'Site ID', type: 'normal' },
    { name: 'brandName', label:'Brand', type:'normal'},
    { name: 'category', label:'Category', type:'normal'},
    { name: 'type', label:'Type', type:'normal'},
    { name: 'subType', label:'Sub Type', type:'normal'},
    { name: 'price', label: 'Quantity', type: 'normal' },
    { name: 'model', label:'Model', type:'normal'},
    { name: 'comments', label: 'Comments', type: 'normal' },
    { name: 'photo1', label: 'Photo 1', type: 'image' },
    { name: 'photo2', label: 'Photo 2', type: 'image' },
    { name: 'photo3', label: 'Photo 3', type: 'image' },
    { name: 'photo4', label: 'Photo 4', type: 'image' },
    { name: 'photo5', label: 'Photo 5', type: 'image' },
    { name: 'photo6', label: 'Photo 6', type: 'image' },
    { name: 'userName', label: 'User Name', type: 'normal' },
    { name: 'created_Date', label: 'Created Date', type: 'date' },
  ];

  public optionsTable = {
    dataId: 'marketPromotionID',
    showData: false,
    actionShowData: '',
    updateData: true,
    actionUpdateData: 'update',
    deleteData: false,
    actionDeleteData: '',
    filterCountryZone: false,
    filterSiteLocation: false,
    filterSitestore: true,
    filterCity: true,
    filterCategory: true,
    filterDateRange: true,
    filterCountry: true,
    filterRegion: true,
    filterCedis: false,
    searchBtn: true,
    exportBtn: true,
    itemsPerPage: 5,
  };

  constructor(
    private reportStoreActivityService: ReportStoreActivityService,
    private utilsService: UtilsService
  ) { }

  ngOnInit() {
    const  self = this;
    const request = {};
    self.ifDataExist = true;
  }

  downloadExcelReport($event){
    const self = this;
    let request = {}
    request["listIds"] = $event.listIds;
    request["email"] = $event.email;
    this.getExcelStoreActivity(request);
  }

  filter(event) {
    const self = this;
    let request = {}
    if(event.start_Date && event.end_Date) {
      request = {
        'start_Date': event.start_Date,
        'end_Date': event.end_Date
      };
    } else return false;
    if(event.siteStoreId) {
      request['sitestores'] = [event.siteStoreId]
    } else if (event.cityId) {
      request['cities'] = [event.cityId]
    } else if (event.countryId) {
      request['countries'] = [event.countryId]
    } else if (event.regionId) {
      request['regions'] = [event.regionId]
    }
    if (event.categoryId) {
      request['categoryID'] = event.categoryId
    }
        
      this.getReportStoreActivity(request)
  }

  private getReportStoreActivity(request) {

    const self = this;
    self.reportStoreActivityService.getReportStoreActivity(request).then(
      function (result) {
        self.data = self.reportStoreActivityService.getVarResults();
        self.ifDataExist = true;
        self.urlExcel = true;
        self.utilsService.debugConsole(result);
      }
    );
  }

  private getExcelStoreActivity(request) {
    const self = this;
    self.reportStoreActivityService.getExcelStoreActivity(request).then(
      function (result) {
        let urlExcel = self.reportStoreActivityService.getVarResultsExcel();
        if( urlExcel.indexOf("http") == 0 ) {
            window.location.assign(urlExcel)
        } else {
          self.urlExcel = urlExcel;
        }
        self.utilsService.debugConsole(result);
      }
    );
  }


}