import { Component, OnInit, ViewChild, TemplateRef, Input, OnChanges } from '@angular/core';
import { NgbModal, } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-alert',
  templateUrl: './modal-alert.component.html',
  styleUrls: ['./modal-alert.component.css']
})
export class ModalAlertComponent implements OnInit, OnChanges {
  @ViewChild('modalAlert') modalAlert: TemplateRef<any>;
  @Input()  message: string;
  @Input()  type: string;
  @Input()  tittle: string;
  @Input() modalOpen = false;

  c = false;
  constructor(
    private modal: NgbModal,
  ) { }

  ngOnInit(): void {
    // console.log(this.type);
    // this.openModal();
  }

  ngOnChanges(): void {}

  openModal(): void {
    this.modal.open(this.modalAlert);
  }

  closeModal(): void {
    this.modal.dismissAll(this.modalAlert);
    // this.openModal();
  }
}
