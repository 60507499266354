import { Injectable } from '@angular/core';
import { CustomHttpService } from './customHttp.service';
import { BaseServiceService } from './baseService.service';

@Injectable()
export class PopByProductService extends BaseServiceService {
	private controller = 'PopByProducts';

	constructor(
		public customHttpService: CustomHttpService
	) {
		super(customHttpService);
		this.TAG = this.controller;
		this.varGet = 'list' + this.controller;
		this.varGetId = 'popByProduct';
	}

}
