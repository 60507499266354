import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../../../../services/utils.service';
import { ProductService } from '../../../../services/product.service';


@Component({
  selector: 'app-product',
  templateUrl: './index.component.html',
  styleUrls: []
})
export class ProductComponent implements OnInit {
  public title = 'Product';
  public ifDataExist = false;
  public tableTitles = [
    { name: 'category.category', label: 'Categoria', type: 'normal' },
    { name: 'model', label: 'Producto', type: 'normal' },
    { name: 'productGroup.productGroup', label: 'Grupo de Producto', type: 'normal' },
    { name: 'productType.type', label: 'Tipo de Producto', type: 'normal' },
    { name: 'modelCode', label: 'Model Code', type: 'normal' },
    { name: 'created_By', label: 'Creado por', type: 'normal' },
  ];
  public data: any = [];
  public optionsTable = {
    dataId: 'productID',
    showData: false,
    actionShowData: '',
    updateData: true,
    actionUpdateData: 'update',
    deleteData: false,
    actionDeleteData: '',
    filterCountryZone: false,
    filterSiteLocation: false,
    filterSitestore: false,
    filterCity: false,
    filterCountry: false,
    filterRegion: false,
    filterCedis: false,
    searchBtn: true,
    exportBtn: true,
  };

  constructor(
    private productService: ProductService,
    private utilsService: UtilsService
  ) { }

  ngOnInit() {
    this.getProduct();
  }

  private getProduct() {
    const self = this;
    self.productService.getResults().then(
      function (result) {
        self.data = self.productService.getVarResults();
        self.ifDataExist = true;
        self.utilsService.debugConsole(result);
      }
    );
  }
}