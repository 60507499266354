/**
 * Created by Juan Vidales Cheil on 30/5/2019.
 */
export class Region {
	public regionId: number; // 123
	public region: string; // El nombre max 25
	public status: number; // 1 or 0
	public created_By: string; // El nombre de quien lo crea
	private created_Date: string; // "2019-05-31T02:33:33.380Z"
	private updated_Date: string; // "2019-05-31T02:33:33.380Z"

	constructor() {
		this.initalizeRegion();
	}

	initalizeRegion() {
		this.regionId = null;
		this.region = null;
		this.status = null;
		this.created_By = null;
		this.created_Date = null;
		this.updated_Date = null;
	}

	setRegion(region) {
		this.regionId = region.regionId;
		this.region = region.region;
		this.status = region.status;
		this.created_By = region.created_By;
		this.created_Date = region.created_Date;
		this.updated_Date = region.updated_Date;
	}

	getJsonBase(): any {
		const jsonBase = {
			'region': this.region,
			'status': this.status
		};
		return jsonBase;
	}

	getJsonCreate(): any {
		const json = this.getJsonBase();
		if (this.created_By !== null) { json['created_By'] = this.created_By; }
		return json;
	}

	getJsonUpdate(): any {
		const json = this.getJsonBase();
		if (this.created_Date !== null) { json['created_Date'] = this.created_Date; }
		if (this.created_By !== null) { json['created_By'] = this.created_By; }
		if (this.regionId !== null) { json['regionId'] = this.regionId; }
		return json;
	}
}
