import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../../../../services/utils.service';
import { DocumentTypeService } from '../../../../services/documentType.service';

@Component({
	selector: 'app-index-document',
	templateUrl: './index.component.html',
	styleUrls: []
})

export class DocumentTypeComponent implements OnInit {
	public title = 'DocumentType';
	public ifDataExist = false;
	public tableTitles = [
        { name: 'documentType', label: 'Nombre', type: 'normal' },
		{ name: 'created_By', label: 'Creado por', type: 'normal' },
	];
	public data: any = [];
	public optionsTable = {
		dataId: 'documentTypeId',
		showData: false,
		actionShowData: '',                   
		updateData: true,
		actionUpdateData: 'update',
		deleteData: false,
		actionDeleteData: '',
		filterCountryZone: false,
		filterSiteLocation: false,
		filterSitestore: false,
		filterCity: false,
		filterCountry: false,
		filterRegion: false,
		filterCedis: false,
		searchBtn: true,
		exportBtn: true,
	};

	constructor(
		private documentTypeService: DocumentTypeService,
		private utilsService: UtilsService
	) {}

	ngOnInit() {
		this.getDocumentType();
	}

	private getDocumentType () {
		const self = this;	
		self.documentTypeService.getResults().then(
			function (result) {
				self.data = self.documentTypeService.getVarResults();
				console.log(self.data);
				self.ifDataExist = true;
				self.utilsService.debugConsole(result);
			}
		);
	}
}