import { Injectable } from '@angular/core';
import { CustomHttpService } from './customHttp.service';

@Injectable()
export class LoginService {
  constructor(
    private customHttpService: CustomHttpService
  ) {}

  postDataLogin(request) {
    const self = this;
    return new Promise(
      resolve => {
        self.customHttpService.httpPOST(true, true, '/Auth', null, request).then(
          function (response) {
            if (response === false) {
              resolve(response);
            }
            console.log(response);
            resolve(true);
          }
        );
      }
    );
  }

  passwordRecovery(email) {
    const self = this;
    const request = {
      email
    };
    return new Promise(
      resolve => {
        self.customHttpService.httpPOST(true, true, 'v1/api/Users/PasswordRecovery', null, request).then(
          (response) => {
            if (response === false) {
              resolve(response);
            }
            resolve(true);
          }
        );
      }
    );
  }

  changePassword(passwordReset, email, newPassword) {
    const self = this;
    const request = {
      email,
      passwordReset,
      newPassword
    };
    return new Promise(
      resolve => {
        self.customHttpService.httpPOST(true, true, 'v1/api/Users/ResetPassword', null, request).then(
          (response) => {
            if (response === false) {
              resolve(response);
            }
            resolve(true);
          }
        );
      }
    );
  }

}
