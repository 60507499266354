import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from '../../../../../services/utils.service';
import { MethodsUtilsService } from '../../../../../services/methodsUtils.service';
import { FormularioBaseComponent } from '../../../../utils/formularioBase.component';
import { CedisService } from '../../../../../services/cedis.service';
import { Cedis } from '../../../../../models/cedis';
import { Country } from '../../../../../models/country';
import { Region } from '../../../../../models/region';
import { CountryZone } from 'src/models/countryZone';
import { City } from 'src/models/city';

// Declare Vars
declare var $: any;

@Component ({
	selector: 'app-form-country',
	templateUrl: './form.component.html',
	styleUrls: []
})

export class FormCedisComponent extends FormularioBaseComponent implements OnInit {

	public page = 'Cedis';
	public title = 'Cedis.Create';
	public titleButton = 'Save';
	public region = new Region();
    public country = new Country();
	public countryZone = new CountryZone();
	public city = new City();
	public cedis = new Cedis();
	public regionID = null;

	constructor(
		private route: ActivatedRoute,
		private cedisService: CedisService,
		public utilsService: UtilsService,
		private router: Router,
		public methodsUtilsService: MethodsUtilsService,
	) {
		super(utilsService, methodsUtilsService);
	}

	ngOnInit() {
		const self = this;
		self.getRegionsList();
		self.param = self.route.params.subscribe(
			params => {
				if (params['id']) {
					self.title = 'Cedis.Update';
					self.titleButton = 'Update';
					self.utilsService.debugConsole(params['id']);
					self.getCedis(params['id']);
				}
			}
		);
	}

	private getCedis (id: any): void {
		const self = this;
		self.cedisService.getResult(id).then(
			function (response) {
				if (response === false) { return; }
				self.cedis.setCedis(self.cedisService.getVarResults());
				self.regionID = self.cedis.country.regionId;
				self.getListCountriesByRegion(self.regionID);
				self.getListCountryZonesFilters({ 'countries': [ self.cedis.countryID ]});
				self.getListCitiesFilters({ 'countries': [ self.cedis.countryID ], 'countriesZones': [] });
				self.getListSiteStoresFilters({ 'cities': [], 'countries': [ self.cedis.countryID ] });	
			}
		);
	}

	public selectedRegion(): void {
		const self = this;
        if (self.regionID != null) {
			self.getListCountriesByRegion(self.regionID);
        } else {
            self.cedis.countryID = null;
            self.cedis.countryZoneID = null;
        }
    }

    public selectedCountry(): void {
        const self = this;
        if (self.cedis.countryID != null) {
			self.getListCountryZonesFilters({ 'countries': [ self.cedis.countryID ]});
			self.getListCitiesFilters({ 'countries': [ self.cedis.countryID ], 'countriesZones': [] });
			self.getListSiteStoresFilters({ 'cities': [], 'countries': [ self.cedis.countryID ] });
		} else {
            self.cedis.countryZoneID = null;
        }
    }

	public saveUpdateCedis(): void {
		const self = this;
		self.cedis.created_By = self.utilsService.getUserId();
		if (self.cedis.cedisID !== null) {
			self.cedisService.putData(self.cedis.cedisID, self.cedis.getJsonUpdate()).then(
				function (response) {
					if (response === false) { return; }
					self.router.navigate(['/cedis']);
				}
			);
		} else {
			self.cedisService.postData(self.cedis.cedisID , self.cedis.getJsonCreate()).then(
				function (response) {
					if (response === false) { return; }
					self.router.navigate(['/cedis']);
				}
			);
}
	}

}
