import { Injectable } from '@angular/core';
import {
    NgbModal,
    NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';
import { Observable, from, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ModalComponent } from '../app/fragment/modal/modal.component';

@Injectable()
export class ModalService  {
    private controller = 'Users';

	constructor(
        private ngbModal: NgbModal,
	) {
		
	}

    confirm(   prompt = 'Really?', title = 'Confirm'
    ): Observable<boolean> {
        const modal = this.ngbModal.open(
            ModalComponent, { backdrop: 'static' });

        modal.componentInstance.prompt = prompt;
        modal.componentInstance.title = title;

        return from(modal.result).pipe(
            catchError(error => {
                console.warn(error);
                return of(undefined);
            })
        );
    }


   


}
