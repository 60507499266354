import { OnInit } from '@angular/core';
import { UtilsService } from '../../services/utils.service';
import { MethodsUtilsService } from '../../services/methodsUtils.service';
import { SegmentsService } from '../../services/segments.service';
import { PopTipologyService } from 'src/services/popTipology.service';
// Declares
declare var jquery: any;
declare var $: any;

export abstract class FormularioBaseComponent implements OnInit {

    public param: any;
    public regions: any;
    public isRegionsData = false;
    public categories: any;
    public isCategoriesData = false;
    public countries: any;
    public isCountriesData = false;
    public countriesZones: any;
    public isCountriesZonesData = false;
    public cities: any;
    public isCitiesData = false;
    public segments: any;
    public isSegmentsData = false;
    public siteStores: any;
    public isSiteStoresData = false;
    public channels: any;
    public isChannelsData = false;

    public rols: any;
    public isRolsData = false;

    public documentTypes: any;
    public isDocumentTypesData = false;

    public disconnectionTypes: any;
    public isDisconnectionTypeData = false;

    public isPopTipologiesData = false;
    public popTipologies: any;

    public isPopSosTypeData = false;
    public popSosTypes: any;

    public isGiveAwayTypesData = false;
    public giveAwayTypes: any;

    public sites: any;
    public user: any;
    public brands: any;
    public isBrandsData = false;
    public productTypes: any;
    public isProductTypesData = false;
    public productGroups: any;
    public isProductGroupsData = false;
    public listProductTypes: any;
    public isListProductTypesData = false;
    public listBrands: any;
    public isListBrandsData = false;
    public products: any;
    public isProductsData = false;
    public cedis: any = [];
    public isCedisData = false;
    public users: any = [];
    public isUsersData = false;
    public listUserRolID: any = [];

    constructor(
        public utilsService: UtilsService = null,
        public methodsUtilsService: MethodsUtilsService = null,
        public segmentsService: SegmentsService = null,
    ) { }

    ngOnInit() { }

    /** Obtiene las Regiones de la base de datos */
    public getRegionsList() {
        const self = this;
        self.methodsUtilsService.getListRegions().then(
            function (response) {
                if (response) {
                    self.regions = self.methodsUtilsService.getVarResults();
                    self.isRegionsData = true;
                }
            }
        );
    }

    /** Obtiene las Categorias de la base de datos */
    getListCategories() {
        const self = this;
        this.methodsUtilsService.getListCategories().then(
            function (response) {
                if (response) {
                    self.categories = self.methodsUtilsService.getVarResults();
                    self.isCategoriesData = true;
                }
            }
        );
    }

    /** Obtiene los Rols de la base de datos */
    getListRols() {
        const self = this;
        this.methodsUtilsService.getListRols().then(
            function (response) {
                if (response) {
                    self.rols = self.methodsUtilsService.getVarResults();
                    self.listUserRolID  =self.rols;
                    self.isRolsData = true;
                }
            }
        );
    }

    getListDisconnectionTypes() {
        const self = this;
        this.methodsUtilsService.getListDisconnectionTypes().then(
            function (response) {
                if (response) {
                    self.disconnectionTypes = self.methodsUtilsService.getVarResults();
                    self.isDisconnectionTypeData = true;
                }
            }
        );
    }

    /** Obtiene los Rols de la base de datos */
    getListDocumentTypes() {
        const self = this;
        this.methodsUtilsService.getListDocumentTypes().then(
            function (response) {
                if (response) {
                    self.documentTypes = self.methodsUtilsService.getVarResults();
                    self.isDocumentTypesData = true;
                }
            }
        );
    }



    /** Obtiene los Paises Asociados a una Region o Usuario de la base de datos */
    getListCountriesByRegion(regionId) {
        const self = this;
        this.methodsUtilsService.getListCountriesByRegion(regionId).then(
            function (response) {
                if (response) {
                    self.countries = self.methodsUtilsService.getVarResults();
                    self.isCountriesData = true;
                }
            }
        );
    }

    /** Obtiene las countryZones dependiendo de una lista de Countries */
    getListCountryZonesFilters(request) {
        const self = this;
        this.methodsUtilsService.getListCountryZonesFilters(request).then(
            function (response) {
                if (response) {
                    self.countriesZones = self.methodsUtilsService.getVarResults();
                    self.isCountriesZonesData = true;
                }
            }
        );
    }

    /** Obtiene las ciudades dependiendo de una lista de Filtros (countries or countriesZones) */
    getListCitiesFilters(request) {
        const self = this;
        this.methodsUtilsService.getListCitiesFilters(request).then(
            function (response) {
                if (response) {
                    self.cities = self.methodsUtilsService.getVarResults();
                    self.isCitiesData = true;
                }
            }
        );
    }

    /** Obtiene los segmentos de la base de datos */
    getListChannels() {
        const self = this;
        self.methodsUtilsService.getListChannels().then(
            function (response) {
                if (response) {
                    self.channels = self.methodsUtilsService.getVarResults();
                    self.isChannelsData = true;
                }
            }
        );
    }

    /** Obtiene los segmentos de la base de datos */
    getSegments() {
        const self = this;
        self.segmentsService.getResults().then(
            function (response) {
                if (response) {
                    self.segments = self.segmentsService.getVarResults();
                    self.isSegmentsData = true;
                }
            }
        );
    }

    /** Obtiene los SiteStores dependiendo de una lista de Filtros (countries or cities) */
    getListSiteStoresFilters(request) {
        const self = this;
        this.methodsUtilsService.getListSiteStoresFilters(request).then(
            function (response) {
                if (response) {
                    self.siteStores = self.methodsUtilsService.getVarResults();
                    self.isSiteStoresData = true;
                }
            }
        );
    }

    /** Obtiene los Usuarios dependiendo de una lista de Filtros (countries or cities) */
    getListUsersFilters(request) {
        const self = this;
        this.methodsUtilsService.getListUsersFilters(request).then(
            function (response) {
                if (response) {
                    self.users = self.methodsUtilsService.getVarResults();
                    self.isUsersData = true;
                }
            }
        );
    }

    /** Obtiene las Tiendas de la ruta del Usuario */
    getSites() {
        this.user = JSON.parse(sessionStorage.getItem('user'));
        this.sites = this.user.routeList;
    }

    /** Obtiene las Marcas por Ciudad de la base de datos */
    getBrandsByCountryID(countryID) {
        const self = this;
        this.methodsUtilsService.getBrandsByCountryID(countryID).then(
            function (response) {
                if (response) {
                    self.brands = self.methodsUtilsService.getVarResults();
                    self.isBrandsData = true;
                }
            }
        );
    }

    /** Obtiene los Tipos de Producto por Categoria de la base de datos */
    getProductTypes(id) {
        const self = this;
        this.methodsUtilsService.getProductTypes(id).then(
            function (response) {
                if (response) {
                    self.productTypes = self.methodsUtilsService.getVarResults();
                    self.isProductTypesData = true;
                }
            }
        );
    }

    /** Obtiene TODOS los grupos de Producto SIN FILTRAR  */
    getListProductGroups() {
        const self = this;
        self.methodsUtilsService.getListProductGroups().then(
            function (response) {
                if (response) {
                    self.productGroups = self.methodsUtilsService.getVarResults();
                    self.isProductGroupsData = true;
                }
            }
        );
    }


    /** Obtiene TODOS los Tipos de Producto SIN FILTRAR  */
    getListProductTypes() {
        const self = this;
        self.methodsUtilsService.getListProductTypes().then(
            function (response) {
                if (response) {
                    self.listProductTypes = self.methodsUtilsService.getVarResults();
                    self.isListProductTypesData = true;
                }
            }
        );
    }

    /** Obtiene TODOS los Tipos de marcas SIN FILTRAR  */
    getListBrands() {
        const self = this;
        self.methodsUtilsService.getListBrands().then(
            function (response) {
                if (response) {
                    self.listBrands = self.methodsUtilsService.getVarResults();
                    self.isListBrandsData = true;
                }
            }
        );
    }


    /** Obtiene los Productos Filtrados de la base de datos */
    getProducts(brandID: number, categoryID: number, productTypeID: number, siteLocationID: number) {
        const self = this;
        self.isProductsData = false;
        const request = {
            'brandID': brandID,
            'categoryID': categoryID,
            'productTypeID': productTypeID,
            'siteLocationID': siteLocationID
        };
        this.methodsUtilsService.getProducts(request).then(
            function (response) {
                if (response) {
                    self.products = self.methodsUtilsService.getVarResults();
                    self.isProductsData = true;
                }
            }
        );
    }

    getListProductsFilters(regionID: number, brandID: any, categoryID: any) {
        const self = this;
        self.isProductsData = false;
        const request = {
            'regions': [regionID],
        };
        if (regionID == null) request['regions'] = [];
        if (brandID != null) request['brandID'] = brandID;
        if (categoryID != null) request['categoryID'] = categoryID;
        this.methodsUtilsService.getListProductsFilters(request).then(
            function (response) {
                if (response) {
                    self.products = self.methodsUtilsService.getVarResults();
                    self.isProductsData = true;
                }
            }
        );
    }

    /** Obtiene los Cedis Filtrados de la base de datos */
    getCedisList(siteStoreID) {
        const request = {
            'siteStoreID': siteStoreID
        };
        const self = this;
        this.methodsUtilsService.getCedis(request).then(
            function (response) {
                if (response) {
                    self.cedis = self.methodsUtilsService.getVarResults();
                    self.isCedisData = true;
                }
            }
        );
    }

    getListPopTipologies() {
        const self = this;
        self.methodsUtilsService.getPopTipologies().then(
            function (response) {
                if (response) {
                    self.popTipologies = self.methodsUtilsService.getVarResults();
                    self.isPopTipologiesData = true;
                }
            }
        );
    }


    getListPopSosTypes() {
        const self = this;
        self.methodsUtilsService.getListPopSosTypes().then(
            function (response) {
                if (response) {
                    self.popSosTypes = self.methodsUtilsService.getVarResults();
                    self.isPopSosTypeData = true;
                }
            }
        );
    }


    getListGiveAwayTypes() {
        const self = this;
        self.methodsUtilsService.getListGiveAwayTypes().then(
            function (response) {
                if (response) {
                    self.giveAwayTypes = self.methodsUtilsService.getVarResults();
                    self.isGiveAwayTypesData = true;
                }
            }
        );
    }



    /** Metodos para modificar el tipo Currency */
    currencyInputKeyDown($event) {
        // tslint:disable-next-line:max-line-length
        if ($event.keyCode === 229 || $event.keyCode === 8 || $event.keyCode === 46 || ($event.target.value !== '' && $event.target.value <= 0)) {
            this.currencyInputChanged($event);
        }
        if (($event.keyCode >= 96 && $event.keyCode < 106) || ($event.keyCode >= 48 && $event.keyCode < 58)) {
            this.currencyInputChanged($event);
        }
    }

    currencyInputKeyUp($event) {
        if ($event.target.value !== '' && $event.target.value <= 0) { $event.target.value = 0; }
        return;
    }

    currencyInputChanged($event): void {
        if ($event.target.value !== null && $event.target.value <= 0) { $event.target.value = 0; }
        $event.target.value = $event.target.value.replace(/[$,]/g, '');
    }
}
