/**
 * Created by Juan Vidales Cheil on 30/5/2019.
 */
export class Channel {
  public chanelID: number; // 123
  public chanel: string; // El nombre max 15
  public active: boolean; // 1 or 0
  public created_By: string; // El nombre de quien lo crea
  private created_Date: string; // "2019-05-31T02:33:33.380Z"
  private updated_Date: string; // "2019-05-31T02:33:33.380Z"

  constructor() {
    this.initalizeChannel();
  }

  initalizeChannel() {
    this.chanelID = null;
    this.chanel = null;
    this.active = null;
    this.created_By = null;
    this.created_Date = null;
    this.updated_Date = null;
  }

  setChannel(channel) {
    this.chanelID = channel.chanelID;
    this.chanel = channel.chanel;
    this.active = channel.active;
    this.created_By = channel.created_By;
    this.created_Date = channel.created_Date;
    this.updated_Date = channel.updated_Date;
  }

  getJsonBase(): any {
    const jsonBase = {
      'chanel': this.chanel,
      'active': this.active
    };
    return jsonBase;
  }

  getJsonCreate(): any {
    const json = this.getJsonBase();
    if (this.created_By !== null) { json['created_By'] = this.created_By; }
    return json;
  }

  getJsonUpdate(): any {
    const json = this.getJsonBase();
    if (this.created_Date !== null) { json['created_Date'] = this.created_Date; }
    if (this.created_By !== null) { json['created_By'] = this.created_By; }
    if (this.chanelID !== null) { json['chanelID'] = this.chanelID; }
    return json;
  }
}
