
import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../../../../services/utils.service';
import { ReportPopRequestService } from '../../../../services/reportPopRequest.service';
import * as $ from 'jquery';

declare var $;
@Component({
  selector: 'app-pop-request',
  templateUrl: './index.component.html',
  styleUrls: []
})

export class PopRequestComponent implements OnInit {
  public title = 'ReportsPopRequest';
  public ifDataExist = false;
  public ifExcelExist=false;
  public regionID = [];
  public cityID = [];
  public countryID = [];
  public data: any = [];
  public urlExcel: any;

  public tableTitles = [
    { name: 'year', label: 'Year', type: 'number' },
    { name: 'month', label: 'Month', type: 'month' },
    { name: 'yearWeek', label: 'Week', type: 'number' },
    { name: 'country', label: 'Country', type: 'normal' },
    { name: 'siteStore', label: 'Store', type: 'normal' },
    { name: 'siteLocation', label: 'Location', type: 'normal' },
    { name: 'category', label: 'Category', type: 'normal' },
    { name: 'productGroup', label: 'Product Group', type: 'normal' },
    { name: 'productGroup', label: 'Product Type', type: 'normal' },
    { name: 'brand', label: 'Brand', type: 'normal' },
    { name: 'product', label: 'Product', type: 'normal' },
    { name: 'campaign', label: 'Campaign', type: 'number' },
    { name: 'cantidad', label: 'Quantity', type: 'number' },
    { name: 'comments', label: 'Comments', type: 'normal' },
    { name: 'fullName', label: 'User', type: 'normal' },
    { name: 'userName', label: 'User Name', type: 'normal' },
    { name: 'supervisors', label: 'Supervisor', type: 'normal' },
    { name: 'created_Date', label: 'Created Date', type: 'date' },
  ];

  public optionsTable = {
    dataId: 'displayPopRequestID',
    showData: false,
    actionShowData: '',
    updateData: false,
    actionUpdateData: 'update',
    deleteData: false,
    actionDeleteData: '',
    filterCountryZone: false,
    filterSiteLocation: false,
    filterSitestore: true,
    filterCity: true,
    filterDateRange: true,
    filterCategory: true,
    filterCountry: true,
    filterRegion: true,
    filterCedis: false,
    searchBtn: true,
    exportBtn: true,
    itemsPerPage: 5,
  };

  constructor(
    private reportPopRequestService: ReportPopRequestService,
    private utilsService: UtilsService
  ) { }

  ngOnInit() {
    const self = this;
    self.ifDataExist = true;

    const request = {};
  }

  downloadExcelReport($event){
    const self = this;
    let request = {}
    request["listIds"] = $event.listIds;
    request["email"] = $event.email;
    this.getReportPopRequestExcel(request);
  }

  filter(event) {
    const self = this;
    let request = {}
    if(event.start_Date && event.end_Date) {
      request = {
        'start_Date': event.start_Date,
        'end_Date': event.end_Date
      };
    } else return false;
    if(event.siteStoreId) {
      request['sitestores'] = [event.siteStoreId]
    } else if (event.cityId) {
      request['cities'] = [event.cityId]
    } else if (event.countryId) {
      request['countries'] = [event.countryId]
    } else if (event.regionId) {
      request['regions'] = [event.regionId]
    }
    if (event.categoryId) {
      request['categoryID'] = event.categoryId
    }
    this.getReportPopRequest(request)
  }

  private getReportPopRequest(request) {

    const self = this;
    self.reportPopRequestService.getReportPopRequest(request).then(
      function (result) {
        self.data = self.reportPopRequestService.getVarResults();
        self.ifDataExist = true;
        self.urlExcel = false;
        self.utilsService.debugConsole(result);
      },
      $('.alert-warning').alert()
    );
  }

  private getReportPopRequestExcel(request) {

    const self = this;
    self.reportPopRequestService.getReportPopRequestExcel(request).then(
      function (result) {
        let urlExcel = self.reportPopRequestService.getVarResults();
        if( urlExcel.indexOf("http") == 0 ) {
            window.location.assign(urlExcel)
        } else {
          self.urlExcel = urlExcel;
        }
        self.ifDataExist = true;
        self.utilsService.debugConsole(result);
      },
      $('.alert-warning').alert()
    );
  }
}
