import { Injectable } from '@angular/core';
import { CustomHttpService } from './customHttp.service';
import { BaseServiceService } from './baseService.service';

@Injectable()
export class StoresService extends BaseServiceService {
  private controller = 'SiteStores';

  constructor(
    public customHttpService: CustomHttpService
  ) {
    super(customHttpService);
    this.TAG = this.controller;
    this.varGet = 'list' + this.controller;
    this.varGetId = 'siteStore';
  }

  

  public getStoresByCountry(CountryID) {
    const self = this;
    return new Promise(
        resolve => {
            self.customHttpService.httpGET(true, true, 'v1/api/Generales/GetStoresByCountry/'+ CountryID).then(
                function (response) {
                    if (response === false) { resolve(response); }
                    self.setVarResults(response['countryZones']);
                    resolve(true);
                }
            );
        }
        
    );
  }
  
  
  public getStoresFilter (request) {
    const self = this;
    return new Promise(
        resolve => {
            self.customHttpService.httpPOST(true, true, 'v1/api/SiteStores/GetSiteStoresFilters', request).then(
                function (response) {
                    if (response === false) { resolve(response); }
                    self.setVarResults(response['listSiteStores']);
                    resolve(true);
                }
            );
        }
        
    );
  }
}
