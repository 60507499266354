import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { UtilsService } from '../../services/utils.service';
import { CustomHttpService } from '../../services/customHttp.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginService } from '../../services/login.service';
import { ModalAlertComponent } from '../components/modal-alert/modal-alert.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})

export class LoginComponent implements OnInit {
  username: string;
  password: string;
  // Generic Modal
  @ViewChild(ModalAlertComponent) modalAlert: ModalAlertComponent;
  public modalType = 'success';
  public modalTittle = 'Gracias';
  public modalMessage = '¡Hemos enviado un correo a ... con las instrucciones!';
  public openModal = false;
  // Password Recovery
  @ViewChild('modalPasswordRecovery') modalPasswordRecovery: TemplateRef<any>;
  public emailPR: string = null;

  // Inject HttpClient into your component or service.
  constructor(
    private router: Router,
    private utilsService: UtilsService,
    private customHttpService: CustomHttpService,
    private modal: NgbModal,
    private loginService: LoginService
  ) { }

  ngOnInit(): void {
    this.modal.dismissAll();
    if (sessionStorage.hasOwnProperty('token')) {
      // this.router.navigate(['home']);
    }
  }

  public loginUser = () => {
    const self = this;

    const request = {
      'userName': this.username,
      'email': this.username,
      'password': this.password
    };

    this.customHttpService.httpPOST(false, true, 'v1/api/Auth/LoginWebAdmin', request).then(
      function (data) {
        if (data === false) {
          return;
        } else {
          let getRoleId;
          let Sessiondata;
          self.utilsService.debugConsole(data);
          getRoleId = data.session.userAccess.userRolID;
          Sessiondata = {
            RoleIdClientSide: data.session.userAccess.userRolID,
            sessionIdClientSide: data.session.sesionID,
            tokenClientSide: data.session.token,
            userIdClientSide: data.session.userId,
            userAccessIdClientSide: data.session.userAccessId
          };
          if (getRoleId !== 1) {
            // self.utilsService.setLoggedIn(true);
            const obj = JSON.stringify(Sessiondata);
            sessionStorage.setItem('sessionData', obj);
            sessionStorage.setItem('session', data.session.sesionID);
            sessionStorage.setItem('token', data.session.token);
            sessionStorage.setItem('userId', data.session.userId);
            sessionStorage.setItem('userEmail', data.session.userAccess.email);
            sessionStorage.setItem('userName', data.session.userAccess.user.fullName);
            sessionStorage.setItem('userRolId', data.session.userAccess.userRolID);
            sessionStorage.setItem('userRolName', data.session.userAccess.userRole.level);
            localStorage.setItem('user', JSON.stringify(data['user']));
            localStorage.setItem('language', 'es');
            self.router.navigate(['home']);
          } else {
            self.utilsService.alert('Acceso denegado a promotores');
            // self.utilsService.showAchievement('Alerta', 'Acceso denegado a promotores', 'error_outline', 'danger');
          }

          // self.utilsService.StorageDelete();
        }
      }
    );
  }

  public openModalPasswordRecovery = () => {
    this.modal.open(this.modalPasswordRecovery);
  }

  public passwordRecovery = () => {
    const self = this;
    self.loginService.passwordRecovery(self.emailPR).then(
      (response) => {
        if (response) {
          // self.router.navigate(['password-recovery']);
          self.modalAlert.openModal();
          self.modalType = 'success';
          self.modalTittle  = 'Recuperación de contraseña';
          self.modalMessage = '¡Hemos enviado un correo a ' + self.emailPR + ' con las instrucciones para la recuperación de contraseña!';
          self.emailPR = null;
        }
      }
    );
  }

  public closeModalPR = () => {
    this.modal.dismissAll();
  }
}
