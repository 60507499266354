import { Region } from './region';
import { CountryZone } from './countryZone';
import { City } from './city';
import { Country } from './country';
import { Category } from './category';
import { UserRol } from './userRol';
import { DocumentType } from './documentType';
import { DisconnectionType } from './disconnectionType';
//import { Route } from '@angular/router';

export class User {
	public userId: number; 
	public active: boolean; 
	public regionID: number;
	public countryID: number;
	public countryZoneID: number;
	public cityID: number;
	public categoryID: number;
	public routeID:number;
	public userRolID: number;
	public document: string; 
	public documentTypeID: string;
	public email: string; 
	public start_Date: Date;
	public end_Date: Date;
	public fullName: string; 
	public gender: string;
	public lastName: string; 
	public name: string;  
	public phone1: string; 
	public phone2: string; 
	public photo: string; 
	public userName: string; 
	public level_Access: number;
	public supervisor: number;
	public disconnection_Type: string; 
	public personalEmail: string; 
	public created_By: string; 
	private created_Date: string; 
	private updated_Date: string; 
	public password: string;

	public region: Region;
	public country: Country;
	public countryZone: CountryZone;
	public city: City;
	public category: Category;
	public userRol: UserRol;
	public documentType: DocumentType;
	public disconnectionType: DisconnectionType;

	constructor() {
		this.initalizeUser();
	}

	initalizeUser() {
		this.userId = null;
		this.active = null;
		this.categoryID = null;
		this.cityID = null;
		this.countryID = null;
		this.countryZoneID = null;
		this.document = null;
		this.documentTypeID = null;
		this.email = null;
		this.end_Date = null;
		this.fullName = null; 
		this.gender = null;
		this.lastName = null;; 
		this.name = null; 
		this.phone1 = null;
		this.phone2 = null; 
		this.photo = null;
		this.regionID = null;
		this.routeID = null;
		this.start_Date = null;
		this.userName = null;
		this.level_Access = null;
		this.supervisor = null;
		this.disconnection_Type = null;
		this.userRolID = null;
		this.personalEmail = null;
		this.created_By = null;
		this.created_Date = null;
		this.updated_Date = null;
		this.password = null;
		this.region = new Region();
		this.country = new Country();
		this.countryZone = new CountryZone();
		this.city = new City();
		this.category = new Category();
		this.userRol = new UserRol();
		this.documentType = new DocumentType();
		this.disconnectionType = new DisconnectionType();
	}

	setUser(user) {
		this.userId = user.userId;
		this.active = user.active;
		this.categoryID = user.categoryID;
		this.cityID = user.cityID;
		this.countryID = user.countryID;
		this.countryZoneID = user.countryZoneID;
		this.document = user.document;
		this.documentTypeID = user.documentTypeID;
		this.email = user.email;
		this.end_Date = user.end_Date;
		this.fullName = user.fullName; 
		this.gender = user.gender;
		this.lastName = user.lastName; 
		this.name = user.name; 
		this.phone1 = user.phone1;
		this.phone2 = user.phone2; 
		this.photo = user.photo;
		this.regionID = user.regionId;
		this.routeID = user.routeId;
		this.start_Date = user.start_Date;
		this.userName = user.userName;
		this.level_Access = user.level_Access;
		this.supervisor = user.supervisor;
		this.disconnection_Type = user.disconnection_Type;
		this.userRolID = user.userRolID;
		this.personalEmail = user.personalEmail;
		this.created_By = user.created_By;
		this.created_Date = user.created_Date;
		this.updated_Date = user.updated_Date;
		this.password = user.password;
		if (user.hasOwnProperty('region') && user.region !== null) { this.region.setRegion(user.region); }
		if (user.hasOwnProperty('country') && user.country !== null) { this.country.setCountry(user.country); }
		if (user.hasOwnProperty('countryZone') && user.countryZone !== null) { this.countryZone.setCountryZone(user.countryZone); }
		if (user.hasOwnProperty('city') && user.city !== null) { this.city.setCity(user.city); }
		if (user.hasOwnProperty('category') && user.category !== null) { this.category.setCategory(user.category); }
		if (user.hasOwnProperty('userRol') && user.userRol !== null) { this.userRol.setUserRol(user.userRol); }
		if (user.hasOwnProperty('documentType') && user.documentType !== null) { this.documentType.setDocumentType(user.documentType); }
		if (user.hasOwnProperty('disconnectionType') && user.disconnectionType !== null) { this.disconnectionType.setDisconnectionType(user.disconnectionType); }

	}

	getJsonBase(): any {
		const jsonBase = {
			'active': this.active,
			'categoryID': this.categoryID ,
			'cityID': this.cityID,
			'countryID': this.countryID ,
			'countryZoneID': this.countryZoneID,
			'document': this.document ,
			'documentTypeID': this.documentTypeID,
			'email': this.email,
			'end_Date': this.end_Date,
			'fullName': this.fullName, 
			'gender': this.gender,
			'lastName': this.lastName, 
			'name': this.name, 
			'phone1': this.phone1,
			'phone2': this.phone2, 
			'photo': this.photo,
			'regionId': this.regionID,
			'routeID': this.routeID,
			'start_Date': this.start_Date,
			'userName': this.userName,
			'level_Access': this.level_Access,
			'supervisor': this.supervisor,
			'disconnection_Type': this.disconnection_Type,
			'userRolID': this.userRolID,
			'personalEmail': this.personalEmail,
			'password' : this.password,
						
		};
		return jsonBase;
	}

	getJsonCreate(): any {
		const json = this.getJsonBase();
		if (this.created_By !== null) { json['created_By'] = this.created_By; }
		return json;
	}

	getJsonUpdate(): any {
		const json = this.getJsonBase();
		if (this.created_Date !== null) { json['created_Date'] = this.created_Date; }
		if (this.updated_Date !== null) { json['updated_Date'] = this.updated_Date; }
		if (this.created_By !== null) { json['created_By'] = this.created_By; }
		if (this.userId !== null) { json['userId'] = this.userId; }
		return json;
	}
}
