import { Injectable } from '@angular/core';
import { CustomHttpService } from './customHttp.service';
import { BaseServiceService } from './baseService.service';

@Injectable()
export class ProductSiteStoreService extends BaseServiceService {
	private controller = 'ProductSiteStores';

	constructor(
		public customHttpService: CustomHttpService
	) {
		super(customHttpService);
		this.TAG = this.controller;
		this.varGet = 'listProductSiteStore';
		this.varGetId = 'productSiteStore';
	}

	getProductSiteStoresFilters(request){
		const self = this;
		return new Promise(
			resolve => {
				self.customHttpService.httpPOST(true, true, 'v1/api/ProductSiteStores/ProductSiteStoresFilters', request).then(
					function (response) {
						if (response === false) { resolve(response); }
						self.setVarResults(response['listProductSiteStore']);
						resolve(true);
					}
				);
			}
			
		);
	}

}
