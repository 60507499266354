import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../../../../services/utils.service';
import {RegionsService} from '../../../../services/regions.service';

@Component({
    selector: 'app-index-region',
    templateUrl: './index.component.html',
    styleUrls: []
})

export class RegionsComponent implements OnInit {

    public title = 'Regions';
    public ifDataExist = false;
    public tableTitles = [
        { name: 'region', label: 'Región', type: 'normal' },
        { name: 'status', label: 'Estado', type: 'status' },
        { name: 'created_By', label: 'Creado por', type: 'normal' },
        { name: 'created_Date', label: 'Fecha de Creación', type: 'dateTime' }
    ];
    
    public data: any = [];
    public optionsTable = {
        dataId: 'regionId',
        showData: false,
        actionShowData: '',
        updateData: true,
        actionUpdateData: 'update',
        deleteData: false,
        actionDeleteData: '',
        filterCountryZone: false,
        filterSiteLocation: false,
        filterSitestore: false,
        filterCity: false,
        filterCountry: false,
        filterRegion: false,
        filterCedis: false,
        searchBtn: true,
        exportBtn: true
    };

    constructor(
        private regionsService: RegionsService,
        private utilsService: UtilsService
    ) {}

    ngOnInit() {
        this.getRegions();
    }

    private getRegions () {
        const self = this;
        self.regionsService.getResults().then(
            function (result) {
                self.data = self.regionsService.getVarResults();
                self.ifDataExist = true;
                self.utilsService.debugConsole(result);
            }
        );
    }

}
