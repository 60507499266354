import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../../../../services/utils.service';
import { ReportSelloutService } from 'src/services/reportSelloutservice';
import { DatePipe } from '@angular/common';
import * as $ from 'jquery';

@Component({
  selector: 'app-report-market-promotions',
  templateUrl: './index.component.html',
  styleUrls: []
})
export class ReportSelloutComponent implements OnInit {

  public title = 'ReportSellout';
  public ifDataExist = true;
  public ifExcelExist = true;
  public regionID = [];
  public cityID = [];
  public countryID = [];
  public data: any = [];
  public urlExcel: any;

  public tableTitles = [
    { name: 'year', label: 'Year', type: 'number' },
    { name: 'month', label: 'Month', type: 'month' },
    { name: 'week', label: 'Week', type: 'number' },
    { name: 'country', label:'Country', type:'normal'},
    { name: 'store', label:'Store', type:'normal'},
    { name: 'location', label:'Location', type:'normal'},
    { name: 'siteID', label:'Site ID', type:'normal'},
    { name: 'category', label:'Category', type:'normal'},
    { name: 'productGroup', label:'Product Group', type:'normal'},
    { name: 'productType', label: 'Product Type', type: 'normal' },
    { name: 'brand', label: 'Brand', type: 'normal' },
    { name: 'productName', label: 'Product', type: 'normal' },
    { name: 'soldUnits', label: 'Sold Units', type: 'number' },
    { name: 'dollarPriceBI', label: 'Dollar Price', type: 'number' },
    { name: 'localPriceBI', label: 'Local Price', type: 'number' },
    { name: 'pricePromoter', label: 'Price Promoter', type: 'numbrt' },
    { name: 'reportDate', label: 'Report Date', type: 'date' },
    { name: 'userFullname', label: 'User Name', type: 'normal' },
    { name: 'userAccessName', label: 'User Acess Name', type: 'normal' },
    //{ name: 'supervisors', label: 'Supervisor', type: 'normal' },
    { name: 'createDate', label: 'Fecha Creación', type: 'date' },
  ];

  public optionsTable = {
    dataId: 'SelloutID',
    showData: false,
    actionShowData: '',
    updateData: false,
    actionUpdateData: 'update',
    deleteData: false,
    filterDateRange: true,
    actionDeleteData: '',
    filterCountryZone: false,
    filterSiteLocation: true,
    filterSitestore: 'required',
    filterCity: false,
    filterCategory: true,
    filterCountry: true,
    filterRegion: 'required',
    filterCedis: false,
    searchBtn: true,
    exportBtn: true,
    itemsPerPage: 5,
  };

  constructor(
    private reportSelloutService: ReportSelloutService,
    private utilsService: UtilsService
  ) { }

  ngOnInit() {
  }

  downloadExcelReport(event){
    let request = {}
    let datePipe = new DatePipe('es');
    const self = this;
    if(event.start_Date && event.end_Date) {
      request = {
        'start_Date': datePipe.transform(event.start_Date, 'yyyy-MM-dd'),
        'end_Date': datePipe.transform(event.end_Date, 'yyyy-MM-dd')
      };
    } else return false;
    if(event.siteStoreId) {
      request['SiteStoreID'] = event.siteStoreId
    } else if (event.countryId) {
      request['Countries'] = [event.countryId]
    } else if (event.regionId) {
      request['RegionID'] = event.regionId
    }
    if (event.categoryId) {
      request['CategoryID'] = event.categoryId
    }
    //self.getReportExcelSellout(request);
  }

  filter(event) {
    let request = {}
    let datePipe = new DatePipe('es');
    const self = this;
    if(event.start_Date && event.end_Date) {
      request = {
        'start_Date': datePipe.transform(event.start_Date, 'yyyy-MM-dd'),
        'end_Date': datePipe.transform(event.end_Date, 'yyyy-MM-dd')
      };
    } else return false;
    if(event.siteStoreId) {
      request['SiteStoreID'] = event.siteStoreId
    } else if (event.countryId) {
      request['Countries'] = [event.countryId]
    } else if (event.regionId) {
      request['RegionID'] = event.regionId
    }
    if (event.categoryId) {
      request['CategoryID'] = event.categoryId
    }
    self.getReportSellout(request);
  }

  private getReportSellout(request) {
    const self = this;
    request["format"]="json";
    self.reportSelloutService.getReportSellout(request).then(
      function (result) {
        self.data = self.reportSelloutService.getVarResults();
        self.ifDataExist = true;
        self.utilsService.debugConsole(result);
      }
    );
  }

  private getReportExcelSellout(request) {
    const self = this;
    request["format"]="excel";
    self.reportSelloutService.getReportSellout(request).then(
      function (result) {
        let urlExcel = self.reportSelloutService.getVarResultsExcel();
        if(urlExcel.indexOf("http") == 0 ) {
            window.location.assign(urlExcel)
        } else {
            self.urlExcel = urlExcel;
        }
        self.utilsService.debugConsole(result);
      }
    );
  }
}