/**
 * Created by Juan Vidales Cheil on 30/5/2019.
 */
export class Category {
	public categoryID: number; // 123
	public category: string; // El nombre max 5
	public webActive: boolean; // true or false
	public created_By: string; // El nombre de quien lo crea
	private created_Date: string; // "2019-05-31T02:33:33.380Z"
	private updated_Date: string; // "2019-05-31T02:33:33.380Z"

	constructor() {
		this.initalizeCategory();
	}

	initalizeCategory() {
		this.categoryID = null;
		this.category = null;
		this.webActive = null;
		this.created_By = null;
		this.created_Date = null;
		this.updated_Date = null;
	}

	setCategory(category) {
		this.categoryID = category.categoryID;
		this.category = category.category;
		this.webActive = category.webActive;
		this.created_By = category.created_By;
		this.created_Date = category.created_Date;
		this.updated_Date = category.updated_Date;
	}

	getJsonBase(): any {
		const jsonBase = {
			'category': this.category,
			'webActive': this.webActive
		};
		return jsonBase;
	}

	getJsonCreate(): any {
		const json = this.getJsonBase();
		if (this.created_By !== null) { json['created_By'] = this.created_By; }
		return json;
	}

	getJsonUpdate(): any {
		const json = this.getJsonBase();
		if (this.created_Date !== null) { json['created_Date'] = this.created_Date; }
		if (this.created_By !== null) { json['created_By'] = this.created_By; }
		if (this.categoryID !== null) { json['categoryID'] = this.categoryID; }
		return json;
	}
}
