import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CustomHttpService } from './customHttp.service';
import { BaseServiceService } from './baseService.service';
import { DatePipe } from '@angular/common';
import * as $ from 'jquery';
import { UtilsService } from './utils.service';


@Injectable()
export class PdfService extends BaseServiceService {
	private resultData: any;

	constructor(
		public customHttpService: CustomHttpService,
		public utilsService : UtilsService,
		private http: HttpClient,
	) {
		super(customHttpService);
	}


	getPdfSamplingAudit(mechandisingID) {
		
	}

}
