import { Country } from './country';
import { Category } from './category';
import { GiveAwayTypes } from './giveAwayTypes';
import { SiteStores } from './stores';

export class GiveAwayCampaigns {
    public giveAwayCampaignID: number;
    public categoryID: number;
    public countryID: number;
    public campaignName: string;
    public giveAwayTypeID: number;
    public siteStoreID: number;
    public category: Category;
    public country: Country;
    public giveAwayType: GiveAwayTypes;
    public siteStore: SiteStores;
    public created_By: string;
    private created_Date: string; 
    private updated_Date: string;

    constructor() {
        this.initalizeGiveAwayCampaign();
    }

    initalizeGiveAwayCampaign() {
        this.giveAwayCampaignID = null;
        this.campaignName = null;
        this.categoryID = null;
        this.countryID = null;
        this.campaignName = null;
        this.giveAwayTypeID = null;
        this.siteStoreID = null;
        this.category = new Category();
        this.country = new Country();
        this.giveAwayType = new GiveAwayTypes();
        this.siteStore = new SiteStores();
        this.created_By = null;
        this.created_Date = null;
        this.updated_Date = null;
    }

    setGiveAwayCampaigns(giveAwayCampaigns) {
        this.giveAwayCampaignID = giveAwayCampaigns.giveAwayCampaignID;
        this.campaignName = giveAwayCampaigns.campaignName;
        this.categoryID = giveAwayCampaigns.categoryID;
        this.countryID = giveAwayCampaigns.countryID;
        this.giveAwayTypeID = giveAwayCampaigns.giveAwayTypeID;
        this.siteStoreID = giveAwayCampaigns.siteStoreID;
        this.created_By = giveAwayCampaigns.created_By;
        this.created_Date = giveAwayCampaigns.created_Date;
        this.updated_Date = giveAwayCampaigns.updated_Date;
        if (giveAwayCampaigns.hasOwnProperty('siteStore') && giveAwayCampaigns.siteStore !== null) { this.siteStore.setSiteStore(giveAwayCampaigns.siteStore); }
        if (giveAwayCampaigns.hasOwnProperty('country') && giveAwayCampaigns.country !== null) { this.country.setCountry(giveAwayCampaigns.country); }
        if (giveAwayCampaigns.hasOwnProperty('category') && giveAwayCampaigns.category !== null) { this.category.setCategory(giveAwayCampaigns.category); }
        if (giveAwayCampaigns.hasOwnProperty('giveAwayType') && giveAwayCampaigns.giveAwayType !== null) { this.giveAwayType.setGiveAwayType(giveAwayCampaigns.giveAwayType); }
    }

    getJsonBase(): any {
        const jsonBase = {
            'campaignName' : this.campaignName,
            'categoryID' : this.categoryID,
            'countryID' : this.countryID,
            'giveAwayTypeID' : this.giveAwayTypeID,
            'siteStoreID' : this.siteStoreID
        };
        return jsonBase;
    }

    getJsonCreate(): any {
        const json = this.getJsonBase();
        if (this.created_By !== null) { json['created_By'] = this.created_By; }
        return json;
    }

    getJsonUpdate(): any {
        const json = this.getJsonBase();
        if (this.created_Date !== null) { json['created_Date'] = this.created_Date; }
        if (this.created_By !== null) { json['created_By'] = this.created_By; }
        if (this.giveAwayCampaignID !== null) { json['giveAwayCampaignID'] = this.giveAwayCampaignID; }
        return json;
    }
}