import { Country } from './country';
import { CountryZone } from './countryZone';

export class City {
  public cityID: number;
  public countryZoneID: number;
  public countryID: number;
  public cityName: string;
  public active: boolean;
  public created_By: string;
  public created_Date: Date;
  public updated_Date: Date;
  public country: Country;
  public countryZone: CountryZone;

  constructor() {
    this.initalizeCities();
  }

  initalizeCities() {
    this.cityID = null;
    this.countryZoneID = null;
    this.countryID = null;
    this.cityName = null;
    this.active = null;
    this.created_By = null;
    this.created_Date = null;
    this.updated_Date = null;
    this.country = new Country();
    this.countryZone = new CountryZone();
  }

  setCity(city) {
    this.cityID = city.cityID;
    this.countryZoneID = city.countryZoneID;
    this.countryID = city.countryID;
    this.cityName = city.cityName;
    this.active = city.active;
    this.created_By = city.created_By;
    this.created_Date = city.created_Date;
    this.updated_Date = city.updated_Date;
    if (city.hasOwnProperty('country') && city.country !== null) { this.country.setCountry(city.country); }
    if (city.hasOwnProperty('countryZone') && city.countryZone !== null) { this.countryZone.setCountryZone(city.countryZone); }
  }

  getJsonBase(): any {
    const jsonBase = {
      'countryZoneID': this.countryZoneID,
      'countryID': this.countryID,
      'cityName': this.cityName,
      'active': this.active
    };
    return jsonBase;
  }

  getJsonCreate(): any {
    const json = this.getJsonBase();
    if (this.created_By !== null) { json['created_By'] = this.created_By; }
    return json;
  }

  getJsonUpdate(): any {
    const json = this.getJsonBase();
    if (this.created_Date !== null) { json['created_Date'] = this.created_Date; }
    if (this.created_By !== null) { json['created_By'] = this.created_By; }
    if (this.cityID !== null) { json['cityID'] = this.cityID; }
    return json;
  }
}

