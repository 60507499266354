import {Pipe} from "@angular/core";

/**
 * A simple string filter, since Angular does not yet have a filter pipe built in.
 */
@Pipe({
    name: 'scheduleFilter'
})
export class ScheduleFilter {

    transform(itemList: string[], searchStr: string){ 
        if (itemList.length === 0 || searchStr === '') { 
            return itemList; 
        } 
        
        return itemList.filter((item) => { 
            if (item["fullName"]) {
                return item["fullName"].toLowerCase().indexOf(searchStr.toLowerCase()) !== -1;
            }
            return false
        }); 
    }
}