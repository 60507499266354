import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../../../../../services/utils.service';
import { ProductSiteStoreService } from '../../../../../services/productSiteStore.service';

@Component({
    selector: 'app-index-product-types',
    templateUrl: './index.component.html',
    styleUrls: []
})

export class ProductSiteStoreComponent implements OnInit {

    public title = 'ProductSiteStore';
    public ifDataExist = false;
    public tableTitles = [
        { name: 'regionName', label: 'Región', type: 'normal' },
        { name: 'countryName', label: 'País', type: 'normal' },
        { name: 'productName', label: 'Modelo', type: 'normal' },
        { name: 'code', label: 'Código', type: 'normal' },
        { name: 'brandName', label: 'Brand', type: 'normal' },
        { name: 'siteStoreName', label: 'Site Store', type: 'normal' },
        { name: 'local_Price', label: 'Local Price', type: 'number' },
        { name: 'dollar_Price', label: 'Dollar Price', type: 'number' },
        { name: 'from_Date', label: 'From Date', type: 'date' },
        { name: 'to_Date', label: 'To Date', type: 'date' },
        { name: 'status', label: 'Estatus', type: 'active' },
        //{ name: 'created_By', label: 'Creado por', type: 'normal' },
        //{ name: 'created_Date', label: 'Fecha de Creación', type: 'dateTime' }
    ];

    public data: any = [];
    public optionsTable = {
        dataId: 'productSiteStoreID',
        showData: false,
        actionShowData: '',
        updateData: true,
        actionUpdateData: 'update',
        deleteData: false,
        actionDeleteData: '',
        filterCountryZone: false,
        filterSiteLocation: false,
        filterSitestore: 'required',
        filterCity: false,
        filterCountry: 'required',
        filterRegion: true,
        filterCedis: false,
        searchBtn: true,
        exportBtn: true
    };

    constructor(
        private productSiteStoreService: ProductSiteStoreService,
        private utilsService: UtilsService
    ) {}

    ngOnInit() {
       
    }

    filter(event) {
		let request = {
            'sitestores': (event.siteStoreId) ? [event.siteStoreId] : [],
            'cities': (event.cityId) ? [event.cityId] : [],
            'countries': (event.countryId) ? [event.countryId] : [],
        }
		this.getProductSiteStores(request);
	}

    private getProductSiteStores(request) {

        const self = this;
        self.productSiteStoreService.getProductSiteStoresFilters(request).then(
            function (result) {
                self.data = self.productSiteStoreService.getVarResults();
                self.ifDataExist = true;
                self.utilsService.debugConsole(result);
            }
        );
    }

}
