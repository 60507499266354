import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../../../../services/utils.service';
import { CountryZonesService } from '../../../../services/countryZones.service';

@Component({
    selector: 'app-index-country-zone',
    templateUrl: './index.component.html',
    styleUrls: []
})

export class CountryZonesComponent implements OnInit {
    public title = 'CountryZones';
    public ifDataExist = false;
    public tableTitles = [
        { name: 'zoneName', label: 'Zona', type: 'normal' },
        //{ name: 'country.country', label: 'País', type: 'normal' },
        { name: 'active', label: 'Estado', type: 'status' },
    ];
    public data: any = [];
    public optionsTable = {
        dataId: 'countryZoneID',
        showData: false,
        actionShowData: '',
        updateData: true,
        actionUpdateData: 'update',
        deleteData: false,
        actionDeleteData: '',
        filterCountryZone: false,
        filterSiteLocation: false,
        filterSitestore: false,
        filterCity: false,
        filterCountry: true,
        filterRegion: true,
        filterCedis: false,
        searchBtn: true,
        exportBtn: true
    };

    constructor(
        private countryZoneService: CountryZonesService
    ) {}

    ngOnInit() {

    }

    filter(event) {
		let request = {
			'countries': (event.countryId) ? [event.countryId] : [],
		};
		this.getZones(request);
	}

    private getZones (request) {
        const self = this;
        self.countryZoneService.getListCountryZones(request).then(
            function (result) {
                self.data = self.countryZoneService.getVarResults();
                self.ifDataExist = true;
            }
        );
    }
}