import { AfterViewInit, Component, OnInit } from '@angular/core';
import { CmsGeneralesService } from '../../../../services/cmsGenerales.service';
import { UtilsService } from '../../../../services/utils.service';
import * as $ from 'jquery';
import { DatePipe } from '@angular/common';
import { ScheduleService } from '../../../../services/schedule.service';


@Component({
	selector: 'app-user-schedule',
	templateUrl: './user-schedule.component.html',
	styleUrls: []
})

export class UserScheduleComponent implements OnInit {
	public title = 'UserScheduleReport';
	public ifDataExist = false;
	private datePipe = new DatePipe('es');
	
	public filter = {
		filterSitestore: true,
		filterCity: true,
		filterCountry: true,
		filterRegion: true,
	};

	constructor(
		private cmsGeneralesService: CmsGeneralesService,
		private utilsService: UtilsService,
		private scheduleService: ScheduleService,
	) {}

	ngOnInit() {
		
	}

}
