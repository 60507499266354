import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
declare var $;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: []
})
export class HeaderComponent implements OnInit {
  public name = '';
  public email = 'test@cheil.com';
  private user = JSON.parse(localStorage.getItem('user'));
  private userID: any;
  public showMenu = false;
  public urlImg = '/assets/images/profile.png';
  private rol: any;
  public rolName: any;

  public title = ''; // 'Web Supervisores MSO';

  constructor(
    private router: Router
  ) { }

  ngOnInit() {

    this.email = this.user.email; // ['email'];
    this.userID = this.user['usuarioID'];
    if (localStorage.getItem('user')) {
      this.urlImg = this.user['photo'];
      this.rol = sessionStorage.getItem('userRolId');
      this.rolName = sessionStorage.getItem('userRolName');
      this.name = sessionStorage.getItem('userName');

      this.showMenu = true;
    } else {
      this.showMenu = false;
    }
    // this.utilsService.loadScript('./assets/Customjs/default.js');
  }

  public logout() {
    sessionStorage.clear();
    this.router.navigate(['login']);
  }
  public menuHide() {
    $('.sidebar').toggleClass('mini-menu');
    $('.content').toggleClass('no-padding-left');
  }

  public menu() {
    console.log('click');
    $('.m-menu').toggleClass('active');
    $('#leftsidebar').toggleClass('active');
  }
}
