import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from '../../../../../services/utils.service';
import { MethodsUtilsService } from '../../../../../services/methodsUtils.service';
import { FormularioBaseComponent } from '../../../../utils/formularioBase.component';
import { CampaignService } from '../../../../../services/campaign.service';
import { Campaign } from '../../../../../models/campaign';

@Component({
  selector: 'app-form-campaing',
  templateUrl: './form.component.html',
  styleUrls: []
})
export class FormOptionsCampaingComponent extends FormularioBaseComponent implements OnInit {

  public page = 'Campaing';
  public title = 'Campaing.Create';
  public campaign = new Campaign();
  public titleButton = 'Save';
  public regionId = null;

  constructor(
		private route: ActivatedRoute,
		private campaignService: CampaignService,
		private router: Router,
		public utilsService: UtilsService,
		public methodsUtilsService: MethodsUtilsService,
	) {
		super(utilsService, methodsUtilsService);
	}

  ngOnInit() {
    const self = this;
    self.getRegionsList();
    self.getListCategories();
		self.param = self.route.params.subscribe(
			params => {
				if (params['id']) {
					self.title = 'Campaing.Update';
					self.titleButton = 'Update';
					self.utilsService.debugConsole(params['id']);
					self.getCampaing(params['id']);
				}
			}
		);
  }
  
  private getCampaing (id: any): void {
    const self = this;
    self.getListCategories();
    self.getRegionsList();
		self.campaignService.getResult(id).then(
			function (response) {
				if (response === false) { return; }
        self.campaign.setCampaign(self.campaignService.getVarResults());
        self.regionId = self.campaign.country.regionId;
        self.selectedRegion();
        self.selectedCountry();
			}
		);
  }

  public selectedRegion(): void {
    const self = this;
    if (self.regionId != null) {
        self.getListCountriesByRegion(self.regionId);
    } else {
        self.campaign.countryID = null;
    }
  }

  public selectedCountry(): void {
      const self = this;
      if (self.campaign.countryID != null) {
          self.getListSiteStoresFilters({ 'cities': [], 'countries': [ self.campaign.countryID ] });
      }
  }

  public saveUpdateCampaign(): void {
		const self = this;
		self.campaign.created_By = self.utilsService.getUserId();
		if (self.campaign.campaignID !== null) {
			self.campaignService.putData(self.campaign.campaignID, self.campaign.getJsonUpdate()).then(
				function (response) {
					if (response === false) { return; }
					self.router.navigate(['/option-campaign']);
				}
			);
		} else {
			self.campaignService.postData(self.campaign.getJsonCreate()).then(
				function (response) {
					if (response === false) { return; }
					self.router.navigate(['/option-campaign']);
				}
			);
		}
	}
}
