import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from '../../../../../services/utils.service';
import { MethodsUtilsService } from '../../../../../services/methodsUtils.service';
import { FormularioBaseComponent } from '../../../../utils/formularioBase.component';
import { ReportPopExecuteService } from '../../../../../services/reportPopExecute.service';
import { PopExecute } from '../../../../../models/popExecuteImplementation';

// Declare Vars
declare var $: any;

@Component({
  selector: 'app-form-pop',
  templateUrl: './form.component.html',
  styleUrls: []
})

export class FormPopExecuteComponent extends FormularioBaseComponent implements OnInit {

  public page = 'PopExecute';
  public title = 'PopExecute.Create';
  public titleButton = 'Save';
  public PopExecute = new PopExecute();

  constructor(
    private route: ActivatedRoute,
    private reportPopExecuteService: ReportPopExecuteService,
    private router: Router,
    public utilsService: UtilsService,
    public methodsUtilsService: MethodsUtilsService,
  ) {
    super(utilsService, methodsUtilsService);
  } 

  ngOnInit() {
    const self = this;
    self.param = self.route.params.subscribe(
      params => {
        if (params['id']) {
          self.title = 'PopExecute.Update';
          self.titleButton = 'Update';
          self.utilsService.debugConsole(params['id']);
          self.getPopExecute(params['id']);
        }
      }
    );
  }

  private getPopExecute(id: any): void {
    const self = this;
    self.reportPopExecuteService.getResult(id).then(
      function (response) {
        if (response === false) { return; }
        self.PopExecute.setPopExecute(self.reportPopExecuteService.getVarResults());
      }
    );
  }

  public saveUpdatePopExecute(): void {
    const self = this;
    self.PopExecute.Created_By = self.utilsService.getUserId();
    if (self.PopExecute.PopExecutionImplementationID !== null) {
      self.reportPopExecuteService.putData(self.PopExecute.PopExecutionImplementationID, self.PopExecute.getJsonUpdate()).then(
        function (response) {
          if (response === false) { return; }
          self.router.navigate(['/reports-pop-execute']);
        }
      );
    } else {
      self.reportPopExecuteService.postData(self.PopExecute.getJsonCreate()).then(
        function (response) {
          if (response === false) { return; }
          self.router.navigate(['/reports-pop-execute']);
        }
      );
    }
  }

}

