import { Injectable } from '@angular/core';
import { CustomHttpService } from './customHttp.service';
import { BaseServiceService } from './baseService.service';
import { DatePipe } from '@angular/common';
import { Question } from 'src/models/question';
declare var $: any;

@Injectable()
export class SurveyService extends BaseServiceService {
    private controller = 'Surveys';

	constructor(
		public customHttpService: CustomHttpService
	) {
		super(customHttpService);
		this.TAG = this.controller;
		this.varGet = 'list' + this.controller;
		this.varGetId = 'survey';
	}

	public getSurveys(request) {
		const self = this;
		return new Promise(
			resolve => {
				self.customHttpService.httpPOST(true, true, 'v1/api/Surveys/GetSurveyFilters', request).then(
					function (response) {
						if (response === false) { resolve(response); }
						self.setVarResults(response['listSurveys']);
						resolve(true);
					}
				);
			}
			
		);
	}

	getSurveyByID(SurveyID){
		const self = this;
		return new Promise(
			resolve => {
				self.customHttpService.httpGET(true, true, 'v1/api/Surveys/'+SurveyID).then(
					function (response) {
						if (response === false) { resolve(response); }
						self.setVarResults(response["survey"]);
						resolve(true);
					}
				);
			}
		);
	}

	getSurveyReportByID(SurveyID){
		const self = this;
		return new Promise(
			resolve => {
				self.customHttpService.httpGET(true, true, 'v1/api/Surveys/SurveyReport/'+SurveyID).then(
					function (response) {
						if (response === false) { resolve(response); }
						self.setVarResults(response);
						resolve(true);
					}
				);
			}
		);
	}

	
	getOptions(value){
		let keys = [];
		if(value != ""){
		  let val = JSON.parse(value.replace(/(['"])?([a-zA-Z0-9_]+)(['"])?:/g, '"$2":'));
		  for (let key in val) {
			keys.push({key: key, value: val[key]});
		  }
		}
		return keys;
		
	}

	getSurveyQuestions(SurveyID){
		const self = this;
		return new Promise(
			resolve => {
				self.customHttpService.httpGET(true, true, 'v1/api/Surveys/'+SurveyID).then(
					function (response) {
						if (response === false) { resolve(response); }
						let questions = response["survey"]["surveyQuestions"];
						let newQuestions = [];
						for(let question of questions){
							let qTemp = new Question();
							qTemp.setQuestion(question);
							qTemp.optionsArray = self.getOptions(question.options);
							newQuestions.push(qTemp);
						}
						self.setVarResults(newQuestions);
						resolve(true);
					}
				);
			}
		);
	}

	sendSurvey(SurveyID) {
		const self = this;
		return new Promise(
		resolve => {
			self.customHttpService.httpGET(true, true, 'v1/api/Surveys/SendSurvey/'+SurveyID).then(
				function (response) {
                    if (response === false) { resolve(response); }
                    self.setVarResults(response['notification']);
					resolve(true);
				}
            );
		});
	}

	deleteSurvey(SurveyID) {
		const self = this;
		return new Promise(
		resolve => {
			self.customHttpService.httpDELETE(true, true, 'v1/api/Surveys/'+SurveyID).then(
				function (response) {
                    if (response === false) { resolve(response); }
                    self.setVarResults(response['notification']);
					resolve(true);
				}
            );
		});
	}


	getQuestionsTypes(){
		const self = this;
		return new Promise(
		resolve => {
			self.customHttpService.httpGET(true, true, 'v1/api/Surveys/SurveyQuestionTypes').then(
				function (response) {
					if (response === false) { resolve(response); }
                    self.setVarResults(response['listSurveyQuestionType']);
					resolve(true);
				}
            );
		});
	}

	createSurvey(request) {
		const self = this;
		return new Promise(
		resolve => {
			self.customHttpService.httpPOST(true, true, 'v1/api/Surveys/', request).then(
				function (response) {
                    if (response === false) { resolve(response); }
                    self.setVarResults(response['survey']);
					resolve(true);
				}
            );
		});
	}

	updateSurvey(survey) {
		const self = this;
		return new Promise(
		resolve => {
			self.customHttpService.httpPUT(true, true, 'v1/api/Surveys/' + survey.surveyID, survey).then(
				function (response) {
                    if (response === false) { resolve(response); }
                    self.setVarResults(response['survey']);
					resolve(true);
				}
            );
		});
	}

	changeState(request){
		const self = this;
		return new Promise(
		resolve => {
			self.customHttpService.httpPOST(true, true, 'v1/api/Surveys/ChangeState/', request).then(
				function (response) {
					if (response === false) { resolve(response); }
					self.setVarResults(response['notification']);
					resolve(true);
				}
			);
		});
	}

}