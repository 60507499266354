import { Country } from './country';

 export class PopSosType {

    /*datos para crear reporte */
    public popSosTypeID: number;
    public sosType: string;
    public countryID: number;
    public created_By: string;
    public created_Date: string;
    public updated_Date: string;
    public country: Country;

    constructor() {
        this.initalizePopSosType();
    }

    initalizePopSosType() {
        this.popSosTypeID = null;
        this.sosType = null;
        this.countryID=null;
        this.created_By = null;
        this.updated_Date = null;
        this.created_Date = null;
        this.country = new Country();
    }

    setPopSosType(optionPopSosType) {
        this.popSosTypeID = optionPopSosType.popSosTypeID;
        this.countryID = optionPopSosType.countryID
        this.sosType = optionPopSosType.type;
        this.created_By = optionPopSosType.Created_By;
        this.updated_Date = optionPopSosType.Updated_Date;
        this.created_Date = optionPopSosType.Created_Date;
        if (optionPopSosType.hasOwnProperty('country') && optionPopSosType.country !== null) { this.country.setCountry(optionPopSosType.country); }
    }

    getJsonBase(): any {
        const jsonBase = {
            'sosType': this.sosType,
            'countryID': this.countryID,
        };
        return jsonBase;
    }

    getJsonCreate(): any {
        const json = this.getJsonBase();
        if (this.created_By !== null) { json['created_By'] = this.created_By; }
        return json;
    }

    getJsonUpdate(): any {
        const json = this.getJsonBase();
        if (this.created_Date !== null) { json['created_Date'] = this.created_Date; }
        if (this.created_By !== null) { json['created_By'] = this.created_By; }
        if (this.popSosTypeID !== null) { json['popSosTypeID'] = this.popSosTypeID; }
        return json;
    }
}