/**
 * Created by Juan Vidales Cheil on 25/4/2019.
 */
import { Pipe, PipeTransform } from '@angular/core';
declare var require: any;
@Pipe({ name: 'translatePipe' })

export class TranslatePipe implements PipeTransform {

	transform(value: any): string {
		if (value != null) {
			const find = value.split('.');
			const language = localStorage.getItem('language');
			const data = require('../assets/i18n/' + language + '.json');
			let count = 0;
			let dataTemp = data;
			for (const search of find) {
				count++;
				if (dataTemp.hasOwnProperty(search)) {
					if (count === find.length) {
						return dataTemp[search];
					} else {
						dataTemp = dataTemp[search];
					}
				} else {
					return value;
				}
			}
		}
		return 'N/A';
	}
}
