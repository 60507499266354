/**
 * Created by Viviana Rincón Cheil on 24/07/2019.
 */
export class DocumentType {
    public documentTypeId: number; // 123
    public documentType: string; // El nombre max 25
    public created_By: string; // El nombre de quien lo crea
    private created_Date: string; // "2019-05-31T02:33:33.380Z"
    private updated_Date: string; // "2019-05-31T02:33:33.380Z"

    constructor() {
        this.initalizeDocumentType();
    }

    initalizeDocumentType() {
        this.documentTypeId = null;
        this.documentType = null;
        this.created_By = null;
        this.created_Date = null;
        this.updated_Date = null;
    }

    setDocumentType(documentType) {
        this.documentTypeId = documentType.documentTypeId;
        this.documentType = documentType.documentType;
        this.created_By = documentType.created_By;
        this.created_Date = documentType.created_Date;
        this.updated_Date = documentType.updated_Date;
    }

    getJsonBase(): any {
        const jsonBase = {
            'documentType': this.documentType
        };
        return jsonBase;
    }

    getJsonCreate(): any {
        const json = this.getJsonBase();
        if (this.created_By !== null) { json['created_By'] = this.created_By; }
        return json;
    }

    getJsonUpdate(): any {
        const json = this.getJsonBase();
        if (this.created_Date !== null) { json['created_Date'] = this.created_Date; }
        if (this.created_By !== null) { json['created_By'] = this.created_By; }
        if (this.documentTypeId !== null) { json['documentTypeId'] = this.documentTypeId; }
        return json;
    }
}