import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../../../../services/utils.service';
import { PopTypeService } from '../../../../services/popType.service';


@Component({
  selector: 'app-pop-type',
  templateUrl: './index.component.html',
  styleUrls: []
})
export class OptionsPopTypeComponent implements OnInit {
  public title = 'PopType';
  public ifDataExist = false;
  public tableTitles = [
    { name: 'type', label: 'Nombre', type: 'normal' },
    { name: 'created_By', label: 'Creado por', type: 'normal' },
  ];
  public data: any = [];
  public optionsTable = {
    dataId: 'popTypeID',
    showData: false,
    actionShowData: '',
    updateData: true,
    actionUpdateData: 'update',
    deleteData: false,
    actionDeleteData: '',
    filterCountryZone: false,
    filterSiteLocation: false,
    filterSitestore: false,
    filterCity: false,
    filterCountry: false,
    filterRegion: false,
    filterCedis: false,
    searchBtn: true,
    exportBtn: true,
  };

  constructor(
    private popTypeService: PopTypeService,
    private utilsService: UtilsService
  ) { }

  ngOnInit() {
    this.getPopTYpe();
  }

  private getPopTYpe() {
    const self = this;
    self.popTypeService.getResults().then(
      function (result) {
        self.data = self.popTypeService.getVarResults();
        console.log(self.data);
        self.ifDataExist = true;
        self.utilsService.debugConsole(result);
      }
    );
  }
}