import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../../../../services/utils.service';
import {CountriesService} from '../../../../services/countries.service';

@Component({
    selector: 'app-index-region',
    templateUrl: './index.component.html',
    styleUrls: []
})

export class CountriesComponent implements OnInit {

    public title = 'Countries';
    public ifDataExist = false;
    public tableTitles = [
        { name: 'region.region', label: 'Región', type: 'normal' },
        { name: 'name', label: 'Nombre', type: 'normal' },
        { name: 'country_Code', label: 'Código País', type: 'normal' },
        { name: 'status', label: 'Estado', type: 'status' },
        { name: 'timeZone', label: 'TimeZone', type: 'normal' },
        { name: 'allowTracking', label: 'Hacer Seguimiento', type: 'boolean' },
        { name: 'trackingTimeSec', label: 'Intervalo Seguimiento', type: 'normal' },
        { name: 'allowBiometrics', label: 'Biometrics', type: 'boolean' },
        { name: 'allowCheckInOutMail', label: 'CheckInOut Mail', type: 'boolean' },
        { name: 'created_Date', label: 'Fecha de Creación', type: 'dateTime' }
    ];
    public data: any = [];
    public optionsTable = {
        dataId: 'countryID',
        showData: false,
        actionShowData: '',
        updateData: true,
        actionUpdateData: 'update',
        deleteData: false,
        actionDeleteData: '',
        filterCountryZone: false,
        filterSiteLocation: false,
        filterSitestore: false,
        filterCity: false,
        filterCountry: false,
        filterRegion: false,
        filterCedis: false,
        searchBtn: true,
        exportBtn: true
    };

    constructor(
        private countriesService: CountriesService,
        private utilsService: UtilsService
    ) {}

    ngOnInit() {
        this.getCountries();
    }

    private getCountries () {
        const self = this;
        self.countriesService.getResults().then(
            function (result) {
                self.data = self.countriesService.getVarResults();
                self.ifDataExist = true;
                self.utilsService.debugConsole(result);
            }
        );
    }

}
