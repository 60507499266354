import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../../../../services/utils.service';
import { PopSosSubTypesService } from '../../../../services/popSosSubTypes.service';


@Component({
  selector: 'app-pop-sos-type',
  templateUrl: './index.component.html',
  styleUrls: []
})

export class PopSosSubTypesComponent implements OnInit {
  public title = 'PopSosSubTypes';
  public ifDataExist = false;
  public tableTitles = [
    { name: 'country.name', label: 'País', type: 'normal' },
    { name: 'sosSubType', label: 'Tipo', type: 'normal' },
    { name: 'created_By', label: 'Creado por', type: 'normal' },
  ];
  public data: any = [];
  public optionsTable = {
    dataId: 'popSosSubTypeID',
    showData: false,
    actionShowData: '',
    updateData: true,
    actionUpdateData: 'update',
    deleteData: false,
    actionDeleteData: '',
    filterCountryZone: false,
    filterSiteLocation: false,
    filterSitestore: false,
    filterCity: false,
    filterCountry: false,
    filterRegion: false,
    filterCedis: false,
    searchBtn: true,
    exportBtn: true,
  };

  constructor(
    private popSosSubTypesService: PopSosSubTypesService,
    private utilsService: UtilsService
  ) { }

  ngOnInit() {
    this.getPopSosSubTypes();
  }

  private getPopSosSubTypes() {
    const self = this;
    self.popSosSubTypesService.getResults().then(
      function (result) {
        self.data = self.popSosSubTypesService.getVarResults();
        self.ifDataExist = true;
        self.utilsService.debugConsole(result);
      }
    );
  }
}