import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../../../../services/utils.service';
import { UsersRolService } from '../../../../services/usersRol.service';

@Component({
	selector: 'app-index-userRol',
	templateUrl: './index.component.html',
	styleUrls: []
})

export class UsersRolComponent implements OnInit {
	public title = 'UsersRol';
	public ifDataExist = false;
	public tableTitles = [
		{ name: 'status', label: 'Estado', type: 'status' },
		{ name: 'created_By', label: 'Creado por', type: 'normal' },
	];
	public data: any = [];
	public optionsTable = {
		dataId: 'rolId',
		showData: false,
		actionShowData: '',
		updateData: true,
		actionUpdateData: 'update',
		deleteData: true,
		actionDeleteData: ''
	};

	constructor(
		private userRolService: UsersRolService,
		private utilsService: UtilsService
	) {}

	ngOnInit() {
		this.getRol();
	}

	private getRol () {
		const self = this;
		self.userRolService.getResults().then(
			function (result) {
				self.data = self.userRolService.getVarResults();
				self.ifDataExist = true;
				self.utilsService.debugConsole(result);
			}
		);
	}

}
