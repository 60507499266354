import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'chart-bar',
	templateUrl: './chart-bar.component.html',
	styleUrls: []
})
export class ChartBarComponent implements OnInit {
	@Input() dataChar: any;
	@Input() optionsChar: any;
	@Input() showData: any;
	@Input() title: string;

	constructor() { }

	ngOnInit(): void {
		//console.log(this.dataChar);
	}
}