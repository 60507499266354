import { Injectable } from '@angular/core';
import { CustomHttpService } from './customHttp.service';

@Injectable()
export class MethodsUtilsService {
    private result: any;

    constructor(
        public customHttpService: CustomHttpService
    ) { }

    /** Función que trae todas las Regiones */
    getListRegions() {
        const self = this;
        return new Promise(
            resolve => {
                // Get Info from 'v1/api/Regions/GetListRegions'
                self.customHttpService.httpGET(true, true, 'v1/api/Regions/GetListRegions').then(
                    function (response) {
                        if (response === false) { resolve(response); }
                        self.result = response['listRegions'];
                        resolve(true);
                    }
                );
            }
        );
    }

    /** Función que trae la lista de Categorias por Rol de usuario */
    getListCategories() {
        const self = this;
        return new Promise(
            resolve => {
                // Get Info from 'v1/api/Categories/GetListCategories'
                self.customHttpService.httpGET(true, true, 'v1/api/Categories/GetListCategories').then(
                    function (response) {
                        if (response === false) { resolve(response); }
                        self.result = response['listCategories'];
                        resolve(true);
                    }
                );
            }
        );
    }

    /** Función que trae la lista de Categorias por Rol de usuario */
    getListRols() {
        const self = this;
        return new Promise(
            resolve => {
                self.customHttpService.httpGET(true, true, 'api/UserRols').then(
                    function (response) {
                        if (response === false) { resolve(response); }
                        self.result = response['listUserRols'];
                        resolve(true);
                    }
                );
            }
        );
    }

    getListDocumentTypes() {
        const self = this;
        return new Promise(
            resolve => {
                self.customHttpService.httpGET(true, true, 'v1/api/DocumentTypes').then(
                    function (response) {
                        if (response === false) { resolve(response); }
                        self.result = response['listDocumentTypes'];
                        resolve(true);
                    }
                );
            }
        );
    }

    getListDisconnectionTypes() {
        const self = this;
        return new Promise(
            resolve => {
                self.customHttpService.httpGET(true, true, 'v1/api/DisconnectionTypes/GetListDisconnectionTypes').then(
                    function (response) {
                        if (response === false) { resolve(response); }
                        self.result = response['listDisconnectionTypes'];
                        resolve(true);
                    }
                );
            }
        );
    }

    /** Función que trae la lista de Categorias por Rol de usuario */
    getListCountriesByRegion(regionID) {
        const self = this;
        return new Promise(
            resolve => {
                // Get Info from 'v1/api/Countries/GetListCountriesByRegion/{regionID}'
                self.customHttpService.httpGET(true, true, 'v1/api/Countries/GetListCountriesByRegion/' + regionID).then(
                    function (response) {
                        if (response === false) { resolve(response); }
                        self.result = response['listCountries'];
                        resolve(true);
                    }
                );
            }
        );
    }

    /** Obtiene las countryZones dependiendo de una lista de Countries */
    getListCountryZonesFilters(request) {
        const self = this;
        return new Promise(
            resolve => {
                // POST Get Info from 'v1/api/CountryZones/GetListCountryZonesFilters'
                self.customHttpService.httpPOST(true, true, 'v1/api/CountryZones/GetListCountryZonesFilters', request).then(
                    function (response) {
                        if (response === false) { resolve(response); }
                        self.result = response['listCountryZones'];
                        resolve(true);
                    }
                );
            }
        );
    }

    /** Obtiene las countryZones dependiendo de una lista de Countries */
    getListCitiesFilters(request) {
        const self = this;
        return new Promise(
            resolve => {
                // POST Get Info from 'v1/api/Cities/GetListCitiesFilters'
                self.customHttpService.httpPOST(true, true, 'v1/api/Cities/GetListCitiesFilters', request).then(
                    function (response) {
                        if (response === false) { resolve(response); }
                        self.result = response['listCities'];
                        resolve(true);
                    }
                );
            }
        );
    }

    /** Obtiene los SiteStores dependiendo de una lista de Ciudades */
    getListSiteStoresFilters(request) {
        const self = this;
        return new Promise(
            resolve => {
                // POST Get Info from 'v1/api/SiteStores/GetListSiteStoresFilters
                self.customHttpService.httpPOST(true, true, 'v1/api/SiteStores/GetListSiteStoresFilters', request).then(
                    function (response) {
                        if (response === false) { resolve(response); }
                        self.result = response['listSiteStores'];
                        resolve(true);
                    }
                );
            }
        );
    }

    /** Obtiene los Users dependiendo de una lista de Ciudades */
    getListUsersFilters(request) {
        const self = this;
        return new Promise(
            resolve => {
                // POST Get Info from 'v1/api/SiteStores/GetListSiteStoresFilters
                self.customHttpService.httpPOST(true, true, 'v1/api/Users/GetUsersFilters', request).then(
                    function (response) {
                        if (response === false) { resolve(response); }
                        self.result = response['users'];
                        resolve(true);
                    }
                );
            }
        );
    }

    /** Obtiene los Channels de la base de datos */
    getListChannels() {
        const self = this;
        return new Promise(
            resolve => {
                // GET Get Info from 'v1/api/Chanels
                self.customHttpService.httpGET(true, true, 'v1/api/Chanels').then(
                    function (response) {
                        if (response === false) { resolve(response); }
                        self.result = response['listChanels'];
                        resolve(true);
                    }
                );
            }
        );
    }

    /** Función que trae todas las Marcas por Ciudad */
    getBrandsByCountryID(id) {
        const self = this;
        return new Promise(
            resolve => {
                // Get Info from 'v1/api/Generales/GetBrandsByCountryID/{CountryID}'
                self.customHttpService.httpGET(true, true, 'v1/api/Generales/GetBrandsByCountryID/' + id).then(
                    function (response) {
                        if (response === false) { resolve(response); }
                        self.result = response['brands'];
                        resolve(true);
                    }
                );
            }
        );
    }

    /** Funcion que trae los Tipos de Producto por Categoria */
    getProductTypes(id) {
        const self = this;
        return new Promise(
            resolve => {
                // Get Info from 'v1/api/Generales/GetProductTypes/{categoriaID}'
                self.customHttpService.httpGET(true, true, 'v1/api/Generales/GetProductTypes/' + id).then(
                    function (response) {
                        if (response === false) { resolve(response); }
                        self.result = response['productTypes'];
                        resolve(true);
                    }
                );
            }
        );
    }

    /** Funcion que trae los productos Filtrados */
    getProducts(request) {
        const self = this;
        return new Promise(
            resolve => {
                // Get Info from 'v1/api/Generales/GetProducts'
                self.customHttpService.httpPOST(true, true, 'v1/api/Generales/GetProducts', request).then(
                    function (response) {
                        if (response === false) { resolve(response); }
                        self.result = response['products'];
                        resolve(true);
                    }
                );
            }
        );
    }

    getListProductsFilters(request) {
        const self = this;
        return new Promise(
            resolve => {
                // Get Info from 'v1/api/Generales/GetProducts'
                self.customHttpService.httpPOST(true, true, 'v1/api/Products/GetListProductsFilters', request).then(
                    function (response) {
                        if (response === false) { resolve(response); }
                        self.result = response['listProducts'];
                        resolve(true);
                    }
                );
            }
        );
    }

    getPopTipologies() {
        const self = this;
        return new Promise(
            resolve => {
                self.customHttpService.httpGET(true, true, 'v1/api/PopTipologies').then(
                    function (response) {
                        if (response === false) { resolve(response); }
                        self.result = response['listPopTipologies'];
                        resolve(true);
                    }
                );
            }
        );
    }

    getListPopSosTypes() {
        const self = this;
        return new Promise(
            resolve => {
                self.customHttpService.httpGET(true, true, 'v1/api/PopSosTypes').then(
                    function (response) {
                        if (response === false) { resolve(response); }
                        self.result = response['listPopSosTypes'];
                        resolve(true);
                    }
                );
            }
        );
    }

    /** Funcion que trae los cedis Filtrados */
    getCedis(request) {
        const self = this;
        return new Promise(
            resolve => {
                // Get Info from 'v1/api/Generales/GetCedis'
                self.customHttpService.httpPOST(true, true, 'v1/api/Generales/GetCedisBySiteStore', request).then(
                    function (response) {
                        if (response === false) { resolve(response); }
                        self.result = response['cedisList'];
                        resolve(true);
                    }
                );
            }
        );
    }


    /** Función que trae la lista de grupos de productos - sin filtro  */
    getListProductGroups() {
        const self = this;
        return new Promise(
            resolve => {
                // Get Info from 'v1/api/Categories/GetListCategories'
                self.customHttpService.httpGET(true, true, 'v1/api/ProductGroups').then(
                    function (response) {
                        if (response === false) { resolve(response); }
                        self.result = response['listProductGroups'];
                        resolve(true);
                    }
                );
            }
        );
    }

    /** Función que trae la lista de tipo de productos - sin filtro  */
    getListProductTypes() {
        const self = this;
        return new Promise(
            resolve => {
                // Get Info from 'v1/api/ProductTypes'
                self.customHttpService.httpGET(true, true, 'v1/api/ProductTypes').then(
                    function (response) {
                        if (response === false) { resolve(response); }
                        self.result = response['listProductTypes'];
                        resolve(true);
                    }
                );
            }
        );
    }

    /** Función que trae la lista de tipo de marcas - sin filtro */
    getListBrands() {
        const self = this;
        return new Promise(
            resolve => {
                // Get Info from 'v1/api/Brands'
                self.customHttpService.httpGET(true, true, 'v1/api/Brands').then(
                    function (response) {
                        if (response === false) { resolve(response); }
                        self.result = response['listBrands'];
                        resolve(true);
                    }
                );
            }
        );
    }

    getListGiveAwayTypes(){
        const self = this;
        return new Promise(
            resolve => {
                self.customHttpService.httpGET(true, true, 'v1/api/GiveAwayTypes').then(
                    function (response) {
                        if (response === false) { resolve(response); }
                        self.result = response['listGiveAwayTypes'];
                        resolve(true);
                    }
                );
            }
        );
    }

    // Getters and Setter Variables
    getVarResults() { return this.result; }

}
