import { Injectable } from '@angular/core';
import { CustomHttpService } from './customHttp.service';
import { BaseServiceService } from './baseService.service';
import { DatePipe } from '@angular/common';
// Declare Vars
declare var $: any;

@Injectable()
export class PushNotificationService extends BaseServiceService {

    constructor(
        public customHttpService: CustomHttpService,
	) {
		super(customHttpService);
    }
    
	sendPushTopic (request) {
		const self = this;
		return new Promise(
		resolve => {
			self.customHttpService.httpPOST(true, true, 'v1/api/Communications/SendTopicPush', request).then(
				function (response) {
                    if (response === false) { resolve(response); }
                    self.setVarResults(response['response']);
					resolve(true);
				}
            );
		});
	}
	
	getImageBase64(image, maxFile, className = '.output_image', previewEmail = null) {
        const self = this;

        const myDate = new Date();

        const datePipe: DatePipe = new DatePipe("es");
        const datetime = datePipe.transform(myDate, 'yyyy-MM-dd');

		const imageBase64 = {
			height: 0,
			timestamp: '',
			uri: '',
			fileName: '',
			data: ''
		};
		const file: File = image.target.files[0];
		const myReader: FileReader = new FileReader();

		myReader.onloadend = (e) => {
			imageBase64.height = file.size;
			imageBase64.timestamp = datetime;
			imageBase64.uri = file.name;
			imageBase64.fileName = file.name;
			imageBase64.data = myReader.result.toString().split(',')[1];
			// Creamos la imagen.
			if (file.size > maxFile) {
				$(className).html(['<div><img class="thumb" src="', myReader.result, '" title="', file.name, '"/><i class="material-icons red">error</i><p>Max 3 MB</p></div>'].join(''));
			} else {
				$(className).html(['<div><img class="thumb" src="', myReader.result, '" title="', file.name, '"/><i class="material-icons green">cloud_done</i></div>'].join(''));
			}
			if (previewEmail !== null) {
				$(previewEmail).html(['<img class="thumb" src="', myReader.result, '" title="Bienvenido a Samsung Plus."/>'].join(''));
			}
		};
		myReader.readAsDataURL(file);
		return imageBase64;
	}
}