import { Injectable } from '@angular/core';
import { CustomHttpService } from './customHttp.service';
import { BaseServiceService } from './baseService.service';
import { DatePipe } from '@angular/common';
import * as $ from 'jquery';


@Injectable()
export class ReportSelloutService extends BaseServiceService {
	private resultData: any;
	
	constructor(
		public customHttpService: CustomHttpService,
	) {
		super(customHttpService);
	}

	getReportSellout(datos) {
		const self = this;
		let loggedUser = JSON.parse(localStorage.getItem('user'));
		return new Promise(
			resolve => {
				const datePipe = new DatePipe('es');
				self.customHttpService.httpPOST((datos.format=='json'), true, 'v1/api/ReportesExcel/GetReportSelloutExcel', datos).then(
					function (data) {
						if (data === false) {
							resolve(data);
						} else {
							self.resultData = data['data'];
							self.setVarResults(self.resultData);
							if(data['reportUrl']) {
								self.resultExcel = data['reportUrl'];
								self.setVarResultsExcel(self.resultExcel);
							} 
							resolve(true);
						}	
					}
				);
			}
		);
	}
}