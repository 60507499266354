import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from '../../../../../services/utils.service';
import { MethodsUtilsService } from '../../../../../services/methodsUtils.service';
import { FormularioBaseComponent } from '../../../../utils/formularioBase.component';
import { SiteStores } from '../../../../../models/stores';
import { StoresService } from '../../../../../services/stores.service';
// Declare Vars
declare var $: any;

@Component ({
    selector: 'app-form-site-store',
    templateUrl: './form.component.html',
    styleUrls: []
})

export class FormStoresComponent extends FormularioBaseComponent implements OnInit {
    public page = 'Stores';
    public title = 'Stores.Create';
    public titleButton = 'Save';
    public store = new SiteStores();
    public regionId = null;

    constructor(
        private route: ActivatedRoute,
        private siteStoresService: StoresService,
        private router: Router,
        public utilsService: UtilsService,
        public methodsUtilsService: MethodsUtilsService,
    ) {
        super(utilsService, methodsUtilsService);
    }

    ngOnInit() {
        const self = this;
        self.getRegionsList();
        self.getListChannels();
        self.param = self.route.params.subscribe(
            params => {
                if (params['id']) {
                    self.title = 'Stores.Update';
                    self.titleButton = 'Update';
                    self.getSiteStores(params['id']);
                }
            }
        );
    }

    private getSiteStores (id: any): void {
        const self = this;
        self.siteStoresService.getResult(id).then(
            function (response) {
                if (response === false) { return; }
                self.store.setSiteStore(self.siteStoresService.getVarResults());
                self.regionId = self.store.country.regionId;
                self.selectedRegion();
                self.selectedCountry();
            }
        );
    }

    public selectedRegion(): void {
        const self = this;
        if (self.regionId != null) {
            self.getListCountriesByRegion(self.regionId);
        } else {
            self.store.countryId = null;
            self.store.countryZoneID = null;
            self.store.cityId = null;
            self.store.siteStoreID = null;
        }
    }

    public selectedCountry(): void {
        const self = this;
        if (self.store.countryId != null) {
            self.getListCountryZonesFilters({ 'countries': [ self.store.countryId ]});
            self.getListCitiesFilters({ 'countries': [ self.store.countryId ], 'countriesZones': [] });
        } else {
            self.store.countryZoneID = null;
            self.store.cityId = null;
        }
    }

    public saveUpdateRegion(): void {
        const self = this;
        if (self.store.siteStoreID !== null) {
            self.siteStoresService.putData(self.store.siteStoreID, self.store.getJsonUpdate()).then(
                function (response) {
                    if (response === false) { return; }
                    self.router.navigate(['/stores']);
                }
            );
        } else {
            self.siteStoresService.postData(self.store.getJsonCreate()).then(
                function (response) {
                    if (response === false) { return; }
                    self.router.navigate(['/stores']);
                }
            );
        }
    }
}
