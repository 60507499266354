import { SiteStores } from './stores';
import { Country } from './country';
import { Category } from './category';

export class Campaign {
	public campaignID: number; 
	public campaignName: string; 
    public created_By: string; 
    public countryID: number; 
    public siteStoreID: number;
    public categoryID: number;
    public country: Country;
    public category: Category;
	public siteStore: SiteStores;
	public active: boolean;
	private created_Date: string; 
	private updated_Date: string; 

	constructor() {
		this.initalizeCampaign();
	}

	initalizeCampaign() {
        this.campaignID = null;
        this.campaignName = null;
        this.countryID = null;
        this.categoryID = null;
        this.siteStoreID = null;
		this.created_By = null;
		this.created_Date = null;
        this.updated_Date = null;
        this.country = new Country();
        this.siteStore = new SiteStores();
		this.category = new Category();
		this.active = false;
	}

	setCampaign(campaign) {
        this.campaignID = campaign.campaignID;
        this.campaignName = campaign.campaignName;
        this.countryID =  campaign.countryID;
        this.categoryID = campaign.categoryID;
        this.siteStoreID = campaign.siteStoreID;
		this.created_By = campaign.created_By;
		this.created_Date = campaign.created_Date;
		this.updated_Date = campaign.updated_Date;
		this.active = campaign.active;
        if (campaign.hasOwnProperty('siteStore') && campaign.siteStore !== null) { this.siteStore.setSiteStore(campaign.siteStore); }
        if (campaign.hasOwnProperty('country') && campaign.country !== null) { this.country.setCountry(campaign.country); }
        if (campaign.hasOwnProperty('category') && campaign.category !== null) { this.category.setCategory(campaign.category); }
	}

	getJsonBase(): any {
		const jsonBase = {
            'campaignName': this.campaignName,
            'countryID': this.countryID,
            'siteStoreID':  this.siteStoreID,
			'categoryID':  this.categoryID,
			'active':  this.active,
		};
		return jsonBase;
	}

	getJsonCreate(): any {
		const json = this.getJsonBase();
		if (this.created_By !== null) { json['created_By'] = this.created_By; }
		return json;
	}

	getJsonUpdate(): any {
		const json = this.getJsonBase();
		if (this.created_Date !== null) { json['created_Date'] = this.created_Date; }
		if (this.created_By !== null) { json['created_By'] = this.created_By; }
		if (this.campaignID !== null) { json['campaignID'] = this.campaignID; }
		return json;
	}
}