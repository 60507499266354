/**
 * Created by Viviana Rincón Cheil on 24/07/2019.
 */

import { Country } from './country';

 export class OptionPopType {

    /*datos para crear reporte */
    public popTypeID: number;
    public type: string;
    public countryID: number;
    public created_By: string;
    public created_Date: string;
    public updated_Date: string;
    public country: Country;

    constructor() {
        this.initalizePopType();
    }

    initalizePopType() {
        this.popTypeID = null;
        this.type = null;
        this.countryID=null;
        this.created_By = null;
        this.updated_Date = null;
        this.created_Date = null;
        this.country = new Country();
    }

    setPopType(optionPopType) {
        this.popTypeID = optionPopType.popTypeID;
        this.countryID = optionPopType.countryID
        this.type = optionPopType.type;
        this.created_By = optionPopType.Created_By;
        this.updated_Date = optionPopType.Updated_Date;
        this.created_Date = optionPopType.Created_Date;
        if (optionPopType.hasOwnProperty('country') && optionPopType.country !== null) { this.country.setCountry(optionPopType.country); }
    }

    getJsonBase(): any {
        const jsonBase = {
            'type': this.type,
            'countryID': this.countryID,
        };
        return jsonBase;
    }

    getJsonCreate(): any {
        const json = this.getJsonBase();
        if (this.created_By !== null) { json['created_By'] = this.created_By; }
        return json;
    }

    getJsonUpdate(): any {
        const json = this.getJsonBase();
        if (this.created_Date !== null) { json['created_Date'] = this.created_Date; }
        if (this.created_By !== null) { json['created_By'] = this.created_By; }
        if (this.popTypeID !== null) { json['popTypeID'] = this.popTypeID; }
        return json;
    }
}