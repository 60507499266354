import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CustomHttpService } from './customHttp.service';
import { BaseServiceService } from './baseService.service';

@Injectable()
export class StaffInfoService extends BaseServiceService {
    private controller = 'CheckInOutReport';

	constructor(
        public customHttpService: CustomHttpService,
        private router: Router
	) {
		super(customHttpService);
		this.TAG = this.controller;
		this.varGet = this.controller;
		this.varGetId = 'user';
	}

    public GetMyteamFilters(request) {
        const self = this;
        return new Promise(
            resolve => {
                self.customHttpService.httpPOST(true, true, 'v1/api/Users/GetMyteamFilters', request).then(
                    function (response) {
                        if (response === false) { 
                            resolve(response); 
                        }
                        self.setVarResults(response["team"]);
                        resolve(true);
                    }
                );
            }
        );
    }

    public reportsExcel(request) {
        const self = this;
        return new Promise(
            resolve => {
                self.customHttpService.httpPOST(true, true, 'v1/api/ReportsExcel/Staff', request).then(
                    function (response) {
                        if (response === false) { 
                            resolve(response); 
                        }
                        self.setVarResultsExcel(response);
                        console.log(response)
                        resolve(true);
                    }
                );
            }
        );
    }

    public GetCreateEmployee(request) {
        const self = this;
        return new Promise(
            resolve => {
                self.customHttpService.httpPOST(true, true, 'v1/api/Users/CreateEmployee', request).then(
                    function (response) {
                        if (response === false) {
                            resolve(response);
                        }
                        self.setVarResults(response["team"]);
                        resolve(true);
                    }
                );
            }
        );
    }

    public UpdateEmployee(UserId, request) {
        const self = this;
        return new Promise(
            resolve => {
                self.customHttpService.httpPUT(true, true, 'v1/api/Users/UpdateEmployee/' + UserId, request).then(
                    function (response) {
                        if (response === false) {
                            resolve(response);
                        }
                        self.setVarResults(response["team"]);
                        resolve(true);
                    }
                );
            }
        );
    }

    public GetEmployeeInfo(UserId) {
        const self = this;
        return new Promise(
            resolve => {
                self.customHttpService.httpGET(true, true, 'v1/api/Users/GetEmployeeInfo/' + UserId).then(
                    function (response) {
                        if (response === false) {
                            resolve(response);
                        }
                        self.setVarResults(response["user"]);
                        resolve(true);
                    }
                );
            }
        );
    }
    
    public GetRouteEmployee() {
        const self = this;
        return new Promise(
            resolve => {
                self.customHttpService.httpGET(true, true, 'v1/api/RouteScheduleTypes').then(
                    function (response) {
                        if (response === false) {
                            resolve(response);
                        }
                        self.setVarResults(response["team"]);
                        resolve(true);
                    }
                );
            }
        );
    }

    public getUploadStaff(request) {
        const self = this;
        return new Promise(
            resolve => {
                self.customHttpService.httpPOST(true, true, 'v1/api/Users/UploadStaff', request).then(
                    function (response) {
                        if (response === false) {
                            resolve(response);
                        }
                        
                        self.setVarResults(response["listEntries"]);
                        resolve(true);
                    }
                );
            }
        );
    }

}
