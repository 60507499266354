import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from '../../../../../services/utils.service';
import { MethodsUtilsService } from '../../../../../services/methodsUtils.service';
import { FormularioBaseComponent } from '../../../../utils/formularioBase.component';
import { PopSosType } from '../../../../../models/popSosType';
import { PopSosTypeService } from '../../../../../services/popSosTypes.service';
// Declare Vars
declare var $: any;

@Component ({
    selector: 'app-form-option-pop-sos-types',
    templateUrl: './form.component.html',
    styleUrls: []
})

export class FormOptionsPopSosTypesComponent extends FormularioBaseComponent implements OnInit {

    public page = 'PopSosTypes';
    public title = 'PopSosTypes.Create';
    public popSosType = new PopSosType();
    public titleButton = 'Save';
    public regionId = null;

    constructor(
        private route: ActivatedRoute,
        private popSosTypesService: PopSosTypeService,
        private router: Router,
        public utilsService: UtilsService,
        public methodsUtilsService: MethodsUtilsService,
    ) {
        super(utilsService, methodsUtilsService);
    }

    ngOnInit() {
        const self = this;
        self.getRegionsList();
        self.param = self.route.params.subscribe(
            params => {
                if (params['id']) {
                    self.title = 'PopSosTypes.Update';
                    self.titleButton = 'Update';
                    self.utilsService.debugConsole(params['id']);
                    self.getPopSosType(params['id']);
                }
            }
        );
    }

    private getPopSosType (id: any): void {
        const self = this;
        self.popSosTypesService.getResult(id).then(
            function (response) {
                if (response === false) { return; }
                self.popSosType.setPopSosType(self.popSosTypesService.getVarResults());
                self.regionId = self.popSosType.country.regionId;
                self.selectedRegion();
            }
        );
    }

    public selectedRegion(): void {
        const self = this;
        if (self.regionId != null) {
            self.getListCountriesByRegion(self.regionId);
        } else {
            self.popSosType.countryID = null;
        }
    }

    public saveUpdatePopSosType(): void {
        const self = this;
        if (self.popSosType.popSosTypeID !== null) {
            self.popSosTypesService.putData(self.popSosType.popSosTypeID, self.popSosType.getJsonUpdate()).then(
                function (response) {
                    if (response === false) { return; }
                    self.router.navigate(['/option-pop-sos-types']);
                }
            );
        } else {
            self.popSosTypesService.postData(self.popSosType.getJsonCreate()).then(
                function (response) {
                    if (response === false) { return; }
                    self.router.navigate(['/option-pop-sos-types']);
                }
            );
        }
    }

}

