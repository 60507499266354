
export class Question {
	public surveyQuestionID: number;
    public question: string;
    public surveyQuestionTypeID: number;
    public order: number; 
	public options: string;
	public optionsArray: any[];
    public active: boolean;
	public mandatory: boolean;
	public listResponses: any[];

    constructor() {
		this.initalizeQuestion();
	}

	initalizeQuestion() {
		this.surveyQuestionID = null;
		this.question = null;
		this.surveyQuestionTypeID = null;
		this.order = null;
		this.options = null;
		this.active = null;
		this.mandatory = null;
		this.listResponses = [];
	}

	setQuestion(surveyQuestion) {
		this.surveyQuestionID = surveyQuestion.surveyQuestionID;
		this.question = surveyQuestion.question;
		this.surveyQuestionTypeID = surveyQuestion.surveyQuestionTypeID;
		this.order = surveyQuestion.order;
		this.options = surveyQuestion.options;
		this.active = surveyQuestion.active;
		this.mandatory = surveyQuestion.mandatory;
		this.listResponses = surveyQuestion.listResponses;
	}

	getJsonBase(): any {
		const jsonBase = {
			'question': this.question,
			'surveyQuestionTypeID': this.surveyQuestionTypeID,
			'order': this.order,
			'options': this.options,
			'active': this.active,
			'mandatory': this.mandatory
		};
		return jsonBase;
	}

}