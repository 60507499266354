import { Injectable } from '@angular/core';
import { CustomHttpService } from './customHttp.service';
import { BaseServiceService } from './baseService.service';

@Injectable()
export class SegmentsService extends BaseServiceService {
    private controller = 'Segments';

    constructor(
        public customHttpService: CustomHttpService
    ) {
        super(customHttpService);
        this.TAG = this.controller;
        this.varGet = 'list' + this.controller;
        this.varGetId = 'segment';
    }

}
