import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from '../../../../../services/utils.service';
import { MethodsUtilsService } from '../../../../../services/methodsUtils.service';
import { FormularioBaseComponent } from '../../../../utils/formularioBase.component';
import { SiteLocations } from '../../../../../models/locations';
import { LocationsService } from '../../../../../services/locations.service';
import {SegmentsService} from "../../../../../services/segments.service";
// Declare Vars
declare var $: any;

@Component ({
    selector: 'app-form-site-locations',
    templateUrl: './form.component.html',
    styleUrls: []
})

export class FormLocationsComponent extends FormularioBaseComponent implements OnInit {

    public page = 'Locations';
    public title = 'Locations.Create';
    public titleButton = 'Save';
    public siteLocations = new SiteLocations();
    public regionId = null;

    constructor(
        private route: ActivatedRoute,
        private siteLocationsService: LocationsService,
        private router: Router,
        public utilsService: UtilsService,
        public methodsUtilsService: MethodsUtilsService,
        public segmentsService: SegmentsService
    ) {
        super(utilsService, methodsUtilsService, segmentsService);
    }

    ngOnInit() {
        const self = this;
        self.getRegionsList();
        self.getSegments();
        self.param = self.route.params.subscribe(
            params => {
                if (params['id']) {
                    self.title = 'Locations.Update';
                    self.titleButton = 'Update';
                    self.getSiteLocations(params['id']);
                }
            }
        );
    }

    private getSiteLocations (id: any): void {
        const self = this;
        self.siteLocationsService.getResult(id).then(
            function (response) {
                if (response === false) { return; }
                self.siteLocations.setSiteLocation(self.siteLocationsService.getVarResults());
                self.regionId = self.siteLocations.country.regionId;
                self.selectedRegion();
                self.selectedCountry();
                self.getSegments();
            }
        );
    }

    public selectedRegion(): void {
        const self = this;
        if (self.regionId != null) {
            self.getListCountriesByRegion(self.regionId);
        } else {
            self.siteLocations.countryID = null;
            self.siteLocations.countryZoneID = null;
            self.siteLocations.cityID = null;
        }
    }

    public selectedCountry(): void {
        const self = this;
        if (self.siteLocations.countryID != null) {
            self.getListCountryZonesFilters({ 'countries': [ self.siteLocations.countryID ]});
            self.getListCitiesFilters({ 'countries': [ self.siteLocations.countryID ], 'countriesZones': [] });
            self.getListSiteStoresFilters({ 'cities': [], 'countries': [ self.siteLocations.countryID ] });
        } else {
            self.siteLocations.countryZoneID = null;
            self.siteLocations.cityID = null;
        }
    }

    public saveUpdateRegion(): void {
        const self = this;
        if (self.siteLocations.siteLocationID !== null) {
            self.siteLocationsService.putData(self.siteLocations.siteLocationID, self.siteLocations.getJsonUpdate()).then(
                function (response) {
                    if (response === false) { return; }
                    self.router.navigate(['/locations']);
                }
            );
        } else {
            self.siteLocationsService.postData(self.siteLocations.getJsonCreate()).then(
                function (response) {
                    if (response === false) { return; }
                    self.router.navigate(['/locations']);
                }
            );
        }
    }

}
