import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

// Import All Routes of App
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { RutasComponent } from './pages/supervisorModule/rutas/rutas.component';
import { ScheduleComponent } from './pages/supervisorModule/schedule/schedule.component';

import { StoresComponent } from './pages/storeManagment/stores/index.component';
import { FormStoresComponent } from './pages/storeManagment/stores/form/form.component';

import { CitiesComponent } from './pages/storeManagment/cities/index.component';
import { FormCitiesComponent } from './pages/storeManagment/cities/form/form.component';

import { CountryZonesComponent } from './pages/storeManagment/countryZones/index.component';
import { FormCountryZonesComponent } from './pages/storeManagment/countryZones/form/form.component';

import { LocationsComponent } from './pages/storeManagment/locations/index.component';
import { FormLocationsComponent } from './pages/storeManagment/locations/form/form.component';

import { DocumentTypeComponent } from './pages/fieldForceManagment/documentType/index.component';
import { FormDocumentTypeComponent } from './pages/fieldForceManagment/documentType/form/form.component';

import { DisconnectionTypeComponent } from './pages/fieldForceManagment/disconnectionType/index.component';
import { FormDisconnectionTypeComponent } from './pages/fieldForceManagment/disconnectionType/form/form.component';

import { OptionsPopTypeComponent } from './pages/optionsReports/popType/index.component';
import { FormOptionsPopTypeComponent } from './pages/optionsReports/popType/form/form.component';

import { OptionsPopExecutionTypeComponent } from './pages/optionsReports/popExecutionType/index.component';
import { FormOptionsPopExecutionTypeComponent } from './pages/optionsReports/popExecutionType/form/form.component';

import { OptionsPopTipologyComponent } from './pages/optionsReports/popTipology/index.component';
import { FormOptionsTipologyComponent } from './pages/optionsReports/popTipology/form/form.component';


import { PopExecutionComponent } from './pages/reports/popExecution/index.component';

import { PopExecutionSelaComponent } from './pages/reports/popExecutionSela/index.component';

import { ProductComponent } from './pages/storeManagment/product/index.component';
import { FormProductComponent } from './pages/storeManagment/product/form/form.component';

import { PopRequestComponent } from './pages/reports/popRequest/index.component';
import { FormPopRequestComponent } from './pages/reports/popRequest/form/form.component';

import { PopSosSubTypesComponent } from './pages/optionsReports/popSosSubTypes/index.component';
import { FormPopSosSubTypesComponent } from './pages/optionsReports/popSosSubTypes/form/form.component';

import { ReportsPromoterControlComponent } from './pages/reports/promotersNow/index.component';
import { ReportsPromoterConsolidateComponent } from './pages/reports/promotersConsolidate/index.component';
import { ReportsPromoterTrackComponent } from './pages/reports/promotersTrack/index.component';

import { ReportsPromoterCheckInOutComponent } from './pages/reports/promotersCheckInOut/index.component';
import { SamplingAuditComponent } from './pages/reports/samplingAudit/index.component';

import { DisplaySosComponent } from './pages/reports/displaySos/index.component';

import { UsersRolComponent } from './pages/usersManagment/usersRol/index.component';
import { FormUsersRolComponent } from './pages/usersManagment/usersRol/form/form.component';

import { UsersInformationComponent } from './pages/usersManagment/usersInformation/index.component';
import { UsersInformationShowComponent } from './pages/usersManagment/usersInformation/show.component';
import { FormUserInformationComponent } from './pages/usersManagment/usersInformation/form/form.component';
import { MySupervisorsComponent } from './pages/supervisorModule/mySupervisors/index.component';
import { SupervisorPromotersComponent } from './pages/supervisorModule/supervisorPromoters/index.component';

import { PushNotificationComponent } from './pages/pushNotification/index.component';

import { CedisComponent } from './pages/storeManagment/cedis/index.component';
import { FormCedisComponent } from './pages/storeManagment/cedis/form/form.component';

import { RegionsComponent } from './pages/storeManagment/regions/index.component';
import { FormRegionComponent } from './pages/storeManagment/regions/form/form.component';

import { CountriesComponent } from './pages/storeManagment/countries/index.component';
import { FormCountryComponent } from './pages/storeManagment/countries/form/form.component';
import { SegmentsComponent } from './pages/storeManagment/segments/index.component';
import { FormSegmentComponent } from './pages/storeManagment/segments/form/form.component';
import { CategoriesComponent } from './pages/storeManagment/categories/index.component';
import { FormCategoryComponent } from './pages/storeManagment/categories/form/form.component';
import { ProductTypesComponent } from './pages/storeManagment/products/productTypes/index.component';
import { FormProductTypeComponent } from './pages/storeManagment/products/productTypes/form/form.component';
import { ChannelsComponent } from './pages/storeManagment/channels/index.component';
import { FormChannelComponent } from './pages/storeManagment/channels/form/form.component';

import { UserScheduleComponent } from './pages/usersManagment/userSchedule/user-schedule.component';
import { StoreScheduleComponent } from './pages/usersManagment/storeSchedule/store-schedule.component';
import { OptionsPopByProductComponent } from './pages/optionsReports/popByProduct/index.component';
import { OptionsCampaingComponent } from './pages/optionsReports/campaing/index.component';
import { OptionsPopSosTypesComponent } from './pages/optionsReports/popSosTypes/index.component';
import { FormOptionsPopByProductComponent } from './pages/optionsReports/popByProduct/form/form.component';
import { FormOptionsCampaingComponent } from './pages/optionsReports/campaing/form/form.component';
import { FormOptionsPopSosTypesComponent } from './pages/optionsReports/popSosTypes/form/form.component';
import { FormBrandComponent } from './pages/storeManagment/brand/form/form.component';
import { BrandComponent } from './pages/storeManagment/brand/index.component';

import { GiveAwayTypesComponent } from './pages/storeManagment/giveAwayTypes/index.component';
import { GiveAwayCampaignsComponent } from './pages/storeManagment/giveAwayCampaigns/index.component';
import { FormGiveAwayTypesComponent } from './pages/storeManagment/giveAwayTypes/form/form.component';
import { FormGiveAwayCampaignsComponent } from './pages/storeManagment/giveAwayCampaigns/form/form.component';
import { ReportGiveAwayComponent } from './pages/reports/giveAway/index.component';

import { ProductSiteStoreComponent } from './pages/storeManagment/products/productSiteStore/index.component';
import { FormProductSiteStoreComponent } from './pages/storeManagment/products/productSiteStore/form/form.component';

import { PromotionTypesComponent } from './pages/storeManagment/promotionType/index.component';
import { FormPromotionTypesComponent } from './pages/storeManagment/promotionType/form/form.component';

import { PromotionSubTypesComponent } from './pages/storeManagment/promotionSubType/index.component';
import { FormPromotionSubTypesComponent } from './pages/storeManagment/promotionSubType/form/form.component';
import { ReportStoreActivityComponent } from './pages/reports/storeActivity/index.component';
import { ReportFlooringComponent } from './pages/reports/reportFlooring/index.component';
import { ReportPriceEvidenceComponent } from './pages/reports/priceEvidence/index.component';

import { SurveysComponent } from './pages/surveys/surveys.component';
import { CreateSurveysComponent } from './pages/surveys/form/surveys-create.component';
import { ShowSurveysComponent } from './pages/surveys/surveys-show.component';
import { ReportSurveysComponent } from './pages/surveys/report/report.component';
import { ReportInventoryComponent } from './pages/reports/inventory/index.component';
import { ReportSelloutComponent } from './pages/reports/sellout/index.component';
import { RouteManagementComponent } from './pages/usersManagment/route-management/route.component';
import { ShowRouteManagementComponent } from './pages/usersManagment/route-management/show.component';
import { PasswordRecoveryComponent } from './pages/password-recovery/password-recovery.component';
// Third Modules

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, pathMatch: 'full' },
  { path: 'home', component: HomeComponent, pathMatch: 'full' },
  { path: 'mi-ruta', component: RutasComponent, pathMatch: 'full' },
  { path: 'calendario-usuarios', component: ScheduleComponent, pathMatch: 'full' },
  { path: 'password-recovery', component: PasswordRecoveryComponent, pathMatch: 'full' },
  // Regions
  { path: 'regions', component: RegionsComponent, pathMatch: 'full' },
  { path: 'regions/create', component: FormRegionComponent, pathMatch: 'full' },
  { path: 'regions/update/:id', component: FormRegionComponent, pathMatch: 'full' },

  // Countries
  { path: 'countries', component: CountriesComponent, pathMatch: 'full' },
  { path: 'countries/create', component: FormCountryComponent, pathMatch: 'full' },
  { path: 'countries/update/:id', component: FormCountryComponent, pathMatch: 'full' },

  // Segments
  { path: 'segments', component: SegmentsComponent, pathMatch: 'full' },
  { path: 'segments/create', component: FormSegmentComponent, pathMatch: 'full' },
  { path: 'segments/update/:id', component: FormSegmentComponent, pathMatch: 'full' },

  // Categories
  { path: 'categories', component: CategoriesComponent, pathMatch: 'full' },
  { path: 'categories/create', component: FormCategoryComponent, pathMatch: 'full' },
  { path: 'categories/update/:id', component: FormCategoryComponent, pathMatch: 'full' },

  // ProductTypes
  { path: 'productTypes', component: ProductTypesComponent, pathMatch: 'full' },
  { path: 'productTypes/create', component: FormProductTypeComponent, pathMatch: 'full' },
  { path: 'productTypes/update/:id', component: FormProductTypeComponent, pathMatch: 'full' },

  // ProductSiteStore
  { path: 'product-sitestore', component: ProductSiteStoreComponent, pathMatch: 'full' },
  { path: 'product-sitestore/create', component: FormProductSiteStoreComponent, pathMatch: 'full' },
  { path: 'product-sitestore/update/:id', component: FormProductSiteStoreComponent, pathMatch: 'full' },

  // Channels
  { path: 'channels', component: ChannelsComponent, pathMatch: 'full' },
  { path: 'channels/create', component: FormChannelComponent, pathMatch: 'full' },
  { path: 'channels/update/:id', component: FormChannelComponent, pathMatch: 'full' },

  // Stores
  { path: 'stores', component: StoresComponent, pathMatch: 'full' },
  { path: 'stores/create', component: FormStoresComponent, pathMatch: 'full' },
  { path: 'stores/update/:id', component: FormStoresComponent, pathMatch: 'full' },

  // Cities
  { path: 'cities', component: CitiesComponent, pathMatch: 'full' },
  { path: 'cities/create', component: FormCitiesComponent, pathMatch: 'full' },
  { path: 'cities/update/:id', component: FormCitiesComponent, pathMatch: 'full' },

  // Locations
  { path: 'locations', component: LocationsComponent, pathMatch: 'full' },
  { path: 'locations/create', component: FormLocationsComponent, pathMatch: 'full' },
  { path: 'locations/update/:id', component: FormLocationsComponent, pathMatch: 'full' },

  // CEDIS
  { path: 'cedis', component: CedisComponent, pathMatch: 'full' },
  { path: 'cedis/create', component: FormCedisComponent, pathMatch: 'full' },
  { path: 'cedis/update/:id', component: FormCedisComponent, pathMatch: 'full' },

  // CountryZones
  { path: 'country-zones', component: CountryZonesComponent, pathMatch: 'full' },
  { path: 'country-zones/create', component: FormCountryZonesComponent, pathMatch: 'full' },
  { path: 'country-zones/update/:id', component: FormCountryZonesComponent, pathMatch: 'full' },

  // DocumentType
  { path: 'document-type', component: DocumentTypeComponent, pathMatch: 'full' },
  { path: 'document-type/create', component: FormDocumentTypeComponent, pathMatch: 'full' },
  { path: 'document-type/update/:id', component: FormDocumentTypeComponent, pathMatch: 'full' },

  // DisconnectionType
  { path: 'disconnection-type', component: DisconnectionTypeComponent, pathMatch: 'full' },
  { path: 'disconnection-type/create', component: FormDisconnectionTypeComponent, pathMatch: 'full' },
  { path: 'disconnection-type/update/:id', component: FormDisconnectionTypeComponent, pathMatch: 'full' },

  // ReportsPopExecute
  { path: 'reports-pop-execute', component: PopExecutionComponent, pathMatch: 'full' },
  { path: 'reports-pop-execute/create', component: PopExecutionComponent, pathMatch: 'full' },
  { path: 'reports-pop-execute/update/:id', component: PopExecutionComponent, pathMatch: 'full' },


  // ReportsPopExecute2
  { path: 'reports-pop-execute-sela', component: PopExecutionSelaComponent, pathMatch: 'full' },
  { path: 'reports-pop-execute-sela/create', component: PopExecutionSelaComponent, pathMatch: 'full' },
  { path: 'reports-pop-execute-sela/update/:id', component: PopExecutionSelaComponent, pathMatch: 'full' },

  // ReportsPopRequest
  { path: 'reports-pop-request', component: PopRequestComponent, pathMatch: 'full' },
  { path: 'reports-pop-request/create', component: PopRequestComponent, pathMatch: 'full' },
  { path: 'reports-pop-request/update/:id', component: PopRequestComponent, pathMatch: 'full' },

  // ReportsPopRequest
  { path: 'reports-sampling-audit', component: SamplingAuditComponent, pathMatch: 'full' },
  { path: 'reports-sampling-audit/create', component: SamplingAuditComponent, pathMatch: 'full' },
  { path: 'reports-sampling-audit/update/:id', component: SamplingAuditComponent, pathMatch: 'full' },

  // ReportsDisplaySos
  { path: 'reports-display-sos', component: DisplaySosComponent, pathMatch: 'full' },
  { path: 'reports-display-sos/create', component: DisplaySosComponent, pathMatch: 'full' },
  { path: 'reports-display-sos/update/:id', component: DisplaySosComponent, pathMatch: 'full' },

  // ReportsPromoterNow
  { path: 'reports-promoters-now', component: ReportsPromoterControlComponent, pathMatch: 'full' },
  { path: 'reports-promoters-consolidate', component: ReportsPromoterConsolidateComponent, pathMatch: 'full' },
  { path: 'reports-promoters-track', component: ReportsPromoterTrackComponent, pathMatch: 'full' },
  { path: 'reports-promoters-checkin-out', component: ReportsPromoterCheckInOutComponent, pathMatch: 'full' },

  // OptionsPopType
  { path: 'option-pop-type', component: OptionsPopTypeComponent, pathMatch: 'full' },
  { path: 'option-pop-type/create', component: FormOptionsPopTypeComponent, pathMatch: 'full' },
  { path: 'option-pop-type/update/:id', component: FormOptionsPopTypeComponent, pathMatch: 'full' },

  // OptionsPopExecutionType
  { path: 'option-execution-type', component: OptionsPopExecutionTypeComponent, pathMatch: 'full' },
  { path: 'option-execution-type/create', component: FormOptionsPopExecutionTypeComponent, pathMatch: 'full' },
  { path: 'option-execution-type/update/:id', component: FormOptionsPopExecutionTypeComponent, pathMatch: 'full' },

  // OptionsPopTipology
  { path: 'option-pop-tipology', component: OptionsPopTipologyComponent, pathMatch: 'full' },
  { path: 'option-pop-tipology/create', component: FormOptionsTipologyComponent, pathMatch: 'full' },
  { path: 'option-pop-tipology/update/:id', component: FormOptionsTipologyComponent, pathMatch: 'full' },

  // OptionPOPByProduct
  { path: 'option-pop-by-product', component: OptionsPopByProductComponent, pathMatch: 'full' },
  { path: 'option-pop-by-product/create', component: FormOptionsPopByProductComponent, pathMatch: 'full' },
  { path: 'option-pop-by-product/update/:id', component: FormOptionsPopByProductComponent, pathMatch: 'full' },

  // OptionCampaign
  { path: 'option-campaign', component: OptionsCampaingComponent, pathMatch: 'full' },
  { path: 'option-campaign/create', component: FormOptionsCampaingComponent, pathMatch: 'full' },
  { path: 'option-campaign/update/:id', component: FormOptionsCampaingComponent, pathMatch: 'full' },

  // OptionPOPSosTypes
  { path: 'option-pop-sos-types', component: OptionsPopSosTypesComponent, pathMatch: 'full' },
  { path: 'option-pop-sos-types/create', component: FormOptionsPopSosTypesComponent, pathMatch: 'full' },
  { path: 'option-pop-sos-types/update/:id', component: FormOptionsPopSosTypesComponent, pathMatch: 'full' },

  // OptionPOPSosSubTypes
  { path: 'pop-sos-sub-types', component: PopSosSubTypesComponent, pathMatch: 'full' },
  { path: 'pop-sos-sub-types/create', component: FormPopSosSubTypesComponent, pathMatch: 'full' },
  { path: 'pop-sos-sub-types/update/:id', component: FormPopSosSubTypesComponent, pathMatch: 'full' },


  // PromotionTypes
  { path: 'promotion-types', component: PromotionTypesComponent, pathMatch: 'full' },
  { path: 'promotion-types/create', component: FormPromotionTypesComponent, pathMatch: 'full' },
  { path: 'promotion-types/update/:id', component: FormPromotionTypesComponent, pathMatch: 'full' },

  // PromotionSubTypes
  { path: 'promotion-sub-types', component: PromotionSubTypesComponent, pathMatch: 'full' },
  { path: 'promotion-sub-types/create', component: FormPromotionSubTypesComponent, pathMatch: 'full' },
  { path: 'promotion-sub-types/update/:id', component: FormPromotionSubTypesComponent, pathMatch: 'full' },


  // Product
  { path: 'product', component: ProductComponent, pathMatch: 'full' },
  { path: 'product/create', component: FormProductComponent, pathMatch: 'full' },
  { path: 'product/update/:id', component: FormProductComponent, pathMatch: 'full' },

  // UsersRol
  { path: 'users-rol', component: UsersRolComponent, pathMatch: 'full' },
  { path: 'users-rol/create', component: FormUsersRolComponent, pathMatch: 'full' },
  { path: 'users-rol/update/:id', component: FormUsersRolComponent, pathMatch: 'full' },

  // UsersManagment
  { path: 'user-information', component: UsersInformationComponent, pathMatch: 'full' },
  { path: 'user-information/show/:id', component: UsersInformationShowComponent, pathMatch: 'full' },
  { path: 'user-information/create', component: FormUserInformationComponent, pathMatch: 'full' },
  { path: 'user-information/update/:id', component: FormUserInformationComponent, pathMatch: 'full' },
  { path: 'push-notification', component: PushNotificationComponent, pathMatch: 'full' },

  { path: 'user-supervisors', component: MySupervisorsComponent, pathMatch: 'full' },
  { path: 'supervisor-promotors/:id', component: SupervisorPromotersComponent, pathMatch: 'full' },

  { path: 'user-supervisor', component: UsersRolComponent, pathMatch: 'full' },
  { path: 'user-country', component: UsersRolComponent, pathMatch: 'full' },
  { path: 'user-category', component: UsersRolComponent, pathMatch: 'full' },
  { path: 'user-activation', component: UsersRolComponent, pathMatch: 'full' },
  { path: 'reset-password', component: UsersRolComponent, pathMatch: 'full' },

  // Schedule
  { path: 'user-schedule', component: UserScheduleComponent, pathMatch: 'full' },
  { path: 'store-schedule', component: StoreScheduleComponent, pathMatch: 'full' },

  // Brands
  { path: 'brands', component: BrandComponent, pathMatch: 'full' },
  { path: 'brands/create', component: FormBrandComponent, pathMatch: 'full' },
  { path: 'brands/update/:id', component: FormBrandComponent, pathMatch: 'full' },

  // GiveAwayTypes
  { path: 'GiveAwayTypes', component: GiveAwayTypesComponent, pathMatch: 'full' },
  { path: 'GiveAwayTypes/create', component: FormGiveAwayTypesComponent, pathMatch: 'full' },
  { path: 'GiveAwayTypes/update/:id', component: FormGiveAwayTypesComponent, pathMatch: 'full' },

  // GiveAwayCampaigns
  { path: 'GiveAwayCampaigns', component: GiveAwayCampaignsComponent, pathMatch: 'full' },
  { path: 'GiveAwayCampaigns/create', component: FormGiveAwayCampaignsComponent, pathMatch: 'full' },
  { path: 'GiveAwayCampaigns/update/:id', component: FormGiveAwayCampaignsComponent, pathMatch: 'full' },

  { path: 'reports-giveaway', component: ReportGiveAwayComponent, pathMatch: 'full' },
  { path: 'reports-store-activity', component: ReportStoreActivityComponent, pathMatch: 'full' },
  { path: 'reports-flooring', component: ReportFlooringComponent, pathMatch: 'full' },

  // Price Evidence
  { path: 'reports-price-evidence', component: ReportPriceEvidenceComponent, pathMatch: 'full' },


  // Inventory
  { path: 'reports-inventory', component: ReportInventoryComponent, pathMatch: 'full' },

  // Sellout
  { path: 'reports-sellout', component: ReportSelloutComponent, pathMatch: 'full' },

  // Surveys
  { path: 'surveys', component: SurveysComponent, pathMatch: 'full' },
  { path: 'surveys/create', component: CreateSurveysComponent, pathMatch: 'full' },
  { path: 'surveys/update/:id', component: CreateSurveysComponent, pathMatch: 'full' },
  { path: 'surveys/show/:id', component: ShowSurveysComponent, pathMatch: 'full' },
  { path: 'surveys/report/:id', component: ReportSurveysComponent, pathMatch: 'full' },

  // Route Nanagement
  { path: 'route-management', component: RouteManagementComponent, pathMatch: 'full' },
  { path: 'route-management/show/:idUser/:idRoute', component: ShowRouteManagementComponent, pathMatch: 'full' },

  // Errores
  { path: '**', component: AccessDeniedComponent },

];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, { useHash: true })
  ],
  declarations: [],
  exports: [RouterModule]
})

export class AppRoutingModule { }
