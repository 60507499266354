import { Component, OnInit } from "@angular/core";
import { UtilsService } from "../../../../services/utils.service";
import { ReportPopExecuteService } from "../../../../services/reportPopExecute.service";
import * as $ from "jquery";

@Component({
  selector: "app-pop-execution",
  templateUrl: "./index.component.html",
  styleUrls: [],
})
export class PopExecutionSelaComponent implements OnInit {
  public title = "ReportsPopExecution";
  public ifDataExist = false;
  public ifExcelExist = true;
  public regionID = [];
  public cityID = [];
  public countryID = [];
  public data: any = [];
  public urlExcel: any;

  public tableTitles = [
    { name: "year", label: "Year", type: "number" },
    { name: "month", label: "Month", type: "month" },
    { name: "yearWeek", label: "Week", type: "number" },
    { name: "country", label: "Country", type: "normal" },
    { name: "siteStore", label: "Store", type: "normal" },
    { name: "siteLocation", label: "Location", type: "normal" },
    { name: "site_id", label: "Site ID", type: "normal" },
    { name: "campaign", label: "Campaign", type: "normal" },
    { name: "category", label: "Category", type: "normal" },
    { name: "productGroup", label: "Product Group", type: "normal" },
    { name: "productType", label: "Product Type ", type: "normal" },
    { name: "brand", label: "Brand", type: "normal" },
    { name: "product", label: "Product", type: "normal" },
    { name: "description", label: "Description", type: "normal" },
    { name: "popType", label: "Pop Type", type: "normal" },
    { name: "cantidad", label: "Quantity", type: "normal" },
    { name: "photoA1", label: "Photo  1", type: "image" },
    { name: "photoA2", label: "Photo  2", type: "image" },
    { name: "photoA3", label: "Photo  3", type: "image" },
    { name: "photoD1", label: "Photo  4", type: "image" },
    { name: "photoD2", label: "Photo  5", type: "image" },
    { name: "photoD3", label: "Photo  6", type: "image" },
    { name: "fullName", label: "User Name", type: "normal" },
    { name: "userName", label: "User", type: "normal" },
    { name: "supervisors", label: "Supervisor", type: "normal" },
    { name: "created_Date", label: "Created Date", type: "date" },
  ];

  public optionsTable = {
    dataId: "popExecutionImplementationID",
    showData: false,
    actionShowData: "",
    updateData: false,
    actionUpdateData: "update",
    deleteData: false,
    actionDeleteData: "",
    filterCountryZone: false,
    filterSiteLocation: false,
    filterDateRange: true,
    filterSitestore: false,
    filterCity: false,
    filterCategory: false,
    filterCountry: true,
    filterRegion: true,
    filterCedis: false,
    filterCampaing: true,
    filterPopType: true,
    searchBtn: true,
    exportBtn: true,
    itemsPerPage: 5,
  };

  constructor(
    private reportPopExecuteService: ReportPopExecuteService,
    private utilsService: UtilsService
  ) {
    if (!sessionStorage.hasOwnProperty("token")) {
      const url = window.location.href;
      const searchParams = url.split("?")[1];
      const params = new URLSearchParams(searchParams);
      const token = params.get("t");
      const userCoded = params.get("u");
      const decodedString = atob(userCoded);
      const user = JSON.parse(decodedString);
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("language", "es");
      sessionStorage.setItem("token", token);
    } else {
      console.log("tokenExist.");
    }
  }

  ngOnInit() {
    const self = this;
    self.ifDataExist = true;
    const request = {};
  }

  downloadExcelReport($event) {
    const self = this;
    let request = {};
    request["listIds"] = $event.listIds;
    request["email"] = $event.email;
    this.getExcelPopExecutionSela(request);
  }

  filter(event) {
    const self = this;
    let request = {};
    if (event.start_Date && event.end_Date) {
      request = {
        start_Date: event.start_Date,
        end_Date: event.end_Date,
        campaignIds: [],
        popTypeIds: [],
      };
    } else return false;
    if (event.campaignID) {
      request["campaignIds"].push(event.campaignID);
    }
    if (event.popTypeID) {
      request["popTypeIds"].push(event.popTypeID);
    }
    if (event.countryId) {
      request["countries"] = [event.countryId];
    }
    if (event.regionId) {
      request["regions"] = [event.regionId];
    }
    self.getReportPopExecutionSela(request);
  }

  private getReportPopExecutionSela(request) {
    const self = this;
    self.reportPopExecuteService
      .getReportPopExecutionSela(request)
      .then(function (result) {
        self.data = self.reportPopExecuteService.getVarResults();
        self.urlExcel = true;
        self.ifDataExist = true;
        self.utilsService.debugConsole(result);
      });
  }
  private getExcelPopExecutionSela(request) {
    const self = this;
    self.reportPopExecuteService
      .getExcelPopExecutionSela(request)
      .then(function (result) {
        let urlExcel = self.reportPopExecuteService.getVarResultsExcel();
        if (urlExcel.indexOf("http") == 0) {
          window.location.assign(urlExcel);
        } else {
          self.urlExcel = urlExcel;
        }
        self.utilsService.debugConsole(result);
      });
  }
}
