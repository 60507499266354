import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../../../../services/utils.service';
import { PopByProductService } from '../../../../services/popByProduct.service';


@Component({
  selector: 'app-pop-type',
  templateUrl: './index.component.html',
  styleUrls: []
})
export class OptionsPopByProductComponent implements OnInit {
  public title = 'PopByProduct';
  public ifDataExist = false;
  public tableTitles = [
    { name: 'active', label: 'Active', type: 'active' },
    { name: 'popCod', label: 'Code', type: 'normal' },
    { name: 'country.name', label: 'Country', type: 'normal' },
    { name: 'popTipology.tipology', label: 'Tipology', type: 'normal' },
    { name: 'product.model', label: 'Product', type: 'normal' },
  ];

  public data: any = [];
  public optionsTable = {
    dataId: 'popByProductID',
    showData: false,
    actionShowData: '',
    updateData: true,
    actionUpdateData: 'update',
    deleteData: false,
    actionDeleteData: '',
    filterCountryZone: false,
    filterSiteLocation: false,
    filterSitestore: false,
    filterCity: false,
    filterCountry: false,
    filterRegion: false,
    filterCedis: false,
    searchBtn: true,
    exportBtn: true,
  };

  constructor(
    private popByProductService: PopByProductService,
    private utilsService: UtilsService
  ) { }

  ngOnInit() {
    this.getPopByProduct();
  }

  private getPopByProduct() {
    const self = this;
    self.popByProductService.getResults().then(
      function (result) {
        self.data = self.popByProductService.getVarResults();
        self.ifDataExist = true;
        self.utilsService.debugConsole(result);
      }
    );
  }
}