import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../../../../services/utils.service';
import { ChanelsService } from '../../../../services/chanels.service';

@Component({
    selector: 'app-index-channels',
    templateUrl: './index.component.html',
    styleUrls: []
})

export class ChannelsComponent implements OnInit {

    public title = 'Channels';
    public ifDataExist = false;
    public tableTitles = [
        { name: 'chanel', label: 'Canal', type: 'normal' },
        { name: 'active', label: 'Activo', type: 'boolean' },
        { name: 'created_By', label: 'Creado por', type: 'normal' },
        { name: 'created_Date', label: 'Fecha de Creación', type: 'dateTime' }
    ];
    public data: any = [];
    public optionsTable = {
        dataId: 'chanelID',
        showData: false,
        actionShowData: '',
        updateData: true,
        actionUpdateData: 'update',
        deleteData: false,
        actionDeleteData: '',
        filterCountryZone: false,
        filterSiteLocation: false,
        filterSitestore: false,
        filterCity: false,
        filterCountry: false,
        filterRegion: false,
        filterCedis: false,
        searchBtn: true,
        exportBtn: true
    };

    constructor(
        private chanelsService: ChanelsService,
        private utilsService: UtilsService
    ) {}

    ngOnInit() {
        this.getCountries();
    }

    private getCountries () {
        const self = this;
        self.chanelsService.getResults().then(
            function (result) {
                self.data = self.chanelsService.getVarResults();
                self.ifDataExist = true;
                self.utilsService.debugConsole(result);
            }
        );
    }

}
