import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../../../../services/utils.service';
import { CedisService } from '../../../../services/cedis.service';

@Component({
	selector: 'app-index-cedis',
	templateUrl: './index.component.html',
	styleUrls: []
})

export class CedisComponent implements OnInit {
	public title = 'Cedis';
	public ifDataExist = false;
	public tableTitles = [
		{ name: 'cedisName', label: 'Nombre', type: 'normal' },
		{ name: 'country.name', label: 'Pais', type: 'normal' },
		{ name: 'countryZone.zoneName', label: 'Country Zone', type: 'normal' },
		{ name: 'city.cityName', label: 'Ciudad', type: 'normal' },
		{ name: 'siteStore.name', label: 'Site Store', type: 'normal' },
		
        
	];
	public data: any = [];
	public optionsTable = {
		dataId: 'cedisID',
		showData: false,
		actionShowData: '',
		updateData: true,
		actionUpdateData: 'update',
		deleteData: false,
		actionDeleteData: '',
		filterCountryZone: false,
		filterSiteLocation: false,
		filterSitestore: false,
		filterCity: false,
		filterCountry: false,
		filterRegion: false,
		filterCedis: false,
		searchBtn: true,
		exportBtn: true,
	};

	constructor(
		private cedisService: CedisService,
		private utilsService: UtilsService
	) {}

	ngOnInit() {
		this.getLocations();
	}

	private getLocations () {
		const self = this;
		self.cedisService.getResults().then(
			function (result) {
				self.data = self.cedisService.getVarResults();
				self.ifDataExist = true;
				self.utilsService.debugConsole(result);
			}
		);
	}
}