import { Country } from './country';

export class PromotionTypes {
    public promotionTypeID: number;
    public active: boolean;
    public countryID: number;
    public type: string;
    public country: Country;
    public created_By: string;
    private created_Date: string; 
    private updated_Date: string;

    constructor() {
        this.initalizePromotionTypes();
    }

    initalizePromotionTypes() {
        this.promotionTypeID = null;
        this.active = null;
        this.countryID = null;
        this.type = null;
        this.country = new Country();
        this.created_By = null;
        this.created_Date = null;
        this.updated_Date = null;
    }

    setPromotionTypes(promotionType) {
        this.promotionTypeID = promotionType.promotionTypeID;
        this.active = promotionType.active;
        this.countryID = promotionType.countryID;
        this.type = promotionType.type;
        this.created_By = promotionType.created_By;
        this.created_Date = promotionType.created_Date;
        this.updated_Date = promotionType.updated_Date;
        if (promotionType.hasOwnProperty('country') && promotionType.country !== null) { this.country.setCountry(promotionType.country); }    
    }

    getJsonBase(): any {
        const jsonBase = {
            'active' : this.active,
            'countryID' : this.countryID,
            'type' : this.type,
        };
        return jsonBase;
    }

    getJsonCreate(): any {
        const json = this.getJsonBase();
        if (this.created_By !== null) { json['created_By'] = this.created_By; }
        return json;
    }

    getJsonUpdate(): any {
        const json = this.getJsonBase();
        if (this.created_Date !== null) { json['created_Date'] = this.created_Date; }
        if (this.created_By !== null) { json['created_By'] = this.created_By; }
        if (this.promotionTypeID !== null) { json['promotionTypeID'] = this.promotionTypeID; }
        return json;
    }
}