import { Country } from './country';

export class GiveAwayTypes {
    public giveAwayTypeID: number;
    public active: boolean;
    public countryID: number;
    public type: string;
    public country: Country;
    public created_By: string;
    private created_Date: string; 
    private updated_Date: string;

    constructor() {
        this.initalizeGiveAwayTypes();
    }

    initalizeGiveAwayTypes() {
        this.giveAwayTypeID = null;
        this.active = null;
        this.countryID = null;
        this.type = null;
        this.country = new Country();
        this.created_By = null;
        this.created_Date = null;
        this.updated_Date = null;
    }

    setGiveAwayType(giveAwayType) {
        this.giveAwayTypeID = giveAwayType.giveAwayTypeID;
        this.active = giveAwayType.active;
        this.countryID = giveAwayType.countryID;
        this.type = giveAwayType.type;
        this.created_By = giveAwayType.created_By;
        this.created_Date = giveAwayType.created_Date;
        this.updated_Date = giveAwayType.updated_Date;
        if (giveAwayType.hasOwnProperty('country') && giveAwayType.country !== null) { this.country.setCountry(giveAwayType.country); }    
    }

    getJsonBase(): any {
        const jsonBase = {
            'active' : this.active,
            'countryID' : this.countryID,
            'type' : this.type,
        };
        return jsonBase;
    }

    getJsonCreate(): any {
        const json = this.getJsonBase();
        if (this.created_By !== null) { json['created_By'] = this.created_By; }
        return json;
    }

    getJsonUpdate(): any {
        const json = this.getJsonBase();
        if (this.created_Date !== null) { json['created_Date'] = this.created_Date; }
        if (this.created_By !== null) { json['created_By'] = this.created_By; }
        if (this.giveAwayTypeID !== null) { json['giveAwayTypeID'] = this.giveAwayTypeID; }
        return json;
    }
}