/**
 * Created by Viviana Rincón Cheil on 24/07/2019.
 */
export class PopExecute {

    /*datos para crear reporte */
    public PopExecutionImplementationID: number;
    public CampaignID: number;
    public Cantidad: number;
    public CountryID: number;
    public Description: string;
    public Month: string;
    public PhotoA1: string;
    public PhotoA2: string;
    public PhotoA3: string;
    public PhotoD1: string;
    public PhotoD2: string;
    public PhotoD3: string;
    public PopTypeID: number;
    public ProductGroupID: number;
    public ProductID: number;
    public SiteLocationID: number;
    public SiteStoreID: number;
    public UserId: number;
    public YearWeek: number;
    public CategoryID: number;
    public Created_By: string;
    public Created_Date: string;
    public Updated_Date: string;

    constructor() {
        this.initalizePopExecute();
    }

    initalizePopExecute() {
            this.PopExecutionImplementationID = null;
            this.CampaignID= null;
            this.Cantidad= null;
            this.CountryID= null;
            this.Description= null;
            this.Month= null;
            this.PhotoA1= null;
            this.PhotoA2= null;
            this.PhotoA3= null;
            this.PhotoD1= null;
            this.PhotoD2= null;
            this.PhotoD3= null;
            this.PopTypeID= null;
            this.ProductGroupID= null;
            this.ProductID= null;
            this.SiteLocationID= null;
            this.SiteStoreID= null;
            this.UserId= null;
            this.YearWeek= null;
            this.CategoryID= null;
            this.Created_By= null;
            this.Updated_Date= null;
            this.Created_Date= null;
    }

    setPopExecute(popExecute) {
        this.PopExecutionImplementationID = popExecute.PopExecutionImplementationID;
        this.CampaignID = popExecute.CampaignID;
        this.Cantidad = popExecute.Cantidad;
        this.CountryID = popExecute.CountryID;
        this.Description = popExecute.Description;
        this.Month = popExecute.Month;
        this.PhotoA1 = popExecute.PhotoA1;
        this.PhotoA2 = popExecute.PhotoA2;
        this.PhotoA3 = popExecute.PhotoA3;
        this.PhotoD1 = popExecute.PhotoD1;
        this.PhotoD2 = popExecute.PhotoD2;
        this.PhotoD3 = popExecute.PhotoD3;
        this.PopTypeID = popExecute.PopTypeID;
        this.ProductGroupID = popExecute.ProductGroupID;
        this.ProductID = popExecute.ProductID;
        this.SiteLocationID = popExecute.SiteLocationID;
        this.SiteStoreID = popExecute.SiteStoreID;;
        this.UserId = popExecute.UserId;
        this.YearWeek = popExecute.YearWeek;
        this.CategoryID = popExecute.CategoryID;
        this.Created_By = popExecute.Created_By;
        this.Updated_Date = popExecute.Updated_Date;
        this.Created_Date = popExecute.Created_Date;
    }

    getJsonBase(): any {
        const jsonBase = {
            'popExecute': this.PopExecutionImplementationID
        };
        return jsonBase;
    }

    getJsonCreate(): any {
        const json = this.getJsonBase();
        if (this.Created_By !== null) { json['Created_By'] = this.Created_By; }
        return json;
    }

    getJsonUpdate(): any {
        const json = this.getJsonBase();
        if (this.Created_Date !== null) { json['Created_Date'] = this.Created_Date; }
        if (this.Created_By !== null) { json['Created_By'] = this.Created_By; }
        if (this.PopExecutionImplementationID !== null) { json['PopExecutionImplementationID'] = this.PopExecutionImplementationID; }
        return json;
    }
}